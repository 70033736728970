/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import { FC } from 'react';
import { Tooltip } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../setup/Store';
import { setLanguage } from '../../../../setup/redux/reducers/UtilsReducers';
import OverlayTrigger from '../../../components/OverlayTrigger/OverlayTrigger';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { language } from '../../../i18n/InternalisationProvider';

type LanguageProps = { type?: 'labeled' | 'iconed' | 'dropdown'; dropDownClassName?: string };
const Languages: FC<LanguageProps> = ({
  type = 'labeled',
  dropDownClassName = 'btn btn-link text-white fs-7 fw-light',
}) => {
  const intl = useIntl();
  const languages = [
    {
      lang: 'en',
      name: intl.formatMessage({ id: 'TOPBAR.ENGLISH' }),
      flag: toAbsoluteUrl('/media/flags/united-states.svg'),
    },
    {
      lang: 'fr',
      name: intl.formatMessage({ id: 'TOPBAR.FRENCH' }),
      flag: toAbsoluteUrl('/media/flags/france.svg'),
    },
    {
      lang: 'ar',
      name: intl.formatMessage({ id: 'TOPBAR.ARABIC' }),
      flag: toAbsoluteUrl('/media/flags/saudi-arabia.svg'),
    },
  ];

  const locale = useSelector((state: RootState) => state.utils.locale);
  const dispatch = useDispatch();
  const currentLanguage = languages.find((x) => x.lang === locale);
  return (
    <>
      {type === 'labeled' && (
        <div
          className='menu-item px-5'
          data-kt-menu-trigger='hover'
          data-kt-menu-placement="{default:'bottom-start', md: 'left-start' }"
          data-kt-menu-flip='bottom'>
          <span className='menu-link px-5'>
            <span className='menu-title fw-semibold position-relative'>
              <FormattedMessage id='TOPBAR.LANGUAGE' />
              <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
                {currentLanguage?.name}{' '}
                <img className='w-15px h-15px rounded-1 ms-2' src={currentLanguage?.flag} alt=' ' />
              </span>
            </span>
          </span>

          <div className='menu-sub menu-sub-dropdown w-175px py-4'>
            {languages.map((l) => (
              <div
                className='menu-item px-3'
                key={l.lang}
                onClick={() => {
                  dispatch(setLanguage({ locale: l.lang as language }));
                }}>
                <span
                  className={clsx('menu-link d-flex px-5', {
                    active: l.lang === currentLanguage?.lang,
                  })}>
                  <span className='symbol symbol-20px me-4'>
                    <img className='rounded-1' src={l.flag} alt=' ' />
                  </span>
                  {l.name}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
      {type === 'iconed' && (
        <div className='d-none d-md-flex align-items-center ms-1'>
          <span className='h-20px border-gray-300 border-start me-1'></span>
          <OverlayTrigger
            placement='bottom'
            overlay={
              <Tooltip>
                <FormattedMessage id='TOPBAR.CHANGE-LANGUAGE' />
              </Tooltip>
            }>
            <button
              className={clsx('btn btn-icon btn-icon-muted w-30px h-30px w-md-40px h-md-40px')}
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom'
              data-kt-menu-flip='bottom'>
              <KTSVG
                path={currentLanguage?.flag as string}
                className='svg-icon svg-icon svg-icon-6 svg-icon-gray-800'
              />
              <KTSVG
                path='/media/icons/swiver/opposite-arrows.svg'
                className='svg-icon svg-icon svg-icon-6  ms-1'
              />
            </button>
          </OverlayTrigger>
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold fs-7 w-150px py-4'
            data-kt-menu='true'>
            <div className='menu-item px-3'>
              {languages.map((l) => (
                <div
                  key={l.lang}
                  onClick={() => {
                    dispatch(setLanguage({ locale: l.lang as language }));
                  }}>
                  <span
                    className={clsx('menu-link d-flex px-2', {
                      active: l.lang === currentLanguage?.lang,
                    })}>
                    <span className='symbol symbol-20px me-4'>
                      <img className='rounded-1' src={l.flag} alt=' ' />
                    </span>
                    {l.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <span className='h-20px border-gray-300 border-start ms-1 '></span>
        </div>
      )}
      {type === 'dropdown' && (
        <>
          <OverlayTrigger
            placement='bottom'
            overlay={
              <Tooltip>
                <FormattedMessage id='TOPBAR.CHANGE-LANGUAGE' />
              </Tooltip>
            }>
            <button
              className={clsx(dropDownClassName)}
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom'
              data-kt-menu-flip='bottom'>
              &nbsp;
              <FormattedMessage
                id={
                  currentLanguage?.lang === 'ar' || currentLanguage?.lang === 'fr'
                    ? 'TOPBAR.ENGLISH'
                    : 'TOPBAR.ARABIC'
                }
              />
              &nbsp;
              <span className='symbol symbol-20px mx-2'>
                <img
                  className='rounded-1'
                  src={
                    currentLanguage?.lang === 'ar' || currentLanguage?.lang === 'fr'
                      ? '/media/flags/united-states.svg'
                      : '/media/flags/saudi-arabia.svg'
                  }
                  alt=' '
                />
              </span>
              <i className=' text-white fs-6 fa-solid fa-caret-down'></i>
            </button>
          </OverlayTrigger>
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold fs-7 w-150px py-4'
            data-kt-menu='true'>
            {languages.map((l) => (
              <div
                key={l.lang}
                onClick={() => {
                  dispatch(setLanguage({ locale: l.lang as language }));
                }}
                className='menu-item px-3'>
                <span
                  className={clsx('menu-link d-flex px-2', {
                    active: l.lang === currentLanguage?.lang,
                  })}>
                  <span className='symbol symbol-20px me-4'>
                    <img className='rounded-1' src={l.flag} alt=' ' />
                  </span>
                  {l.name}
                </span>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export { Languages };
