import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Product, ProductResponse } from '../../../app/models/ProductResponse';
import store from '../../Store';
import { toast } from 'react-toastify';
import { createIntl, createIntlCache } from 'react-intl';
import { getProducts, patchProduct, postProduct } from '../effects/ProductEffects';
import { capitalize } from '../../../app/utils/utils';

type ProductQuery = {
  model: string;
  in_stock: boolean;
  barCode: string;
  category: string;
  brand: string;
  tax: string;
  vat: string;
  price_operator: string;
  price: string;
  page?: number;
  query?: string;
  limit?: number;
  enabled?: boolean;
  date_start?: string;
  date_end?: string;
};

export const readProducts = createAsyncThunk(
  'products/fetch',
  async (payload: Partial<ProductQuery | any>, thunkAPI) => {
    const state = store.getState();
    try {
      const { data } = await getProducts({
        enabled: payload.enabled,
        offset: payload.page ? payload.page * state.product.limit - state.product.limit : 0,
        limit: state.product.limit || 10,
        keyword: payload.queryString || '',
        model: payload.model || '',
        in_stock: payload.inStock,
        bar_code: payload.barCode || '',
        category: payload.category || '',
        brand: payload.brand || '',
        tax: payload.tax || '',
        vat: payload.vat || '',
        price_operator: payload.price_operator || '',
        price: payload.price || '',
        date_start: payload.date_start ?? '',
        date_end: payload.date_end ?? '',
      });
      return data as ProductResponse;
    } catch (error: any) {
      thunkAPI.rejectWithValue(error.response.data.message || error?.toJSON()?.message || 'Error');
    }
  }
);
export const addProduct = createAsyncThunk('products/add', async (payload: any, thunkAPI) => {
  try {
    const state = store.getState();
    const cache = createIntlCache();
    const intl = createIntl({ locale: state.utils.locale, messages: state.utils.messages }, cache);
    const { data } = await toast.promise(
      postProduct({ ...payload, company: state.auth?.user?.current_company?.id }),
      {
        pending: intl.formatMessage(
          { id: 'TOAST.ADD.PENDING' },
          { name: payload.name, category: intl.formatMessage({ id: 'PRODUCT.NAME' }) }
        ),
        error: intl.formatMessage(
          { id: 'TOAST.ADD.ERROR' },
          { name: payload.name, category: intl.formatMessage({ id: 'PRODUCT.NAME' }) }
        ),
        success: intl.formatMessage(
          { id: 'TOAST.ADD.SUCCESS' },
          { name: payload.name, category: intl.formatMessage({ id: 'PRODUCT.NAME' }) }
        ),
      }
    );
    return data;
  } catch (error: any) {
    thunkAPI.rejectWithValue(error.response.data.message || error?.toJSON()?.message || 'Error');
  }
});
export const toggleEnabled = createAsyncThunk(
  'products/toggleEnabled',
  async (payload: Product, thunkAPI) => {
    const state = store?.getState();
    const cache = createIntlCache();
    const intl = createIntl({ locale: state.utils.locale, messages: state.utils.messages }, cache);
    try {
      return await toast.promise(patchProduct(payload), {
        pending: capitalize(
          intl.formatMessage(
            { id: !payload.enabled ? 'TOAST.DISABLE.PENDING' : 'TOAST.ENABLE.PENDING' },
            { name: payload.name, category: intl.formatMessage({ id: 'PRODUCT.NAME' }) }
          )
        ),
        success: capitalize(
          intl.formatMessage(
            { id: !payload.enabled ? 'TOAST.DISABLE.SUCCESS' : 'TOAST.ENABLE.SUCCESS' },
            { name: payload.name, category: intl.formatMessage({ id: 'PRODUCT.NAME' }) }
          )
        ),
        error: capitalize(
          intl.formatMessage(
            { id: !payload.enabled ? 'TOAST.DISABLE.ERROR' : 'TOAST.ENABLE.ERROR' },
            { name: payload.name, category: intl.formatMessage({ id: 'PRODUCT.NAME' }) }
          )
        ),
      });
    } catch (error: any) {
      thunkAPI.rejectWithValue(error.response.data.message || error?.toJSON()?.message || 'Error');
    }
  }
);

const productSlice = createSlice({
  name: 'product',
  initialState: {
    products: [] as Product[],
    loading: false,
    offset: 0,
    count: 0,
    countArchived: 0,
    countEnabled: 0,
    limit: 10,
    enabled: true,
  },
  reducers: {
    changeLimit: (state, action) => {
      state.limit = action.payload;
      state.offset = 0;
    },
    setEnabled: (state, action) => {
      state.enabled = action.payload as boolean;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(readProducts.fulfilled, (state, action: any) => {
      state.products = (action.payload?.rows as any[]) || [];
      state.count = action.payload?.count || 0;
      state.countArchived = Number(action.payload?.countArchived) || 0;
      state.countEnabled = Number(action.payload?.countEnabled) || 0;
      const args = action.meta.arg;
      state.offset = args.page ? args.page * state.limit - state.limit : 0;
      state.loading = false;
    });
    builder.addCase(readProducts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(readProducts.rejected, (state, action: any) => {
      state.products = [];
      state.loading = false;
    });
  },
});

export default productSlice.reducer;
export const { changeLimit, setEnabled } = productSlice.actions;
