import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Permissions } from '../../../../app/models/settings/PermissionsResponse';
import { getPermissionsList } from '../../effects/PermissionEffects';

export const readPermissions = createAsyncThunk('permissions/read', async (payload, thunkAPI) => {
  const { data: permissions } = await getPermissionsList();
  return permissions;
});

const permissionSlice = createSlice({
  name: 'permissions',
  initialState: {
    permissions: [] as Permissions[],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(readPermissions.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(readPermissions.fulfilled, (state, action) => {
      state.loading = false;
      state.permissions = action.payload;
    });
    builder.addCase(readPermissions.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default permissionSlice.reducer;
