import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { FormattedMessage } from 'react-intl';
import { Outlet, useLocation } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../helpers';
import { Languages } from '../../../utils/content/header-menu/Languages';
import { MenuComponent } from '../../../utils/ts/components';
import ReactPixel from 'react-facebook-pixel';

function LayoutAuth() {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 100);
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactPixel.init('2270896579623101', undefined, options);
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: window.location.pathname,
    });
    ReactPixel.trackSingle('Auth page', location.pathname);
  }, [location.pathname]);

  return (
    <div
      className='bg-auth h-100'
      style={{
        background: `url(${toAbsoluteUrl('/media/bg/bg-auth.svg')}) no-repeat top center / 100%,
        linear-gradient(305.51deg, #00b5ff -10.62%, #0288d1 47.61%, #01589a 70.44%, #002964 98.99%)`,
      }}>
      <div className='container-md h-100'>
        <div className='d-flex flex-column justify-content-center h-100'>
          <div className='d-none d-md-flex flex-column justify-content-center pb-10'>
            <div className='d-flex justify-content-end'>
              <span className='text-white fw-light align-self-center'>
                <i className='fs-6 me-2 text-white fa-light fa-earth-americas'></i>
                <FormattedMessage id='WALKTHROUGHT.AVAILABLE.LANGUAGE' /> :{' '}
                <Languages type={'dropdown'}></Languages>
              </span>
            </div>
          </div>

          <div className='row'>
            <div className='d-flex d-md-none justify-content-center py-6'>
              <img
                src={toAbsoluteUrl('/media/logos/logo-swiver-white.svg')}
                className='my-md-4 my-10 h-40px'
                alt=''
              />
            </div>
            <div className='d-none d-md-block col-7'>
              <div className='d-flex flex-column gap-4 flex-grow-md-1 align-items-start lh-xxl h-100 justify-content-between max-h-600px'>
                <div>
                  <img
                    src={toAbsoluteUrl('/media/logos/logo-swiver-white.svg')}
                    className='my-md-4 my-10 h-30px h-md-40px  h-lg-65px'
                    alt=''
                  />
                </div>
                <div className='d-flex flex-column gap-3'>
                  <h1 className='fw-bolder  fs-2 fs-lg-2hx text-white'>
                    <FormattedMessage id='AUTH.TITLE' />
                  </h1>
                  <p className='fs-1 lh-base text-white'>
                    <FormattedMessage id='AUTH.SUBTITLE' />.
                  </p>
                </div>
                <div className='d-none d-md-flex gap-4 '>
                  <div className='d-flex gap-4 align-items-center flex-wrap'>
                    <div className='symbol-group symbol-hover symbol-slide  flex-nowrap'>
                      <div className='symbol symbol-circle symbol-50px hover-scale border-0'>
                        <img src={toAbsoluteUrl('/media/avatars/150-9 4.png')} alt='' />
                      </div>
                      <div className='symbol symbol-circle symbol-50px hover-scale border-0'>
                        <img src={toAbsoluteUrl('/media/avatars/300-2 1.png')} alt='' />
                      </div>
                      <div className='symbol symbol-circle symbol-50px hover-scale border-0'>
                        <img src={toAbsoluteUrl('/media/avatars/150-8 1.png')} alt='' />
                      </div>
                      <div className='symbol symbol-circle symbol-50px hover-scale border-0'>
                        <img src={toAbsoluteUrl('/media/avatars/300-3 2.png')} alt='' />
                      </div>
                      <div className='symbol symbol-circle symbol-50px hover-scale border-0'>
                        <img src={toAbsoluteUrl('/media/avatars/150-7 1.png')} alt='' />
                      </div>
                      <div className='symbol symbol-circle symbol-50px hover-scale border-0'>
                        <img src={toAbsoluteUrl('/media/avatars/150-18.jpg')} alt='' />
                      </div>
                      <div className='symbol bg-white symbol-circle symbol-50px hover-scale border-0'>
                        <img src={toAbsoluteUrl('/media/avatars/150-6 1.png')} alt='' />
                      </div>
                      <div className='symbol symbol-circle symbol-50px hover-scale border-0'>
                        <img src={toAbsoluteUrl('/media/avatars/150-5 1.png')} alt='' />
                      </div>
                    </div>
                    <div className='text-white fs-4'>
                      <FormattedMessage id='LOGIN.14K.CUSTOMER.TRUST.SWIVER' />
                    </div>
                  </div>
                </div>
                <div className='d-flex gap-3'>
                  <img src={toAbsoluteUrl('/media/logos/ooredoo.png')} className='h-30px' alt='' />
                  <img src={toAbsoluteUrl('/media/logos/attijari.png')} className='h-30px' alt='' />
                  <img src={toAbsoluteUrl('/media/logos/bt.png')} className='h-30px' alt='' />
                  <img src={toAbsoluteUrl('/media/logos/cct.png')} className='h-30px' alt='' />
                </div>
              </div>
            </div>
            <div className='col-12 col-md-5'>
              <div className='d-flex overflow-auto w-100 bg-body p-6 p-md-10 p-xl-20 rounded-5  min-md-h-600px min-h-420px ms-auto max-w-500px'>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LayoutAuth;
