import { createContext, FC, useContext, useState } from 'react';
import { Balance } from '../models/CashManagementResponse';
import BankDepositWizzard from '../pages/Accounting/BankDepositSteps/BankDepositWizzard';
import SaveOperation from '../pages/Accounting/CashManagement/Balance/SaveOperation';
import AddDepenseWizzard from '../pages/Regulation/AddDepenseWizzard';
const contextDefaultValues = {
  isAddExpenseModalShown: false,
  handleHideExpenseModal: () => {},
  handleShowExpenseModal: (balance: Balance) => {},
  isBankDepositModalShown: false,
  handleHideBankDepositModal: () => {},
  handleShowBankDepositModal: (balance: Balance) => {},
  isSaveTheOperationModalShown: false,
  handleHideSaveTheOperationModal: () => {},
  handleShowSaveTheOperationModal: (balance: Balance) => {},
};
const BalanceModalsContext = createContext(contextDefaultValues);
export const useBalanceModalsContext = () => useContext(BalanceModalsContext);

export const BalanceModalsProvider: FC = ({ children }) => {
  const [isAddExpenseModalShown, setIsAddExpenseModalShown] = useState(false);
  const [isBankDepositModalShown, setIsBankDepositModalShown] = useState(false);
  const [isSaveTheOperationModalShown, setIsSaveTheOperationModalShown] = useState(false);
  const [selectedBalance, setSelectedBalance] = useState<Balance>();
  const handleHideExpenseModal = () => {
    setIsAddExpenseModalShown(false);
  };
  const handleShowExpenseModal = (b: Balance) => {
    setSelectedBalance(b);
    setIsAddExpenseModalShown(true);
  };
  const handleHideBankDepositModal = () => {
    setIsBankDepositModalShown(false);
  };

  const handleShowBankDepositModal = (b: Balance) => {
    setSelectedBalance(b);
    setIsBankDepositModalShown(true);
  };
  const handleHideSaveTheOperationModal = () => {
    setIsSaveTheOperationModalShown(false);
  };
  const handleShowSaveTheOperationModal = (b: Balance) => {
    setSelectedBalance(b);
    setIsSaveTheOperationModalShown(true);
  };

  return (
    <BalanceModalsContext.Provider
      value={{
        handleHideBankDepositModal,
        handleHideExpenseModal,
        handleHideSaveTheOperationModal,
        handleShowBankDepositModal,
        handleShowExpenseModal,
        handleShowSaveTheOperationModal,
        isAddExpenseModalShown,
        isBankDepositModalShown,
        isSaveTheOperationModalShown,
      }}>
      {children}

      <AddDepenseWizzard
        show={isAddExpenseModalShown}
        onHide={handleHideExpenseModal}
        balanceId={selectedBalance?.id}
      />
      <BankDepositWizzard
        balance={selectedBalance}
        show={isBankDepositModalShown}
        onHide={handleHideBankDepositModal}
      />
      <SaveOperation
        balanceId={selectedBalance?.id}
        show={isSaveTheOperationModalShown}
        onHide={handleHideSaveTheOperationModal}
      />
    </BalanceModalsContext.Provider>
  );
};
