import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import StyledCurrency from '../../../components/StyledCurrency/StyledCurrency';
import { KTSVG } from '../../../helpers';
import { Balance } from '../../../models/CashManagementResponse';

const paymentTypes = ['CASH', 'DRAFT', 'CHECK'] as const;
type PaymentType = (typeof paymentTypes)[number];
const BankDepositStep1 = ({ balance }: { balance: Balance }) => {
  const { formatMessage } = useIntl();
  const [payments, setPayments] = useState<
    {
      type: PaymentType;
      amount: number;
    }[]
  >([]);

  useEffect(() => {
    if (!balance) return;
    const balanceContent = balance.contents
      .filter((content) =>
        paymentTypes.includes(content.payment_method.method_abrev as PaymentType)
      )
      .map((content) => ({
        type: content.payment_method.method_abrev as PaymentType,
        amount: Number(content.amount),
      }));
    setPayments(balanceContent);
  }, [balance]);
  return (
    <Controller
      name='depositMethod'
      rules={{ required: 'FORM.ERRORS.REQUIRED' }}
      render={({ field: { name, onBlur, onChange, value }, fieldState: { error, isTouched } }) => (
        <>
          <label className='form-label required mb-3'>
            <FormattedMessage id='BANK.DEPOSIT.TYPE' />
          </label>
          <div className='row w-100 g-4 justify-content-center'>
            {payments.map((payment) => (
              <div key={payment.type} className='col-md-6 col-lg-4'>
                <input
                  type='radio'
                  className='btn-check'
                  name={name}
                  value={payment.type}
                  checked={payment.type === value}
                  onChange={onChange}
                  onBlur={onBlur}
                  disabled={payment.amount <= 0}
                  id={`ADD-EXPENSE-METHOD-${payment.type}`}
                />
                <label
                  className={clsx(
                    'btn btn-outline btn-outline-dashed btn-outline-default  p-7 d-flex align-items-center h-100',
                    payment.type === value && 'border border-primary text-primary active',
                    payment.amount <= 0 && 'bg-secondary'
                  )}
                  htmlFor={`ADD-EXPENSE-METHOD-${payment.type}`}>
                  {payment.type == 'CASH' && (
                    <KTSVG
                      path='/media/icons/duotune/finance/fin003.svg'
                      className='svg-icon svg-icon-3x me-5'
                    />
                  )}
                  {payment.type == 'CHECK' && (
                    <KTSVG
                      path='/media/icons/swiver/check.svg'
                      className='svg-icon svg-icon-3x me-5'
                    />
                  )}
                  {payment.type == 'DRAFT' && (
                    <KTSVG
                      path='/media/icons/duotune/finance/fin006.svg'
                      className='svg-icon svg-icon-3x me-5'
                    />
                  )}

                  <span className='d-block fw-semibold text-start'>
                    <span className='fw-semibold d-block fs-4 mb-2'>
                      <FormattedMessage id={`BALANCE.WIDGET.${payment.type}`} />
                    </span>
                    <span className='text-muted fw-semibold fs-6'>
                      <StyledCurrency
                        amount={payment.amount}
                        amountClassName={clsx(
                          'fw-bold fs-5',
                          payment.amount > 0 ? 'text-primary' : 'text-danger'
                        )}
                        supClassName='fs-8 text-muted fw-bold'
                        containerClassName='d-flex gap-1 align-items-center'
                      />
                    </span>
                  </span>
                </label>
              </div>
            ))}
          </div>
          {error && (
            <div className='text-danger mt-3'>
              <span role='alert'>
                <FormattedMessage
                  id={error.message}
                  values={{
                    field: formatMessage({ id: 'BANK.DEPOSIT.TYPE' }),
                  }}
                />
              </span>
            </div>
          )}
        </>
      )}
    />
  );
};
export { BankDepositStep1 };
