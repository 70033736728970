import { Navigate, Route, Routes } from 'react-router-dom';
import BankModalProvider from '../ModalProviders/BankModal';
import { lazyWithRetry } from '../utils/LazyWithRetry';
import { SuspensedView } from './PrivateRoutes';

const Walkthrough = lazyWithRetry(() => import('../pages/Walkthrough/Walkthrough'));
function WelcomeRoutes() {
  return (
    <Routes>
      <Route
        path='/welcome'
        element={
          <SuspensedView>
            <BankModalProvider>
              <Walkthrough />
            </BankModalProvider>
          </SuspensedView>
        }
      />
      <Route path='*' element={<Navigate to='/welcome' />} />
    </Routes>
  );
}
export default WelcomeRoutes;
