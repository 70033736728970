/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { RootState } from '../../setup/Store';
import { getUser } from '../../setup/redux/reducers/AuthReducer';
import { MasterInit } from '../layout/MasterInit';
import { LayoutSplashScreen } from '../layout/core';
import { Logout } from '../modules/auth';
import { ForgotPassword } from '../modules/auth/components/ForgotPassword';
import LayoutAuth from '../modules/auth/components/LayoutAuth';
import { Login } from '../modules/auth/components/Login';
import { Registration } from '../modules/auth/components/Registration';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { Error404 } from '../modules/errors/components/Error404';
import { Error500 } from '../modules/errors/components/Error500';
import LoadingLottie from '../pages/Lottie/LoadingLottie';
import PrivateRoutes from './PrivateRoutes';
import WelcomeRoutes from './WelcomeRoutes';
import ResetPassword from '../modules/auth/components/ResetPassword';

const AppRoutes: FC = () => {
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const isWalkthrough = useSelector(
    (state: RootState) => state.auth.user?.current_company.is_walkthrough
  );

  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    if (isAuthenticated) dispatch(getUser());
  }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Routes location={location}>
          {!isAuthenticated ? (
            /*Render auth page when user at `/auth` and not authorized.*/
            <Route element={<LayoutAuth />}>
              <Route path='/sign-in' element={<Login />} />
              <Route path='/sign-up' element={<Registration />} />
              <Route path='/forgot-password' element={<ForgotPassword />} />
              <Route path='/reset-password' element={<ResetPassword />} />
              <Route element={<Navigate to='/sign-in' />} />
            </Route>
          ) : (
            /*Otherwise redirect to root page (`/`)*/
            <Route path='/auth' element={<Navigate to='/' />} />
          )}

          <Route path='/error' element={<ErrorsPage />}>
            <Route path='/error/404' element={<Error404 />} />
            <Route path='/error/500' element={<Error500 />} />
            <Route path='/error' element={<Navigate to='/error/404' />} />
            <Route path='*' element={<Navigate to='/error/404' />} />
          </Route>
          <Route path='/logout' element={<Logout />} />

          {!isAuthenticated ? (
            /*Redirect to `/sign-in` when user is not authorized*/
            <Route path='*' element={<Navigate to='/sign-in' />} />
          ) : user ? (
            !isWalkthrough ? (
              <Route path='/*' element={<PrivateRoutes />} />
            ) : (
              <Route path='/*' element={<WelcomeRoutes />} />
            )
          ) : (
            <Route path='/*' element={<LoadingLottie />} />
          )}
        </Routes>
      </Suspense>
      <MasterInit />
    </>
  );
};

export { AppRoutes as Routes };
