import '@formatjs/intl-relativetimeformat/locale-data/ar';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/fr';
import '@formatjs/intl-relativetimeformat/polyfill';
import { FC, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../setup/Store';
import { setMessages } from '../../setup/redux/reducers/UtilsReducers';

const messagesPaths = {
  en: './messages/en.json',
  fr: './messages/fr.json',
  ar: './messages/ar.json',
};
export type language = 'en' | 'fr' | 'ar';
export const supportedLanguages = ['en', 'fr', 'ar'];

const InternalisationProvider: FC = ({ children }) => {
  const locale: language = useSelector((state: RootState) => state.utils.locale);
  const dispatch = useDispatch();
  const messages = useSelector((state: RootState) => state.utils.messages);
  useEffect(() => {
    if (locale && supportedLanguages.includes(locale)) {
      import(`${messagesPaths[locale]}`)
        .then((data) => {
          dispatch(setMessages(data));
        })
        .catch(() => {
          window.location.reload();
        });
    } else {
      import(`${messagesPaths['en']}`)
        .then((data) => {
          dispatch(setMessages(data));
        })
        .catch(() => {
          window.location.reload();
        });
    }
  }, [locale]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export default InternalisationProvider;
