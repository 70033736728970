import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import * as _redux from './setup';
import store from './setup/Store';
// Axios
import axios from 'axios';
import TagManager from 'react-gtm-module';

// Apps
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './app/App';
import { updateTokens } from './setup/redux/reducers/AuthReducer';

// Google analytics
import ReactGA from 'react-ga4';

// Google analytics

const updateJWT = (tokens: { token: string; refreshToken: string }) => {
  store.dispatch(updateTokens(tokens));
};

_redux.setupAxios(axios, store, updateJWT);

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_DSN,
//   release: process.env.REACT_APP_SENTRY_RELEASE,
//   environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
//   integrations: [new BrowserTracing()],

//   tracesSampleRate: 0.1,
// });

// Initialyze GA
ReactGA.initialize('G-2Q9MCLMGL7');

const tagManagerArgs = {
  gtmId: 'GTM-5C7G6KP',
};
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
