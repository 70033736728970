import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { resetPassword, resetPasswordWithToken } from '../../../../setup/redux/effects/AuthEffects';

const initialValues = {
  email: '',
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('FORM.ERRORS.EMAIL')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
});

const newPasswordSchema = Yup.object().shape({
  first: Yup.string().required('FORM.ERRORS.REQUIRED').min(8, 'FORM.ERRORS.MINLENGTH'),
  second: Yup.string().required('FORM.ERRORS.REQUIRED').min(8, 'FORM.ERRORS.MINLENGTH'),
});

export function ForgotPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [searchParams] = useSearchParams();
  const { formatMessage } = useIntl();
  const token = searchParams.get('token');
  const [resetMessage, setResetMessage] = useState('');

  const onTokenSubmit = async (resetPasswordValues: { first: string; second: string }) => {
    try {
      setHasErrors(undefined);
      await resetPasswordWithToken({ token: token || '', ...resetPasswordValues });
      setHasErrors(false);
      setResetMessage(formatMessage({ id: 'PROFILE.RESET_PASSWORD.STATUS.SUCCESS' }));
      setTimeout(() => {
        navigate('/sign-in');
      }, 2000);
    } catch (error) {
      setHasErrors(true);
      setResetMessage(
        (error as AxiosError<{ message: string; code: number }>).response?.data?.message ||
          formatMessage({ id: 'TOAST.ERROR' })
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);
      setTimeout(() => {
        resetPassword(values.email)
          .then(({ data: { result } }) => {
            setHasErrors(false);
            setLoading(false);
            setTimeout(() => {
              navigate('/sign-in');
            }, 2000);
          })
          .catch(() => {
            setHasErrors(true);
            setLoading(false);
            setSubmitting(false);
            setStatus('The login detail is incorrect');
          });
      }, 1000);
    },
  });
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(newPasswordSchema),
    defaultValues: {
      first: '',
      second: '',
    },
  });

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={token ? handleSubmit(onTokenSubmit) : formik.handleSubmit}>
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>
          <FormattedMessage id={token ? 'PROFILE.RESET_PASSWORD' : 'AUTH.FORGET_PASSWORD.TITLE'} />
        </h1>
        {/* end::Title */}

        {/* begin::Link */}
        {!token && (
          <div className='text-gray-400 fw-semibold fs-4'>
            <FormattedMessage id='AUTH.FORGET_PASSWORD.DESC' />
          </div>
        )}
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            {token ? resetMessage : <FormattedMessage id='AUTH.FORGET_PASSWORD.ERROR' />}
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className={clsx('mb-10 p-8 rounded', token ? 'bg-light-info' : 'bg-light-success')}>
          <div className='text-info'>
            {token ? resetMessage : <FormattedMessage id='AUTH.FORGET_PASSWORD.SUCCESS' />}
          </div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='row g-4 mb-10'>
        {token ? (
          <>
            <div>
              <label className='form-label fw-bold text-gray-900 fs-6'>
                <FormattedMessage id='AUTH.FORM.PASSWORD.LABEL' />
              </label>
              <Controller
                name='first'
                control={control}
                render={({ field, fieldState: { error, isTouched } }) => (
                  <>
                    <input
                      type='password'
                      placeholder=''
                      autoComplete='off'
                      {...field}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        { 'is-invalid': isTouched && error },
                        {
                          'is-valid': isTouched && !error,
                        }
                      )}
                    />
                    {isTouched && error && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>
                            <FormattedMessage
                              id={error.message}
                              values={{
                                field: formatMessage({ id: 'AUTH.FORM.PASSWORD.LABEL' }),
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    )}
                  </>
                )}
              />
            </div>
            <div>
              <label className='form-label fw-bold text-gray-900 fs-6'>
                <FormattedMessage id='AUTH.REGISTRATION.CONFIRMPASSWORD.LABEL' />
              </label>
              <Controller
                name='second'
                control={control}
                render={({ field, fieldState: { error, isTouched } }) => (
                  <>
                    <input
                      type='password'
                      placeholder=''
                      autoComplete='off'
                      {...field}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        { 'is-invalid': isTouched && error },
                        {
                          'is-valid': isTouched && !error,
                        }
                      )}
                    />
                    {isTouched && error && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>
                            <FormattedMessage
                              id={error.message}
                              values={{
                                field: formatMessage({
                                  id: 'AUTH.REGISTRATION.CONFIRMPASSWORD.LABEL',
                                }),
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    )}
                  </>
                )}
              />
            </div>
          </>
        ) : (
          <div>
            <label className='form-label fw-bold text-gray-900 fs-6'>
              <FormattedMessage id='AUTH.FORGET_PASSWORD.EMAIL' />
            </label>
            <input
              type='email'
              placeholder=''
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    <FormattedMessage
                      id={formik.errors.email}
                      values={{ field: formik.values.email }}
                    />
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button
          type='submit'
          id='kt_password_reset_submit'
          className='btn btn-lg btn-primary fw-bold me-4'
          disabled={loading || isSubmitting}>
          <span className='indicator-label'>
            <FormattedMessage id='FORM.SUBMIT' />
          </span>
          {loading ||
            (isSubmitting && (
              <span className='indicator-progress'>
                <FormattedMessage id='COMMON.LOADING' />
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ))}
        </button>
        <Link to='/sign-in'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-lg btn-light-primary fw-bold'
            disabled={formik.isSubmitting || !formik.isValid}>
            <FormattedMessage id='FORM.CANCEL' />
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  );
}
