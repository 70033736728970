import { Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../setup/Store';
import OverlayTrigger from '../../OverlayTrigger/OverlayTrigger';

function ListingActions() {
  const listActions = useSelector((state: RootState) => state.page.listActions);

  return (
    <div className='d-flex align-items-center gap-3 ms-auto'>
      {listActions.display?.custom && listActions.customActions}

      {listActions.display?.export && (
        <OverlayTrigger
          placement='bottom'
          overlay={<Tooltip>{listActions?.tooltips?.export}</Tooltip>}>
          <button
            onClick={listActions?.actions?.export}
            className='btn btn-sm btn-sw-outline btn-sw-outline-primary d-flex align-items-center'>
            <i className='fa-light fa-file-export'></i>
            {listActions?.labels?.export}
          </button>
        </OverlayTrigger>
      )}
      {listActions.display?.import && (
        <OverlayTrigger
          placement='bottom'
          overlay={<Tooltip>{listActions?.tooltips?.import}</Tooltip>}>
          <button
            onClick={listActions?.actions?.import}
            className='btn btn-sm btn-sw-outline btn-sw-outline-primary d-flex align-items-center'>
            <i className='fa-light fa-file-import'></i>
            {listActions?.labels?.import}
          </button>
        </OverlayTrigger>
      )}
      {listActions.display?.separator && (
        <span className='h-20px border-gray-300 border-start ms-1'></span>
      )}
      {listActions.display?.add && (
        <OverlayTrigger
          placement='bottom'
          overlay={<Tooltip>{listActions?.tooltips?.add}</Tooltip>}>
          <button
            onClick={listActions?.actions?.add}
            className='btn btn-sm btn-sw-primary d-flex align-items-center'>
            <i className='fa-light fa-file-circle-plus'></i>
            {listActions?.labels?.add}
          </button>
        </OverlayTrigger>
      )}
      {listActions.display?.secondaryAdd && (
        <OverlayTrigger
          placement='bottom'
          overlay={<Tooltip>{listActions?.tooltips?.secondaryAdd}</Tooltip>}>
          <button
            onClick={listActions?.actions?.secondaryAdd}
            className='btn btn-sm btn-sw-primary d-flex align-items-center'>
            <i className='fa-light fa-file-circle-plus'></i>
            {listActions?.labels?.secondaryAdd}
          </button>
        </OverlayTrigger>
      )}
      {listActions.display?.tertiaryAdd && (
        <OverlayTrigger
          placement='bottom'
          overlay={<Tooltip>{listActions?.tooltips?.tertiaryAdd}</Tooltip>}>
          <button
            onClick={listActions?.actions?.tertiaryAdd}
            className='btn btn-sm btn-sw-primary d-flex align-items-center'>
            <i className='fa-light fa-file-circle-plus'></i>
            {listActions?.labels?.tertiaryAdd}
          </button>
        </OverlayTrigger>
      )}
    </div>
  );
}

export default ListingActions;
