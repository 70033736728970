import { lazy, useEffect } from 'react';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Navigate, NavLink, Outlet, Route, Routes } from 'react-router-dom';

import { SuspensedView } from '../../routing/PrivateRoutes';
import { setPageData } from '../../../setup/redux/reducers/PageReducer';
import { lazyWithRetry } from '../../utils/LazyWithRetry';
const ProfileNav = lazyWithRetry(() => import('./ProfileNav'));
const ProfileDetails = lazyWithRetry(() => import('./ProfileDetails'));
const ProfileOverview = lazyWithRetry(() => import('./ProfileOverview'));
const ProfilePasswordReset = lazyWithRetry(() => import('./ProfileSettings'));

export default function ProfileRoutes() {
  return (
    <Routes>
      <Route element={<ProfileMasterLayout />}>
        <Route
          path='/overview'
          element={
            <SuspensedView>
              <ProfileOverview />
            </SuspensedView>
          }
        />
        <Route
          path='/settings'
          element={
            <SuspensedView>
              <ProfilePasswordReset />
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/profile/overview' />} />
      </Route>
    </Routes>
  );
}

const ProfileMasterLayout = () => {
  return (
    <>
      <SuspensedView>
        <div className='d-flex flex-column flex-xl-row'>
          <div className='flex-column flex-lg-row-auto w-100 w-xl-350px mb-10'>
            <ProfileDetails />
          </div>
          <div className='flex-lg-row-fluid ms-lg-15'>
            <ProfileNav />
            <div className='tab-content' id='myTabContent'>
              <div className='tab-pane fade active show' role='tabpanel'>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </SuspensedView>
    </>
  );
};
