import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getBalanceHistory, getBalancesList } from '../effects/BalanceEffects';
import { Balance } from '../../../app/models/CashManagementResponse';
import { delayPromise } from '../../../app/utils/utils';
import store from '../../Store';

export const readBalancesList = createAsyncThunk('balances/get', async () => {
  const state = store?.getState();
  const CurrentCompanyId = state.auth?.user?.current_company?.id!;
  const { data: balances } = await delayPromise(getBalancesList(CurrentCompanyId));
  return balances;
});

export const readBlanceHistory = createAsyncThunk('balance/history/get', async (payload: any) => {
  const { data } = await getBalanceHistory({ ...payload });
  return data;
});

const balanceSlice = createSlice({
  name: 'balance',
  initialState: {
    balanceList: undefined as Balance[] | undefined,
    blanceHistory: undefined as any[] | undefined,
    loading: false,
    count: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(readBalancesList.fulfilled, (state, action) => {
      state.balanceList = action.payload;
      state.loading = false;
    });
    builder.addCase(readBalancesList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(readBlanceHistory.fulfilled, (state, action) => {
      state.blanceHistory = action.payload.rows;
      state.count = action.payload.count;
      state.loading = false;
    });
    builder.addCase(readBlanceHistory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(readBalancesList.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default balanceSlice.reducer;
