import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DocumentQuery } from '../../../app/models/ListDocumentModels';
import { getDocuments, payloadConvert } from '../effects/DocumentsEffects';

export const readDocuments = createAsyncThunk(
  'documents/get',
  async (payload: DocumentQuery | any, thunkAPI) => {
    try {
      const { data: documents } = await getDocuments({
        state: payload?.state || '',
        states: !payload?.states.includes('all') ? payload?.states.join(',') : [] || [],
        offset: payload?.offset || 0,
        limit: payload?.limit || 10,
        type: payload?.type || 0,
        date_start: payload?.dateBegin || '',
        date_end: payload?.dateEnd || '',
        keyword: payload?.keyword || '',
        late: payload?.late || '',
        partial_paid: payload?.partialPaid || '',
        is_credit_note: payload?.isCreditNote || '',
        sort: payload?.sort || '',
        sort_field: payload?.sortField || '',
        contact: payload?.contact || '',
        due_date_start: payload?.due_date_start || '',
        due_date_end: payload?.due_date_end || '',
        converted: payload?.converted || '',
        project: payload?.project || '',
        amount: payload?.amount ?? '',
        amount_operator: payload?.amount_operator || '',
      });

      return documents;
    } catch (error: any) {
      thunkAPI.rejectWithValue(
        error?.response?.data.message || error?.toJSON()?.message || 'Error'
      );
    }
  }
);

export const convertDocument = createAsyncThunk(
  'document/convert',
  async (payload: payloadConvert | null, thunkAPI) => {
    try {
      const document: any = await convertDocument({
        id: payload?.id || 0,
        from: payload?.from || '',
        to: payload?.to || '',
        ids: payload?.ids,
      });

      return document;
    } catch (error: any) {
      thunkAPI.rejectWithValue(
        error?.response?.data.message || error?.toJSON()?.message || 'Error'
      );
    }
  }
);

const documents = createSlice({
  name: 'documents',
  initialState: {
    count: 0,
    documents: [] as any[],
    loading: false,
    offset: 0,
    limit: 10,
    enabled: true,

    amounts: {
      total: {
        TTC: 0,
        HT: 0,
      },
      payed: 0.0,
      not_payed: 0,
      due: 0,
    },
  },
  reducers: {
    setCurrentPageDocuments: (state, action) => {
      state.enabled = action.payload as boolean;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(readDocuments.fulfilled, (state, action) => {
      state.documents = (action.payload?.rows as any[]) || [];
      state.count = action.payload?.count!;
      state.amounts = action.payload?.amounts as any;
      state.loading = false;
      const args = action.meta.arg;
    });
    builder.addCase(readDocuments.rejected, (state, action) => {
      state.documents = [];
      state.loading = false;
    });
    builder.addCase(readDocuments.pending, (state, action) => {
      state.loading = true;
    });
  },
});

export default documents.reducer;
