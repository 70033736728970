import axios, { AxiosResponse } from 'axios';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../setup/Store';
import { delayPromise } from '../../../utils/utils';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';
import { readBalancesList } from '../../../../setup/redux/reducers/BalanceReducer';
import { sweetAlertSuccessConfig } from '../../../utils/configs';
import { Modal } from 'react-bootstrap';
import clsx from 'clsx';
import { BankDepositStep1 } from './BankDepositStep1';
import BankModalProvider from '../../../ModalProviders/BankModal';
import { BankDepositStep2 } from './BankDepositStep2';
import { BankDepositStep3 } from './BankDepositStep3';
import { BankDepositStep4 } from './BankDepositStep4';
import { KTSVG } from '../../../helpers';
import { Balance } from '../../../models/CashManagementResponse';
import { CheckOrDraft } from '../../../models/ChecksAndDraftResponse';

const steps = ['STEP_1', 'STEP_2', 'STEP_3', 'STEP_4'];

const defaultValues = {
  depositMethod: '' as 'CHECK' | 'DRAFT' | 'CASH' | '',
  depositNumber: '',
  date: new Date(),
  bankAccountDestination: '',
  bankDetails: '',
  cashAmount: 1,
  checks: [] as number[],
  checkSum: 0,
  draftSum: 0,
  drafts: [] as number[],
  note: '',
};
const MySwal = withReactContent(Swal);

export default function BankDepositWizzard({
  balance,
  show = false,
  onHide = () => {},
}: {
  balance?: Balance;
  show?: boolean;
  onHide?: () => void;
}) {
  const methods = useForm({ mode: 'all', reValidateMode: 'onChange', defaultValues });
  const dispatch = useDispatch();
  const { formatMessage, formatDate } = useIntl();
  const [currentStep, setCurrentStep] = useState(0);
  const company = useSelector((state: RootState) => state.auth.user?.current_company.id);
  const locale = useSelector((state: RootState) => state.utils.locale);
  const [checks, setChecks] = useState<CheckOrDraft[]>();
  const [drafts, setDrafts] = useState<CheckOrDraft[]>();
  const [isLoadingDraftsOrChecks, setIsLoadingDraftsOrChecks] = useState(false);
  const depositMethod = methods.watch('depositMethod');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const getChecksOrDrafts = async () => {
    try {
      setIsLoadingDraftsOrChecks(true);
      const { data }: AxiosResponse<CheckOrDraft[]> = await delayPromise(
        axios.get<CheckOrDraft[]>('/secure/balance/content/list/payments', {
          params: { balance: balance?.id, method: depositMethod, company },
        })
      );
      if (depositMethod === 'CHECK') setChecks(data);
      else if (depositMethod === 'DRAFT') setDrafts(data);
      setIsLoadingDraftsOrChecks(false);
    } catch (error) {
      setIsLoadingDraftsOrChecks(false);
    }
  };

  const resetForm = () => {
    methods.reset(defaultValues, {
      keepValues: false,
      keepDefaultValues: true,
      keepTouched: false,
    });
    setChecks(undefined);
    setDrafts(undefined);
    setCurrentStep(0);
    setIsLoadingDraftsOrChecks(false);
    setIsSubmitting(false);
  };

  const nextStep = async (values: typeof defaultValues) => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else if (currentStep === steps.length - 1) {
      setIsSubmitting(true);
      try {
        const requestData =
          values.depositMethod === 'CASH'
            ? {
                balanceSource: balance?.id,
                bankAccountDestination: values.bankAccountDestination,
                date: format(values.date, 'yyyy-MM-dd'),
                depositNumber: values.depositNumber,
                note: values.note,
                cashAmount: values.cashAmount,
                company,
              }
            : values.depositMethod === 'DRAFT'
            ? {
                balanceSource: balance?.id,
                bankAccountDestination: values.bankAccountDestination,
                date: format(values.date, 'yyyy-MM-dd'),
                depositNumber: values.depositNumber,
                note: values.note,
                drafts: values.drafts.join(','),
                company,
              }
            : values.depositMethod === 'CHECK'
            ? {
                balanceSource: balance?.id,
                bankAccountDestination: values.bankAccountDestination,
                date: format(values.date, 'yyyy-MM-dd'),
                depositNumber: values.depositNumber,
                note: values.note,
                checks: values.checks.join(','),
                company,
              }
            : {};

        const { data, headers } = await axios.post('/secure/bank-account/deposit', requestData, {
          responseType: 'arraybuffer',
        });
        const blob = new Blob([data], {
          type: headers['content-type'],
        });
        saveAs(
          blob,
          `${values.depositMethod} - ${values.depositNumber} - ${formatDate(new Date())}.pdf`
        );
        dispatch(readBalancesList());
        setIsSubmitting(false);
        MySwal.fire({
          ...sweetAlertSuccessConfig,
          titleText: formatMessage({ id: 'BANK.DEPOSIT.SUCCESS' }),
          cancelButtonText: formatMessage({ id: 'FORM.CLOSE' }),
          showConfirmButton: false,
          timer: 0,
        });
      } catch (error) {
        setIsSubmitting(false);
      }
    }
  };
  const previousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };
  const hideModal = () => {
    resetForm();
    onHide();
  };

  return (
    <Modal size='xl' centered show={show} backdrop='static' onHide={hideModal}>
      <FormProvider {...methods}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id='BANK.DEPOSIT' />
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={methods.handleSubmit(nextStep)}>
          <Modal.Body className='align-items-center'>
            <div className='stepper stepper-links d-flex flex-column pt-15'>
              <div className='stepper-nav mb-5'>
                {steps.map((step, index) => (
                  <div
                    key={index}
                    className={clsx('stepper-item', {
                      current: currentStep === index,
                      completed: currentStep > index,
                    })}>
                    <h3 className='stepper-title'>
                      <FormattedMessage id={`CASH_MANAGEMENT.BANK_DEPOSIT.${step}`} />
                    </h3>
                  </div>
                ))}
              </div>
              <div className='mx-auto mw-800px w-100 pt-15 pb-10'>
                {currentStep === 0 && <BankDepositStep1 balance={balance!} />}
                {currentStep === 1 && (
                  <BankModalProvider>
                    <BankDepositStep2 balance={balance!} />
                  </BankModalProvider>
                )}
                {currentStep === 2 && (
                  <BankModalProvider>
                    <BankDepositStep3
                      balance={balance!}
                      checks={checks}
                      drafts={drafts}
                      getChecksOrDrafts={getChecksOrDrafts}
                      isLoading={isLoadingDraftsOrChecks}
                      method={depositMethod as 'CHECK' | 'DRAFT' | 'CASH'}
                    />
                  </BankModalProvider>
                )}
                {currentStep === 3 && <BankDepositStep4 />}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type='button'
              className='btn btn-light'
              disabled={isSubmitting}
              onClick={previousStep}>
              <FormattedMessage id='FORM.PREVIOUS' />
            </button>
            <button
              type='submit'
              disabled={isLoadingDraftsOrChecks || isSubmitting}
              className='btn btn-lg btn-primary me-3'>
              <span className='indicator-label'>
                {isSubmitting && <span className='spinner-border align-middle me-2'></span>}
                {currentStep < steps.length - 1 && <FormattedMessage id='FORM.CONTINUE' />}
                {!(currentStep < steps.length - 1) && <FormattedMessage id='FORM.SUBMIT' />}
                <KTSVG
                  path={
                    locale === 'ar'
                      ? '/media/icons/duotune/arrows/arr063.svg'
                      : '/media/icons/duotune/arrows/arr064.svg'
                  }
                  className='svg-icon-3 ms-2 me-0'
                />
              </span>
            </button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  );
}
