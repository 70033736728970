import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../setup/Store';
import PhoneDisplayFlag from '../../components/PhoneDisplayFlag/PhoneDisplayFlag';
import { toAbsoluteUrl } from '../../helpers';
import { parsePhoneNumber } from 'libphonenumber-js';

function PreviewCompany() {
  const companySizes = [
    { value: 1, label: '1-10' },
    { value: 2, label: '11-50' },
    { value: 3, label: '51-100' },
    { value: 4, label: '101-500' },
  ];
  const { watch } = useFormContext();
  const logo = watch('logo');

  const companyName = watch('step2.company_name');
  const currentCountry = watch('step2.country');
  const taxRegister = watch('step2.tradeRegister');
  const currency = watch('step2.currency');
  const email = watch('step2.email');
  const address = watch('step2.address');
  const region = watch('step2.region');
  const zipCode = watch('step2.zip_code');
  const vat = watch('step3.vat');
  const taxes = watch('step3.taxes');
  const activity = watch('step4.activity');
  const companySize = watch('step4.company_size');
  const activities = useSelector((state: RootState) => state.utils.activities);
  const contact_phones = watch('contact_phones');

  const listVats = useSelector((state: RootState) => state.utils.vats);
  const listTaxes = useSelector((state: RootState) => state.utils.taxes);
  const [selectedCurrency, setselectedCurrency] = useState<any>(null);

  const currencies = useSelector((state: RootState) => state.utils.currencies);
  useEffect(() => {
    const interval = setInterval(() => {
      if (currencies?.length) {
        const options = currencies.map((currency) => ({
          value: currency.code,
          label: `${currency.code} - ${currency.currency}`,
        }));

        setselectedCurrency(options.find((option) => option.value === currency));
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [currency]);
  const findActivity = (id: any) => {
    return activities?.find((item) => item.id == id)?.name;
  };
  const findVat = (id: number) => {
    return listVats?.find((item) => item.id === id)?.vat;
  };
  const findTaxe = (id: number) => {
    const tax = listTaxes?.find((item) => item.id === id);
    return (
      tax?.name +
      ' ' +
      tax?.amount +
      ' ' +
      (tax?.type == 1 ? process.env.REACT_APP_DEFAULT_CURRENCY || 'TND' : '%')
    );
  };
  return (
    <div className='d-flex flex-column rounded-5 w-100'>
      <div className='d-flex gap-3 rounded-5 bg-primary rounded-bottom-0 p-4'>
        <div>
          <img
            src={logo || toAbsoluteUrl('/media/logos/placeHolder.png')}
            className='w-50px h-50px rounded-2'
            alt=''
          />
        </div>
        <div className='align-self-center'>
          <h4 className='text-white fw-bold'>{companyName}</h4>
          <div className='d-flex'>
            <div className='w-20px'>
              <PhoneDisplayFlag code={currentCountry} />{' '}
            </div>
            <span className='ms-2 fs-4 fw-semibold text-white'>{taxRegister}</span>
          </div>
        </div>
      </div>
      <div className='d-flex flex-column gap-3 rounded-5 bg-body rounded-top-0 p-4 overflow-hidden'>
        {selectedCurrency && (
          <div className='p-4 bg-checkbox rounded-5 animate__animated animate__fadeInUp animate__fast'>
            {selectedCurrency?.label}
          </div>
        )}
        {email && (
          <div className='p-4 bg-checkbox rounded-5 animate__animated animate__fadeInUp animate__fast'>
            <i className='text-primary me-2 fa-regular fa-at'></i> {email}
          </div>
        )}
        {contact_phones.map((phone: any, index: number) => (
          <div
            key={index}
            className='p-4 bg-checkbox rounded-5 animate__animated animate__fadeInUp animate__fast'>
            <i className='text-primary me-2 fa-regular fa-phone'></i>
            <span dir='ltr' style={{ direction: 'ltr' }}>
              {parsePhoneNumber(phone.value)?.formatInternational()}
            </span>
          </div>
        ))}

        {(address || region || zipCode) && (
          <div className='p-4 bg-checkbox rounded-5 animate__animated animate__fadeInUp animate__fast'>
            <i className='text-primary me-2 fa-regular fa-location-dot'></i> {address},{region},
            {zipCode} {currentCountry}
          </div>
        )}

        {(vat.length > 0 || taxes.length > 0) && (
          <span className='py-2'>
            <FormattedMessage id='WALKTHROUGHT.STEPS.TAX_AND_VAT_DETAILS' />
          </span>
        )}

        {vat.length > 0 && (
          <div className='d-flex gap-2 animate__animated animate__fadeInUp animate__fast'>
            {vat.map(
              (item: any, index: number) =>
                listVats?.filter((v: any) => v.id == item.id) && (
                  <div className='p-4 bg-checkbox rounded-5'>{findVat(item)}%</div>
                )
            )}
          </div>
        )}

        {taxes.length > 0 && (
          <div className='d-flex gap-2 pb-3 animate__animated animate__fadeInUp animate__fast'>
            {taxes.map(
              (item: any, index: number) =>
                listTaxes?.filter((t: any) => t.id == item.id) && (
                  <div className='p-4 bg-checkbox rounded-5'>{findTaxe(item)}</div>
                )
            )}
          </div>
        )}

        {activity && (
          <div className='p-4 bg-checkbox rounded-5 animate__animated animate__fadeInUp animate__fast'>
            {' '}
            <i className='text-primary me-2 fa-regular fa-building'></i> {findActivity(activity)}
          </div>
        )}

        {companySize && (
          <div className='p-4 bg-checkbox rounded-5 animate__animated animate__fadeInUp animate__fast'>
            <i className='text-primary me-2 fa-regular fa-users'></i>{' '}
            <FormattedMessage id='NUMBER.OF.EMPLOYEES' /> : {companySizes[companySize - 1]?.label}
          </div>
        )}
      </div>
    </div>
  );
}

export default PreviewCompany;
