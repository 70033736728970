/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx';
import { Header } from './Header';
import { Navbar } from './Navbar';
import { useLayout } from '../../core';

export function HeaderWrapper() {
  const { config, classes } = useLayout();
  if (!config?.app?.header?.display) {
    return null;
  }

  return (
    <div id='kt_app_header' className='app-header'>
      <div
        id='kt_app_header_container'
        className={clsx(
          'app-container flex-grow-1',
          classes?.headerContainer?.join(' '),
          config?.app?.header?.default?.containerClass
        )}>
        {config?.app?.sidebar?.display && (
          <>
            <div
              className='d-flex align-items-center d-lg-none ms-n2 me-2'
              title='Show sidebar menu'>
              <div
                className='btn btn-icon btn-active-color-primary d-flex align-items-center w-35px h-35px'
                id='kt_app_sidebar_mobile_toggle'>
                <i className='fa-regular fa-bars fs-1 text-gray-600'></i>
              </div>
            </div>
          </>
        )}

        <div id='kt_app_header_wrapper' className='d-flex align-items-stretch flex-grow-1'>
          <Header />
          <Navbar />
        </div>
      </div>
    </div>
  );
}
