import { nanoid } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
type CheckBox = {
  checked?: boolean;
  name?: string;
  value?: string | number;
  label?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  children?: React.ReactNode;
};

function CustomSolidCheckbox({
  checked = false,
  name = '',
  value = '',
  onChange = () => {},
  onBlur = () => {},
  children,
}: CheckBox) {
  const [checkBoxId, setCheckBoxId] = useState('');
  useEffect(() => {
    setCheckBoxId(nanoid());
  }, []);
  return (
    <div
      className={clsx(
        'btn btn-outline btn-outline-default p-4 py-auto border-checkbox',
        checked && 'btn-light-primary btn-active-light-primary border border-primary'
      )}>
      <div className='form-check form-check-custom form-check-solid'>
        <input
          type='checkbox'
          className='form-check-input d-none'
          name={name}
          value={value}
          checked={checked}
          onChange={(e) => {
            onChange(e);
          }}
          id={checkBoxId}
          onBlur={onBlur}
        />
        <label htmlFor={checkBoxId} className='form-check-label'>
          {children}
        </label>
      </div>
    </div>
  );
}

export default CustomSolidCheckbox;
