import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import SwPageNavigation from '../../../stories/Organismes/SwPageNavigation/SwPageNavigation';
import { DrawerComponent } from '../../utils/ts/components';
import { useLayout } from '../core';

const Content: React.FC = ({ children }) => {
  const { config, classes } = useLayout();
  const location = useLocation();
  useEffect(() => {
    DrawerComponent.hideAll();
  }, [location]);

  const appContentContainer = config.app?.content?.container;
  return (
    <div
      id='kt_app_content'
      className={clsx(
        'app-content flex-column-fluid',
        classes.content.join(' '),
        config?.app?.content?.class
      )}>
      {appContentContainer ? (
        <div id='kt_app_content_container' className='app-container min-h-100'>
          <SwPageNavigation />
          {children}
        </div>
      ) : (
        <>
          <SwPageNavigation />
          {children}
        </>
      )}
    </div>
  );
};

export { Content };
