import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../setup/Store';
import { getTaxesSwiver, getVatSwiver } from '../../../setup/redux/effects/UtilsEffects';
import { readTaxes, readVats } from '../../../setup/redux/reducers/UtilsReducers';
import CustomSolidCheckbox from '../../components/CustomSolidCheckbox/CustomSolidCheckbox';
import { delayPromise } from '../../utils/utils';

function Step3Wth({
  addCompany = false,
  setIsloading = () => {},
}: {
  addCompany?: boolean;
  setIsloading?: (loading: boolean) => void;
}) {
  const dispatch = useDispatch();
  const { setValue, watch } = useFormContext();
  const listVats = useSelector((state: RootState) => state.utils.vats);
  const listTaxes = useSelector((state: RootState) => state.utils.taxes);
  const isLoadingTaxes = useSelector((state: RootState) => state.utils.loading.taxes);
  const isLoadingVats = useSelector((state: RootState) => state.utils.loading.vats);
  const vat = watch('step3.vat') || [];
  const taxes = watch('step3.taxes') || [];
  const [listTaxesSwiver, setListTaxesSwiver] = useState<any>([]);
  const [listVatSwiver, setListVatSwiver] = useState<any>([]);

  useEffect(() => {
    (async () => {
      if (addCompany) {
        try {
          setIsloading(true);
          const taxesPromise = delayPromise(getTaxesSwiver());
          const vatsPromise = delayPromise(getVatSwiver());
          const [{ data: taxesData }, { data: vatsData }] = await Promise.all([
            taxesPromise,
            vatsPromise,
          ]);
          setListTaxesSwiver(taxesData);
          setListVatSwiver(vatsData);
          setIsloading(false);
        } catch (error) {}
        setIsloading(false);
      } else {
        dispatch(readVats());
        dispatch(readTaxes());
      }
    })();
  }, []);
  useEffect(() => {
    if (addCompany) setIsloading(isLoadingTaxes || isLoadingVats);
  }, [isLoadingTaxes, isLoadingVats, addCompany]);
  return (
    <>
      <div className='animate__animated animate__fadeInLeft animate__fast'>
        <h1>
          <FormattedMessage id='WALKTHROUGHT.STEP3_DESC' />!
        </h1>
        <p>
          <FormattedMessage id='WALKTHROUGHT.STEP3_DESC2' />
        </p>
      </div>
      <div className='px-0 px-md-20  row g-6 animate__animated animate__fadeInRight animate__fast  min-h-400px'>
        <div className='col-12 '>
          <h3 className='text-center py-6'>
            <FormattedMessage id='DOCUMENT.TABLE.VAT' />
          </h3>
          <div className='d-flex justify-content-center gap-3 flex-wrap mb-3'>
            {(addCompany ? listVatSwiver : listVats)?.map((v: any) => (
              <CustomSolidCheckbox
                key={v.id}
                value={v.id}
                onBlur={(e) => null}
                onChange={(e) => {
                  vat.includes(v.id)
                    ? setValue(
                        'step3.vat',
                        vat.filter((item: number) => item !== v.id)
                      )
                    : setValue('step3.vat', [...vat, v.id]);
                }}
                checked={vat?.includes(v.id)}>
                {`${v.vat} %`}
              </CustomSolidCheckbox>
            ))}
          </div>
        </div>
        <div className='col-12 '>
          <h3 className='text-center py-6'>
            <FormattedMessage id='COMMON.TAX.APPLIED' />
          </h3>
          <div className='d-flex justify-content-center gap-3 flex-wrap mb-3'>
            {(addCompany ? listTaxesSwiver : listTaxes)?.map((t: any) => (
              <CustomSolidCheckbox
                key={t.id}
                onChange={(e) => {
                  taxes.includes(t.id)
                    ? setValue(
                        'step3.taxes',
                        taxes.filter((item: number) => item !== t.id)
                      )
                    : setValue('step3.taxes', [...taxes, t.id]);
                }}
                checked={taxes?.includes(t.id)}>
                {`${t.name} ${t.amount} ${
                  t.type == 1 ? process.env.REACT_APP_DEFAULT_CURRENCY || 'TND' : '%'
                }`}
              </CustomSolidCheckbox>
            ))}
          </div>
        </div>
        <div className='col-12 text-center'>
          <span className='text-center'>
            <i className='me-2 text-primary fa-light fa-lightbulb'></i>
            <FormattedMessage id='WALKTHROUGHT.STEP3.HINT' />.
          </span>
        </div>
      </div>
    </>
  );
}

export default Step3Wth;
