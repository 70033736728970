import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import QuickAddCustomerSupplierAndProductModalsProvider from '../../ModalContexts/QuickAddCustomerSupplierAndProductModalsContext';
import { SuspensedView } from '../../routing/PrivateRoutes';
import { lazyWithRetry } from '../../utils/LazyWithRetry';
import DocumentStateProvider from './DocumentStateProvider';
const Document = lazyWithRetry(() => import('./Document'));
export default function DocumentRoutes() {
  return (
    <Routes>
      <Route element={<DocumentMasterLayout />}>
        <Route
          path='/:id'
          element={
            <DocumentStateProvider>
              <QuickAddCustomerSupplierAndProductModalsProvider>
                <SuspensedView>
                  <Document />
                </SuspensedView>
              </QuickAddCustomerSupplierAndProductModalsProvider>
            </DocumentStateProvider>
          }
        />
      </Route>
      <Route path='/' element={<Navigate to='/home' />}></Route>
    </Routes>
  );
}

const DocumentMasterLayout = () => {
  return <Outlet />;
};
