import axios from 'axios';
import clsx from 'clsx';
import DOMPurify from 'dompurify';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import paymentLoadingAnimation from '../../assets/payment_loading.json';
import BillingAddress from './BillingAddress';
import BillingDetails from './BillingDetails';
import CustomerBilling from './CustomerBilling';
import GatwayPayment from './GatwayPayment';
import Lottie from '../../components/Lottie';
import { RootState } from '../../../setup/Store';
import {
  getUserPlan,
  getPlanPrice,
  getLinkPayment,
  getPaymentGateways,
} from '../../../setup/redux/effects/UtilsEffects';
import { setPageData } from '../../../setup/redux/reducers/PageReducer';
import { readSubscriptions } from '../../../setup/redux/reducers/SubscriptionsReducer';
import LeftStickyBar from '../../components/CardWithStickyBar/CardWithStickyBar';
import { KTSVG } from '../../helpers';
import { sweetAlertSuccessConfig } from '../../utils/configs';

const MySwal = withReactContent(Swal);

const Pricing = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formatMessage } = intl;
  const [maxCompany, setmaxCompany] = useState(0);
  const [maxUser, setmaxUser] = useState(0);
  const [minCompany, setminCompany] = useState(0);
  const [minUser, setminUser] = useState(0);
  const [basicPlan, setbasicPlan] = useState<any>(null);
  const creditSMS: any = useSelector((state: RootState) =>
    state.auth.user?.credits.filter((item) => item.resource === 'PACK_SMS')
  );
  const creditUser: any = useSelector((state: RootState) =>
    state.auth.user?.credits.filter((item) => item.resource === 'EXTRA_USER')
  );
  const creditCompany: any = useSelector((state: RootState) =>
    state.auth.user?.credits.filter((item) => item.resource === 'EXTRA_COMPANY')
  );
  const currentPlant = useSelector((state: RootState) => state.auth.user?.current_plan);
  const currentUser = useSelector((state: RootState) => state.auth.user);
  const [extratCompany, setextratCompany] = useState(0);
  const [extratUser, setextratUser] = useState(0);
  const [nbrCompany, setnbrCompany] = useState(minCompany + extratCompany || 0);
  const [paymentType, setPaymentType] = useState('card');
  const [step, setStep] = useState('plan');
  const [nbrUser, setnbrUser] = useState(minUser + extratUser || 1);
  const [nbrSMS, setnbrSMS] = useState(20);
  const [banktransferAmount, setBanktransferAmount] = useState<number>();
  const [bankTransferCurrency, setBankTransferCurrency] = useState<string>();
  const [bankTransferPaymentId, setBankTransferPaymentId] = useState<number>();
  const [monthlyPrice, setmonthlyPrice] = useState([]);
  const [yearlyPrice, setyearlyPrice] = useState([]);
  const [paymentGateway, setpaymentGateway] = useState<any>(null);
  const [total, settotal] = useState({
    totalMonthly: 0,
    totalYearly: 0,
    type: 'yearly',
    monthlyPrices: {
      price: 0,
      nbrCompany: 0,
      nbrUser: 0,
      nbrSMS: 0,
      priceExtraCompany: 0,
      priceExtraUser: 0,
      priceExtraSMS: 0,
    },
    yearlyPrices: {
      price: 0,
      nbrCompany: 0,
      nbrUser: 0,
      nbrSMS: 0,
      priceExtraCompany: 0,
      priceExtraUser: 0,
      priceExtraSMS: 0,
    },
    coupon: '',
  });
  const [isBankTransferModalShown, setIsBankModalShown] = useState(false);
  const fileRef = useRef<HTMLInputElement>(null);
  const subscriptionQuery = useSelector((state: RootState) => state.subscription.query);
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: {
      taxNumber: currentUser?.current_company?.setting.tax_number,
      address: currentUser?.current_company?.setting.address,
    },
  });
  const { watch } = methods;
  const {
    register: registerBank,
    handleSubmit: handleBankSubmit,
    control: bankControl,
    formState: { isValid: isBankFormValid, errors: bankFormErrors, isSubmitting: isBankSubmitting },
  } = useForm({ mode: 'onTouched', reValidateMode: 'onSubmit' });
  const submitBank = async (bankFormData: FieldValues) => {
    if (isBankSubmitting) return;
    try {
      const formData = new FormData();

      formData.append('bankTransferNumber', bankFormData.ref);
      formData.append('file', bankFormData.file[0]);
      await axios.post(`/secure/user/purchases/edit/${bankTransferPaymentId}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      MySwal.fire({
        ...sweetAlertSuccessConfig,
        title: formatMessage({ id: 'Document updated successfully' }),
        text: formatMessage({ id: 'COMMON.SUCCESS' }),
        confirmButtonText: formatMessage({ id: 'COMMON.OK' }),

        showDenyButton: false,
        showCancelButton: false,
      });
      dispatch(readSubscriptions(subscriptionQuery));
      setTimeout(() => {
        navigate('/profile/overview');
      }, 500);
    } catch (error) {}
  };

  const formattedText = useMemo(() => {
    const text =
      banktransferAmount === undefined
        ? ''
        : formatMessage({ id: 'PRICING.BANK_TRANSFER.MESSAGE' });
    const boldAmount = `<strong>${intl.formatNumber(banktransferAmount || 0, {
      style: 'currency',
      currency: bankTransferCurrency,
    })}</strong>`;
    const replacedText = text.replace(/\$amount\$/g, boldAmount);
    return DOMPurify.sanitize(replacedText);
  }, [banktransferAmount]);

  useEffect(() => {
    getUserPlan().then((res: any) => {
      if (res.data) {
        let basicPlan = res.data.find((a: any) => a.name === 'BASIC');
        setextratCompany(basicPlan.extra_company);
        setextratUser(basicPlan.extra_user);
        setmaxCompany(basicPlan.max_company);
        setmaxUser(basicPlan.max_user);
        setbasicPlan(basicPlan);
        setminCompany(basicPlan.extra_company + basicPlan.max_company);
        setminUser(basicPlan.extra_user + basicPlan.max_user);
        setnbrCompany(basicPlan.extra_company + basicPlan.max_company);
        setnbrUser(basicPlan.extra_user + basicPlan.max_user);
        getYearlyPrice();
      }
    });

    dispatch(
      setPageData({
        title: <FormattedMessage id='PRICING.TITLE' />,
        breadcrumbs: [
          {
            path: '/',
            title: formatMessage({ id: 'TOOLBAR.BREADCRUMBS.HOME' }),
          },
        ],
        actionBar: 'noActions',
      })
    );
  }, []);
  const [coupon, setcoupon] = useState('');
  const [data, setdata] = useState({
    type: 1,
    address: currentUser?.current_company?.setting.address,
    paymentType: paymentType === 'card' ? 1 : 2,
    period: total.type == 'monthly' ? 1 : 12,
    periodType: total.type == 'monthly' ? 'MONTHLY' : 'YEARLY',
    coupon: coupon,
    packSms: nbrSMS,
    extraCompany: nbrCompany - minCompany + maxCompany,
    extraUser: nbrUser - minUser + maxUser,
    bankTransferNumber: '',
    user: currentUser?.id,
    plan: basicPlan?.id || 3,
    currency: process.env.REACT_APP_DEFAULT_CURRENCY || 'TND',
    taxNumber: currentUser?.current_company?.setting.tax_number,
    paymentGateway: null,
  });
  const [previewImage, setPreviewImage] = useState<string | null | 'pdf'>();
  const getYearlyPrice = () => {
    getPlanPrice('YEARLY').then((res: any) => {
      setyearlyPrice(res.data);
      let packSms = res.data.filter((item: any) => item.code === 'PACK_SMS')[0].amount_ht;
      let extraCompany = res.data.filter((item: any) => item.code === 'EXTRA_COMPANY')[0].amount_ht;
      let extratUser = res.data.filter((item: any) => item.code === 'EXTRA_USER')[0].amount_ht;
      let price = res.data.filter((item: any) => item.code === 'PREMIUM_SUBSCRIPTION')[0].amount_ht;
      settotal({
        ...total,
        type: 'yearly',
        yearlyPrices: {
          price: price,
          nbrCompany: 0,
          nbrUser: 0,
          nbrSMS: 0,
          priceExtraCompany: extraCompany,
          priceExtraUser: extratUser,
          priceExtraSMS: packSms,
        },
      });
    });
  };
  const getMonthlyPrice = () => {
    getPlanPrice('MONTHLY').then((res: any) => {
      setmonthlyPrice(res.data);
      let packSms = res.data.filter((item: any) => item.code === 'PACK_SMS')[0].amount_ht;
      let extraCompany = res.data.filter((item: any) => item.code === 'EXTRA_COMPANY')[0].amount_ht;
      let extratUser = res.data.filter((item: any) => item.code === 'EXTRA_USER')[0].amount_ht;
      let price = res.data.filter((item: any) => item.code === 'PREMIUM_SUBSCRIPTION')[0].amount_ht;
      settotal({
        ...total,
        type: 'monthly',
        monthlyPrices: {
          price: price,
          nbrCompany: 0,
          nbrUser: 0,
          nbrSMS: 0,
          priceExtraCompany: extraCompany,
          priceExtraUser: extratUser,
          priceExtraSMS: packSms,
        },
      });
    });
  };
  const [totalAmount, settotalAmount] = useState(0);
  const [totalMonthly, settotalAmountMonthly] = useState(0);
  const [showLoading, setshowLoading] = useState(false);
  const totalPrice = () => {
    let totalAmount = basicPlan
      ? total.type == 'monthly'
        ? total.monthlyPrices.price +
          (nbrCompany - maxCompany) * total.monthlyPrices.priceExtraCompany +
          (nbrUser - maxUser) * total.monthlyPrices.priceExtraUser +
          ((nbrSMS - 20) / 1000) * total.monthlyPrices.priceExtraSMS
        : total.yearlyPrices.price * 12 +
          (nbrCompany - maxCompany) * total.yearlyPrices.priceExtraCompany * 12 +
          (nbrUser - maxUser) * total.yearlyPrices.priceExtraUser * 12 +
          ((nbrSMS - 20) / 1000) * total.yearlyPrices.priceExtraSMS
      : 0;
    let totalAmountMonthly = basicPlan
      ? total.type == 'monthly'
        ? total.monthlyPrices.price +
          (nbrCompany - maxCompany) * total.monthlyPrices.priceExtraCompany +
          (nbrUser - maxUser) * total.monthlyPrices.priceExtraUser +
          ((nbrSMS - 20) / 1000) * total.monthlyPrices.priceExtraSMS
        : total.yearlyPrices.price +
          (nbrCompany - maxCompany) * total.yearlyPrices.priceExtraCompany +
          (nbrUser - maxUser) * total.yearlyPrices.priceExtraUser +
          ((nbrSMS - 20) / 1000) * total.yearlyPrices.priceExtraSMS
      : 0;

    settotalAmount(totalAmount);
    settotalAmountMonthly(totalAmountMonthly);
    return totalAmount;
  };
  useEffect(() => {
    totalPrice();
  }, [total.type]);
  const onChangeType = (paymentmethod: any, paymentGatway: any) => {
    setPaymentType(paymentmethod);
    setpaymentGateway(paymentGatway);
  };
  const taxNumber = watch('taxNumber');
  const address = watch('address');
  const paymentGenerate = () => {
    setshowLoading(true);
    let dataPayment = data;
    data.extraUser = nbrUser - minUser + extratUser;
    data.extraCompany = nbrCompany - minCompany + extratCompany;
    data.plan = basicPlan?.id;
    data.coupon = coupon;
    dataPayment.packSms = nbrSMS - 20;
    dataPayment.paymentGateway = paymentGateway;
    dataPayment.address = address;
    dataPayment.taxNumber = taxNumber;
    dataPayment.period = total.type == 'monthly' ? 1 : 12;
    dataPayment.periodType = total.type == 'monthly' ? 'MONTHLY' : 'YEARLY';
    dataPayment.paymentType = paymentType == 'transaction' ? 2 : 1;
    getLinkPayment(data)
      .then((res: any) => {
        setshowLoading(false);
        if (paymentType !== 'transaction') window.location.href = res.data.message;
        else {
          setIsBankModalShown(true);
          setBanktransferAmount(res?.data?.amount_ttc || 0);
          setBankTransferCurrency(
            res?.data?.currency || process.env.REACT_APP_DEFAULT_CURRENCY || 'TND'
          );
          setBankTransferPaymentId(res?.data?.id);

          setshowLoading(false);
        }
      })
      .catch((e) => {
        setshowLoading(false);
        toast.error(formatMessage({ id: 'TOAST.ERROR' }));
      });
  };

  useEffect(() => {
    totalPrice();
  }, [basicPlan, nbrCompany, nbrUser, nbrSMS, total]);
  const [paymentGatway, setpaymentGatway] = useState<any>([]);
  useEffect(() => {
    getPaymentGateways({
      country: currentUser?.current_company.setting.country,
      currency: currentUser?.current_company.setting.default_currency,
    }).then((res: any) => {
      setpaymentGatway(res.data);
      setpaymentGateway(res.data[0]?.id);
    });
  }, [currentUser]);
  const validateFile = (value: FileList) => {
    if (!value || value?.length === 0) {
      return 'FORM.ERRORS.NO_FILE';
    }

    const file = value?.[0];

    if (!file.type.startsWith('image/') && file.type !== 'application/pdf') {
      return 'FORM.ERRORS.NOT_IMAGE_OR_PDF';
    }
    return true;
  };
  return (
    <div>
      {step === 'plan' && (
        <div className='card'>
          <div className='card-body p-lg-17'>
            <div className='d-flex flex-column'>
              <div className='mb-13 text-center'>
                <h1 className='fs-2hx fw-semibold mb-5'>
                  <FormattedMessage id='PRICING.STEP1.CHOSE_PLAN_TITLE' />
                </h1>
                <div className='text-gray-400 fw-semibold fs-5'>
                  <FormattedMessage id='PRICING.STEP1.CHOSE_PLAN_DESC_LIGNE_1' />
                  <br />
                  <FormattedMessage id='PRICING.STEP1.CHOSE_PLAN_DESC_LIGNE2' />
                  <br />
                  <span style={{ fontWeight: 'bold' }}>
                    <FormattedMessage id='PRICING.STEP1.SATISFIED' />
                  </span>{' '}
                  <FormattedMessage id='PRICING.STEP1.OR' />{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    <FormattedMessage id='PRICING.STEP1.REFUNDED' />
                  </span>{' '}
                  <FormattedMessage id='PRICING.STEP1.CHOSE_PLAN_DESC_LIGNE3' />
                </div>
              </div>

              <div className='row justify-content-center'>
                <div className='col-xl-4 p-0'>
                  <div className='d-flex h-100 align-items-center'>
                    <div className='w-100 d-flex flex-column flex-center rounded-3 bg-primary   text-white py-20 px-10'>
                      <div className='mb-7 w-100'>
                        <h1 className='text-white mb-5 fw-bold'>
                          <FormattedMessage id='PRICING.STEP1.PLAN.TITLE' />
                        </h1>
                        <div className='text-white fw-semibold mb-5'>
                          <FormattedMessage id='PRICING.STEP1.PLAN.DOC_LIMITS' />
                          <br />
                          <FormattedMessage id='PRICING.STEP1.PLAN.DOC_DEF' />
                        </div>
                        <div className='mb-7'>
                          <span className='fs-3x '>
                            {total.type == 'monthly'
                              ? total.monthlyPrices.price
                              : total.yearlyPrices.price}
                          </span>
                          <span className='mb-2 '>
                            {data.currency} <FormattedMessage id='COMMON.TAX.EXCL-TAXES' />
                          </span>
                          <span className='fs-7 fw-semibold '>
                            /
                            <span data-kt-element='period'>
                              <FormattedMessage id='PRICING.STEP1.PLAN.ABRV_MONTH' />
                            </span>
                          </span>
                        </div>
                        <span className='badge badge-danger badge-square badge-sm '>
                          <FormattedMessage id='PRICING.STEP1.PLAN.INVOICE_GENERATE' />
                        </span>
                      </div>
                      <div className='w-100 '>
                        <div className='d-flex align-items-center mb-5'>
                          <span className='fw-semibold fs-6  flex-grow-1 pe-3'>
                            <FormattedMessage id='PRICING.STEP1.PLAN.DETAILS.ULTIMITED_DOCUMENTS' />
                          </span>
                          <span className='svg-icon svg-icon-1 svg-icon-white'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen043.svg'
                              className='svg-icon'
                            />
                          </span>
                        </div>
                        <div className='d-flex align-items-center mb-5'>
                          <span className='fw-semibold fs-6  flex-grow-1 pe-3'>
                            <FormattedMessage id='PRICING.STEP1.PLAN.DETAILS.STOCK_MANAGEMENT' />
                          </span>
                          <span className='svg-icon svg-icon-1 svg-icon-white'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen043.svg'
                              className='svg-icon'
                            />
                          </span>
                        </div>
                        <div className='d-flex align-items-center mb-5'>
                          <span className='fw-semibold fs-6  flex-grow-1 pe-3'>
                            <FormattedMessage id='PRICING.STEP1.PLAN.DETAILS.ACCOUNT_CUSTOMIZATION' />
                          </span>
                          <span className='svg-icon svg-icon-1 svg-icon-white'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen043.svg'
                              className='svg-icon'
                            />
                          </span>
                        </div>
                        <div className='d-flex align-items-center mb-5'>
                          <span className='fw-semibold fs-6  flex-grow-1 pe-3'>
                            <FormattedMessage id='PRICING.STEP1.PLAN.DETAILS.SALE_MANAGEMENT' />
                          </span>
                          <span className='svg-icon svg-icon-1 svg-icon-white'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen043.svg'
                              className='svg-icon'
                            />
                          </span>
                        </div>
                        <div className='d-flex align-items-center mb-5'>
                          <span className='fw-semibold fs-6  flex-grow-1 pe-3'>
                            <FormattedMessage id='PRICING.STEP1.PLAN.DETAILS.CASH_MANAGEMENT' />
                          </span>
                          <span className='svg-icon svg-icon-1 svg-icon-white'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen043.svg'
                              className='svg-icon'
                            />
                          </span>
                        </div>
                        <div className='d-flex align-items-center mb-5'>
                          <span className='fw-semibold fs-6  flex-grow-1 pe-3'>
                            <FormattedMessage id='PRICING.STEP1.PLAN.DETAILS.TECHNICAL_SUPPORT' />
                          </span>
                          <span className='svg-icon svg-icon-1 svg-icon-white'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen043.svg'
                              className='svg-icon'
                            />
                          </span>
                        </div>
                        <div className='d-flex align-items-center mb-5'>
                          <span className='fw-semibold fs-6  flex-grow-1 pe-3'>
                            <FormattedMessage id='PRICING.STEP1.PLAN.DETAILS.PURCHASE_MANAGEMENT' />
                          </span>
                          <span className='svg-icon svg-icon-1 svg-icon-white'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen043.svg'
                              className='svg-icon'
                            />
                          </span>
                        </div>
                        <div className='d-flex align-items-center mb-5'>
                          <span className='fw-semibold fs-6  flex-grow-1 pe-3'>
                            <FormattedMessage id='PRICING.STEP1.PLAN.DETAILS.DASHBOARD' />
                          </span>
                          <span className='svg-icon svg-icon-1 svg-icon-white'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen043.svg'
                              className='svg-icon'
                            />
                          </span>
                        </div>
                        <div className='d-flex align-items-center mb-5'>
                          <span className='fw-semibold fs-6  flex-grow-1 pe-3'>
                            <FormattedMessage id='PRICING.STEP1.PLAN.DETAILS.FLOW_REGULATION' />
                          </span>
                          <span className='svg-icon svg-icon-1 svg-icon-white'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen043.svg'
                              className='svg-icon'
                            />
                          </span>
                        </div>
                        <div className='d-flex align-items-center mb-5'>
                          <span className='fw-semibold fs-6  flex-grow-1 pe-3'>
                            <FormattedMessage id='PRICING.STEP1.PLAN.DETAILS.REMINDER_SMS' />
                          </span>
                          <span className='svg-icon svg-icon-1 svg-icon-white'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen043.svg'
                              className='svg-icon'
                            />
                          </span>
                        </div>
                        <div className='d-flex align-items-center mb-5'>
                          <span className='fw-semibold fs-6  flex-grow-1 pe-3'>
                            <FormattedMessage id='PRICING.STEP1.PLAN.DETAILS.COMPANIES' />
                          </span>

                          <span className='svg-icon svg-icon-1 svg-icon-white'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen043.svg'
                              className='svg-icon'
                            />
                          </span>
                        </div>
                        <div className='d-flex align-items-center mb-5'>
                          <span className='fw-semibold fs-6  flex-grow-1 pe-3'>
                            <FormattedMessage id='PRICING.STEP1.PLAN.DETAILS.USERS' />
                          </span>

                          <span className='svg-icon svg-icon-1 svg-icon-white'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen043.svg'
                              className='svg-icon'
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-xl-4 p-0 shaddow'>
                  <div className='d-flex h-100 align-items-center'>
                    <div className='w-100 d-flex flex-column flex-center rounded-3 rounded-start-0 border py-15 px-10'>
                      <div className='mb-7 w-100'>
                        <h1 className=' mb-5 fw-bold'>
                          <FormattedMessage id='PRICING.STEP1.PLAN.EXTRAS.CUSTOM_PLAN' />
                        </h1>
                        <div className=' fw-semibold mb-5'>
                          <FormattedMessage id='PRICING.STEP1.PLAN.EXTRAS.DESC' />

                          <br />
                        </div>
                      </div>
                      <div className='w-100 mb-5'>
                        <div className='d-flex align-items-center mb-5'>
                          <span className='fw-semibold fs-4  flex-grow-1 pe-3'>
                            <FormattedMessage id='PRICING.STEP1.PLAN.EXTRAS.EXTRAS' />
                          </span>
                        </div>

                        <div className='d-flex align-items-center mb-5'>
                          <span className='fw-semibold fs-6  flex-grow-1 pe-3'>
                            <FormattedMessage id='PRICING.STEP1.PLAN.EXTRAT.COMPANIES' />
                          </span>
                          <div>
                            <div className='input-group'>
                              <button
                                type='button'
                                className='btn btn-icon btn-light-primary'
                                disabled={minCompany == nbrCompany}
                                onClick={() => {
                                  minCompany < nbrCompany && setnbrCompany(nbrCompany - 1);
                                }}>
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr089.svg'
                                  className='svg-icon svg-icon-primary svg-icon-1'
                                />
                              </button>
                              <input
                                type='number'
                                value={nbrCompany}
                                className={clsx(
                                  'form-control form-control form-control-solid flex-grow-1 w-60px text-center bg-body input-number'
                                )}
                                autoComplete='off'
                              />
                              <button
                                type='button'
                                className='btn btn-icon btn-light-primary'
                                onClick={() => {
                                  setnbrCompany(nbrCompany + 1);
                                }}>
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr087.svg'
                                  className='svg-icon svg-icon-primary svg-icon-1'
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className='d-flex align-items-center mb-5'>
                          <span className='fw-semibold fs-6  flex-grow-1 pe-3'>
                            <FormattedMessage id='PRICING.STEP1.PLAN.EXTRAT.USERS' />
                          </span>
                          <div>
                            <div className='input-group'>
                              <button
                                type='button'
                                className='btn btn-icon btn-light-primary'
                                disabled={minUser == nbrUser}
                                onClick={() => {
                                  minUser < nbrUser && setnbrUser(nbrUser - 1);
                                }}>
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr089.svg'
                                  className='svg-icon svg-icon-primary svg-icon-1'
                                />
                              </button>
                              <input
                                type='number'
                                min={2}
                                value={nbrUser}
                                className={clsx(
                                  'form-control form-control form-control-solid flex-grow-1 w-60px text-center bg-body input-number'
                                )}
                                autoComplete='off'
                              />
                              <button
                                type='button'
                                className='btn btn-icon btn-light-primary'
                                onClick={() => {
                                  setnbrUser(nbrUser + 1);
                                }}>
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr087.svg'
                                  className='svg-icon svg-icon-primary svg-icon-1'
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className='d-flex align-items-center mb-7'>
                          <span className='fw-semibold fs-6  flex-grow-1 pe-3'>
                            <FormattedMessage id='PRICING.STEP1.PLAN.EXTRAT.SMS' />
                          </span>
                          <div>
                            <div className='input-group'>
                              <button
                                type='button'
                                className='btn btn-icon btn-light-primary'
                                disabled={20 == nbrSMS}
                                onClick={() => {
                                  nbrSMS > 20 && setnbrSMS(nbrSMS - 1000);
                                }}>
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr089.svg'
                                  className='svg-icon svg-icon-primary svg-icon-1'
                                />
                              </button>
                              <input
                                type='number'
                                min={20}
                                value={nbrSMS}
                                className={clsx(
                                  'form-control form-control form-control-solid flex-grow-1 w-60px text-center bg-body input-number'
                                )}
                                autoComplete='off'
                              />
                              <button
                                type='button'
                                className='btn btn-icon btn-light-primary'
                                onClick={() => {
                                  setnbrSMS(nbrSMS + 1000);
                                }}>
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr087.svg'
                                  className='svg-icon svg-icon-primary svg-icon-1'
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className='d-flex align-items-center mb-5'>
                          <span className='fw-semibold fs-4 text-primary flex-grow-1 pe-3'>
                            <FormattedMessage id='PRICING.STEP1.PLAN.EXTRAT.TOTAL' />
                          </span>
                          <div className='text-primary'>
                            <span className='fs-3x  '>{totalMonthly}</span>
                            <span className='mb-2 '>
                              {data.currency} <FormattedMessage id='COMMON.TAX.EXCL-TAXES' />
                            </span>
                            <span className='fs-7 fw-semibold '>
                              /
                              <span data-kt-element='period'>
                                <FormattedMessage id='PRICING.STEP1.PLAN.EXTRAT.MONTH_ABRV' />
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className='w-100 position-relative mb-7'>
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            value={coupon}
                            onChange={(e) => setcoupon(e.target.value)}
                            placeholder={intl.formatMessage({ id: 'PRICING.DISCOUNT.CODE' })}
                          />
                        </div>
                        <div className='d-flex justify-content-center'>
                          <button
                            type='button'
                            className='btn btn-lg btn-primary fw-bold'
                            onClick={() => {
                              setStep('payment');
                            }}>
                            <span className='indicator-label'>
                              <FormattedMessage id='PRICING.STEP1.PLAN.EXTRAT.CONFIRM' />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {step == 'payment' && (
        <FormProvider {...methods}>
          <LeftStickyBar
            contentClassNames='d-flex flex-column flex-column-fluid'
            sideBarPosition='end'
            sideBarHeader={
              <div className='card-title'>
                <h2>
                  <FormattedMessage id='PRICING.BILLING.DETAILS' />
                </h2>
              </div>
            }
            content={
              <>
                <div className='d-flex flex-md-row flex-column align-items-stretch gap-5 pb-3 '>
                  <div className='flex-fill '>
                    <div className='card card-flush py-4 flex-row-fluid'>
                      <div className='card-header'>
                        <div className='card-title'>
                          <h2>
                            <FormattedMessage id='PRICING.CUSTOMER.TITLE' />
                          </h2>
                        </div>
                      </div>
                      <div className='card-body pt-0'>
                        <CustomerBilling></CustomerBilling>
                      </div>
                    </div>
                  </div>
                  <div className='flex-fill '>
                    <div className='card card-flush py-4 flex-row-fluid h-100'>
                      <div className='card-header'>
                        <div className='card-title'>
                          <h2>
                            <FormattedMessage id='PRICING.PAYMENT.METHOD' />
                          </h2>
                        </div>
                      </div>
                      <div className='card-body pt-0'>
                        <GatwayPayment
                          data={paymentGatway}
                          onChangeType={onChangeType}></GatwayPayment>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='d-flex gap-5 '>
                  <div className='flex-fill'>
                    <div className='card card-flush py-4'>
                      <div className='card-header'>
                        <div className='card-title'>
                          <h2>
                            <FormattedMessage id='PRICING.ADDRESS.TITLE' />
                          </h2>
                        </div>
                      </div>
                      <div className='card-body pt-0'>
                        <BillingAddress></BillingAddress>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
            sideBar={
              <BillingDetails
                planDetails={{
                  total,
                  totalTTC: totalAmount,
                  totalHT: totalAmount,
                  totalMonthly: totalAmount,
                  nbrCompany,
                  nbrSMS: nbrSMS,
                  nbrUser,
                  paymentType,
                  data,
                  minCompany,
                  maxCompany,
                  extratCompany,
                  minUser,
                  maxUser,
                  extratUser,
                  vat: basicPlan?.vat,
                }}
                paymentAction={methods.handleSubmit(paymentGenerate)}
              />
            }
          />
        </FormProvider>
      )}
      <Modal
        show={showLoading}
        onHide={() => setshowLoading(false)}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered>
        <div className='card card-flush h-md-100'>
          <div
            className='card-body d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0'
            style={{
              backgroundPosition: '100% 50%',
              backgroundImage: 'url("media/42.png")',
            }}>
            <div className='mb-10'>
              <div className='fs-2hx fw-bold text-gray-800 text-center mb-13'>
                <span className='me-2'>
                  <FormattedMessage id='PAYMENT.IN_PROGRESS.MESSAGE1' />
                  <br />
                  <FormattedMessage id='PAYMENT.IN_PROGRESS.MESSAGE2' />
                </span>
              </div>
              <div className='d-flex justify-content-center'>
                <Lottie animation={paymentLoadingAnimation} height={300} width={300}></Lottie>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        scrollable
        show={isBankTransferModalShown}
        size='lg'
        onHide={() => {
          setIsBankModalShown(false);
          setTimeout(() => {
            setshowLoading(false);
          }, 350);
        }}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id='COMMON.BANK_INFORMATIONS' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleBankSubmit(submitBank)} className='row g-3'>
            <div dangerouslySetInnerHTML={{ __html: formattedText }} />
            <div className='bg-light p-2 rounded-2 position-relative'>
              <table className='table table-flush fw-semibold gy-1 gx-2 align-middle mb-0'>
                <tbody>
                  <tr>
                    <td className='text-gray-600 min-w-100px w-100px min-w-md-175px w-md-175px'>
                      <FormattedMessage id='BANK.BANK' />
                    </td>
                    <td className='text-gray-800 fw-bolder'>
                      {process.env.REACT_APP_BUILD_VARIATION === 'TAMOL'
                        ? 'البنك العربي الوطني'
                        : 'Attijari Bank'}
                    </td>
                  </tr>
                  <tr>
                    <td className='text-gray-600 min-w-100px w-100px min-w-md-175px w-md-175px'>
                      <FormattedMessage id='PRICING.BANK_PAYMENT.RIB' />
                    </td>
                    <td className='text-gray-800 fw-bolder'>
                      {process.env.REACT_APP_BUILD_VARIATION === 'TAMOL'
                        ? 'Sa3230400108095611390016'
                        : '04 057 142 0053258414 24'}
                    </td>
                  </tr>
                  {process.env.REACT_APP_BUILD_VARIATION !== 'TAMOL' && (
                    <tr>
                      <td className='text-gray-600 min-w-100px w-100px min-w-md-175px w-md-175px'>
                        <FormattedMessage id='PRICING.BANK_PAYMENT.IBAN' />
                      </td>
                      <td className='text-gray-800 fw-bolder'>TN5904 057 142 0053258414 24</td>
                    </tr>
                  )}
                  <tr>
                    <td className='text-gray-600 min-w-100px w-100px min-w-md-175px w-md-175px'>
                      <FormattedMessage id='PRICING.BANK_PAYMENT.HOLDER' />
                    </td>
                    <td className='text-gray-800 fw-bolder'>
                      {process.env.REACT_APP_BUILD_VARIATION === 'TAMOL'
                        ? 'مؤسسة تعامل لتقنية المعلومات'
                        : 'SWIVER INVEST'}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className='position-absolute top-0 end-0 mt-4 me-4 cursor-pointer'>
                <OverlayTrigger
                  placement='auto'
                  trigger='click'
                  rootClose
                  overlay={
                    <Tooltip id='button-tooltip'>
                      <FormattedMessage id='COMMON.COPIED' />
                    </Tooltip>
                  }>
                  <i
                    onClick={async () => {
                      (function copyToClipboard(text) {
                        const input = document.createElement('textarea');
                        input.value = text;
                        document.body.appendChild(input);
                        input.select();
                        document.execCommand('copy');
                        document.body.removeChild(input);
                      })(
                        process.env.REACT_APP_BUILD_VARIATION === 'TAMOL'
                          ? 'معلومات الحساب المصرفي \nالبنك: البنك العربي الوطني \nرقم الحساب المصرفي: Sa3230400108095611390016 \nالمالك: مؤسسة تعامل لتقنية المعلومات'
                          : 'INFORMATIONS BANCAIRES \nBanque Attijari Bank \nIdentifiant national de compte bancaire - RIB \n04 057 142 0053258414 24 \nIdentifiant international de compte bancaire - IBAN \nIBAN TN5904 057 142 0053258414 24 \nTitulaire du compte bancaire \nSWIVER INVEST'
                      );
                    }}
                    className='fa-light fa-copy text-gray-600 fs-2'></i>
                </OverlayTrigger>
              </div>
            </div>
            <div className='form-label required mt-0'>
              <FormattedMessage id='DOCUMENT.Ref' />
            </div>
            <input
              type='text'
              {...registerBank('ref', { required: 'FORM.ERRORS.REQUIRED' })}
              className='form-control form-control-solid'
            />
            {bankFormErrors?.ref && (
              <div className='form-error text-danger'>
                <FormattedMessage
                  id={bankFormErrors.ref.message + ''}
                  values={{ field: formatMessage({ id: 'DOCUMENT.Ref' }) }}
                />
              </div>
            )}
            {previewImage ? (
              <div
                className='bg-light rounded-1 p-4 d-flex align-items-center justify-content-center'
                style={{ height: '200px' }}>
                {previewImage === 'pdf' ? (
                  <i className=' text-primary fa-light fa-file-pdf fs-3hx'></i>
                ) : (
                  <img src={previewImage} style={{ maxHeight: '100%', maxWidth: '100%' }} alt='' />
                )}
              </div>
            ) : (
              <Controller
                name='file'
                control={bankControl}
                rules={{ validate: validateFile }}
                render={({ field, fieldState }) => (
                  <>
                    <input
                      type='file'
                      accept='image/*,application/pdf'
                      onChange={(event) => {
                        field.onChange(event?.target?.files);
                        const selectedFile = event?.target?.files?.[0];
                        if (selectedFile) {
                          if (selectedFile.type === 'application/pdf') {
                            setPreviewImage('pdf');
                            return;
                          }
                          if (!selectedFile.type.startsWith('image/')) return;
                          const reader = new FileReader();

                          reader.onload = (e) => {
                            const result = e.target?.result as string;
                            setPreviewImage(result);
                          };

                          reader.readAsDataURL(selectedFile);
                        }
                      }}
                      className='d-none'
                      ref={fileRef}
                    />
                    <div
                      onClick={() => {
                        fileRef.current?.click();
                      }}
                      className='alert  bg-light-primary border border-dashed border-primary d-flex align-items-center gap-4 p-5 mt-4 cursor-pointer'>
                      <i className='fa-light fa-file fs-2hx text-primary'></i>

                      <div className='d-flex flex-column pe-0 pe-sm-10'>
                        <h5 className='text-primary mb-1'>
                          <FormattedMessage id='PRICING.BANK_PAYMENT.SEND_ATTACHMENT.TITLE' />
                        </h5>

                        <span>
                          <FormattedMessage id='PRICING.BANK_PAYMENT.SEND_ATTACHMENT.CONTENT' />
                        </span>
                      </div>
                    </div>
                    {fieldState?.error && (
                      <div className='form-error text-danger'>
                        <FormattedMessage
                          id={fieldState.error.message + ''}
                          values={{ field: 'file' }}
                        />
                      </div>
                    )}
                  </>
                )}
              />
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-primary'
            disabled={isBankSubmitting}
            onClick={handleBankSubmit(submitBank)}>
            <FormattedMessage id='FORM.BUTTON.SUBMIT' />
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Pricing;
