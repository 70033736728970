import { ReactNode } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ReactGA from 'react-ga4';

type PageLink = {
  title: React.ReactNode;
  path: string;
  isActive?: boolean;
  isSeparator?: boolean;
};
type ListActions = {
  labels?: {
    add?: ReactNode;
    import?: ReactNode;
    export?: ReactNode;
    secondaryAdd?: ReactNode;
    tertiaryAdd?: ReactNode;
  };
  actions?: {
    add?: () => void;
    import?: () => void;
    export?: () => void;
    secondaryAdd?: () => void;
    tertiaryAdd?: () => void;
  };
  tooltips?: {
    add?: string;
    import?: string;
    export?: string;
    secondaryAdd?: string;
    tertiaryAdd?: string;
  };
  display?: {
    add?: boolean;
    import?: boolean;
    export?: boolean;
    secondaryAdd?: boolean;
    tertiaryAdd?: boolean;
    separator?: boolean;
    edit?: boolean;
    custom?: boolean;
  };
  customActions?: React.ReactNode;
  customElements?: React.ReactNode;
};
type DetailsActions = {
  tooltips?: {
    export?: string;
    edit?: string;
  };
  actions?: {
    export?: () => void;
    edit?: () => void;
    clone?: () => void;
    markAsInactive?: () => void;
    archive?: () => void;
  };
  display?: {
    export?: boolean;
    edit?: boolean;
    clone?: boolean;
    markAsInactive?: boolean;
    archive?: boolean;
    customActions?: boolean;
  };
};
type DocumentsActions = {
  labels: {
    title: string;
    approveAndPreview?: string;
    cancel?: string;
    return?: string;
  };
  actions: {
    approveAndPreview?: () => void;
    cancel?: () => void;
    return?: () => void;
  };
};
type ActionBarTypes =
  | 'listActions'
  | 'addActions'
  | 'editActions'
  | 'detailsActions'
  | 'documentsActions'
  | 'customActions'
  | 'noActions';
export type TopNavTabType = {
  content?: React.ReactNode;
  quantity?: number | undefined;
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

type PageData = {
  title?: React.ReactNode;
  description?: string;
  hideTitleAndBreadCrumbs?: boolean;
  actionBar?: ActionBarTypes;
  breadcrumbs?: PageLink[];
  listActions?: ListActions;
  detailsActions?: DetailsActions;
  documentsActions?: DocumentsActions;
  customActions?: React.ReactNode;
  topNavType?: boolean | 'custom';
  topNavTabs?: TopNavTabType[];
  customTopNav?: React.ReactNode;
  returnUrl?: string;
};

export const pageSlice = createSlice({
  name: 'navigation',
  initialState: {
    title: '' as React.ReactNode,
    description: '',
    breadcrumbs: [] as PageLink[],
    actionBar: 'noActions' as ActionBarTypes,
    listActions: {
      labels: {
        add: 'Add',
        import: 'Import',
        export: 'Export',
        secondaryAdd: 'Add',
      },
      tooltips: {
        add: 'Add',
        import: 'Import',
        export: 'Export',
        secondaryAdd: 'Add',
      },
      actions: {
        add: () => {},
        import: () => {},
        export: () => {},
        secondaryAdd: () => {},
      },
      display: {
        add: true,
        import: false,
        export: true,
        secondaryAdd: false,
        separator: true,
        custom: false,
      },
      customActions: undefined as React.ReactNode,
    } as ListActions,
    detailsActions: {
      actions: {
        archive: () => {},
        clone: () => {},
        edit: () => {},
        markAsInactive: () => {},
        export: () => {},
      },
      tooltips: {
        edit: 'Edit',
        export: 'Export',
      },
      display: {
        edit: true,
        export: true,
        clone: false,
        markAsInactive: false,
        archive: false,
        customAction: false,
      },
    } as DetailsActions,
    documentsActions: {
      labels: {
        title: 'ESTIMATE',
        approveAndPreview: 'Approve and Preview',
        cancel: 'Cancel',
        return: 'Return',
      },
      actions: {
        approveAndPreview: () => {},
        cancel: () => {},
        return: () => {},
      },
    } as DocumentsActions,
    customActions: undefined as React.ReactNode,
    hideTitleAndBreadCrumbs: false,
    topNavType: false as boolean | 'custom',
    topNavTabs: [] as TopNavTabType[],
    customTopNav: undefined as React.ReactNode,
    returnUrl: '',
  },
  reducers: {
    setPageData: (state, action: PayloadAction<PageData>) => {
      if (action.payload.title) {
        state.title = action.payload.title;
        if (process.env.REACT_APP_BUILD_VARIATION === 'SWIVER')
          ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname,
            title: window.location.pathname,
          });
      }
      if (action.payload.description) state.description = action.payload.description;
      if (action.payload.breadcrumbs) state.breadcrumbs = action.payload.breadcrumbs;
      if (action.payload.actionBar) state.actionBar = action.payload.actionBar;
      if (action.payload.listActions) state.listActions = action.payload.listActions;
      if (action.payload.detailsActions) state.detailsActions = action.payload.detailsActions;
      if (action.payload.documentsActions) state.documentsActions = action.payload.documentsActions;
      if (action.payload.customActions) state.customActions = action.payload.customActions;
      if (action.payload.topNavType) {
        state.topNavType = action.payload.topNavType;
        if (action.payload.topNavType === 'custom') {
          state.customTopNav = action.payload.customTopNav || undefined;
        } else {
          state.topNavTabs = action.payload.topNavTabs || [];
        }
      } else {
        state.topNavType = false;
        state.customTopNav = undefined;
        state.topNavTabs = [];
      }
      state.hideTitleAndBreadCrumbs = !!action.payload.hideTitleAndBreadCrumbs;
      state.returnUrl = action.payload.returnUrl || '';
    },
  },
});

export default pageSlice.reducer;
export const { setPageData } = pageSlice.actions;
