import { differenceInDays } from 'date-fns/esm';
import React, { useEffect, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { RootState } from '../../setup/Store';
import { toggleFullScreen } from '../../setup/redux/reducers/UtilsReducers';
import SWTopNav from '../../stories/SwTopNav/SwTopNav';
import Toolbar from '../components/Toolbar/Toolbar';
import { MenuComponent } from '../utils/ts/components';
import Banner from './components/Banner';
import { Content } from './components/Content';
import { ScrollTop } from './components/ScrollTop';
import { Sidebar } from './components/Sidebar';
import { HeaderWrapper } from './components/header/HeaderWrapper';
import { clarity } from 'react-microsoft-clarity';

const MasterLayout: React.FC = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const [daysDifference] = useState(
    differenceInDays(new Date(user?.subscription_expiry as string), new Date())
  );
  const topNavType = useSelector((state: RootState) => state.page.topNavType);
  const navTabs = useSelector((state: RootState) => state.page.topNavTabs);
  const fullscreen = useSelector((state: RootState) => state.utils.FullScreen);
  const handle = useFullScreenHandle();

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, []);

  useEffect(() => {
    if (fullscreen && handle) {
      handle?.enter();
    } else {
      handle?.exit();
    }
  }, [fullscreen]);
  useEffect(() => {
    if (!user) return;
    // if (process.env.NODE_ENV === 'development') return;
    if (clarity.hasStarted()) {
      clarity.setTag('email', user.email);
      clarity.setTag('name', user.first_name + ' ' + user.last_name);
      clarity.setTag('phone', user.phone);
      clarity.setTag('company', user.current_company?.name);
      clarity.upgrade('Added user data');
    }
  }, [user]);
  const dispatch = useDispatch();
  return (
    <>
      <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
        <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
          <HeaderWrapper />
          <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
            <Sidebar />
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
              <div className='d-flex flex-column flex-column-fluid'>
                {/* <ToolbarWrapper /> */}
                <Toolbar />
                {daysDifference <= (user?.current_plan?.name?.startsWith('FREE') ? 5 : 14) && (
                  <Banner daysDifference={daysDifference} userPlan={user?.current_plan?.name} />
                )}
                {!!topNavType && <SWTopNav navTabs={navTabs} />}
                <FullScreen
                  handle={handle}
                  onChange={(state) => {
                    if (!state && fullscreen) {
                      dispatch(toggleFullScreen());
                    }
                  }}>
                  <Content>
                    <Outlet />
                  </Content>
                </FullScreen>
              </div>
              {/* <FooterWrapper /> */}
            </div>
          </div>
        </div>
      </div>
      <ScrollTop />
    </>
  );
};

export { MasterLayout };
