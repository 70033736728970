import axios from 'axios';
import { Permissions } from '../../../app/models/settings/PermissionsResponse';

export interface UserPayload {
  id?: number;
  email: string;
  firstName: string;
  enabled: boolean;
  lastName: string;
  phone: string;
  password: string;
  monthly_turnover_target: number;
  userHasPermissions: {
    company: number;
    permissions: string[];
  }[];
}

export const getPermissionsList = async () => {
  return await axios.get<Permissions[]>('/secure/user/list-permissions');
};

export const postUser = async (user: UserPayload) => {
  return await axios.post('/secure/user/new', user);
};

export const putUser = async (user: Partial<UserPayload>) => {
  return await axios.post(`/secure/user/edit/${user.id}`, user);
};
