/* eslint-disable react/jsx-no-target-blank */
import { toAbsoluteUrl } from '../../../helpers';
import { useMemo } from 'react';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';

const SidebarFooter = () => {
  const date = useMemo(() => format(new Date(), 'yyyy-MM'), []);
  return (
    <div className='app-sidebar-footer' id='kt_app_sidebar_footer'>
      <div className='app-sidebar-footer-top '>
        <a
          href={`https://calendly.com/oumaima-h/30min?month=${date}`}
          target='_blank'
          className='btn btn-custom'>
          <i className='fa-light fa-laptop'></i>
          <span className='btn-label'>
            <FormattedMessage id='SIDEMENU.FOOTER.DEMO' />
          </span>
        </a>
        <div className='separator'></div>
        <a href='tel:+21636018000' className='btn btn-custom'>
          <i className='fa-light fa-headset'></i>
          <span className='btn-label'>
            <FormattedMessage id='SIDEMENU.FOOTER.SUPPORT' />
          </span>
        </a>
        <button className='btn btn-custom btn-footer-menu'>
          <i className='fa-light fa-ellipsis-vertical'></i>
        </button>
      </div>
      <div className='app-sidebar-footer-bottom'>
        <img
          className='footer-logo-full show-dark'
          src={toAbsoluteUrl('/media/logos/logo-swiver.svg')}
          alt=''
        />
        <img
          className='footer-logo-full show-light'
          src={toAbsoluteUrl('/media/logos/logo-swiver-dark.svg')}
          alt=''
        />
        <img
          className='footer-logo-minimized'
          src={toAbsoluteUrl('/media/logos/logo.svg')}
          alt=''
        />
        <div className='sw-version'>V2</div>
      </div>
    </div>
  );
};

export { SidebarFooter };
