import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Guard, {
  authorizeOnRoleOrPermissionAndPermissionPlan,
} from '../../../components/Security/Guard';
import { Permission, PermissionPlan } from '../../../modules/auth/models/UserModel';
import { SuspensedView } from '../../../routing/PrivateRoutes';
import { lazyWithRetry } from '../../../utils/LazyWithRetry';

const ListDocuments = lazyWithRetry(() => import('./ListDocuments'));
const routes = [
  'supplierorder',
  'supplierinvoice',
  'supplierdeliverynote',
  'invoice',
  'order',
  'deliverynote',
  'estimate',
  'inventory',
  'issuenote',
  'transferorder',
];
export const documentPermissionPlan = [
  'supplierOrder',
  'supplierInvoice',
  'supplierDeliveryNote',
  'invoice',
  'order',
  'deliveryNote',
  'estimate',
  'inventory',
  'issueNote',
  'transferOrder',
];
export const permissionRead = [
  'SupplierOrder.read',
  'SupplierInvoice.read',
  'SupplierDeliveryNote.read',
  'Invoice.read',
  'Order.read',
  'DeliveryNote.read',
  'Estimate.read',
  'Inventory.read',
  'IssueNote.read',
  'TransferOrder.read',
];
export const permissionWrite = [
  'SupplierOrder.read.write',
  'SupplierInvoice.read.write',
  'SupplierDeliveryNote.read.write',
  'Invoice.read.write',
  'Order.read.write',
  'DeliveryNote.read.write',
  'Estimate.read.write',
  'Inventory.read.write',
  'IssueNote.read.write',
  'TransferOrder.read.write',
];
export default function ListDocumentRoutes() {
  const location = useLocation();
  return (
    <Routes location={location}>
      {routes.map((route, index) => (
        <Route
          path={`/${route}`}
          key={index}
          element={
            <Guard
              planPermissions={[(documentPermissionPlan[index] + 'Management') as PermissionPlan]}
              permissions={[
                permissionRead[index] as Permission,
                permissionWrite[index] as Permission,
              ]}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <SuspensedView>
                <ListDocuments key={index} type={index as 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9} />
              </SuspensedView>
            </Guard>
          }></Route>
      ))}
    </Routes>
  );
}
