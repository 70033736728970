import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import DoneLottie from '../../../components/DoneLottie/DoneLottie';

function AddDepenseStep5() {
  // const {formatMessage} = useIntl()
  return (
    <div className='d-flex flex-column gap-3 align-items-center justify-content-center w-100 h-100'>
      <DoneLottie height={150} width={250} />
      <div className='fs-1 text-dark fw-bold'>
        <FormattedMessage id='EXPENSE.ADDED' />
      </div>
    </div>
  );
}
export { AddDepenseStep5 };
