import clsx from 'clsx';
import { electronicFormatIBAN, isValidIBAN } from 'ibantools';
import { FC, createContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { GroupBase, OptionProps } from 'react-select';
import { RootState } from '../../setup/Store';
import { postBank, putBank } from '../../setup/redux/effects/UtilsEffects';
import {
  readBankList,
  readListBanks,
  readUserBanks,
} from '../../setup/redux/reducers/UtilsReducers';
import SwiverSelect from '../components/SwiverSelect/SwiverSelect';
import { toAbsoluteUrl } from '../helpers';
import { Bank, UserBank } from '../models/BankResponse';
import { validateSWIFT } from '../utils/utils';

export type BankModalContextProps = {
  isModalShown: boolean;
  hideBankModal: () => void;
  showAddBankModal: () => void;
  showEditBankModal: (
    bank:
      | UserBank
      | {
          id: number;
          number: string;
          name: string;
          ribKey: string;
          agency: string;
          iban: string;
          updated_at: string;
          bank: {
            code: string;
            name: string;
          };
          balance: number;
        }
  ) => void;
};
export type BankOptionType = {
  value: string | number;
  label: string;
  image: string;
  code: string;
};
const defaultValue = {
  isModalShown: false,
  hideBankModal: () => {},
  showAddBankModal: () => {},
  showEditBankModal: () => {},
};
const defaultFormValues = { bank: '', name: '', agency: '', swift: '', iban: '' };
export const BankModalContext = createContext<BankModalContextProps>(defaultValue);

const BankModalProvider: FC = ({ children }) => {
  const {
    register,
    control,
    formState: { touchedFields, errors },
    handleSubmit,
    setValue,
    setFocus,
    reset,
  } = useForm({ mode: 'onTouched', reValidateMode: 'onChange' });
  const [bankOptions, setBankOptions] = useState<BankOptionType[]>([]);
  const intl = useIntl();
  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();
  const [isModalShown, setIsModalShown] = useState(false);
  const [bankToEdit, setBankToEdit] = useState<
    | UserBank
    | {
        id: number;
        number: string;
        name: string;
        ribKey: string;
        agency: string;
        iban: string;
        updated_at: string;
        bank: {
          code: string;
          name: string;
        };
        balance: number;
      }
  >();
  const hideBankModal = () => {
    setIsModalShown(false);
    reset(defaultFormValues, {
      keepErrors: false,
      keepValues: false,
      keepTouched: false,
      keepIsValid: false,
    });
  };
  const bankList = useSelector((state: RootState) => state.utils.bankList);
  const [isEditMode, setIsEditMode] = useState(false);
  const showAddBankModal = () => {
    setIsModalShown(true);
    setIsEditMode(false);
    setBankToEdit(undefined);
  };
  const showEditBankModal = (
    bank:
      | UserBank
      | {
          id: number;
          number: string;
          name: string;
          ribKey: string;
          agency: string;
          iban: string;
          updated_at: string;
          bank: {
            code: string;
            name: string;
          };
          balance: number;
        }
  ) => {
    setIsModalShown(true);
    setIsEditMode(true);
    setBankToEdit(bank);
    reset(
      {
        bank: (bank?.bank as Bank)?.id || bank.id,
        name: bank?.name,
        agency: bank?.agency,
        swift: (bank as UserBank)?.swift || '',
        iban: bank?.iban,
      },
      {
        keepErrors: false,
        keepValues: false,
        keepTouched: false,
        keepIsValid: false,
      }
    );
  };
  const addBankAccount = async (values: Partial<UserBank>) => {
    const electronicIban = electronicFormatIBAN(values.iban as string);
    const rib = (electronicIban || '').slice(12, -2);
    const ribKey = (electronicIban || '').slice(-2);
    await postBank({
      ...values,
      company: user?.current_company?.id,
      number: rib || '',
      ribKey: ribKey || '',
      is_display_on_document: true,
      enabled: true,
    });
    dispatch(readUserBanks());
    dispatch(readListBanks());
    hideBankModal();
  };
  const editBankAccount = async (values: Partial<UserBank>) => {
    const electronicIban = electronicFormatIBAN(values.iban as string);
    const rib = (electronicIban || '').slice(12, -2);
    const ribKey = (electronicIban || '').slice(-2);
    await putBank({
      ...bankToEdit,
      ...values,
      number: rib || '',
      ribKey: ribKey || '',
      company: user?.current_company?.id,
    } as Partial<UserBank>);
    dispatch(readUserBanks());
    dispatch(readListBanks());
    hideBankModal();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (bankList === undefined) {
        dispatch(readBankList());
      } else {
        if (!bankOptions?.length && bankList?.length) {
          const blOptions: BankOptionType[] = bankList.map((bank) => ({
            value: bank.id,
            label: bank.name,
            image: bank.logo?.web_path || toAbsoluteUrl(`/media/banks/${bank.code}.png`),
            code: bank.code,
          }));
          setBankOptions(blOptions);
        }
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [bankList]);
  return (
    <BankModalContext.Provider
      value={{
        isModalShown,
        hideBankModal,
        showAddBankModal,
        showEditBankModal,
      }}>
      {children}
      <Modal
        show={isModalShown}
        dialogClassName={'w-auto mw-md-600px mw-lg-850px'}
        onHide={hideBankModal}
        backdrop='static'
        centered>
        <Modal.Header closeButton className='border-bottom-0 pb-2 pt-4'>
          <Modal.Title>
            <FormattedMessage
              id='TABLE.ADD'
              values={{ name: intl.formatMessage({ id: 'BANK.NAME' }) }}
            />
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(isEditMode ? editBankAccount : addBankAccount)}>
          <Modal.Body>
            <div className='row g-6'>
              <div>
                <label className='form-label fw-normal fs-7'>
                  <FormattedMessage id='BANK.BANK' />
                </label>
                <Controller
                  name='bank'
                  control={control}
                  rules={{ required: 'FORM.ERRORS.REQUIRED' }}
                  render={({
                    field: { name, onBlur, onChange, value },
                    fieldState: { invalid, isTouched, error },
                  }) => (
                    <>
                      <SwiverSelect
                        className={clsx('swiver-select-solid', {
                          'is-invalid': error,
                        })}
                        name={name}
                        onBlur={onBlur}
                        onChange={(e) => {
                          onChange(e?.value || '');
                          if (e) {
                            setValue('name', `${e.code} - `);
                            setFocus('name');
                          }
                        }}
                        value={Number(value)}
                        components={{ Option: CustomBankOption }}
                        options={bankOptions}
                      />
                      {isTouched && invalid && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger'>
                            <span role='alert'>
                              <FormattedMessage
                                id={error?.message}
                                values={{
                                  field: intl.formatMessage({ id: 'BANK.BANK' }),
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
              <div>
                <label className='form-label fw-normal fs-7'>
                  <FormattedMessage id='BANK.DESIGNATION' />
                </label>
                <input
                  className={clsx('form-control form-control-solid', {
                    'is-invalid': touchedFields?.name && errors?.name,
                    'is-valid': touchedFields?.name && !errors?.name,
                  })}
                  type='text'
                  {...register('name', { required: 'FORM.ERRORS.REQUIRED' })}
                />
                {touchedFields?.name && errors?.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>
                      <span role='alert'>
                        <FormattedMessage
                          id={('' + errors?.name.message) as string}
                          values={{
                            field: intl.formatMessage({ id: 'BANK.DESIGNATION' }),
                          }}
                        />
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <label className='form-label fw-normal fs-7'>
                  <FormattedMessage id='BANK.AGENCY' />
                </label>
                <input
                  type='text'
                  {...register('agency')}
                  className={clsx('form-control form-control-solid', {
                    'is-invalid': touchedFields?.agency && errors?.agency,
                    'is-valid': touchedFields?.agency && !errors?.agency,
                  })}
                />
                {touchedFields?.agency && errors?.agency && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>
                      <span role='alert'>
                        <FormattedMessage
                          id={('' + errors?.agency.message) as string}
                          values={{
                            field: intl.formatMessage({ id: 'BANK.AGENCY' }),
                          }}
                        />
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <label className='form-label fw-normal fs-7'>
                  <FormattedMessage id='BANKING.SWIFT' />
                </label>
                <input
                  type='text'
                  className={clsx('form-control form-control-solid', {
                    'is-invalid': touchedFields?.swift && errors?.swift,
                    'is-valid': touchedFields?.swift && !errors?.swift,
                  })}
                  {...register('swift', {
                    validate: (value) =>
                      value ? validateSWIFT(value) || 'FORM.ERRORS.FORMAT' : true,
                  })}
                />
                {touchedFields?.swift && errors?.swift && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>
                      <span role='alert'>
                        <FormattedMessage
                          id={('' + errors?.swift.message) as string}
                          values={{
                            type: intl.formatMessage({ id: 'TYPE.SWIFT' }),
                          }}
                        />
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <label className='form-label fw-normal fs-7'>
                  <FormattedMessage id='BANKING.IBAN' />
                </label>
                <input
                  type='text'
                  className={clsx('form-control form-control-solid', {
                    'is-invalid': touchedFields?.iban && errors?.iban,
                    'is-valid': touchedFields?.iban && !errors?.iban,
                  })}
                  placeholder='____ ____ ____ ____ ____ __'
                  {...register('iban', {
                    required: 'FORM.ERRORS.REQUIRED',
                    validate: (value) =>
                      value
                        ? isValidIBAN(electronicFormatIBAN(value || '') || '') ||
                          'FORM.ERRORS.FORMAT'
                        : true,
                  })}
                />
                <div className='fs-8 text-muted'>
                  <FormattedMessage
                    id='FORM.EXAMPLE'
                    values={{
                      example: 'TN59 1000 6035 1835 9847 8831',
                    }}
                  />
                </div>
                {touchedFields?.iban && errors?.iban && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>
                      <span role='alert'>
                        <FormattedMessage
                          id={('' + errors?.iban.message) as string}
                          values={{
                            field: intl.formatMessage({ id: 'BANKING.IBAN' }),
                            type: intl.formatMessage({ id: 'BANKING.IBAN' }),
                          }}
                        />
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end gap-3 me-4'>
              <div className='btn btn-secondary' onClick={hideBankModal}>
                <FormattedMessage id='FORM.CANCEL' />
              </div>
              <button type='submit' className='btn btn-primary'>
                <FormattedMessage id='FORM.SUBMIT' />
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </BankModalContext.Provider>
  );
};
export default BankModalProvider;
export const CustomBankOption: React.ComponentType<
  OptionProps<BankOptionType, boolean, GroupBase<BankOptionType>>
> = ({ innerRef, innerProps, data, isFocused, isSelected }) => (
  <div ref={innerRef} {...innerProps}>
    <div
      className={clsx(
        'd-flex gap-3 p-2 cursor-pointer',
        isFocused && !isSelected && 'bg-light-primary',
        isSelected && 'bg-primary text-white'
      )}>
      <img src={data.image} className='h-25px mw-25px' alt='' />
      <div className='flex-grow-1'>{data.label}</div>
    </div>
  </div>
);
