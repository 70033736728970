import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useQuery } from '@tanstack/react-query';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import clsx from 'clsx';
import { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { Accordion, Modal } from 'react-bootstrap';
import { Controller, UseFormReturn, useFieldArray, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../setup/Store';
import { getWarehouses } from '../../../setup/redux/effects/UtilsEffects';
import { readBrands, readCategories, readUnits } from '../../../setup/redux/reducers/UtilsReducers';
import { KTSVG, toAbsoluteUrl } from '../../helpers';
import { LogisticsInfo, Product, ProductPriceList, Tax } from '../../models/ProductResponse';
import { AddProductModalsContext } from '../../pages/Products/AddProduct/AddProductModalsContext';
import { getPartialPrice, getTotalPrice } from '../../utils/calcUtils';
import CurrencyFormatter from '../CurrencyFormatter/CurrencyFormatter';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import CustomRadio from '../CustomRadio/CustomRadio';
import ImageCropper from '../ImageCropper/ImageCropper';
import PriceListItem from '../PriceListItem/PriceListItem';
import SwiverSelect from '../SwiverSelect/SwiverSelect';

const productsSteps = [
  'FORM.STEPPER.PRODUCT.TYPE',
  'FORM.STEPPER.PRODUCT.INFORMATIONS',
  'FORM.STEPPER.PRODUCT.ADDITIONAL-INFORMATIONS',
];
type SelectProps = {
  label: string;
  value: string | number;
  count_qty_fields?: number;
  company?: null | number;
}[];

const filterPriceLists = (
  priceList: SelectProps,
  selectedPriceLists: Partial<ProductPriceList[]>,
  currentPrice: number | string
) =>
  priceList.filter(
    (price) =>
      price.value === currentPrice + '' ||
      !selectedPriceLists?.find((selected) => selected?.price_list === price.value)
  );
const filterWarehouseList = (
  warehouseList: SelectProps,
  selectedWarehouseLists: LogisticsInfo[] = [],
  currentWarehouse: number | string
) =>
  warehouseList
    .filter(
      (warehouse) =>
        warehouse.value + '' === currentWarehouse + '' ||
        !selectedWarehouseLists.find((selected) => selected.warehouse + '' === warehouse.value + '')
    )
    .map((warehouse) => ({ ...warehouse, value: warehouse.value + '' }));

export default function QuickAddProductsModal({
  show,
  methods,
  currentStep,
  canQuickAdd,
  handleHideModal,
  isSubmit,
  previousStep,
  submitStep,
  documentType,
  selectedWarehouse,
}: {
  show: boolean;
  methods: UseFormReturn<Partial<Product>>;
  currentStep: number;
  submitStep: (values: Partial<Product>, quickAdd?: boolean) => void;
  previousStep: () => void;
  canQuickAdd: boolean;
  isSubmit: boolean;
  handleHideModal: () => void;
  documentType: string;
  selectedWarehouse: string;
}) {
  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    formState: { touchedFields, errors, isSubmitting },
  } = methods;
  const {
    fields: barcodeFields,
    remove: removeBarcode,
    append: appendBarCode,
  } = useFieldArray({ name: 'bar_codes', control });
  const [sellingPrice, vat, logisticInfos, model, tPrice, countQTyFields] = watch([
    'unit_price',
    'vat',
    'logistics_infos',
    'model',
    'tPrice',
    'count_qty_fields',
  ]);
  const hasSerialNumber = useWatch({
    name: 'has_serial_number',
    control,
    defaultValue: false,
  });
  const productPriceListValues =
    useWatch({ name: 'product_price_list', control, defaultValue: [] }) || [];
  const [priceListOptions, setPriceListOptions] = useState<SelectProps>([]);
  const [brandsOptions, setBrandsOptions] = useState<SelectProps>([]);
  const [unitsOptions, setUnitsOptions] = useState<SelectProps>([]);
  const [categoriesOptions, setCategoriesOptions] = useState<SelectProps>([]);
  const { formatMessage } = useIntl();
  const locale = useSelector((state: RootState) => state.utils.locale);
  const vats = useSelector((state: RootState) => state.utils.vats);
  const taxes = useSelector((state: RootState) => state.utils.taxes);
  const priceList = useSelector((state: RootState) => state.utils.priceList);
  const modals = useContext(AddProductModalsContext);
  const getVatById = (id: string) => vats?.find((v) => v.id === Number(id))?.vat || 0;
  const brands = useSelector((state: RootState) => state.utils.brands);
  const units = useSelector((state: RootState) => state.utils.units);
  const categories = useSelector((state: RootState) => state.utils.categories);
  const company = useSelector((state: RootState) => state.auth.user?.current_company.id);
  const dispatch = useDispatch();
  const brand = watch('brand');
  const unit = watch('unit');
  const category = watch('category');
  const barcodes = watch('bar_codes') || [];
  const filtredUnitOptions = useMemo(
    () =>
      hasSerialNumber
        ? unitsOptions.map((unit) => ({
            ...unit,
            isDisabled: +(unit?.count_qty_fields || 0) === 2,
          }))
        : unitsOptions,
    [hasSerialNumber, unitsOptions]
  );
  const {
    fields: logisticsInfoList,
    append: appendLogisticsInfo,
    remove: removeLogisticsInfo,
  } = useFieldArray({
    name: 'logistics_infos',
    control,
  });
  const addBarcode = () => {
    if (!(barcodes as string[])?.includes(barcodeField) && barcodeField) {
      appendBarCode(barcodeField);
    }
    setBarcodeField('');
  };
  const { data: warehouses } = useQuery(
    [
      'warehouses',
      {
        company: company,
      },
    ],
    () =>
      getWarehouses({
        company: company,
      }).then((res) => res.data),
    {
      staleTime: 60000,
      refetchOnReconnect: true,
    }
  );
  const [isBarCodeShown, setIsBarCodeShown] = useState(false);
  const [barcodeField, setBarcodeField] = useState('');
  const {
    fields: productPriceList,
    append,
    remove,
  } = useFieldArray({
    name: 'product_price_list',
    control: control,
  });
  useEffect(() => {
    if (warehouses !== undefined) {
      if (!logisticInfos?.length) {
        appendLogisticsInfo({
          warehouse: (warehouses?.rows.find((warehouse) => warehouse.active)?.id || '') + '',
          min_stock: 0,
          max_stock: 0,
          allow_alert: false,
        });
      } else {
        setValue(
          'logistics_infos.0.warehouse',
          (warehouses?.rows.find((warehouse) => warehouse.active)?.id || '') + ''
        );
        setValue('logistics_infos.0.min_stock', 0);
        setValue('logistics_infos.0.max_stock', 0);
      }
    }
  }, [warehouses]);
  const warehouseOptions = useMemo<SelectProps>(
    () =>
      warehouses?.rows
        ?.filter((warehouse) => warehouse.active)
        .map((warehouse) => ({ label: warehouse.name + '', value: warehouse.id + '' })) || [],
    [warehouses]
  );
  useEffect(() => {
    if (priceList !== undefined)
      setPriceListOptions(
        priceList.map((price) => ({ label: price?.name + '', value: price?.id + '' }))
      );
  }, [priceList]);
  useEffect(() => {
    if (brands !== undefined) {
      setBrandsOptions(brands.map((brand) => ({ label: brand?.name, value: brand?.id + '' })));
      if (!brand) setValue('brand', brands?.[0]?.id + '' || '');
    } else {
      dispatch(readBrands());
    }
  }, [brands]);

  useEffect(() => {
    if (units !== undefined) {
      if (!unit) setValue('unit', units?.[0]?.id + '' || '');
      setUnitsOptions(
        units.map((unit) => ({
          value: unit.id?.toString(),
          label: unit.company
            ? unit?.name
            : formatMessage({
                id: `UNITS.${(unit.name || '').replace(' ', '_').toUpperCase()}`,
                defaultMessage: unit.name,
              }),
          count_qty_fields: unit?.count_qty_fields,
        }))
      );
    } else {
      dispatch(readUnits());
    }
  }, [units]);

  useEffect(() => {
    if (categories !== undefined) {
      setCategoriesOptions(
        categories.map((category) => ({ label: category?.label, value: category?.id + '' }))
      );
      if (!category) setValue('category', categories?.[0]?.id + '' || '');
    } else {
      dispatch(readCategories());
    }
  }, [categories]);

  return (
    <Modal onHide={handleHideModal} show={show} centered size='xl'>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id='PRODUCT.ADD.TITLE' />
        </Modal.Title>
      </Modal.Header>
      <form
        onSubmit={handleSubmit(async (values) => {
          await submitStep(values);
        })}>
        <Modal.Body>
          <div className='mx-auto w-100 mw-800px'>
            <div className='stepper stepper-links d-flex flex-column'>
              <div className='stepper-nav d-none d-md-flex  mb-5'>
                {productsSteps.map((step, index) => (
                  <div
                    className={clsx('stepper-item', index === currentStep && 'current')}
                    key={index}>
                    <h3 className='stepper-title'>
                      <FormattedMessage id={step} />
                    </h3>
                  </div>
                ))}
              </div>
              <div className='current mt-10 w-100'>
                {currentStep === 0 && (
                  <div className='border p-8 rounded-3 w-100'>
                    <div className='mb-4'>
                      <h2 className='fw-bold d-flex align-items-center text-dark mb-6'>
                        <FormattedMessage id='FORM.STEPPER.PRODUCT.TYPE' />
                      </h2>
                    </div>
                    <div className='row'>
                      <Controller
                        name='model'
                        control={control}
                        render={({
                          field: { name, onBlur, onChange, value },
                          fieldState: { invalid, isTouched, error },
                        }) => (
                          <>
                            <div className='col-md-6'>
                              <input
                                name={name}
                                type='radio'
                                onChange={onChange}
                                onBlur={onBlur}
                                className='btn-check'
                                id='sw_product_material'
                                value={'materiel'}
                                checked={value === 'materiel'}
                              />
                              <label
                                className={clsx(
                                  'btn btn-outline btn-outline-dashed btn-outline-default  p-7 d-flex align-items-center mb-10',
                                  value === 'materiel' && 'border border-primary'
                                )}
                                htmlFor='sw_product_material'>
                                <KTSVG
                                  path='/media/icons/duotune/general/gen017.svg'
                                  className='svg-icon svg-icon-3x me-5'
                                />
                                <span className='d-block fw-semibold text-start'>
                                  <span className='text-dark fw-bold d-block fs-4 mb-2'>
                                    <FormattedMessage id='PRODUCT.TOOLTIP.TYPE.MATERIAL' />
                                  </span>
                                </span>
                              </label>
                            </div>
                            <div className='col-md-6'>
                              <input
                                name={name}
                                type='radio'
                                onChange={(e) => {
                                  if (documentType + '' === '7' || documentType + '' === '9')
                                    return;
                                  onChange(e);
                                }}
                                onBlur={onBlur}
                                className='btn-check'
                                id='sw_product_service'
                                value='service'
                                checked={value === 'service'}
                                disabled={documentType + '' === '7' || documentType + '' === '9'}
                              />
                              <label
                                className={clsx(
                                  'btn btn-outline btn-outline-dashed btn-outline-default  p-7 d-flex align-items-center mb-10',
                                  value === 'service' && 'border border-primary'
                                )}
                                htmlFor='sw_product_service'>
                                <KTSVG
                                  path='/media/icons/duotune/finance/fin006.svg'
                                  className='svg-icon svg-icon-3x me-5'
                                />
                                <span className='d-block fw-semibold text-start'>
                                  <span className='text-dark fw-bold d-block fs-4 mb-2'>
                                    <FormattedMessage id='PRODUCT.TOOLTIP.TYPE.SERVICE' />
                                  </span>
                                </span>
                              </label>
                            </div>
                            <div className='text-danger mt-2'>
                              {invalid && (
                                <FormattedMessage
                                  id={error?.message}
                                  values={{
                                    field: formatMessage({ id: 'PRODUCT.FIELD.TYPE' }),
                                  }}
                                />
                              )}
                            </div>
                          </>
                        )}
                      />
                    </div>
                  </div>
                )}
                {currentStep === 1 && (
                  <div className='d-flex flex-column gap-4'>
                    <div className='border rounded-3 p-8'>
                      <div className='mb-4'>
                        <h2 className='fw-bold d-flex align-items-center text-dark mb-6'>
                          <FormattedMessage id={'FORM.STEPPER.PRODUCT.INFORMATIONS'} />
                        </h2>
                      </div>

                      <div className='row'>
                        <div className='mb-4'>
                          <label className='form-label required mb-3'>
                            <FormattedMessage id='FORM.PRODUCT.NAME' />
                          </label>
                          <input
                            type='text'
                            {...register('name')}
                            autoComplete='off'
                            className={clsx('form-control form-control-lg form-control-solid', {
                              'is-invalid': touchedFields?.name && errors?.name,
                              'is-valid': touchedFields?.name && !errors?.name,
                            })}
                          />
                          {errors?.name && touchedFields?.name && (
                            <div className='text-danger mt-3'>
                              <span role='alert'>
                                <FormattedMessage
                                  id={'' + errors?.name?.message}
                                  values={{
                                    field: formatMessage({ id: 'FORM.PRODUCT.NAME' }),
                                  }}
                                />
                              </span>
                            </div>
                          )}
                        </div>
                        <Controller
                          name='vat'
                          control={control}
                          render={({
                            field: { name, onBlur, onChange, value },
                            fieldState: { invalid, isTouched, error },
                          }) => (
                            <>
                              <div className='mb-4'>
                                <label className='form-label required mb-3'>
                                  <FormattedMessage id='PRODUCT.FIELD.VAT' />
                                </label>
                                <div className='d-flex gap-3 flex-wrap mb-3'>
                                  {vats?.map((vat) => (
                                    <CustomRadio
                                      key={vat.id}
                                      name={name}
                                      value={vat.id}
                                      onBlur={onBlur}
                                      onChange={onChange}
                                      checked={value?.toString() === vat?.id?.toString()}>
                                      {`${vat.vat} %`}
                                    </CustomRadio>
                                  ))}
                                  <button
                                    type='button'
                                    onClick={modals.showVatModal}
                                    className='btn btn-icon btn-light-success'>
                                    <KTSVG
                                      path='/media/icons/duotune/arrows/arr087.svg'
                                      className='svg-icon svg-icon-1'
                                    />
                                  </button>
                                </div>
                                {invalid && (
                                  <div className='text-danger mt-3'>
                                    <span role='alert'>
                                      <FormattedMessage
                                        id={error?.message}
                                        values={{
                                          field: formatMessage({
                                            id: 'CUSTOMER.FIELD.CIVILITY',
                                          }),
                                        }}
                                      />
                                    </span>
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        />
                        <div className='mb-4'>
                          <label className='form-label mb-3'>
                            <FormattedMessage id='FORM.PRODUCT.TAXES' />
                          </label>
                          <div className='d-flex gap-3 mb-3 flex-wrap'>
                            <Controller
                              control={control}
                              name='taxes'
                              defaultValue={[]}
                              render={({
                                field: { name, onBlur, onChange, value },
                                fieldState: { invalid, isTouched, error },
                              }) => (
                                <Checkboxes
                                  name={name}
                                  options={(taxes as Tax[]) || []}
                                  value={value! as (string | number)[]}
                                  onChange={onChange}
                                />
                              )}
                            />
                            <button
                              type='button'
                              className='btn btn-icon btn-light-success'
                              onClick={modals.showTaxModal}>
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr087.svg'
                                className='svg-icon svg-icon-1'
                              />
                            </button>
                          </div>
                          {touchedFields?.taxes && errors?.taxes && (
                            <div className='text-danger mt-3'>
                              <span role='alert'>
                                <FormattedMessage
                                  id={'' + errors?.taxes?.message}
                                  values={{
                                    field: formatMessage({ id: 'FORM.PRODUCT.TAXES' }),
                                  }}
                                />
                              </span>
                            </div>
                          )}
                        </div>
                        <div className='mb-4'>
                          <label className='form-label required mb-3'>
                            <FormattedMessage id='FORM.PRODUCT.PRICE' />
                          </label>
                          <div className='row'>
                            <div className='col-lg-6 mb-4'>
                              <Controller
                                name='unit_price'
                                defaultValue={0}
                                control={control}
                                render={({
                                  field: { name, onBlur, onChange, value },
                                  fieldState: { error, isTouched },
                                }) => (
                                  <>
                                    <input
                                      type='number'
                                      step='any'
                                      name={name}
                                      className='form-control form-control form-control-solid'
                                      value={value}
                                      onChange={onChange}
                                      onBlur={onBlur}
                                    />
                                    {error && isTouched && (
                                      <div className='text-danger mt-3'>
                                        <span role='alert'>
                                          <FormattedMessage
                                            id={'' + error.message}
                                            values={{
                                              field: formatMessage({
                                                id: 'FORM.PRODUCT.PRICE',
                                              }),
                                            }}
                                          />
                                        </span>
                                      </div>
                                    )}
                                  </>
                                )}
                              />
                            </div>
                            <div className='col-lg-6 mb-4'>
                              <Controller
                                name='tPrice'
                                control={control}
                                defaultValue={'true'}
                                render={({
                                  field: { name, onBlur, onChange, value },
                                  fieldState: { invalid, isTouched, error },
                                }) => (
                                  <>
                                    <div className='d-flex gap-3'>
                                      <div className='position-relative'>
                                        <CustomRadio
                                          name={name}
                                          onChange={onChange}
                                          onBlur={onBlur}
                                          checked={value === 'false'}
                                          value={'false'}
                                          className='w-100'>
                                          <FormattedMessage id='COMMON.TAX.INCL-TAXES' />
                                        </CustomRadio>
                                        <div
                                          className={clsx(
                                            'w-100 text-center fw-semibold fs-6 bottom-m-3',
                                            value === 'false' && 'text-primary'
                                          )}>
                                          <CurrencyFormatter
                                            amount={
                                              value === 'false'
                                                ? sellingPrice || 0
                                                : getTotalPrice(
                                                    Number(sellingPrice),
                                                    getVatById(vat as string)
                                                  )
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className='position-relative'>
                                        <CustomRadio
                                          name={name}
                                          onChange={onChange}
                                          onBlur={onBlur}
                                          checked={value === 'true'}
                                          value={'true'}
                                          className='w-100'>
                                          <FormattedMessage id='COMMON.TAX.EXCL-TAXES' />
                                        </CustomRadio>
                                        <div
                                          className={clsx(
                                            'w-100 text-center fw-semibold fs-6 bottom-m-3',
                                            value === 'true' && 'text-primary'
                                          )}>
                                          <CurrencyFormatter
                                            amount={
                                              value === 'true'
                                                ? sellingPrice || 0
                                                : getPartialPrice(
                                                    Number(sellingPrice),
                                                    getVatById(vat as string)
                                                  )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {invalid && isTouched && (
                                      <div className='text-danger mt-3'>
                                        <span role='alert'>
                                          <FormattedMessage
                                            id={error?.message}
                                            values={{
                                              field: formatMessage({
                                                id: 'FORM.PRODUCT.PRICE',
                                              }),
                                            }}
                                          />
                                        </span>
                                      </div>
                                    )}
                                  </>
                                )}
                              />
                            </div>
                            {model === 'materiel' && (
                              <div className='mb-4'>
                                <div className='form-check form-check-custom form-check-solid'>
                                  <Controller
                                    name='allow_empty_stock'
                                    control={control}
                                    render={({ field: { value, name, onBlur, onChange, ref } }) => (
                                      <>
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          id='allow_empty_stock'
                                          checked={value}
                                          name={name}
                                          onBlur={onBlur}
                                          ref={ref}
                                          onChange={(e) => {
                                            setValue('has_serial_number', false);
                                            onChange(e);
                                          }}
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor='allow_empty_stock'>
                                          <FormattedMessage id='FORM.PRODUCT.ALLOW-EMPTY' />
                                        </label>
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                            {model === 'materiel' && ['2', '7'].includes(documentType) && (
                              <div>
                                <Controller
                                  name='has_serial_number'
                                  control={control}
                                  disabled={+(countQTyFields || 0) === 2}
                                  render={({
                                    field: { name, value, onBlur, onChange, ref, disabled },
                                  }) => (
                                    <div className='form-check form-check-custom form-check-solid'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='has_serial_number'
                                        checked={value}
                                        name={name}
                                        onBlur={onBlur}
                                        ref={ref}
                                        disabled={disabled}
                                        onChange={(e) => {
                                          if (+(countQTyFields || 0) === 2) return;
                                          setValue('allow_empty_stock', false);
                                          onChange(e);
                                        }}
                                      />

                                      <label
                                        className='form-check-label'
                                        htmlFor='has_serial_number'>
                                        <FormattedMessage id='FORM.PRODUCT.HAS.SERIAL.NUMBER' />
                                      </label>
                                    </div>
                                  )}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <Accordion className='rounded-3'>
                      <Accordion.Item eventKey='0'>
                        <div className='accordion-header'>
                          <Accordion.Button className='bg-body'>
                            <div className='d-flex align-items-center gap-3'>
                              {errors?.product_price_list && (
                                <KTSVG
                                  path='/media/icons/duotune/general/gen044.svg'
                                  className='svg-icon-1 svg-icon-danger'
                                />
                              )}
                              <span className='fw-bold text-dark fs-2'>
                                <FormattedMessage id={'CUSTOMER.FIELD.PRICE-LIST'} />
                              </span>
                            </div>
                          </Accordion.Button>
                        </div>
                        <Accordion.Body>
                          <div className='row g-4'>
                            {productPriceList?.length ? (
                              productPriceList?.map((productPrice, index) => (
                                <Fragment key={productPrice.id}>
                                  <Controller
                                    name={`product_price_list.${index}.price`}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                      <PriceListItem
                                        tPrice={tPrice!}
                                        onChange={onChange}
                                        vat={getVatById(vat as string)}
                                        value={+(value || 0)}
                                        price={productPriceListValues?.[index]}
                                        unitPrice={sellingPrice!}
                                      />
                                    )}
                                  />
                                  <div className='col-lg-6'>
                                    <label className='form-label required mb-3'>
                                      <FormattedMessage id='FORM.FIELD.TYPE' />
                                    </label>
                                    <div>
                                      <Controller
                                        name={`product_price_list.${index}.price_list`}
                                        control={control}
                                        render={({
                                          field: { name, value, onChange, onBlur },
                                          fieldState: { error },
                                        }) => (
                                          <>
                                            <div className='d-flex gap-3'>
                                              <div className='flex-grow-1'>
                                                <SwiverSelect
                                                  name={name}
                                                  value={value + ''}
                                                  isClearable={false}
                                                  isSearchable={false}
                                                  onChange={(e) => {
                                                    onChange(e ? e.value + '' : '');
                                                  }}
                                                  onBlur={onBlur}
                                                  options={filterPriceLists(
                                                    priceListOptions,
                                                    productPriceListValues,
                                                    +((value as string) || 0)
                                                  )}
                                                  className='swiver-select-solid h-50px'
                                                />
                                              </div>
                                              <div
                                                onClick={() => {
                                                  remove(index);
                                                }}
                                                className='btn btn-icon btn-light-danger h-50px w-50px'>
                                                <KTSVG
                                                  className='svg-icon-1'
                                                  path='/media/icons/duotune/arrows/arr090.svg'
                                                />
                                              </div>
                                            </div>
                                            {error && (
                                              <div className='text-danger mt-3'>
                                                <span role='alert'>
                                                  <FormattedMessage
                                                    id={error?.message}
                                                    values={{
                                                      field: formatMessage({
                                                        id: 'FORM.FIELD.TYPE',
                                                      }),
                                                    }}
                                                  />
                                                </span>
                                              </div>
                                            )}
                                          </>
                                        )}
                                      />
                                    </div>
                                  </div>
                                  <div className='d-none d-lg-block col-6'></div>
                                  <div className='col-lg-6'>
                                    <label className='form-label required mb-3'>
                                      <FormattedMessage id='FORM.PRODUCT.CALCULATION-BASE' />
                                    </label>
                                    <Controller
                                      name={`product_price_list.${index}.calculType`}
                                      control={control}
                                      render={({
                                        field: { name, onBlur, onChange, value },
                                        fieldState: { error },
                                      }) => (
                                        <>
                                          <div className='d-flex gap-3'>
                                            <CustomRadio
                                              name={name}
                                              onChange={(e) => {
                                                onChange(e);
                                                setValue(
                                                  `product_price_list.${index}.calcul_method`,
                                                  productPriceListValues?.[index]?.calcul_method ===
                                                    'ART'
                                                    ? 'FMR'
                                                    : productPriceListValues?.[index]?.calcul_method
                                                );
                                              }}
                                              onBlur={onBlur}
                                              checked={value === '2'}
                                              value={'2'}>
                                              <FormattedMessage id='FORM.PRODUCT.CALCULATION-BASE.PUBLIC' />
                                            </CustomRadio>
                                            <CustomRadio
                                              name={name}
                                              onChange={onChange}
                                              onBlur={onBlur}
                                              checked={value === '1'}
                                              value={'1'}>
                                              <FormattedMessage id='FORM.PRODUCT.CALCULATION-BASE.ARBITRARY' />
                                            </CustomRadio>
                                          </div>
                                          {error && (
                                            <div className='text-danger mt-3'>
                                              <span role='alert'>
                                                <FormattedMessage
                                                  id={error?.message}
                                                  values={{
                                                    field: formatMessage({
                                                      id: 'FORM.PRODUCT.CALCULATION-BASE',
                                                    }),
                                                  }}
                                                />
                                              </span>
                                            </div>
                                          )}
                                        </>
                                      )}
                                    />
                                  </div>
                                  <div className='col-lg-6'>
                                    {productPriceListValues?.[index]?.calculType === '2' && (
                                      <>
                                        <label className='form-label mb-3 required'>
                                          <FormattedMessage id='FORM.PRODUCT.APPLIED-RULES' />
                                        </label>
                                        <Controller
                                          name={`product_price_list.${index}.calcul_method`}
                                          control={control}
                                          render={({
                                            field: { name, onBlur, onChange, value },
                                            fieldState: { invalid, error, isTouched },
                                          }) => (
                                            <>
                                              <div className='d-flex gap-3'>
                                                <CustomRadio
                                                  name={name}
                                                  onChange={onChange}
                                                  onBlur={onBlur}
                                                  checked={value === 'FMR'}
                                                  value='FMR'>
                                                  <FormattedMessage id='FORM.TYPE.FIXED' />
                                                </CustomRadio>
                                                <CustomRadio
                                                  name={name}
                                                  onChange={onChange}
                                                  onBlur={onBlur}
                                                  checked={value === 'RMR'}
                                                  value='RMR'>
                                                  <FormattedMessage id='FORM.TYPE.PERCENT' />
                                                </CustomRadio>
                                              </div>
                                              {isTouched && invalid && (
                                                <div className='text-danger mt-3'>
                                                  <span role='alert'>
                                                    <FormattedMessage
                                                      id={error?.message}
                                                      values={{
                                                        field: formatMessage({
                                                          id: 'FORM.PRODUCT.APPLIED-RULES',
                                                        }),
                                                      }}
                                                    />
                                                  </span>
                                                </div>
                                              )}
                                            </>
                                          )}
                                        />
                                      </>
                                    )}
                                  </div>
                                  <div className='col-lg-6'>
                                    {productPriceListValues?.[index]?.calculType === '1' ||
                                    productPriceListValues?.[index]?.calcul_method === 'FMR' ? (
                                      <Controller
                                        control={control}
                                        name={`product_price_list.${index}.rate`}
                                        render={({ field, fieldState: { error } }) => (
                                          <>
                                            <label className='form-label required mb-3'>
                                              <FormattedMessage id='FORM.FIELD.AMOUNT' />
                                            </label>
                                            <div className='d-flex gap-3 align-items-center'>
                                              <div className='input-group'>
                                                <span
                                                  className='input-group-text py-0 px-1 cursor-pointer'
                                                  onClick={() => {
                                                    Number(field.value) > 0 &&
                                                      field.onChange(
                                                        Number(productPriceListValues[index].rate) -
                                                          1
                                                      );
                                                  }}>
                                                  <KTSVG
                                                    className='svg-icon svg-icon-2hx'
                                                    path='/media/icons/duotune/general/gen036.svg'
                                                  />
                                                </span>
                                                <input
                                                  type='number'
                                                  step='any'
                                                  {...field}
                                                  value={+(field.value || 0)}
                                                  onWheel={(event) => event.currentTarget.blur()}
                                                  className={clsx(
                                                    'form-control form-control-solid flex-grow-1 text-center h-45px'
                                                  )}
                                                  autoComplete='off'
                                                />
                                                <span
                                                  className='input-group-text py-0 px-1 cursor-pointer'
                                                  onClick={() => {
                                                    field.onChange(
                                                      Number(productPriceListValues[index].rate) + 1
                                                    );
                                                  }}>
                                                  <KTSVG
                                                    className='svg-icon svg-icon-2hx'
                                                    path='/media/icons/duotune/general/gen035.svg'
                                                  />
                                                </span>
                                              </div>
                                            </div>
                                            {error && (
                                              <div className='text-danger mt-3'>
                                                <span role='alert'>
                                                  <FormattedMessage
                                                    id={error?.message}
                                                    values={{
                                                      field: formatMessage({
                                                        id: 'FORM.FIELD.AMOUNT',
                                                      }),
                                                    }}
                                                  />
                                                </span>
                                              </div>
                                            )}
                                          </>
                                        )}
                                      />
                                    ) : (
                                      <Controller
                                        control={control}
                                        name={`product_price_list.${index}.margin`}
                                        render={({ field, fieldState: { error } }) => (
                                          <>
                                            <label className='form-label required mb-3'>
                                              <FormattedMessage id='FORM.TYPE.PERCENT' />
                                            </label>
                                            <div className='d-flex gap-3 align-items-center'>
                                              <div className='input-group'>
                                                <span
                                                  className='input-group-text py-0 px-1 cursor-pointer'
                                                  onClick={() => {
                                                    field.onChange(
                                                      +(
                                                        productPriceListValues?.[index]?.margin || 0
                                                      ) - 1
                                                    );
                                                  }}>
                                                  <KTSVG
                                                    className='svg-icon svg-icon-2hx'
                                                    path='/media/icons/duotune/general/gen036.svg'
                                                  />
                                                </span>
                                                <input
                                                  type='number'
                                                  {...field}
                                                  step='any'
                                                  value={
                                                    +(productPriceListValues?.[index]?.margin || 0)
                                                  }
                                                  className={clsx(
                                                    'form-control form-control-solid flex-grow-1 text-center'
                                                  )}
                                                  autoComplete='off'
                                                />
                                                <span
                                                  className='input-group-text py-0 px-1 cursor-pointer'
                                                  onClick={() => {
                                                    field.onChange(
                                                      +(
                                                        productPriceListValues?.[index]?.margin || 0
                                                      ) + 1
                                                    );
                                                  }}>
                                                  <KTSVG
                                                    className='svg-icon svg-icon-2hx'
                                                    path='/media/icons/duotune/general/gen035.svg'
                                                  />
                                                </span>
                                              </div>
                                            </div>
                                            {error && (
                                              <div className='text-danger mt-3'>
                                                <span role='alert'>
                                                  <FormattedMessage
                                                    id={error?.message}
                                                    values={{
                                                      field: formatMessage({
                                                        id: 'FORM.TYPE.PERCENT',
                                                      }),
                                                    }}
                                                  />
                                                </span>
                                              </div>
                                            )}
                                          </>
                                        )}
                                      />
                                    )}
                                  </div>
                                  {productPriceListValues?.[index]?.calculType === '2' && (
                                    <div className='col-lg-6 d-flex flex-column'>
                                      <label className='form-label mb-3'>
                                        <FormattedMessage id='FORM.FIELD.RESULT' />
                                      </label>
                                      <div className='d-flex gap-3 flex-grow-1 flex-wrap align-items-center text-primary fs-7 fw-bold'>
                                        <div className='d-flex gap-1'>
                                          <CurrencyFormatter
                                            amount={getTotalPrice(
                                              +(productPriceListValues?.[index]?.price || 0),
                                              getVatById(vat as string)
                                            )}
                                          />
                                          <sup>
                                            <FormattedMessage id='COMMON.TAX.INCL-TAXES' />
                                          </sup>
                                        </div>
                                        <div className='d-flex gap-1'>
                                          <CurrencyFormatter
                                            amount={+(productPriceListValues?.[index]?.price || 0)}
                                          />
                                          <sup className='text-wrap'>
                                            <FormattedMessage id='COMMON.TAX.EXCL-TAXES' />
                                          </sup>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {productPriceListValues[index]?.calculType === '1' && (
                                    <Controller
                                      control={control}
                                      name={`product_price_list.${index}.tPriceG`}
                                      defaultValue={'true'}
                                      render={({ field: { name, onBlur, onChange, value } }) => (
                                        <div className='col-lg-6'>
                                          <label className='form-label mb-3'>​</label>
                                          <div className='d-flex gap-3'>
                                            <div className='position-relative'>
                                              <CustomRadio
                                                name={name}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                checked={value === 'false'}
                                                value={'false'}
                                                className='w-100 h-45px'>
                                                <FormattedMessage id='COMMON.TAX.INCL-TAXES' />
                                              </CustomRadio>
                                              <div
                                                className={clsx(
                                                  'w-100 text-center fw-semibold fs-6 bottom-m-3',
                                                  value === 'false' && 'text-primary'
                                                )}>
                                                <CurrencyFormatter
                                                  amount={getTotalPrice(
                                                    +(productPriceListValues[index].price || 0),
                                                    getVatById(vat as string)
                                                  )}
                                                />
                                              </div>
                                            </div>
                                            <div className='mb-4 position-relative'>
                                              <CustomRadio
                                                name={name}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                checked={value === 'true'}
                                                value={'true'}
                                                className='w-100'>
                                                <FormattedMessage id='COMMON.TAX.EXCL-TAXES' />
                                              </CustomRadio>
                                              <div
                                                className={clsx(
                                                  'w-100 text-center fw-semibold fs-6 bottom-m-3',
                                                  value === 'true' && 'text-primary'
                                                )}>
                                                <CurrencyFormatter
                                                  amount={
                                                    +(productPriceListValues[index].price || 0)
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    />
                                  )}

                                  {index < productPriceList.length - 1 && (
                                    <div className='border-bottom-dashed border-1 border-gray-400 mt-6 mb-2'></div>
                                  )}
                                </Fragment>
                              ))
                            ) : (
                              <div className='text-center fs-5 fw-bold mb-6'>
                                <FormattedMessage id='FORM.PRODUCT.NO__PRICELIST' />
                              </div>
                            )}
                            {productPriceListValues?.length! < priceListOptions?.length && (
                              <div className='mt-4 d-flex justify-content-end'>
                                <div
                                  className='btn btn-primary btn-sm'
                                  onClick={() => {
                                    append({
                                      price_list:
                                        priceListOptions.find(
                                          (option) =>
                                            !productPriceListValues?.find(
                                              (price) => price.price_list === option.value + ''
                                            )
                                        )?.value + '' || '',
                                      calcul_method: 'FMR',
                                      calculType: '2',
                                      price: '0',
                                      rate: 0,
                                      margin: '0',
                                      tPriceG: 'true',
                                    });
                                  }}>
                                  <FormattedMessage id='FORM.PRODUCT.ADD_PRICE' />
                                </div>
                              </div>
                            )}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    {model === 'materiel' && (
                      <Accordion className='rounded-3'>
                        <Accordion.Item eventKey='0'>
                          <div className='accordion-header'>
                            <Accordion.Button className='bg-body'>
                              <div className='d-flex align-items-center gap-3'>
                                {errors?.logistics_infos && (
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen044.svg'
                                    className='svg-icon-1 svg-icon-danger'
                                  />
                                )}
                                <span className='fw-bold fs-2 text-dark'>
                                  <FormattedMessage id='PRODUCT.LOGISTIC_INFORMATIONS' />
                                </span>
                              </div>
                            </Accordion.Button>
                          </div>
                          <Accordion.Body>
                            <div className='row g-4'>
                              {logisticsInfoList?.length ? (
                                logisticsInfoList?.map((logisticInfo, index) => (
                                  <Fragment key={logisticInfo.id}>
                                    <div className='col-lg-6 mb-4'>
                                      <label className='form-label required mb-3'>
                                        <FormattedMessage id='STOCK-MOUVMENT.WAREHOUSE' />
                                      </label>
                                      <Controller
                                        name={`logistics_infos.${index}.warehouse`}
                                        control={control}
                                        render={({
                                          field: { name, onBlur, onChange, value },
                                          fieldState: { error },
                                        }) => (
                                          <>
                                            <SwiverSelect
                                              name={name}
                                              value={value}
                                              onChange={(e) => {
                                                onChange(e ? e.value : '');
                                              }}
                                              onBlur={onBlur}
                                              options={filterWarehouseList(
                                                warehouseOptions,
                                                logisticInfos!,
                                                value + ''
                                              )}
                                              isDisabled={
                                                !!selectedWarehouse &&
                                                ['1', '2', '5', '3', '7', '9'].includes(
                                                  documentType + ''
                                                )
                                              }
                                              isClearable={false}
                                              className='swiver-select-solid h-50px'
                                            />
                                            {error && (
                                              <div className='text-danger mt-3'>
                                                <span role='alert'>
                                                  <FormattedMessage
                                                    id={error?.message}
                                                    values={{
                                                      field: formatMessage({
                                                        id: 'STOCK-MOUVMENT.WAREHOUSE',
                                                      }),
                                                    }}
                                                  />
                                                </span>
                                              </div>
                                            )}
                                          </>
                                        )}
                                      />
                                    </div>
                                    <div className='col-lg-3 col-md-6 mb-4'>
                                      <label className='form-label mb-3'>
                                        <FormattedMessage id='FORM.FIELD.MIN' />
                                      </label>
                                      <Controller
                                        name={`logistics_infos.${index}.min_stock`}
                                        defaultValue={0}
                                        control={control}
                                        render={({
                                          field: { name, value, onBlur, onChange },
                                          fieldState: { invalid, isTouched, error },
                                        }) => (
                                          <>
                                            <div className='d-flex gap-3 align-items-center'>
                                              <div className='input-group'>
                                                <span
                                                  className='input-group-text py-0 px-1 cursor-pointer'
                                                  onClick={() => {
                                                    onChange(Number(value) - 1);
                                                  }}>
                                                  <KTSVG
                                                    className='svg-icon svg-icon-2hx'
                                                    path={toAbsoluteUrl(
                                                      '/media/icons/duotune/general/gen036.svg'
                                                    )}
                                                  />
                                                </span>
                                                <input
                                                  type='text'
                                                  name={name}
                                                  onBlur={onBlur}
                                                  onChange={onChange}
                                                  value={value}
                                                  className={clsx(
                                                    'form-control form-control-solid flex-grow-1 h-50px text-center'
                                                  )}
                                                  autoComplete='off'
                                                />
                                                <span
                                                  className='input-group-text py-0 px-1 cursor-pointer'
                                                  onDoubleClick={(e) => {
                                                    e.preventDefault();
                                                  }}
                                                  onClick={() => {
                                                    onChange(Number(value) + 1);
                                                  }}>
                                                  <KTSVG
                                                    className='svg-icon svg-icon-2hx'
                                                    path={toAbsoluteUrl(
                                                      '/media/icons/duotune/general/gen035.svg'
                                                    )}
                                                  />
                                                </span>
                                              </div>
                                            </div>
                                            {isTouched && invalid && (
                                              <div className='text-danger mt-3'>
                                                <span role='alert'>
                                                  <FormattedMessage
                                                    id={error?.message}
                                                    values={{
                                                      field: formatMessage({
                                                        id: 'PRODUCT.FIELD.MIN-STOCK',
                                                      }),
                                                    }}
                                                  />
                                                </span>
                                              </div>
                                            )}
                                          </>
                                        )}
                                      />
                                    </div>
                                    <div className='col-lg-3 col-md-6 mb-4'>
                                      <label className='form-label mb-3'>
                                        <FormattedMessage id='FORM.FIELD.MAX' />
                                      </label>
                                      <Controller
                                        name={`logistics_infos.${index}.max_stock`}
                                        defaultValue={0}
                                        control={control}
                                        render={({
                                          field: { name, value, onBlur, onChange },
                                          fieldState: { invalid, isTouched, error },
                                        }) => (
                                          <>
                                            <div className='d-flex gap-3 align-items-center'>
                                              <div className='input-group'>
                                                <span
                                                  className='input-group-text py-0 px-1 cursor-pointer'
                                                  onClick={() => {
                                                    onChange(Number(value) - 1);
                                                  }}>
                                                  <KTSVG
                                                    className='svg-icon svg-icon-2hx'
                                                    path={toAbsoluteUrl(
                                                      '/media/icons/duotune/general/gen036.svg'
                                                    )}
                                                  />
                                                </span>
                                                <input
                                                  type='text'
                                                  name={name}
                                                  onBlur={onBlur}
                                                  onChange={onChange}
                                                  value={value}
                                                  onDoubleClick={(e) => {
                                                    e.preventDefault();
                                                  }}
                                                  className={clsx(
                                                    'form-control form-control-solid flex-grow-1 h-50px text-center'
                                                  )}
                                                  autoComplete='off'
                                                />
                                                <span
                                                  className='input-group-text py-0 px-1 cursor-pointer'
                                                  onClick={() => {
                                                    onChange(Number(value) + 1);
                                                  }}>
                                                  <KTSVG
                                                    className='svg-icon svg-icon-2hx'
                                                    path={toAbsoluteUrl(
                                                      '/media/icons/duotune/general/gen035.svg'
                                                    )}
                                                  />
                                                </span>
                                              </div>
                                            </div>
                                            {isTouched && invalid && (
                                              <div className='text-danger mt-3'>
                                                <span role='alert'>
                                                  <FormattedMessage
                                                    id={error?.message}
                                                    values={{
                                                      field: formatMessage({
                                                        id: 'PRODUCT.FIELD.MAX-STOCK',
                                                      }),
                                                      min: logisticInfos?.[0]?.min_stock,
                                                    }}
                                                  />
                                                </span>
                                              </div>
                                            )}
                                          </>
                                        )}
                                      />
                                    </div>
                                    <div className='d-flex'>
                                      <div className='form-check form-check-custom form-check-solid'>
                                        <input
                                          className='form-check-input'
                                          {...register(`logistics_infos.${index}.allow_alert`)}
                                          type='checkbox'
                                          id='disable-alert'
                                        />
                                        <label className='form-check-label' htmlFor='disable-alert'>
                                          <FormattedMessage id='FORM.PRODUCT.DISABLE_ALERT' />
                                        </label>
                                      </div>
                                      {logisticsInfoList.length > 1 && (
                                        <div className=' ms-auto'>
                                          <div
                                            onClick={() => {
                                              removeLogisticsInfo(index);
                                            }}
                                            className='btn btn-sm btn-icon btn-light-danger'>
                                            <KTSVG
                                              className='svg-icon-1'
                                              path={toAbsoluteUrl(
                                                '/media/icons/duotune/arrows/arr090.svg'
                                              )}
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    {index < logisticsInfoList.length - 1 && (
                                      <div className='border-bottom-dashed border border-1 border-gray-400 mt-6 mb-2'></div>
                                    )}
                                  </Fragment>
                                ))
                              ) : (
                                <div className='text-center fs-5 fw-bold mb-6'>
                                  <FormattedMessage id='FORM.PRODUCT.NO__WAREHOUSE_LIST' />
                                </div>
                              )}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    )}
                  </div>
                )}
                {currentStep === 2 && (
                  <div className='d-flex flex-column gap-4'>
                    <div className='border rounded-3 p-8'>
                      <div className='mb-4'>
                        <h2 className='fw-bold d-flex align-items-center text-dark mb-6'>
                          <FormattedMessage id='FORM.STEPPER.PRODUCT.ADDITIONAL-INFORMATIONS' />
                        </h2>
                      </div>
                      <div className='row fv-row'>
                        <div className='mb-4 d-flex justify-content-center'>
                          <Controller
                            control={control}
                            name='image'
                            render={({
                              field: { name, onBlur, onChange, value },
                              fieldState: { error, isTouched },
                            }) => (
                              <>
                                <ImageCropper value={value} onChange={onChange} />
                              </>
                            )}
                          />
                        </div>
                        <div className='mb-4'>
                          <label className='form-label fw-normal fs-7'>
                            <FormattedMessage id='PRODUCT.FIELD.BRAND' />
                          </label>
                          <Controller
                            control={control}
                            name='brand'
                            render={({
                              field: { name, value, onChange, onBlur },
                              fieldState: { invalid, isTouched, error },
                            }) => (
                              <>
                                <SwiverSelect
                                  className='swiver-select-solid swiver-select-lg'
                                  name={name}
                                  value={value}
                                  isClearable={false}
                                  onChange={(e) => {
                                    onChange(e ? e.value : '');
                                  }}
                                  onBlur={onBlur}
                                  options={brandsOptions}
                                />
                                {invalid && isTouched && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block text-danger'>
                                      <span role='alert'>
                                        <FormattedMessage
                                          id={error?.message}
                                          values={{
                                            field: formatMessage({ id: 'PRODUCT.FIELD.BRAND' }),
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          />
                        </div>
                        <div className='col-lg-6 mb-4'>
                          <label className='form-label fw-normal fs-7'>
                            <FormattedMessage id='PRODUCT.FIELD.CATEGORIES' />
                          </label>
                          <Controller
                            name='category'
                            control={control}
                            render={({
                              field: { name, value, onChange, onBlur },
                              fieldState: { invalid, isTouched, error },
                            }) => (
                              <>
                                <SwiverSelect
                                  className='swiver-select-solid'
                                  name={name}
                                  value={value}
                                  isClearable={false}
                                  onChange={(e) => {
                                    onChange(e ? e.value : '');
                                  }}
                                  onBlur={onBlur}
                                  options={categoriesOptions}
                                />
                                {invalid && isTouched && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block text-danger'>
                                      <span role='alert'>
                                        <FormattedMessage
                                          id={error?.message}
                                          values={{
                                            field: formatMessage({
                                              id: 'PRODUCT.FIELD.CATEGORIES',
                                            }),
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          />
                        </div>
                        <div className='col-lg-6 mb-4'>
                          <label className='form-label fw-normal fs-7'>
                            <FormattedMessage id='FORM.FIELD.UNIT' />
                          </label>
                          <Controller
                            name='unit'
                            control={control}
                            render={({
                              field: { name, value, onChange, onBlur },
                              fieldState: { invalid, isTouched, error },
                            }) => (
                              <>
                                <SwiverSelect
                                  className='swiver-select-solid swiver-select-lg flex-grow-1 h-50px'
                                  name={name}
                                  isClearable={false}
                                  value={value}
                                  onChange={(e) => {
                                    if (+(e?.count_qty_fields || 0) === 2) {
                                      setValue('has_serial_number', false);
                                    }
                                    setValue('count_qty_fields', +(e?.count_qty_fields || 0));
                                    onChange(e ? e.value : '');
                                  }}
                                  onBlur={onBlur}
                                  options={filtredUnitOptions}
                                />

                                {invalid && isTouched && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block text-danger'>
                                      <span role='alert'>
                                        <FormattedMessage
                                          id={error?.message}
                                          values={{
                                            field: formatMessage({
                                              id: 'FORM.FIELD.UNIT',
                                            }),
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          />
                        </div>
                        <div className='col-lg-6 mb-4'>
                          <label className='form-label fw-normal fs-7'>
                            <FormattedMessage id='PRODUCT.FIELD.INTERNAL-REF' />
                          </label>
                          <input
                            type='text'
                            {...register('internal_ref')}
                            className='form-control form-control-solid'
                          />
                          {errors?.internal_ref && touchedFields?.internal_ref && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger'>
                                <span role='alert'>
                                  <FormattedMessage
                                    id={'' + errors?.internal_ref.message}
                                    values={{
                                      field: formatMessage({ id: 'PRODUCT.FIELD.INTERNAL-REF' }),
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='col-lg-6 mb-4'>
                          <label className='form-label fw-normal fs-7'>
                            <FormattedMessage id='PRODUCT.FIELD.MANUFACTURER-REF' />
                          </label>
                          <input
                            type='text'
                            {...register('manufacturer_ref')}
                            className='form-control form-control-solid'
                          />
                          {errors?.manufacturer_ref && touchedFields?.manufacturer_ref && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger'>
                                <span role='alert'>
                                  <FormattedMessage
                                    id={'' + errors?.manufacturer_ref.message}
                                    values={{
                                      field: formatMessage({
                                        id: 'PRODUCT.FIELD.MANUFACTURER-REF',
                                      }),
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div>
                          <label className='form-label fw-normal fs-7'>
                            <FormattedMessage id='FORM.FIELD.DESCRIPTION' />
                          </label>
                          <div className='bg-light w-100'>
                            <Controller
                              control={control}
                              name='description'
                              render={({ field: { name, onBlur, onChange, value } }) => (
                                <CKEditor
                                  editor={Editor}
                                  data={value || ''}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    onChange(data);
                                  }}
                                  onBlur={onBlur}
                                  config={{
                                    language: locale,
                                    initialData: '',
                                  }}
                                />
                              )}
                            />
                          </div>
                          {errors?.description && touchedFields?.description && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger'>
                                <span role='alert'>
                                  <FormattedMessage
                                    id={'' + errors?.description.message}
                                    values={{
                                      field: formatMessage({ id: 'FORM.FIELD.DESCRIPTION' }),
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='border rounded-3 p-8'>
                      {isBarCodeShown ? (
                        <div className='row'>
                          <div className='col-10 mb-4'>
                            <label className='form-label fw-normal fs-7'>
                              <FormattedMessage id='FORM.PRODUCT.BARECODES' />
                            </label>
                            <div className='d-flex gap-3 mb-2'>
                              <input
                                step='any'
                                type='number'
                                className='form-control form-control-solid flex-grow-1'
                                value={barcodeField}
                                onChange={(e) => {
                                  setBarcodeField(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    addBarcode();
                                    e.stopPropagation();
                                    e.preventDefault();
                                  }
                                }}
                              />
                              <div className='btn btn-light-success btn-icon' onClick={addBarcode}>
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr087.svg'
                                  className='svg-icon-1'
                                />
                              </div>
                            </div>
                            <div className='d-flex gap-3 flex-wrap'>
                              {barcodeFields.map((barcode, index) => (
                                <div key={barcode.id} className='badge badge-primary d-flex px-2'>
                                  <span>{barcodes?.[index]}</span>
                                  <span className='h-100 border-gray-300 border-start ms-2 me-1'></span>
                                  <span
                                    onClick={() => {
                                      removeBarcode(index);
                                    }}>
                                    <i className='fa-light fa-x text-white fw-semibold cursor-pointer' />
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className='btn btn-sm btn-primary'
                          onClick={() => {
                            setIsBarCodeShown(true);
                          }}>
                          <FormattedMessage id='FORM.PRODUCT.NO-BARECODES' />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn btn-bg-light btn-color-primary me-auto'
            onClick={previousStep}>
            <KTSVG
              path={
                locale === 'ar'
                  ? '/media/icons/duotune/arrows/arr064.svg'
                  : '/media/icons/duotune/arrows/arr063.svg'
              }
              className='svg-icon-4 me-1'
            />
            <FormattedMessage id='FORM.BACK' />
          </button>
          {canQuickAdd && (
            <button
              type='button'
              disabled={isSubmitting}
              onClick={handleSubmit(async (values) => {
                await submitStep(values, true);
              })}
              className='btn btn-bg-light btn-color-primary'>
              <FormattedMessage id='FORM.QUICK-ADD' />
            </button>
          )}
          <button className='btn btn-primary' disabled={isSubmitting}>
            <span className='indicator-label'>
              {isSubmitting && <span className='spinner-border align-middle me-2'></span>}
              {!isSubmit && (
                <>
                  <FormattedMessage id='FORM.CONTINUE' />
                  <KTSVG
                    path={
                      locale === 'ar'
                        ? '/media/icons/duotune/arrows/arr063.svg'
                        : '/media/icons/duotune/arrows/arr064.svg'
                    }
                    className='svg-icon-3 ms-2 me-0'
                  />
                </>
              )}
              {isSubmit && <FormattedMessage id='FORM.SUBMIT' />}
            </span>
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
type CheckboxesProps = {
  name: string;
  options: Tax[];
  value: (string | number)[];
  onChange: (value: (string | number)[]) => void;
};
const Checkboxes = ({ options, name, value, onChange }: CheckboxesProps) => {
  useEffect(() => {
    onChange(value?.map((v) => v.toString()));
  }, []);

  const toggleSelected = (id: string | number) => {
    if (value?.includes(id.toString())) {
      onChange(value.filter((item) => item.toString() !== id.toString()));
    } else {
      onChange([...value, id.toString()]);
    }
  };
  return (
    <>
      {options
        .filter((option) => option.is_product_tax_only && option.enabled)
        .map((option, index) => (
          <CustomCheckbox
            onChange={() => {
              toggleSelected(option.id);
            }}
            key={option.id}
            checked={value?.includes(option.id.toString())}
            name={name}
            value={option.id.toString()}>
            {option.name}
          </CustomCheckbox>
        ))}
    </>
  );
};
