import clsx from 'clsx';
import React, { useContext, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

function AddDepenseStep4() {
  const {
    register,
    formState: { errors, touchedFields },
  } = useFormContext();

  return (
    <div className='w-100'>
      <div className='mb-10'>
        <label className='form-label mb-3'>
          <FormattedMessage id='DOCUMENT.DETAILS_BLOCK.DOCUMENT_NOTE' />
        </label>
        <Controller
          name='note'
          render={({
            field: { name, onBlur, onChange, value },
            fieldState: { error, isTouched },
          }) => (
            <>
              <textarea
                className={clsx('form-control form-control-lg form-control-solid', {
                  'is-valid': isTouched && !error,
                  'is-invalid': isTouched && error,
                })}
                value={value}
                onChange={onChange}
                name={name}
                onBlur={onBlur}
                autoComplete='off'
              />
              {error && isTouched && (
                <div className='text-danger'>
                  <span role='alert'>
                    <FormattedMessage
                      id={error.message + ''}
                      values={{
                        field: FormattedMessage({ id: 'DOCUMENT.DETAILS_BLOCK.DOCUMENT_NOTE' }),
                      }}
                    />
                  </span>
                </div>
              )}
            </>
          )}
        />
      </div>
    </div>
  );
}
export { AddDepenseStep4 };
