import axios from 'axios';
import { ChecksAndDraftsResponse } from '../../../app/models/ChecksAndDraftResponse';

export type GetChecksAndDraftsPayload = {
  balance?: number | string;
  company: number | string;
  bankAccount?: number | string;
  document?: number | string;
  query?: string;
  contact?: number | string;
  offset?: number;
  limit?: number;
  dateBegin?: string | Date;
  dateEnd?: string | Date;
  dueDateBegin?: string | Date;
  dueDateEnd?: string | Date;
  amount?: number;
  method?: 'CHECK' | 'DRAFT' | 'ALL';
  type?: 'in' | 'out';
  status?: '' | 'paid' | 'unpaid' | 'in_progress';
  sort?: '' | 'asc' | 'desc';
  'sort-field'?: string;
  dateType: 'date' | 'dueDate';
  late: 'true' | 'all';
  shouldRefetch?: boolean;
};

export interface CheckOrDraftPayload {
  payments: Payment[];
}

export interface Payment {
  draftNumber: string;
  bankAccountDestinationNumber: string;
  bankAccountSource: string;
  bankAccountDestination: string;
  bank_destination_extra: string;
  bank_source_extra: string;
  bankSource: string;
  bankAccountSourceNumber: string;
  dueDate: string;
  checkNumber: string;
  bankExtra: string;
  bank: string;
  note: string;
  holder: string;
  balance: string;
  exchange: string;
  company: string;
  document: string;
  contact: string;
  paymentMethod: string;
  date: string;
  amount: string;
}

export const getChecksAndDrafts = (payload: Partial<GetChecksAndDraftsPayload | any>) => {
  return axios.get<ChecksAndDraftsResponse | any>('/secure/payment/history', {
    params: payload,
  });
};

export const deleteCheckOrDraft = (id: string | number) => {
  return axios.delete(`/secure/payment/v2/${id}/delete`);
};

export const postCheckOrDraft = (payload: CheckOrDraftPayload) => {
  return axios.post('/secure/payment/v2/new', payload);
};

export const toPaid = (id: number | string) => {
  return axios.put(`/secure/payment/${id}/to_paid`, {});
};
export const toUnpaid = (id: number | string) => {
  return axios.put(`/secure/payment/${id}/to_unpaid`, {});
};
