import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../setup/Store';
import ChecksList from '../../../components/ChecksList/ChecksList';
import StyledCurrency from '../../../components/StyledCurrency/StyledCurrency';
import { KTSVG } from '../../../helpers';
import { Balance } from '../../../models/CashManagementResponse';
import { CheckOrDraft } from '../../../models/ChecksAndDraftResponse';

const BankDepositStep3 = ({
  balance,
  checks,
  drafts,
  method,
  getChecksOrDrafts,
  isLoading = true,
}: {
  method: 'CHECK' | 'DRAFT' | 'CASH';
  balance: Balance;
  checks?: CheckOrDraft[];
  drafts?: CheckOrDraft[];
  getChecksOrDrafts: () => void;
  isLoading?: boolean;
}) => {
  const [availableBalance, setAvailableBalance] = useState(0);
  const currency = useSelector(
    (state: RootState) => state.auth.user?.current_company.setting.default_currency
  );
  useEffect(() => {
    if (
      (method === 'CHECK' && checks === undefined) ||
      (method === 'DRAFT' && drafts === undefined)
    ) {
      getChecksOrDrafts();
    }
  }, [method]);

  useEffect(() => {
    setAvailableBalance(
      Number(
        balance.contents.find((content) => content.payment_method.method_abrev === 'CASH')
          ?.amount || 0
      )
    );
  }, [balance]);
  const { setValue } = useFormContext();
  const { formatMessage, formatNumber } = useIntl();
  const intl = useIntl();

  return (
    <>
      {method === 'CASH' ? (
        <div className='d-flex justify-content-center'>
          <div className='mw-500px'>
            <div className='d-flex justify-content-between'>
              <label className='form-label mb-10 required'>
                <FormattedMessage id='BANK.BALANCE.DEPOSITED' />
              </label>
              <div>
                <FormattedMessage id='BANK.AVAILABLEBALANCE' />
                <StyledCurrency
                  amount={availableBalance}
                  amountClassName='fw-bold text-primary fs-5'
                  supClassName='fs-8 text-muted fw-bold'
                  containerClassName='ms-auto d-flex gap-1 align-items-center'
                />
              </div>
            </div>
            <Controller
              name='cashAmount'
              rules={{
                required: 'FORM.ERRORS.REQUIRED',
                validate: (value: string | number) => {
                  return isNaN(value as number)
                    ? 'FORM.ERRORS.NUMBER'
                    : Number(value) <= 0
                    ? 'FORM.ERRORS.GREATER_THAN'
                    : Number(value) > availableBalance
                    ? 'FORM.ERRORS.MAX'
                    : true;
                },
              }}
              render={({
                field: { name, onBlur, onChange, value },
                fieldState: { error, isTouched },
              }) => (
                <>
                  <div className='input-group'>
                    <div
                      onClick={() => {
                        if (isNaN(value)) return;
                        onChange(Number(value) - 1 || 0);
                      }}
                      className='btn btn-light-primary d-inline-flex align-items-center justify-content-center'>
                      <KTSVG
                        className='svg-icon-2 me-0'
                        path='/media/icons/duotune/arrows/arr090.svg'
                      />
                    </div>
                    <input
                      type='text'
                      name={name}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      className='form-control form-control-flush fs-2 text-center'
                      placeholder={intl.formatMessage({ id: 'DOCUMENT.DETAILS.AMOUNT' })}
                    />
                    <div
                      onClick={() => {
                        if (isNaN(value)) return;
                        onChange(Number(value) + 1 || 0);
                      }}
                      className='btn btn-light-primary d-inline-flex align-items-center justify-content-center'>
                      <KTSVG
                        className='svg-icon-2 me-0'
                        path='/media/icons/duotune/arrows/arr075.svg'
                      />
                    </div>
                  </div>
                  {error && isTouched && (
                    <div className='text-danger mt-3'>
                      <span role='alert'>
                        <FormattedMessage
                          id={error.message}
                          values={{
                            field: formatMessage({ id: 'BANK.BALANCE.DEPOSITED' }),
                            min: formatNumber(0, { style: 'currency', currency }),
                            max: formatNumber(availableBalance, { style: 'currency', currency }),
                          }}
                        />
                      </span>
                    </div>
                  )}
                </>
              )}
            />
          </div>
        </div>
      ) : (
        <>
          {(method === 'CHECK' || method === 'DRAFT') && (
            <>
              <Controller
                name={method === 'CHECK' ? 'checks' : 'drafts'}
                rules={{
                  validate: (value) => value.length > 0 || `BANK.DEPOSIT.ERRORS.REQUIRED.${method}`,
                }}
                render={({
                  field: { name, onBlur, onChange, value },
                  fieldState: { error, isTouched },
                }) => (
                  <>
                    <ChecksList
                      isModal
                      loading={isLoading}
                      type={method}
                      checks={checks}
                      drafts={drafts}
                      selectedChecks={value}
                      selectedDrafts={value}
                      onCheckSumChange={(value) => {
                        if (method === 'CHECK') setValue('checkSum', value);
                      }}
                      onDraftSumChange={(value) => {
                        if (method === 'DRAFT') setValue('draftSum', value);
                      }}
                      className='mh-500px'
                      onChecksChange={(e) => {
                        if (method === 'CHECK') onChange(e);
                      }}
                      onDraftsChange={(e) => {
                        if (method === 'DRAFT') onChange(e);
                      }}
                    />
                    {error && (
                      <div className='text-danger mt-3'>
                        <span role='alert'>
                          <FormattedMessage id={error.message} />
                        </span>
                      </div>
                    )}
                  </>
                )}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
export { BankDepositStep3 };
