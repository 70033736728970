import { useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { readExpenseCategories } from '../../../setup/redux/reducers/UtilsReducers';
import { RootState } from '../../../setup/Store';
import { StepperComponent } from '../../utils/ts/components';
import { KTSVG } from '../../helpers';
import BankModalProvider from '../../ModalProviders/BankModal';
import DoneLottie from '../../components/DoneLottie/DoneLottie';
import Lottie from '../../components/Lottie';
import pick from 'lodash.pick';
import {
  AddDepenseStep1,
  AddDepenseStep2,
  AddDepenseStep3,
  AddDepenseStep4,
  AddDepenseStep5,
} from './stepsAddDepense';
import { format } from 'date-fns/esm';
import { depenseValidationSchemas } from './DepenseValidationSchemas';
import produce from 'immer';
import { toast } from 'react-toastify';
import { delayPromise } from '../../utils/utils';
import { Modal } from 'react-bootstrap';
import clsx from 'clsx';
const defaultValues = {
  paymentMethod: 'CASH',
  amount: 0,
  expenseCategory: '',
  contact: '',
  date: new Date(),
  dueDate: new Date(),
  bankAccountSource: '',
  checkNumber: '',
  note: '',
};
export default function AddDepenseWizzard({
  balanceId,
  show = false,
  onHide = () => {},
}: {
  balanceId?: number;
  show?: boolean;
  onHide?: () => void;
}) {
  const [currentSchema, setCurrentSchema] = useState(depenseValidationSchemas[0]);
  const company = useSelector((state: RootState) => state.auth.user?.current_company?.id) as number;
  const [isloading, setisLoading] = useState(false);
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [submittedForm, setSubmittedForm] = useState(false);
  const [isSubmitButton, setSubmitButton] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [canQuickAdd, setCanQuickAdd] = useState(false);
  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(currentSchema),
    defaultValues,
  });
  const [paymentMethod, amount] = methods.watch(['paymentMethod', 'amount']);
  const { formatMessage } = useIntl();

  const {
    formState: { errors },
  } = methods;
  const dispatch = useDispatch();
  const [isSubmited, setisSubmited] = useState(false);
  const expenseCategories = useSelector((state: RootState) => state.utils.expenseCategories);

  const resetForm = () => {
    methods.reset(defaultValues, { keepErrors: false, keepValues: false });
    stepper.current?.goFirst();
    setSubmitButton(false);
    setSubmittedForm(false);
    setisSubmited(false);
    setCanQuickAdd(false);
    setCurrentSchema(depenseValidationSchemas[0]);
  };

  const submitStep = async (values: any) => {
    if (!stepper.current) {
      return;
    }
    if (isSubmitButton) {
      setIsSubmitting(true);
      try {
        const fields = ['paymentMethod', 'amount', 'expenseCategory', 'contact', 'date', 'note'];
        const pickedFields = pick(
          values,
          values.paymentMethod === 'CHECK'
            ? [...fields, 'checkNumber', 'bankAccountSource', 'dueDate']
            : fields
        );
        const updatedFields = produce(pickedFields, (draft: any) => {
          if (draft.paymentMethod === 'CHECK') draft.dueDate = format(draft.dueDate, 'yyyy-MM-dd');
          draft.balance = balanceId;
          draft.amount = +draft.amount;
          draft.date = format(draft.date, 'yyyy-MM-dd');
        });
        await toast.promise(delayPromise(axios.post('/secure/expense/new/v2', updatedFields)), {
          pending: formatMessage({ id: 'EXPENSE.ADDING' }),
          success: formatMessage({ id: 'EXPENSE.ADDED' }),
          error: formatMessage({ id: 'EXPENSE.ADDING.ERROR' }),
        });
        setisSubmited(true);
        setSubmittedForm(true);
        setTimeout(() => {
          hideModal();
        }, 2500);
        stepper.current.goLast();
        setIsSubmitting(false);
      } catch (error) {
        setIsSubmitting(false);
        return;
      }
    }
    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      setCanQuickAdd(stepper.current.currentStepIndex + 1 === 3);
      setSubmitButton(stepper.current.currentStepIndex + 1 === 4);
      setCurrentSchema(depenseValidationSchemas[stepper.current.currentStepIndex]);
      stepper.current.goNext();
    }
  };
  const quickAdd = async (values: any) => {
    setIsSubmitting(true);
    try {
      const fields = ['paymentMethod', 'amount', 'expenseCategory', 'contact', 'date', 'note'];
      const pickedFields = pick(
        values,
        values.paymentMethod === 'CHECK'
          ? [...fields, 'checkNumber', 'bankAccountSource', 'dueDate']
          : fields
      );
      const updatedFields = produce(pickedFields, (draft: any) => {
        if (draft.paymentMethod === '') draft.dueDate = format(draft.dueDate, 'yyyy-MM-dd');
        draft.balance = balanceId;
        draft.amount = +draft.amount;
        draft.date = format(draft.date, 'yyyy-MM-dd');
      });
      await toast.promise(delayPromise(axios.post('/secure/expense/new/v2', updatedFields)), {
        pending: formatMessage({ id: 'EXPENSE.ADDING' }),
        success: formatMessage({ id: 'EXPENSE.ADDED' }),
        error: formatMessage({ id: 'EXPENSE.ADDING.ERROR' }),
      });
      setisSubmited(true);
      setSubmittedForm(true);
      stepper?.current?.goLast();
      setIsSubmitting(false);
      setTimeout(() => {
        hideModal();
      }, 2500);
    } catch (error) {
      setIsSubmitting(false);
      return;
    }
  };
  const hideModal = () => {
    resetForm();
    onHide();
  };
  const prevStep = () => {
    if (!stepper.current) {
      return;
    }
    setCanQuickAdd(stepper.current.currentStepIndex - 1 === 3);

    setSubmitButton(false);
    stepper.current.goPrev();

    setCurrentSchema(depenseValidationSchemas[stepper.current.currentStepIndex - 1]);
  };
  const intl = useIntl();
  const locale = useSelector((state: RootState) => state.utils.locale);
  const currency = useSelector(
    (state: RootState) => state.auth.user?.current_company.setting.default_currency
  );
  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }

    loadStepper();
  }, [stepperRef, show]);
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement);
  };
  return (
    <Modal
      size='xl'
      centered
      show={show}
      onHide={hideModal}
      onShow={() => {
        if (expenseCategories === undefined) dispatch(readExpenseCategories());
      }}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id='DROPDOWN.ACTIONS.ADD_DEPENSE' />
        </Modal.Title>
      </Modal.Header>
      {isloading && (
        <div className='position-absolute top-0 right-0  w-100 h-100 d-flex justify-content-center align-items-center bg-body opacity-5'>
          <div className='opacity-100'>
            <Lottie />
          </div>
        </div>
      )}
      {isSubmited && !isloading && (
        <div
          id='completed'
          className='d-flex flex-column gap-3 align-items-center justify-content-center w-100 h-100 p-12'>
          <DoneLottie height={150} width={250} />
          <div className='fs-1 text-dark fw-bold'>
            <FormattedMessage id='EXPENSE.ADDED' />
          </div>
          <div className='text-muted fs-4'>
            <FormattedMessage
              id='EXPENSE.ADDED.SUCCESS'
              values={{
                type: intl.formatMessage({
                  id: `PAYMENT.${paymentMethod}`,
                }),
                amount: intl.formatNumber(amount, { style: 'currency', currency }),
              }}
            />
          </div>
        </div>
      )}
      {!isSubmited && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(submitStep)}>
            <Modal.Body className='align-items-center'>
              <div ref={stepperRef} className='stepper stepper-links d-flex flex-column '>
                <div className='stepper-nav mb-5'>
                  <div className='stepper-item current' data-kt-stepper-element='nav'>
                    <h3 className='stepper-title'>
                      <FormattedMessage id={'STEPS.ADD_EXPENSE.TITLE1'} />
                    </h3>
                  </div>
                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    <h3 className='stepper-title'>
                      <FormattedMessage id={'STEPS.ADD_EXPENSE.TITLE2'} />
                    </h3>
                  </div>
                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    <h3 className='stepper-title'>
                      <FormattedMessage id='STEPS.ADD_EXPENSE.TITLE3' />
                    </h3>
                  </div>
                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    <h3 className='stepper-title'>
                      <FormattedMessage id='STEPS.ADD_EXPENSE.TITLE4' />
                    </h3>
                  </div>
                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    <h3 className='stepper-title'>
                      <FormattedMessage id='STEPS.ADD_EXPENSE.TITLE5' />
                    </h3>
                  </div>
                </div>
                <div className='mx-auto w-100 mw-800px pt-15 pb-10'>
                  <div className='current' data-kt-stepper-element='content'>
                    {/* <Step1 /> */}
                    <AddDepenseStep1 />
                  </div>
                  <div data-kt-stepper-element='content'>
                    {/* <Step2 /> */}
                    <AddDepenseStep2 />
                  </div>
                  <div data-kt-stepper-element='content'>
                    {/* <Step3 /> */}
                    <BankModalProvider>
                      <AddDepenseStep3 />
                    </BankModalProvider>
                  </div>
                  <div data-kt-stepper-element='content'>
                    {/* <Step4 /> */}
                    <AddDepenseStep4 />
                  </div>
                  <div data-kt-stepper-element='content'>
                    {/* <Step5 /> */}
                    <AddDepenseStep5 />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {!submittedForm && (
                <>
                  <button
                    onClick={prevStep}
                    type='button'
                    className='btn btn-lg btn-light'
                    data-kt-stepper-action='previous'>
                    <KTSVG
                      path={
                        locale === 'ar'
                          ? '/media/icons/duotune/arrows/arr064.svg'
                          : '/media/icons/duotune/arrows/arr063.svg'
                      }
                      className='svg-icon-4 me-1'
                    />
                    <FormattedMessage id='FORM.BACK' />
                  </button>
                  {canQuickAdd && (
                    <button
                      type='button'
                      disabled={isSubmitting}
                      onClick={methods.handleSubmit(quickAdd)}
                      className='btn btn-bg-light btn-color-primary ms-auto'>
                      <span className='indicator-label'>
                        {isSubmitting && <span className='spinner-border align-middle me-2'></span>}
                        <FormattedMessage id='FORM.QUICK-ADD' />
                      </span>
                    </button>
                  )}
                  <button
                    type='submit'
                    disabled={isSubmitting}
                    className={clsx('btn btn-lg btn-primary me-3', !canQuickAdd && 'ms-auto')}>
                    <span className='indicator-label'>
                      {isSubmitting && <span className='spinner-border align-middle me-2'></span>}
                      {!isSubmitButton && <FormattedMessage id='FORM.CONTINUE' />}
                      {isSubmitButton && <FormattedMessage id='FORM.SUBMIT' />}
                      <KTSVG
                        path={
                          locale === 'ar'
                            ? '/media/icons/duotune/arrows/arr063.svg'
                            : '/media/icons/duotune/arrows/arr064.svg'
                        }
                        className='svg-icon-3 ms-2 me-0'
                      />
                    </span>
                  </button>
                </>
              )}
            </Modal.Footer>
          </form>
        </FormProvider>
      )}
    </Modal>
  );
}
