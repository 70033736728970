import Big from 'big.js';
import currency from 'currency.js';
import groupBy2 from 'lodash.groupby';
import { ProductPriceList } from '../models/ProductResponse';
import { SwiverDocument, Tax } from '../pages/Documents/models/Document';
import { DocumentLine, Product } from '../pages/Documents/models/DocumentLine';
import { groupBy, groupByV2 } from './utils';

export const SwCurrency = (value: string | number) => currency(value, { precision: 3 });
// Formula without Big.js: exclTax * (1 + tax / 100)
export const getTotalPrice = (exclTax = 0, tax = 0) => {
  try {
    return Big(exclTax)
      .times(Big(1).plus(Big(tax).div(100)))
      .toNumber();
  } catch (error) {
    return 0;
  }
};

// Formula without Big.js: inclTax / (1 + tax / 100)
export const getPartialPrice = (inclTax = 0, tax = 0) => {
  try {
    return Big(inclTax)
      .div(Big(1).plus(Big(tax).div(100)))
      .toNumber();
  } catch (error) {
    return 0;
  }
};

// Formula without Big.js: total - (total / 100) * percentage
export const reducePercentage = (total = 0, percentage = 0) => {
  try {
    return Big(total)
      .minus(Big(total).times(Big(percentage).div(100)))
      .toNumber();
  } catch (error) {
    return 0;
  }
};

export const getProductPrice = (
  unitPrice: number,
  tPrice: boolean,
  vat: number = 0,
  line: ProductPriceList
) => {
  const price = tPrice ? getPartialPrice(unitPrice, vat) : unitPrice;
  const { calcul_method: calcMethod, rate } = line;
  const calcType = Number(line.calculType || 0);
  const tPriceG = Boolean(line.tPriceG || false);
  if (calcType === 1) {
    if (!vat) {
      return !isNaN(+rate!) || +rate! >= 0 ? rate : 0;
    }
    // else{
    //   if ()
    // }
  }
};

// Formula without Big.js: priceValue + (priceValue * (vat / 100))
const calcVatAmount = (
  exclTax: number | string = 0,
  vatValue: number = 0,
  taxValue: number = 0
): number => {
  try {
    return Big(exclTax)
      .plus(Big(exclTax).times(taxValue).div(100))
      .times(vatValue)
      .div(100)
      .toNumber();
  } catch (error) {
    return 0;
  }
};
const calcTaxAmount = (exclTax: string | number = 0, taxValue = 0) => {
  try {
    return Big(exclTax).times(taxValue).div(100).toNumber();
  } catch (error) {
    return 0;
  }
};
export const getUPInclTaxes = (priceValue: number | string = 0, vat: number = 0, tax = 0) => {
  try {
    return Big(priceValue)
      .plus(calcVatAmount(priceValue, vat, tax))
      .plus(calcTaxAmount(priceValue, tax))
      .toNumber();
  } catch (error) {
    return 0;
  }
};
// Formula without Big.js: priceValue / (((1 + tax / 100) * vat) / 100 + 1 + tax / 100)
export const getUPExclTaxes = (priceValue: number | string = 0, vat: number = 0, tax = 0) => {
  try {
    return Big(priceValue)
      .div(Big(1).plus(Big(tax).div(100)).times(vat).div(100).plus(1).plus(Big(tax).div(100)))
      .toNumber();
  } catch (error) {
    return 0;
  }
};

export const getLineInclTaxes = (
  priceValue: number | string,
  discount: number = 0,
  qty: number = 0,
  vat: number = 0,
  tax = 0
) => {
  try {
    const price = Big(priceValue);
    const discountedLinePrice = price.minus(price.times(discount).div(100)).times(qty);
    return Big(discountedLinePrice)
      .plus(calcVatAmount(discountedLinePrice.toNumber(), vat, tax))
      .plus(calcTaxAmount(discountedLinePrice.toNumber(), tax))
      .toNumber();
  } catch (error) {
    return 0;
  }
};

export const totalExclTax = (lines: DocumentLine[]) => {
  return (
    lines?.reduce((acc, line) => {
      return (
        acc +
        reducePercentage(
          !isNaN(Number(line.unit_price)) ? Number(line.unit_price) : 0,
          !isNaN(Number(line.discount)) ? Number(line.discount) : 0
        ) *
          (!isNaN(Number(line.qty)) ? Number(line.qty) : 0)
      );
    }, 0) || 0
  );
};

export const totalInclTax = (lines: DocumentLine[]) => {
  return (
    lines?.reduce((acc, line) => {
      return (
        acc +
        getTotalPrice(
          (Number(line.unit_price) || 0) - Number(line.discount || 0),
          Number(line.vat || 0)
        ) *
          (line.qty || 0)
      );
    }, 0) || 0
  );
};

export const groupByVat = (lines: DocumentLine[]) => {
  const groupedVats = groupBy<DocumentLine>(
    lines.filter((v) => {
      return v?.type?.toString() === '0' && v.product;
    }),
    'vat'
  );
  return Object.keys(groupedVats).map((key) => {
    const baseAmount = groupedVats[key].reduce((acc: number, line: DocumentLine) => {
      return SwCurrency(acc).add(
        SwCurrency(Number(line.unit_price || 0))
          .subtract(Number(line.discount || 0))
          .multiply(line.qty || 0)
      ).value;
    }, 0);
    return {
      vat: key,
      baseAmount,
      amount: SwCurrency(SwCurrency(baseAmount).divide(100).value).multiply(Number(key) || 0).value,
    };
  });
};

export const getPayloadTaxes = (doc: SwiverDocument, totalVat: number, totalVatTTC: number) => {
  if (doc) {
    const filtredTaxes = doc.taxesOri?.filter((tax) => !tax.is_vat_tax) || [];
    const groupedTaxes: {
      [key: string]: Tax[];
    } = groupBy(filtredTaxes, 'label');
    return Object.keys(groupedTaxes).map((key) => {
      const amount = groupedTaxes[key].reduce((acc: number, tax) => {
        const taxAmount =
          tax.type === 2 && tax.belong_vat
            ? (totalVat + tax.amount) / 100
            : tax.type === 2
            ? (totalVatTTC * tax.amount) / 100
            : tax.amount;
        return tax.tax_sign ? acc + taxAmount : acc - taxAmount;
      }, 0);
      return {
        label: key,
        amount: amount,
        isVatTax: groupedTaxes[key][0].is_vat_tax,
        weight: groupedTaxes[key][0].weight,
      };
    });
  }
  return [];
};
export const getLinesPayloadTaxes = (doc: SwiverDocument) => {
  if (doc) {
    const lines = (doc.document_lines || []).filter(
      (line) => Number(line.type) === 0 && line.product
    );
    const lineTaxes =
      lines?.reduce((acc, line) => {
        const taxesWithLines = ((line.product as Product).taxes as Tax[]).map((tax) => ({
          tax,
          line,
        }));
        return [...acc, ...taxesWithLines];
      }, [] as { tax: Tax; line: DocumentLine }[]) || [];
    const groupedLines = groupBy2(lineTaxes, 'tax.label') || [];
    return Object.keys(groupedLines)
      .filter((key) => {
        const line = groupedLines[key][0];
        return (
          (Number(doc.type) < 3 &&
            (Number(line.tax.tax_group) === 3 || Number(line.tax.tax_group) === 1)) ||
          (Number(doc.type) > 2 &&
            (Number(line.tax.tax_group) === 2 || Number(line.tax.tax_group) === 1))
        );
      })
      .map((key) => {
        const filtredTaxes = groupedLines[key].filter(
          (line) =>
            (Number(doc.type) < 3 &&
              (Number(line.tax.tax_group) === 3 || Number(line.tax.tax_group) === 1)) ||
            (Number(doc.type) > 2 &&
              (Number(line.tax.tax_group) === 2 || Number(line.tax.tax_group) === 1))
        );

        const amount = filtredTaxes.reduce((acc: number, line) => {
          const uP = isNaN(Number(line?.line?.unit_price))
            ? 0
            : Number(Number(line?.line?.unit_price));
          const discountPerCent = line?.line?.discount! / 100;
          const discount = uP * discountPerCent;
          const unitPrice = uP - discount;
          const taxAmount =
            line.tax.type === 2
              ? SwCurrency(unitPrice).multiply(
                  SwCurrency(line?.line?.qty!).multiply(
                    SwCurrency(line.tax.amount).divide(100).value
                  )
                ).value
              : SwCurrency(line.tax.amount).multiply(line?.line?.qty!).value;
          return line.tax.tax_sign
            ? SwCurrency(acc).add(taxAmount).value
            : SwCurrency(acc).subtract(taxAmount).value;
        }, 0);
        return {
          label: key,
          amount: amount,
          isVatTax: filtredTaxes?.[0]?.tax?.is_vat_tax,
          weight: filtredTaxes?.[0]?.tax?.weight,
        };
      });
  }
  return [];
};
// getPayloadTaxesOfDocumentInclTaxes()
export const getPayloadTaxesOfDocument = (
  doc: SwiverDocument,
  totalVat: number = 0,
  totalVatTTC: number = 0
) => {
  const filtredTaxes = doc.taxesOri?.filter((tax) => tax.is_vat_tax) || [];
  const groupedTaxes: {
    [key: string]: Tax[];
  } = groupBy(filtredTaxes, 'label');
  return Object.keys(groupedTaxes).map((key) => {
    const amount = groupedTaxes[key].reduce((acc: number, tax) => {
      const taxAmount =
        tax.type === 2 && tax.belong_vat
          ? SwCurrency((totalVat * tax.amount) / 100).value
          : tax.type === 2
          ? SwCurrency((totalVatTTC * tax.amount) / 100).value
          : SwCurrency(tax.amount).value;
      return tax.tax_sign
        ? SwCurrency(acc).add(taxAmount).value
        : SwCurrency(acc).subtract(taxAmount).value;
    }, 0);
    return {
      label: key,
      amount: amount,
      isVatTax: groupedTaxes[key][0].is_vat_tax,
      weight: groupedTaxes[key][0].weight,
    };
  });
};
export const getTotalVat = (doc: SwiverDocument) => {
  return (
    groupByVat(doc.document_lines!)?.reduce(
      (acc, vat) => SwCurrency(acc).add(vat.amount).value,
      0
    ) || 0
  );
};

export const getTotalDocumentExclTaxes = (
  doc: SwiverDocument,
  payloadTaxesDocument: {
    label: string;
    amount: number;
    isVatTax: boolean;
    weight: number;
  }[],
  payloadTaxesLines: {
    label: string;
    amount: number;
    isVatTax: boolean;
    weight: number;
  }[]
) => {
  const totalDocumentWithoutVAT = getTotalDocumentWithoutVAT(doc);
  const totalDocumentWithPayloadTaxesDocument = payloadTaxesDocument
    .filter((tax) => !tax.isVatTax)
    .reduce((acc, tax) => SwCurrency(acc).add(tax.amount).value, totalDocumentWithoutVAT);
  return payloadTaxesLines
    .filter((tax) => !tax.isVatTax)
    .reduce(
      (acc, tax) => SwCurrency(acc).add(tax.amount).value,
      totalDocumentWithPayloadTaxesDocument
    );
};

export const getTotalDocumentWithoutVAT = (doc: SwiverDocument) => {
  return (
    doc.document_lines
      ?.filter((v) => Number(v.type) === 0)
      .reduce((acc, line) => {
        return SwCurrency(acc).add(line?.unit_price!).value;
      }, 0) || 0
  );
};

export const getTotalDocInclTaxes = (totalDocExcluTaxes: number, totalVat: number) => {
  return SwCurrency(totalDocExcluTaxes).add(totalVat).value;
};
export const getdocumentTaxesInclTaxes = (
  doc: SwiverDocument,
  totalVat: number,
  totalVatTTC: number
) => {
  const payloadTaxesDocument = getPayloadTaxesOfDocument(doc, totalVat, totalVatTTC);
  const payloadTaxesLines = getLinesPayloadTaxes(doc);
  const totalDocExclTaxes = getTotalDocumentExclTaxes(doc, payloadTaxesDocument, payloadTaxesLines);
  return getPayloadTaxesOfDocumentInclTaxes(doc, totalDocExclTaxes);
};
// here
export const gettotalVatInclTaxes = (doc: SwiverDocument, totExclTaxes: number) => {
  const totalVat = getTotalVat(doc);
  const totalDocInclTaxes = getTotalDocInclTaxes(totExclTaxes, totalVat);
  const payloadTaxesInclTaxes = getPayloadTaxesOfDocumentInclTaxes(doc, totalDocInclTaxes);
  return payloadTaxesInclTaxes.reduce(
    (acc, cuurent) => SwCurrency(acc).add(cuurent.amount).value,
    totalDocInclTaxes
  );
};
const getTotalLineExclTaxes = (line: DocumentLine) => {
  if (Number(line.type) === 0) {
    const { unit_price: unitPrice } = line;
    const discountPerCent = Number(line.discount) / 100;
    const discount = Number(unitPrice) * discountPerCent;
    const UnitPriceAfterDiscount = Number(unitPrice) - discount;
    return UnitPriceAfterDiscount * Number(line.qty);
  }
  return 0;
};
export const getTotalVatInclTaxes = (doc: SwiverDocument) => {
  const payloadTaxesOfDoument = getPayloadTaxesOfDocument(doc);
  const payloadTaxesLines = getLinesPayloadTaxes(doc);
  const totDocExclTaxes = getTotalDocumentExclTaxes(doc, payloadTaxesOfDoument, payloadTaxesLines);
  const totalVat = getTotalVat(doc);
  return SwCurrency(totDocExclTaxes).add(totalVat).value;
};

// const getPayloadVatsOfLinesv2 = (doc: SwiverDocument) => {
//   // Filter docLines with type === 0
//   const filtredLines = doc.document_lines.filter(
//     (line) => Number(line.type) === 0 && line.vat !== null
//   );
//   // GroupBy Vat
//   const groupedLines = groupBy2(filtredLines, 'vat');
//   return Object.keys(groupedLines).map((key) => {
//     const vatLines = groupedLines[key];
//     // Map the vatLines
//     vatLines.map((line) => {
//       const initialUnitPrice = getTotalLineExclTaxes(line);
//       const taxes = (line.taxes as Tax[]).filter(
//         (tax) =>
//           (Number(doc.type) < 3 && [3, 1].includes(tax.tax_group)) ||
//           (Number(doc.type) > 2 && [1, 2].includes(tax.tax_group) && !tax.is_vat_tax)
//       );
//     });
//   });
// };
export const getPayloadVatsOfLines = (doc: SwiverDocument) => {
  const filtredLines =
    doc.document_lines?.filter((line) => Number(line.type) === 0 && line.vat !== null) || [];
  const groupedLines = groupByV2(filtredLines, 'vat');
  const unOrderedKeys = filtredLines
    .map((line) => line.vat?.toString()!)
    .reduce(
      (prev, current) => (prev.includes(current) ? prev : [...prev, current]),
      [] as string[]
    );
  return unOrderedKeys.map((key) => {
    const vatLines = groupedLines[key];
    return vatLines
      .map((line) => {
        const initialUnitPrice = getTotalLineExclTaxes(line);
        const taxes = (line.taxes as Tax[]).filter(
          (tax) =>
            (Number(doc.type) < 3 && [3, 1].includes(tax.tax_group)) ||
            (Number(doc.type) > 2 && [1, 2].includes(tax.tax_group))
        );
        const unitPrice = taxes.reduce((acc, tax) => {
          if (!tax.is_vat_tax) {
            const amount =
              tax.type === 2
                ? SwCurrency(acc).multiply(SwCurrency(tax.amount).divide(100).value).value
                : tax.amount;
            return SwCurrency(acc).add(amount).value;
          }
          return acc;
        }, initialUnitPrice);
        return {
          label: key,
          amount: SwCurrency(unitPrice).multiply(SwCurrency(line.vat as number).divide(100).value)
            .value,
          base: unitPrice,
        };
      })
      .reduce(
        (prevValue, newValue) => ({
          ...prevValue,
          amount: SwCurrency(prevValue.amount).add(newValue.amount).value,
          base: SwCurrency(prevValue.base).add(newValue.base).value,
        }),
        { label: key, amount: 0, base: 0 }
      );
  });
};

export const getPayloadTaxesOfDocumentInclTaxes = (
  doc: SwiverDocument,
  totalDocInclTaxes: number
) => {
  if (doc) {
    const groupedTaxes = groupBy<Tax>(doc.taxesOri?.filter((t) => t.is_vat_tax) || [], 'label');
    return Object.keys(groupedTaxes).map((key) => {
      const amount = groupedTaxes[key].reduce((acc: number, tax) => {
        const taxAmount =
          tax.type === 2
            ? SwCurrency(totalDocInclTaxes).multiply(tax.amount / 100).value
            : tax.amount;
        return tax.tax_sign
          ? SwCurrency(acc).add(taxAmount).value
          : SwCurrency(acc).subtract(taxAmount).value;
      }, 0);
      return {
        label: key,
        amount: amount,
        isVatTax: groupedTaxes[key][0].is_vat_tax,
        weight: groupedTaxes[key][0].weight,
      };
    });
  }
  return [];
};
export const getPayloadTaxesOfDocumentInclTaxesV2 = (
  doc: SwiverDocument,
  totalDocInclTaxes: number,
  totalVats: number = 0
) => {
  if (doc) {
    const groupedTaxes = groupBy<Tax>(doc.taxesOri?.filter((t) => t.is_vat_tax) || [], 'label');
    return Object.keys(groupedTaxes).map((key) => {
      const amount = groupedTaxes[key].reduce((acc: number, tax) => {
        const taxAmount =
          tax.type === 2
            ? SwCurrency(totalDocInclTaxes).multiply(tax.amount / 100).value
            : tax.amount;
        return tax.tax_sign
          ? SwCurrency(acc).add(taxAmount).value
          : SwCurrency(acc).subtract(taxAmount).value;
      }, 0);
      return {
        label: key,
        amount: amount,
        isVatTax: groupedTaxes[key][0].is_vat_tax,
        weight: groupedTaxes[key][0].weight,
      };
    });
  }
  return [];
};
