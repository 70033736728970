import { useSelector } from 'react-redux';
import { RootState } from '../../../../setup/Store';
import DetailsActions from './DetailsActions';
import DocumentsActions from './DocumentsActions';
import ListingActions from './ListingActions';

function ActionBars() {
  const actionBar = useSelector((state: RootState) => state.page.actionBar);
  const CustomActions = useSelector((state: RootState) => state.page.customActions);
  return (
    <>
      {actionBar === 'listActions' && <ListingActions />}
      {actionBar === 'detailsActions' && <DetailsActions />}
      {actionBar === 'documentsActions' && <DocumentsActions />}
      {actionBar === 'customActions' && CustomActions}
    </>
  );
}

export default ActionBars;
