import clsx from 'clsx';
import { Fragment, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../setup/Store';
import { readActivities, readCurrencies } from '../../../setup/redux/reducers/UtilsReducers';

function Step4Wth() {
  const companySize = [
    { value: 1, label: '1-10' },
    { value: 2, label: '11-50' },
    { value: 3, label: '51-100' },
    { value: 4, label: '101-500' },
  ];
  const dispatch = useDispatch();
  const activities = useSelector((state: RootState) => state.utils.activities);
  const currencies = useSelector((state: RootState) => state.utils.currencies);
  useEffect(() => {
    if (!currencies.length) {
      dispatch(readCurrencies());
    }
    if (!activities.length) {
      dispatch(readActivities());
    }
  }, []);
  return (
    <>
      <div className='animate__animated animate__fadeInLeft animate__fast'>
        <h1>
          <FormattedMessage id='WALKTHROUGHT.STEP4_DESC' />!
        </h1>
        <p>
          <FormattedMessage id='WALKTHROUGHT.STEP4_DESC2' />
        </p>
      </div>
      <div className='px-0 px-md-20  row g-2 g-md-6 animate__animated animate__fadeInRight animate__fast  min-h-400px'>
        <div className='col-12 '>
          <h3 className='text-center py-6'>
            <FormattedMessage id='WALKTHROUGHT.STEP4.ACTIVITY.SECTOR' />
          </h3>
          <div className='d-flex justify-content-center gap-1 gap-md-3  flex-wrap mb-3'>
            <Controller
              name='step4.activity'
              rules={{ shouldUnregister: false }}
              render={({ field: { name, onChange, value }, fieldState: { error, isTouched } }) => (
                <>
                  {activities.map((activity) => (
                    <div key={activity.id} className=' py-2'>
                      <label
                        className={clsx(
                          'btn btn-outline btn-outline-default bg-checkbox w-100 p-4',
                          value == activity.id && 'border-primary text-primary',
                          error && 'border-danger text-danger'
                        )}>
                        <input
                          type='radio'
                          className='btn-check'
                          name={name}
                          value={+activity.id}
                          checked={value == activity.id}
                          onChange={onChange}
                        />
                        <span className=' fs-3' style={{ whiteSpace: 'nowrap' }}>
                          {activity?.name}
                        </span>
                      </label>
                    </div>
                  ))}
                </>
              )}
            />
          </div>
        </div>
        <div className='col-12 '>
          <h3 className='text-center py-6'>
            <FormattedMessage id='NUMBER.OF.EMPLOYEES' />
          </h3>
          <div className='d-flex justify-content-center gap-3 flex-wrap mb-3'>
            <Controller
              name='step4.company_size'
              render={({ field: { name, onChange, value }, fieldState: { error, isTouched } }) => (
                <>
                  {companySize.map((item) => (
                    <Fragment key={item.value}>
                      <div className=''>
                        <label
                          className={clsx(
                            'btn btn-outline btn-outline-default bg-checkbox w-100 p-4',
                            value == item.value && 'border-primary text-primary',
                            error && 'border-danger text-danger'
                          )}>
                          <input
                            type='radio'
                            className='btn-check'
                            name={name}
                            value={item.value}
                            onChange={onChange}
                            checked={value == item.value}
                          />
                          <span className=' fs-3'>{item.label}</span>
                        </label>
                      </div>
                    </Fragment>
                  ))}
                </>
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Step4Wth;
