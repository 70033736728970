import axios, { AxiosResponse } from 'axios';
import { SubscriptionResponse } from '../../../app/models/SubscriptionsResponse';
import { delayPromise } from '../../../app/utils/utils';
import { SubscriptionQuery } from '../reducers/SubscriptionsReducer';

export const getSubscriptions = (payload: SubscriptionQuery) => {
  return delayPromise(
    axios.get<SubscriptionResponse>('/secure/user/purchases', { params: payload })
  ) as Promise<AxiosResponse<SubscriptionResponse>>;
};

export const getVerifyPurschase = (orderId: string) => {
  return delayPromise(axios.get(`/secure/user/purchases/verify/${orderId}`));
};
