import clsx from 'clsx';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { KTSVG } from '../../helpers';
import { Link } from 'react-router-dom';
import { UserPlan } from '../../modules/auth/models/UserModel';

function Banner({
  daysDifference = 0,
  userPlan = 'FREE14',
}: {
  daysDifference?: number;
  userPlan?: UserPlan;
}) {
  const { formatRelativeTime } = useIntl();
  useEffect(() => {
    document.body.setAttribute('sw-banner', 'true');
    return () => {
      document.body.setAttribute('sw-banner', 'false');
    };
  }, []);
  return (
    <div
      className={clsx(
        'sw-banner d-flex flex-column flex-md-row justify-content-md-between gap-3 rounded-0 p-5 sw-alert-header',
        daysDifference > 7 && 'alert-primary',
        daysDifference <= 7 && daysDifference > 3 && 'alert-warning',
        daysDifference <= 3 && 'alert-danger'
      )}>
      <div className='d-flex align-items-center'>
        <KTSVG
          path='/media/svg/swiver/upgrade.svg'
          className={clsx(
            'svg-icon svg-icon-2hx me-3',
            daysDifference > 7 && 'svg-icon-primary',
            daysDifference <= 7 && daysDifference > 3 && 'svg-icon-warning',
            daysDifference <= 3 && 'svg-icon-danger'
          )}
        />
        <div
          className={clsx(
            'd-flex flex-column pe-0 pe-sm-10',
            daysDifference > 7 && 'text-primary',
            daysDifference <= 7 && daysDifference > 3 && 'text-warning',
            daysDifference <= 3 && 'text-danger'
          )}>
          <h4
            className={clsx(
              daysDifference >= 0 ? 'mb-2' : 'mb-0',
              daysDifference > 7 && 'text-primary',
              daysDifference <= 7 && daysDifference > 3 && 'text-warning',
              daysDifference <= 3 && 'text-danger'
            )}>
            {daysDifference >= 0 ? (
              <FormattedMessage
                id='EXPIRATION.HEADER.TITLE'
                values={{ relativetimeformat: formatRelativeTime(daysDifference, 'days') }}
              />
            ) : (
              <FormattedMessage id='BANNER.UPGRADE_TO_PRO' />
            )}
          </h4>
          {daysDifference >= 0 && (
            <span>
              <FormattedMessage id='EXPIRATION.HEADER.BODY' />
            </span>
          )}
        </div>
      </div>
      <div className='d-none d-md-flex align-items-center'>
        <Link
          to='/pricing'
          className={clsx(
            'btn',
            daysDifference > 7 && 'btn-primary',
            daysDifference <= 7 && daysDifference > 3 && 'btn-warning',
            daysDifference <= 3 && 'btn-danger'
          )}>
          <FormattedMessage
            id={
              daysDifference >= 0 && !userPlan.startsWith('FREE')
                ? 'EXPIRATION.HEADER.RENEW'
                : 'USER.MENU.ACCOUNT.UPGRADE'
            }
          />
        </Link>
      </div>
      <Link
        to='/pricing'
        className={clsx(
          'btn',
          daysDifference > 7 && 'btn-primary',
          daysDifference <= 7 && daysDifference > 3 && 'btn-warning',
          daysDifference <= 3 && 'btn-danger',
          'd-md-none'
        )}>
        <FormattedMessage
          id={
            daysDifference >= 0 && !userPlan.startsWith('FREE')
              ? 'EXPIRATION.HEADER.RENEW'
              : 'USER.MENU.ACCOUNT.UPGRADE'
          }
        />
      </Link>
    </div>
  );
}

export default Banner;
