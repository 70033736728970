import axios from 'axios';
import { delayPromise } from '../../../app/utils/utils';
import store from '../../Store';
import { Product, ProductResponse } from '../../../app/models/ProductResponse';

type ProductPayload = {
  company?: number;
  enabled: boolean;
  offset: number;
  limit: number;
  keyword?: string;
  query?: string;
  model: string;
  in_stock: boolean | undefined;
  bar_code: string;
  category: string;
  brand: string;
  tax: string;
  vat: string;
  price_operator: string;
  price: string;
  date_start: string;
  date_end: string;
  has_serial_number?: boolean;
  warehouse?: string | number;
};

type ProductWarehousePayload = {
  enabled: boolean;
  offset: number;
  limit: number;
  keyword?: string;
  model: string;
  in_stock: boolean | undefined;
  category: string;
  brand: string;
  price_operator: string;
  price: string;
  warehouse: string;
  name: string;
  purchase_price: string;
  purchase_price_operator: string;
  stock_value: string;
  stock_value_operator: string;
  sort: string;
  sort_field: string;
  isApplied: boolean;
};

export const getProducts = (payload: ProductPayload) => {
  return delayPromise(axios.get<ProductResponse>('/secure/products/v2', { params: payload }));
};

export const getProductsWarehouse = (payload: ProductWarehousePayload) => {
  return delayPromise(axios.get<ProductResponse>('/secure/products/v2', { params: payload }));
};

export const undelayedGetProductsV2 = (payload: Partial<ProductPayload>) => {
  return axios.get<ProductResponse>('/secure/products/v2', { params: payload });
};

export const undelayedGetProducts = (payload: Partial<ProductPayload>) => {
  const company = store?.getState().auth.user?.current_company?.id;
  return axios.get<ProductResponse>('/secure/products', { params: { ...payload, company } });
};

export const postProduct = (payload: FormData) => {
  return axios.post<Product>('/secure/product', payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getProductById = (id: string) => {
  return axios.get<Product>(`/secure/product/${id}`);
};

export const patchProduct = (payload: Product) => {
  return axios.post<Product>(`/secure/product/${payload.id}`, { enabled: payload.enabled });
};

export const putProduct = (payload: FormData) => {
  const id = payload.get('id');
  return axios.post<Product>(`/secure/product/${id}`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const exportProducts = (query: any) => {
  return axios.get(`/secure/products/export/v2`, { params: query, responseType: 'arraybuffer' });
};

export const verifyListProducts = (file: any, onUploadProgress: (progressEvent: any) => void) => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post(`/secure/products/import/verify`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
};
export const importProducts = (file: string, warehouse?: number) => {
  //send in query param file_name
  return axios.post(`/secure/products/import`, null, {
    params: { file_name: file, warehouse: warehouse || null },
  });
};
// export const download Customer model
export const downloadProductsModel = () => {
  return axios.get(`/secure/products/template`, {
    responseType: 'arraybuffer',
  });
};

// export const download Customer model
export const downloadInventoryModel = (warehouse: number) => {
  return axios.get(`/secure/stock/export/model`, {
    responseType: 'arraybuffer',
    params: { warehouse: warehouse, format: 'csv', enabled: true, model: 'materiel' },
  });
};
