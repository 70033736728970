import React, { useState } from 'react';
import { Cropper } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

type Props = {
  onCropImage: (img: any) => void;
};
const ModalCropper: React.FC<Props> = ({ onCropImage }) => {
  const [isModalShown, setIsModalShown] = useState(false);
  const handleShow = () => setIsModalShown(true);
  const handleHide = () => setIsModalShown(false);
  const [image, setImage] = useState(
    'https://images.unsplash.com/photo-1599140849279-1014532882fe?fit=crop&w=1300&q=80'
  );

  const handleFileSelect = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (ev: any) => {
        setImage(ev.target.result);
      };
      reader.readAsDataURL(file);
      handleShow();
    } else {
      if (!file) return;
    }
  };

  return (
    <>
      <div>
        <input
          type='file'
          id='file-input'
          style={{ display: 'none' }}
          onChange={handleFileSelect}
        />
        <label
          className='btn sw-btn-light position-absolute bottom-0 end-0 p-2 h-45px w-45px rounded-5 shadow'
          htmlFor='file-input'>
          <i className='ms-1 lh-30px text-primary fs-2 fa-regular fa-image'></i>
        </label>
      </div>
      <Modal show={isModalShown} contentClassName={'rounded-5'} onHide={handleHide} centered>
        <Modal.Body>
          <div className='d-flex flex-column gap-1'>
            <div>
              <h3>
                <FormattedMessage id='WALKTHROUGH.PROFILE_PICTURE_TITLE' />
              </h3>
              <p>
                <FormattedMessage id='WALKTHROUGH.PROFILE_PICTURE_SUBTITLE' />
              </p>
            </div>

            <div className=''>
              <Cropper
                style={{ height: '400px', width: '100%' }}
                src={image}
                className={'cropper'}
              />
            </div>
            <div className='d-flex justify-content-between gap-3 pt-4'>
              <button
                className='btn btn-link btn-color-muted btn-active-color-primary fw-bold'
                onClick={handleHide}>
                {/* <i className='fs-4 fa-regular fa-arrow-left'></i> */}
                <FormattedMessage id='COMMON.CANCEL' />
              </button>
              <button
                className='btn btn-primary btn-submit'
                onClick={() => {
                  onCropImage(image);
                  handleHide();
                }}>
                <FormattedMessage id='COMMON.SAVE' />
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalCropper;
