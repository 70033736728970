import axios from 'axios';
import { ProfileUpdateResponse, SignUpRequest } from '../../../app/modules/auth/models/SignUpModel';

type Credentials = { email: string; password: string };
type NewPassword = {
  first: string;
  second: string;
};
export type ResetPasswordPayload = {
  oldPassword: string;
  newPassword: NewPassword;
};

export interface ResetPasswordWithTokenPayload extends NewPassword {
  token: string;
}

export const signIn = (payload: Credentials) => {
  return axios.post('/login', payload);
};

export const signUp = (payload: SignUpRequest) => {
  return axios.post('/open/register', payload);
};

export const enableUser = (payload: SignUpRequest) => {
  return axios.post('/open/user/enable', payload);
};

export const getUser = () => {
  return axios.get('/secure/me');
};

export const putCompany = (payload: number) => {
  return axios.put(`/secure/user/update-current-company/${payload}`);
};

export const getCurrentCompany = (id: number) => {
  return axios.get(`/secure/company/${id}`);
};

export const postRefreshToken = (refreshToken?: string) => {
  return axios.post('/token/refresh', { refreshToken });
};

export const logOut = (refreshToken: string) => {
  return axios.post('/secure/token/invalidate', { refresh_token: refreshToken });
};

export const updatePassword = (resetPasswordPayload: ResetPasswordPayload) => {
  return axios.post('/secure/user/change-password', resetPasswordPayload);
};

export const resetPassword = (mail: any) => {
  return axios.post('/open/user/request-password', { email: mail });
};

export const requestSMS = (phone: any) => {
  return axios.post('/open/user/partner/send-sms', { phone: phone });
};

export const updateStamp = (data: any) => {
  const formData = new FormData();
  formData.append('attachment', data.attachment);
  formData.append('password', data.password);
  return axios.post(`/secure/company/stamp`, formData);
};

export const updateProfilePictureEffect = (data: FormData, userId: number) => {
  return axios.post<ProfileUpdateResponse>(`/secure/user/edit/${userId}`, data);
};

export const resetPasswordWithToken = ({ token, ...passwords }: ResetPasswordWithTokenPayload) =>
  axios.post(`/open/user/reset-password/${token}`, {
    reset_password: {
      password: passwords,
    },
  });
