import { SweetAlertCustomClass, SweetAlertOptions } from 'sweetalert2';
export const sweetAlertConfig: SweetAlertOptions<any> = {
  showConfirmButton: true,
  showCancelButton: true,
  showCloseButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  focusCancel: true,

  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-danger',
    actions: 'd-flex flex-row-reverse gap-3',
    timerProgressBar: 'bg-primary',
  } as SweetAlertCustomClass,
  buttonsStyling: false,
  // timer: 10000,
  timerProgressBar: false,
};
export const sweetAlertWarningConfig: SweetAlertOptions<any> = {
  ...sweetAlertConfig,
  icon: 'warning',
  customClass: { ...(sweetAlertConfig.customClass as SweetAlertCustomClass), icon: 'text-warning' },
};
export const sweetAlertErrorConfig: SweetAlertOptions<any> = {
  ...sweetAlertConfig,
  icon: 'error',
  customClass: { ...(sweetAlertConfig.customClass as SweetAlertCustomClass), icon: 'text-danger' },
};
export const sweetAlertValidationConfig: SweetAlertOptions<any> = {
  ...sweetAlertConfig,
  customClass: {
    ...(sweetAlertConfig.customClass as SweetAlertCustomClass),
    confirmButton: 'btn btn-light',
  },
};
export const sweetAlertInfoConfig: SweetAlertOptions<any> = {
  ...sweetAlertConfig,
  icon: 'info',
  customClass: { ...(sweetAlertConfig.customClass as SweetAlertCustomClass), icon: 'text-info' },
};
export const sweetAlertSuccessConfig: SweetAlertOptions<any> = {
  ...sweetAlertConfig,
  icon: 'success',
  customClass: { ...(sweetAlertConfig.customClass as SweetAlertCustomClass), icon: 'text-success' },
};
export const tooltipDelay = { show: 500, hide: 0 };
export const promiseDelay: number = 500;

export const contactPhonesLimit = 3;
export const contactAddressesLimit = 3;

export const dateFormatsPerLocale = {
  en: 'MM/dd/yyyy',
  fr: 'dd/MM/yyyy',
  es: 'dd/MM/yyyy',
  de: 'dd.MM.yyyy',
  it: 'dd/MM/yyyy',
  pt: 'dd/MM/yyyy',
  ru: 'dd.MM.yyyy',
  zh: 'yyyy年MM月dd日',
  ja: 'yyyy/MM/dd',
  ko: 'yyyy.MM.dd',
  ar: 'dd/MM/yyyy',
  hi: 'dd/MM/yyyy',
  bn: 'dd/MM/yyyy',
  tr: 'dd.MM.yyyy',
  nl: 'dd-MM-yyyy',
  sv: 'yyyy-MM-dd',
  no: 'dd.MM.yyyy',
  fi: 'dd.MM.yyyy',
  da: 'dd-MM-yyyy',
  pl: 'dd.MM.yyyy',
  cs: 'dd.MM.yyyy',
  hu: 'yyyy.MM.dd.',
  ro: 'dd.MM.yyyy',
  uk: 'dd.MM.yyyy',
  bg: 'dd.MM.yyyy',
  el: 'dd/MM/yyyy',
  he: 'dd/MM/yyyy',
  th: 'dd/MM/yyyy',
  vi: 'dd/MM/yyyy',
};
