import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { RootState } from '../../../setup/Store';

function SWToastContainer() {
  const theme = useSelector((state: RootState) => state.utils.theme);
  return (
    <ToastContainer
      position='top-right'
      theme={theme === 'dark' ? 'dark' : 'light'}
      hideProgressBar
    />
  );
}

export default SWToastContainer;
