import clsx from 'clsx';
import { ChangeEventHandler, FormEventHandler } from 'react';
type radioProps = {
  checked?: boolean;
  name?: string;
  value?: string | number | boolean;
  label?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FormEventHandler<HTMLDivElement>;
  children?: React.ReactNode;
  disabled?: boolean;
  className?: string;
};
function CustomRadio({
  checked = false,
  name = '',
  value = '',
  onChange = () => {},
  onBlur = () => {},
  disabled = false,
  children,
  className,
}: radioProps) {
  return (
    <label
      className={clsx(
        'btn btn-outline btn-outline-dashed d-flex align-items-center gap-3 px-4 py-auto',
        checked && 'btn-light-primary btn-active-light-primary border border-1 border-primary',
        className
      )}>
      <div className='form-check form-check-custom form-check-solid d-flex justify-content-center'>
        <input
          type='radio'
          className='form-check-input'
          name={name}
          value={value as string | number}
          checked={checked}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
        />
        <span className='form-check-label'>{children}</span>
      </div>
    </label>
  );
}

export default CustomRadio;
