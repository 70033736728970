// import { printFormat } from 'iban';
import { friendlyFormatIBAN } from 'ibantools';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedDate, FormattedMessage } from 'react-intl';
import StyledCurrency from '../../../components/StyledCurrency/StyledCurrency';
import { Bank, UserBank } from '../../../models/BankResponse';

const BankDepositStep4 = () => {
  const { watch } = useFormContext();
  const bank: UserBank = watch('bankDetails');
  const depositMethod = watch('depositMethod');
  const cashAmount = watch('cashAmount');
  const checkSum = watch('checkSum');
  const draftSum = watch('draftSum');
  const depositNumber = watch('depositNumber');
  const date = watch('date');

  return (
    <div className='row g-4'>
      <div className='collapse show fs-6 ps-10'>
        <div className='d-flex flex-wrap py-5'>
          <div className='flex-equal me-5'>
            <table className='table table-flush fw-semibold gy-1'>
              <thead>
                <tr>
                  <th className='fs-5 fw-bold' colSpan={2}>
                    <FormattedMessage id='BANK.DETAILS' />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='text-muted min-w-125px w-125px'>
                    <FormattedMessage id='BANK.ACCOUNT.NAME' />
                  </td>
                  <td className='text-gray-800'>{bank.name}</td>
                </tr>
                <tr>
                  <td className='text-muted min-w-125px w-125px'>
                    <FormattedMessage id='BANK.BANK_NAME' />
                  </td>
                  <td className='text-gray-800'>{(bank?.bank as Bank)?.name || '--'}</td>
                </tr>
                <tr>
                  <td className='text-muted min-w-125px w-125px'>
                    <FormattedMessage id='BANK.CODE' />
                  </td>
                  <td className='text-gray-800'>{(bank?.bank as Bank)?.code || '--'}</td>
                </tr>
                <tr>
                  <td className='text-muted min-w-125px w-125px'>
                    <FormattedMessage id='BANKING.IBAN' />
                  </td>
                  <td className='text-gray-800'>
                    {bank.iban ? friendlyFormatIBAN(bank?.iban || '') : '--'}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='flex-equal'>
            <table className='table table-flush fw-semibold gy-1'>
              <thead>
                <tr>
                  <th className='fs-5 fw-bold' colSpan={2}>
                    <FormattedMessage id='BANK.BALANCE.DEPOSIT.DETAILS' />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='text-muted min-w-125px w-125px'>
                    <FormattedMessage id='FORM.FIELD.TYPE' />
                  </td>
                  <td className='text-gray-800'>
                    <FormattedMessage id={`BANK.${depositMethod}`} />
                  </td>
                </tr>
                <tr>
                  <td className='text-muted min-w-125px w-125px'>
                    <FormattedMessage id='BANK.DEPOSIT.NUMBER' />
                  </td>
                  <td className='text-gray-800'>{depositNumber || '--'}</td>
                </tr>
                <tr>
                  <td className='text-muted min-w-125px w-125px'>
                    <FormattedMessage id='FORM.FIELD.AMOUNT' />
                  </td>
                  <td className='text-gray-800'>
                    <a href='#' className='text-gray-900 text-hover-primary'>
                      <StyledCurrency
                        amount={
                          (depositMethod === 'CASH'
                            ? cashAmount
                            : depositMethod === 'DRAFT'
                            ? draftSum
                            : depositMethod === 'CHECK'
                            ? checkSum
                            : 0) || 0
                        }
                        amountClassName='fw-bold text-primary fs-6'
                        supClassName='fs-8 text-muted fw-bold'
                        containerClassName='d-flex gap-1 align-items-center'
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className='text-muted min-w-125px w-125px'>
                    <FormattedMessage id='BANKING.DATE' />
                  </td>
                  <td className='text-gray-800'>
                    <FormattedDate value={date} day='2-digit' month='short' year='numeric' />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div>
        <label className='form-label mb-3'>
          <FormattedMessage id='DOCUMENT.DETAILS_BLOCK.DOCUMENT_NOTE' />
        </label>
        <Controller
          name='note'
          defaultValue=''
          render={({
            field: { name, onBlur, onChange, value },
            fieldState: { error, isTouched },
          }) => (
            <>
              <textarea
                name={name}
                onChange={onChange}
                value={value}
                onBlur={onBlur}
                className='form-control form-control-solid'
              />
            </>
          )}
        />
      </div>
    </div>
  );
};
export { BankDepositStep4 };
