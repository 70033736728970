import { delayPromise } from '../../../../app/utils/utils';
import { User } from '../../../../app/modules/auth/models/UserModel';
import axios from 'axios';

type UserQuery = {
  company: number;
};

export function getUsers(params: UserQuery) {
  return delayPromise(axios.get<User[]>('/secure/user/list', { params }));
}
