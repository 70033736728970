import clsx from 'clsx';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import ar from 'react-phone-input-2/lang/ar.json';
import fr from 'react-phone-input-2/lang/fr.json';
import { useSelector } from 'react-redux';
import isEmail from 'validator/es/lib/isEmail';
import { RootState } from '../../../setup/Store';
import SwiverSelect from '../../components/SwiverSelect/SwiverSelect';
import { contactPhonesLimit } from '../../utils/configs';

function Step2Wth() {
  const { register, setValue } = useFormContext();
  const {
    fields: phoneFields,
    append,
    remove,
  } = useFieldArray({
    name: 'contact_phones',
  });
  const intl = useIntl();
  const locale = useSelector((state: RootState) => state.utils.locale);
  const currentCompany = useSelector((state: RootState) => state.auth.user?.current_company);

  return (
    <>
      <div className='animate__animated animate__fadeInLeft animate__fast'>
        <h1>
          <FormattedMessage id='WALKTHROUGHT.STEP2_DESC' />
        </h1>
        <p>
          <FormattedMessage id='WALKTHROUGHT.STEP2_DESC2' />
        </p>
      </div>
      <div className='px-0 px-md-20  row g-6 animate__animated animate__fadeInRight animate__fast min-h-400px'>
        <div className='col-12 '>
          <label className='required fw-semibold fs-6 mb-2'>
            <FormattedMessage id='WALKTHROUGHT.ENTREPRISE.EMAIL' />
          </label>
          <Controller
            name='step2.email'
            rules={{
              validate: (value) => {
                return (value && isEmail(value || '')) || 'FORM.ERRORS.INVALID';
              },
              required: 'FORM.ERRORS.REQUIRED',
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <input
                  type='text'
                  className={clsx('form-control', error && 'is-invalid')}
                  {...field}
                  placeholder={intl.formatMessage({ id: 'WALKTHROUGHT.EMAIL.PLACEHOLER' })}
                />
                {error && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>
                      <span role='alert'>
                        <FormattedMessage
                          id={error?.message}
                          values={{
                            field: intl.formatMessage({ id: 'FORM.FIELD.EMAIL' }),
                          }}
                        />
                      </span>
                    </div>
                  </div>
                )}
                <span className='text-muted'>
                  <FormattedMessage id='WALKTHROUGHT.EMAIL.DESC' />
                </span>
              </>
            )}
          />
        </div>
        <div className='col-12 '>
          <label className='fw-semibold fs-6 mb-2'>
            <FormattedMessage id='WALKTHROUGHT.ENTREPRISE.TEL' />
          </label>

          <div className='d-flex flex-column gap-4'>
            {phoneFields.map((phoneField, index) => (
              <div className='d-flex gap-3 align-items-start' key={phoneField.id}>
                <div className='max-w-150px'>
                  <Controller
                    name={`contact_phones.${index}.type`}
                    render={({
                      field: { name, onChange, value },
                      fieldState: { isTouched, error },
                    }) => (
                      <>
                        <SwiverSelect
                          // className='form-control '
                          menuWidth='200px'
                          name={name}
                          value={value}
                          isClearable={false}
                          isSearchable={false}
                          onChange={(e) => {
                            onChange(e.value || '');
                          }}
                          options={[
                            {
                              label: intl.formatMessage({ id: 'PHONE.TYPES.MOBILE' }),
                              value: '1',
                            },
                            { label: intl.formatMessage({ id: 'PHONE.TYPES.FAX' }), value: '2' },
                            {
                              label: intl.formatMessage({ id: 'PHONE.TYPES.WHATSAPP' }),
                              value: '3',
                            },
                            { label: intl.formatMessage({ id: 'PHONE.TYPES.VIBER' }), value: '4' },
                            {
                              label: intl.formatMessage({ id: 'PHONE.TYPES.SUPPORT' }),
                              value: '5',
                            },
                            {
                              label: intl.formatMessage({ id: 'PHONE.TYPES.LINE' }),
                              value: '8',
                            },
                          ]}
                        />
                        {error && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger'>
                              <span role='alert'>
                                <FormattedMessage
                                  id={error?.message}
                                  values={{
                                    field: intl.formatMessage({ id: 'CUSTOMER.FIELD.PHONE-TYPE' }),
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>

                <Controller
                  name={`contact_phones.${index}.value`}
                  render={({
                    field: { value, onChange, onBlur, name },
                    fieldState: { isTouched, error },
                  }) => (
                    <>
                      <div className='flex-grow-1'>
                        <div className='d-flex align-items-center gap-3'>
                          <PhoneInput
                            localization={locale === 'ar' ? ar : locale === 'fr' ? fr : undefined}
                            // containerClass='d-flex flex-row flex-grow-1'
                            inputClass={clsx('h-45px ', {
                              'is-invalid': error,
                              'ps-16 pe-3': !error,
                            })}
                            // searchClass=''
                            // dropdownClass='bg-body form-control'
                            alwaysDefaultMask={true}
                            defaultMask='...........................'
                            country={currentCompany?.setting.country.toLocaleLowerCase()}
                            value={value}
                            inputProps={{
                              name: name,
                              required: true,
                              autoComplete: 'off',
                            }}
                            onChange={(value, data, event, formattedValue) => {
                              const phone = formattedValue.replaceAll(' ', '');
                              onChange(phone);
                              setValue(
                                `contact_phones.${index}.country`,
                                (data as CountryData)?.countryCode.toUpperCase() || ''
                              );
                            }}
                            onBlur={() => {
                              onBlur();
                            }}
                          />
                          {index != 0 && (
                            <button
                              onClick={() => {
                                remove(index);
                              }}
                              className='btn btn-secondary h-45px w-45px py-3 px-5'>
                              <i className='fs-4 fa-regular fa-trash '></i>
                            </button>
                          )}
                        </div>
                        {error && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                <FormattedMessage
                                  id={error.message}
                                  values={{
                                    field: intl.formatMessage({ id: 'COMMON.FIELDS.PHONE' }),
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                />
              </div>
            ))}
            {phoneFields.length < contactPhonesLimit && (
              <div>
                <button
                  type='button'
                  onClick={() => {
                    append({
                      country: '',
                      value: '',
                      type: '1',
                      display_in_doc: true,
                    });
                  }}
                  className='btn btn-link btn-color-primary btn-active-color-primary d-flex gap-2'>
                  <div className='w-20px h-20px bg-primary rounded-2 align-self-center'>
                    <i className='me-5px text-white fa-solid fa-plus'></i>
                  </div>{' '}
                  <FormattedMessage id='ADD.ANOTHER.PHONE.NUMBER' />
                </button>
              </div>
            )}
          </div>
        </div>
        <div className='col-12 '>
          <label className=' fw-semibold fs-6 mb-2'>
            <FormattedMessage id='COMPANY.ADDRESS' />
          </label>

          <input
            type='text'
            {...register('step2.address')}
            className='form-control '
            placeholder={intl.formatMessage({ id: 'WALKTHROUGHT.ADDRESS.PLACEHOLER' })}
          />
        </div>
        <div className='col-6 '>
          <label className='fw-semibold fs-6 mb-2'>
            <FormattedMessage id='PRICING.ADDRESS.CITY' />{' '}
          </label>

          <input
            type='text'
            {...register('step2.region')}
            className='form-control '
            placeholder={intl.formatMessage({ id: 'PRICING.ADDRESS.CITY' })}
          />
        </div>
        <div className='col-6'>
          <label className=' fw-semibold fs-6 mb-2'>
            <FormattedMessage id='FORM.FIELD.ZIP' />{' '}
          </label>

          <input
            type='text'
            {...register('step2.zip_code')}
            className='form-control '
            placeholder={intl.formatMessage({ id: 'FORM.FIELD.ZIP' })}
          />
        </div>
      </div>
    </>
  );
}

export default Step2Wth;
