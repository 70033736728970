import clsx from 'clsx';
import { createContext, FC, useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { postPriceList } from '../../setup/redux/effects/UtilsEffects';
import { readPriceList } from '../../setup/redux/reducers/UtilsReducers';
import { RootState } from '../../setup/Store';

const defaultValues = {
  isPriceListModalShown: false,
  hidePriceListModal: () => {},
  showPriceListModal: () => {},
};

const PriceListModalsContext = createContext(defaultValues);
export const usePriceListContext = () => useContext(PriceListModalsContext);
const PriceListModalProvider: FC = ({ children }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [isPriceListModalShown, setIsPriceListModalShown] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const company = useSelector((state: RootState) => state.auth.user?.current_company.id);
  const {
    register,
    reset,
    formState: { errors, touchedFields },
    handleSubmit,
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: { name: '' },
  });
  const hidePriceListModal = () => {
    setIsPriceListModalShown(false);
    reset({ name: '' }, { keepValues: false, keepTouched: false });
  };
  const showPriceListModal = () => {
    setIsPriceListModalShown(true);
  };
  const onSubmit = async (values: { name: string }) => {
    try {
      setIsSubmitting(true);
      await postPriceList({ name: values.name, company, enabled: true });
      dispatch(readPriceList());
      hidePriceListModal();
    } catch (error) {}
    setIsSubmitting(false);
  };
  return (
    <PriceListModalsContext.Provider
      value={{ isPriceListModalShown, showPriceListModal, hidePriceListModal }}>
      <>
        {children}
        <Modal show={isPriceListModalShown} onHide={hidePriceListModal} centered>
          <Modal.Header className='border-bottom-0'>
            <Modal.Title>
              <FormattedMessage id='FORM.PRICE.ADD.TITLE' />
            </Modal.Title>
          </Modal.Header>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
              <label className='form-label fw-normal fs-7'>
                <FormattedMessage id='FORM.FIELD.NAME' />
              </label>
              <input
                type='text'
                {...register('name', { required: 'FORM.ERRORS.REQUIRED' })}
                className={clsx('form-control form-control-solid', {
                  'is-valid': touchedFields?.name && !errors?.name,
                  'is-invalid': touchedFields.name && errors?.name,
                })}
              />
              {errors?.name && touchedFields?.name && (
                <div className='text-danger mt-3'>
                  <span role='alert'>
                    <FormattedMessage
                      id={errors?.name?.message as string}
                      values={{
                        field: formatMessage({ id: 'FORM.FIELD.NAME' }),
                      }}
                    />
                  </span>
                </div>
              )}
              {/* </div> */}
            </Modal.Body>
            <Modal.Footer>
              <div className='btn btn-light' onClick={hidePriceListModal}>
                <FormattedMessage id='FORM.CANCEL' />
              </div>
              <button type='submit' disabled={isSubmitting} className='btn btn-primary'>
                <FormattedMessage id='FORM.SUBMIT' />
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    </PriceListModalsContext.Provider>
  );
};
export default PriceListModalProvider;
