import { useEffect, useState } from 'react';
import { Tooltip } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../setup/Store';
import OverlayTrigger from '../OverlayTrigger/OverlayTrigger';
export type StyledCurrencyProps = {
  amount: string | number;
  currency?: string;
  containerClassName?: string;
  supClassName?: string;
  amountClassName?: string;
  currencyDisplay?: 'symbol' | 'code' | 'name';
  noSup?: boolean;
};

export default function StyledCurrency({
  amount,
  currency: selectedCurrency,
  containerClassName: containerClass = '',
  supClassName = '',
  amountClassName = '',
  currencyDisplay = 'symbol',
  noSup = false,
}: StyledCurrencyProps) {
  const { formatNumberToParts, formatNumber } = useIntl();
  const [position, setPosition] = useState<'start' | 'end'>();
  const [displayedCurrency, setDisplayedCurrency] = useState('');
  const [displayedCurrencyName, setDisplayedCurrencyName] = useState('');
  const [formattedAmount, setFormattedAmount] = useState('');
  const locale = useSelector((state: RootState) => state.utils.locale);
  const defaultCurrency = useSelector(
    (state: RootState) => state.auth.user?.current_company?.setting?.default_currency
  );
  useEffect(() => {
    const parts = formatNumberToParts(+amount, {
      style: 'currency',
      currency:
        selectedCurrency || defaultCurrency || process.env.REACT_APP_DEFAULT_CURRENCY || 'TND',
      currencyDisplay: currencyDisplay,
    });
    const formattedNumber = formatNumber(+amount, {
      style: 'currency',
      currency:
        selectedCurrency || defaultCurrency || process.env.REACT_APP_DEFAULT_CURRENCY || 'TND',
      currencyDisplay: currencyDisplay,
    });

    const currencyName =
      formatNumberToParts(0, {
        style: 'currency',
        currency:
          selectedCurrency || defaultCurrency || process.env.REACT_APP_DEFAULT_CURRENCY || 'TND',
        currencyDisplay: 'name',
      }).find((part) => part.type === 'currency')?.value ||
      selectedCurrency ||
      defaultCurrency ||
      process.env.REACT_APP_DEFAULT_CURRENCY ||
      'TND';
    setDisplayedCurrencyName(currencyName);
    const currencyIndex = parts.findIndex((part) => part.type === 'currency');
    const literalIndex = parts.findIndex((part) => part.type === 'integer');
    setPosition(currencyIndex < literalIndex ? 'start' : 'end');
    setDisplayedCurrency(parts[currencyIndex].value);
    setFormattedAmount(
      formattedNumber
        .replace(
          parts[currencyIndex]?.value ||
            selectedCurrency ||
            defaultCurrency ||
            process.env.REACT_APP_DEFAULT_CURRENCY ||
            'TND',
          ''
        )
        .trim()
    );
  }, [defaultCurrency, amount, selectedCurrency, locale]);

  return (
    <div className={containerClass}>
      {position === 'start' && (
        <OverlayTrigger
          placement='bottom'
          overlay={<Tooltip className='text-capitalize'>{displayedCurrencyName}</Tooltip>}>
          {noSup ? (
            <span className={supClassName}>{displayedCurrency}</span>
          ) : (
            <sup className={supClassName}>{displayedCurrency}</sup>
          )}
        </OverlayTrigger>
      )}
      <span className={amountClassName}>{formattedAmount}</span>
      {position === 'end' && (
        <OverlayTrigger
          placement='bottom'
          overlay={<Tooltip className='text-capitalize'>{displayedCurrencyName}</Tooltip>}>
          {noSup ? (
            <span className={supClassName}>{displayedCurrency}</span>
          ) : (
            <sup className={supClassName}>{displayedCurrency}</sup>
          )}
        </OverlayTrigger>
      )}
    </div>
  );
}
