import axios from 'axios';
import { TransationType } from '../../../app/models/TransactionType';

export type SearchOperationQuery = {
  company: string | number;
  bankAccount: string | number;
  status: string | number;
  operationType: string | number;
  transactionType: string | number;
  amount: string | number;
  comparisonOp: string | number;
  offset: number;
  limit: number;
  dateBegin: string | undefined;
  dateEnd: string | undefined;
  query: string;
  expenseCategory: string;
};

// Generated by https://quicktype.io

export interface SearchOperationResponse {
  count: number;
  rows: SearchOperation[];
}

export interface SearchOperation {
  id: number;
  date: string;
  type: string;
  debit: number | null | string;
  credit: null | string;
  number: null | string;
  reference: string;
  note: null | string;
  contact: Contact | null;
  expense_category: ExpenseCategory | null;
  income_category: null;
}

export interface Contact {
  id: number;
  name: string;
  registration: null | string;
  company_name: null | string;
  reference: string;
  enabled: boolean;
}

export interface ExpenseCategory {
  id: number;
  name: string;
}

export const getSearchOperation = (params: Partial<SearchOperationQuery>) => {
  return axios.get<SearchOperationResponse>('/secure/bank-account/history/transaction/v2', {
    params,
  });
};

export const getTransactionTypes = () => {
  return axios.get<TransationType[]>('/secure/bank-account/transaction/types');
};
export const getBalanceTransactionTypes = () => {
  return axios.get<TransationType[]>('/secure/balance/transactions/types/list');
};
