import clsx from 'clsx';
import { useMemo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import { RootState } from '../../../../setup/Store';
import { postDraft } from '../../../../setup/redux/effects/DocumentEffects';
import Guard, {
  AuthorizeInputs,
  authorizeOnRole,
  authorizeOnRoleOrPermissionAndPermissionPlan,
} from '../../../components/Security/Guard';
import { ThemeModeSwitcher } from '../../../components/ThemeProvider/ThemeModeSwitcher';
import { HeaderUserMenu } from '../../../utils';
import { useFullScreenHandle } from 'react-full-screen';
import { toggleFullScreen } from '../../../../setup/redux/reducers/UtilsReducers';
import { Permission, PermissionPlan } from '../../../modules/auth/models/UserModel';

const itemClass = 'ms-1 ms-lg-3';
const userAvatarClass = 'symbol-35px symbol-md-40px';
const Navbar = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const currentCompany = useSelector((state: RootState) => state.auth.user?.current_company);
  const navigate = useNavigate();
  const addNewDocument = async (type: any) => {
    const { data } = await postDraft({ type });
    navigate(`/document/${data.id}`);
  };
  const addNewPOS = async (type: any) => {
    const { data } = await postDraft({ type, pos: true });
    navigate(`/pos/${data.id}`);
  };
  const addNew = (type: string) => {
    navigate(`/${type}/add`);
  };
  const quickActionsLinks = useMemo<
    {
      planPermissions: PermissionPlan[];
      permissions: Permission[];
      roles: string[];
      authorize: ((authorizeInputs: AuthorizeInputs) => boolean) | boolean;
      onClick: () => void;
      icon: string;
      title?: React.ReactNode;
    }[]
  >(
    () => [
      {
        planPermissions: ['estimateManagement'],
        permissions: ['Estimate.read.write'],
        roles: ['ROLE_ADMIN'],
        authorize: authorizeOnRoleOrPermissionAndPermissionPlan,
        onClick: () => {
          addNewDocument(6);
        },
        icon: 'fa-light fa-file-invoice',
        title: <FormattedMessage id='TOPBAR.QUICK_ACTIONS.ADD_ESTIMATE' />,
      },
      {
        planPermissions: ['invoiceManagement'],
        permissions: ['Invoice.read.write'],
        roles: ['ROLE_ADMIN'],
        authorize: authorizeOnRoleOrPermissionAndPermissionPlan,
        onClick: () => {
          addNewDocument(3);
        },
        icon: 'fa-light fa-file-invoice',
        title: <FormattedMessage id='TOPBAR.QUICK_ACTIONS.ADD_INVOICE' />,
      },
      {
        planPermissions: ['customerManagement'],
        permissions: ['Customer.read.write'],
        roles: ['ROLE_ADMIN'],
        authorize: authorizeOnRoleOrPermissionAndPermissionPlan,
        onClick: () => {
          addNew('customers');
        },
        icon: 'fa-light fa-users',
        title: <FormattedMessage id='TOPBAR.QUICK_ACTIONS.ADD_CUSTOMER' />,
      },
      {
        planPermissions: ['supplierManagement'],
        permissions: ['Supplier.read.write'],
        roles: ['ROLE_ADMIN'],
        authorize: authorizeOnRoleOrPermissionAndPermissionPlan,
        onClick: () => {
          addNew('suppliers');
        },
        icon: 'fa-light fa-truck',
        title: <FormattedMessage id='TOPBAR.QUICK_ACTIONS.ADD_SUPPLIER' />,
      },
      {
        planPermissions: ['productsManagement'],
        permissions: ['Catalog.read.write'],
        roles: ['ROLE_ADMIN'],
        authorize: authorizeOnRoleOrPermissionAndPermissionPlan,
        onClick: () => {
          addNew('products');
        },
        icon: 'fa-light fa-box',
        title: <FormattedMessage id='TOPBAR.QUICK_ACTIONS.ADD_PRODUCT' />,
      },
    ],
    []
  );
  const handle = useFullScreenHandle();
  const dispatch = useDispatch();
  //show button only in route /pos
  const match = useMatch('/pos/:id');

  return (
    <div className='app-navbar flex-shrink-0 ms-auto'>
      {/* Quick actions */}

      <div className={clsx('app-navbar-item', itemClass)}>
        <OverlayTrigger
          placement='bottom'
          overlay={
            <Tooltip>
              <FormattedMessage id='TOPBAR.QUICK-ACTIONS' />
            </Tooltip>
          }>
          <button
            className={clsx('btn-navbar')}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'>
            <i className='fa-light fa-plus'></i>
          </button>
        </OverlayTrigger>
        <div className='menu menu-sub menu-sub-dropdown menu-column sw-menu' data-kt-menu='true'>
          <div className='sw-menu-header'>
            <span className='sw-menu-title'>
              <FormattedMessage id='TOPBAR.QUICK-ACTIONS' />
            </span>
          </div>
          <div className='sw-menu-body'>
            {quickActionsLinks.map((action, index) => (
              <Guard
                key={index}
                planPermissions={action.planPermissions}
                permissions={action.permissions}
                roles={action.roles}
                authorize={action.authorize}>
                <div className='sw-menu-item' onClick={action.onClick}>
                  <span className='sw-menu-item-icon'>
                    <i className={action.icon}></i>
                  </span>
                  <span className='sw-menu-item-title'>{action.title}</span>
                </div>
              </Guard>
            ))}
          </div>
        </div>
      </div>
      {match && (
        <div
          className={clsx('app-navbar-item', itemClass)}
          onClick={() => {
            dispatch(toggleFullScreen());
          }}>
          <OverlayTrigger
            placement='bottom'
            overlay={
              <Tooltip>
                <FormattedMessage id='TOPBAR.POS' />
              </Tooltip>
            }>
            <button className={clsx('btn-navbar')}>
              <i className='fa-light fa-expand'></i>
            </button>
          </OverlayTrigger>
        </div>
      )}

      {/* POS */}

      <Guard
        planPermissions={['invoiceManagement']}
        permissions={['Invoice.read', 'Invoice.read.write']}
        roles={['ROLE_ADMIN']}
        authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
        <div
          className={clsx('app-navbar-item', itemClass)}
          onClick={() => {
            addNewPOS(3);
          }}>
          <OverlayTrigger
            placement='bottom'
            overlay={
              <Tooltip>
                <FormattedMessage id='TOPBAR.POS' />
              </Tooltip>
            }>
            <button className={clsx('btn-navbar position-relative')}>
              <i className='fa-light fa-cash-register'></i>
              <span className=' h-6px w-6px position-absolute translate-middle  fs-8 beta-blink'>
                Beta
              </span>
            </button>
          </OverlayTrigger>
        </div>
      </Guard>

      {/* Settings */}

      <Guard roles={['ROLE_ADMIN']} authorize={authorizeOnRole}>
        <div className={clsx('app-navbar-item', itemClass)}>
          <OverlayTrigger
            placement='bottom'
            overlay={
              <Tooltip>
                <FormattedMessage id='TOPBAR.SETTINGS' />
              </Tooltip>
            }>
            <button
              className={clsx('btn-navbar')}
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'>
              <i className='fa-light fa-gear'></i>
            </button>
          </OverlayTrigger>
          <div className='menu menu-sub menu-sub-dropdown menu-column sw-menu' data-kt-menu='true'>
            <div className='sw-menu-body'>
              <Link to={'/settings/company'} className='sw-menu-item'>
                <span className='sw-menu-item-icon'>
                  <i className='fa-light fa-buildings'></i>
                </span>
                <span className='sw-menu-item-title'>
                  <FormattedMessage id='TOPBAR.SETTINGS.COMPANYPROFILE' />
                </span>
              </Link>
              <Link to={'/settings/documents'} className='sw-menu-item'>
                <span className='sw-menu-item-icon'>
                  <i className=' fa-light fa-file-circle-check'></i>
                </span>
                <span className='sw-menu-item-title'>
                  <FormattedMessage id='TOPBAR.SETTINGS.DOCUMENTSSETTINGS' />
                </span>
              </Link>
              <Link to={'/settings/vats-and-taxes'} className='sw-menu-item'>
                <span className='sw-menu-item-icon'>
                  <i className='fa-light fa-file-invoice-dollar'></i>
                </span>
                <span className='sw-menu-item-title'>
                  <FormattedMessage id='TOPBAR.SETTINGS.TAXESANDVTA' />
                </span>
              </Link>
              <Guard
                planPermissions={['userManagement']}
                permissions={['Customer.read.write']}
                roles={['ROLE_ADMIN']}
                authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
                <Link to='/settings/users' className='sw-menu-item'>
                  <span className='sw-menu-item-icon'>
                    <i className='fa-light fa-users'></i>
                  </span>
                  <span className='sw-menu-item-title'>
                    <FormattedMessage id='TOPBAR.SETTINGS.USERSANDROLES' />
                  </span>
                </Link>
              </Guard>
              <Link to='/settings/banks' className='sw-menu-item'>
                <span className='sw-menu-item-icon'>
                  <i className='fa-light fa-building-columns'></i>
                </span>
                <span className='sw-menu-item-title'>
                  <FormattedMessage id='TOPBAR.SETTINGS.BANKACCOUNT' />
                </span>
              </Link>
              <Link to={'/settings/accounting'} className='sw-menu-item'>
                <span className='sw-menu-item-icon'>
                  <i className='fa-light fa-chart-user'></i>
                </span>
                <span className='sw-menu-item-title'>
                  <FormattedMessage id='TOPBAR.SETTINGS.ACCOUNTANT' />
                </span>
              </Link>
              <Link to={'/settings/prices-list'} className='sw-menu-item'>
                <span className='sw-menu-item-icon'>
                  <i className='fa-light fa-money-check-dollar-pen'></i>
                </span>
                <span className='sw-menu-item-title'>
                  <FormattedMessage id='TOPBAR.SETTINGS.PRICELIST' />
                </span>
              </Link>
              <Link to={'/settings/projects'} className='sw-menu-item'>
                <span className='sw-menu-item-icon'>
                  <i className='fa-light fa-folders'></i>
                </span>
                <span className='sw-menu-item-title'>
                  <FormattedMessage id='TOPBAR.SETTINGS.PROJECTS' />
                </span>
              </Link>
              <Link to={'/settings/brands-and-categories'} className='sw-menu-item'>
                <span className='sw-menu-item-icon'>
                  <i className='fa-light fa-copyright'></i>
                </span>
                <span className='sw-menu-item-title'>
                  <FormattedMessage id='TOPBAR.SETTINGS.BRANDS_CATEGORIES_AND_UNITS' />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </Guard>

      {/* Theme Switcher */}
      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-navbar')} />
      </div>

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <Languages type='iconed' />
      </div> */}
      {/* Swiver lite */}
      <div className={clsx('app-navbar-item', itemClass)}>
        {currentCompany?.plan.max_document !== -1 && (
          <>
            <div className='d-flex align-items-center mx-2 '>
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip>
                    <FormattedMessage id='TOPBAR.SETTINGS' />
                  </Tooltip>
                }>
                <button
                  className={clsx(
                    'btn btn-icon btn-icon-muted btn-active-light btn-color-muted h-30px px-10 h-md-40px'
                  )}
                  data-kt-menu-trigger='hover'
                  data-kt-menu-attach='parent'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='bottom'>
                  <i className='me-2 fs-3 fa-sharp fa-light fa-file-invoice text-gray-800'></i>{' '}
                  {currentCompany?.count_documents_per_month}/
                  {currentCompany?.plan.max_document_per_month}
                </button>
              </OverlayTrigger>
              <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold fs-7 w-auto py-4'
                data-kt-menu='true'>
                <div className='menu-title px-6 mb-4 text-gray-600 text-uppercase text-center'>
                  <div className='d-flex flex-column gap-2'>
                    <span>
                      <FormattedMessage id='TOPBAR.DOCUMENT_LIMIT' />{' '}
                    </span>
                    <button className='btn btn-primary fs-6'>
                      <i className='me-2 fs-3 fa-sharp fa-light fa-file-invoice '></i>{' '}
                      {currentCompany?.count_documents_per_month}/
                      {currentCompany?.plan.max_document_per_month}{' '}
                      <FormattedMessage id='TOPBAR.DOCUMENT_LIMIT.MONTHLY' />
                    </button>
                    <button className='btn btn-primary fs-6'>
                      <i className='me-2 fs-3 fa-sharp fa-light fa-file-invoice '></i>{' '}
                      {currentCompany?.count_documents}/{currentCompany?.plan.max_document}{' '}
                      <FormattedMessage id='TOPBAR.DOCUMENT_LIMIT.YEARLY' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {/* User */}
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'>
          <span className='d-flex align-items-center'>
            <div className='d-none d-md-flex flex-column text-right  me-1'>
              <span className='text-muted font-weight-bold'>
                <FormattedMessage id='COMMON.GREETING' />,
              </span>
              <span className='text-dark-75 font-weight-bold'>{user?.first_name}</span>
            </div>
            <div className='symbol ml-2'>
              <span className='symbol-label bg-light-primary text-primary font-size-h5'>
                {user?.first_name.charAt(0).toUpperCase()}
              </span>
            </div>
          </span>
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  );
};

export { Navbar };
