import { Navigate, Route, Routes } from 'react-router-dom';
import { BalanceModalsProvider } from '../../ModalContexts/BalanceModalsContext';
import BankModalProvider from '../../ModalProviders/BankModal';
import { SuspensedView } from '../../routing/PrivateRoutes';
import { lazyWithRetry } from '../../utils/LazyWithRetry';
import Guard, {
  authorizeOnRoleOrPermissionAndPermissionPlan,
} from '../../components/Security/Guard';

const CashManagement = lazyWithRetry(() => import('./CashManagement'));
const Balance = lazyWithRetry(() => import('./CashManagement/Balance'));
const BankAccount = lazyWithRetry(() => import('./BankAccount'));
const SearchOperation = lazyWithRetry(() => import('./BankAccount/SearchOperation'));

function AcountingRoutes() {
  return (
    <Routes>
      <Route>
        <Route path='/' element={<Navigate to='/accounting/cash-management' />} />
        <Route
          path='/cash-management'
          element={
            <Guard
              planPermissions={['cashManagement']}
              permissions={['Accounting.manage.balance']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <SuspensedView>
                <CashManagement />
              </SuspensedView>
            </Guard>
          }
        />
        <Route
          path='/search-operation/:id'
          element={
            <Guard
              planPermissions={['bankAccount']}
              permissions={['Accounting.manage.bankAccount']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <SuspensedView>
                <SearchOperation />
              </SuspensedView>
            </Guard>
          }
        />
        <Route
          path='/balance/:id'
          element={
            <Guard
              planPermissions={['cashManagement']}
              permissions={['Accounting.manage.balance']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <SuspensedView>
                <BalanceModalsProvider>
                  <Balance />
                </BalanceModalsProvider>
              </SuspensedView>{' '}
            </Guard>
          }
        />
        <Route
          path='/bank-account'
          element={
            <Guard
              planPermissions={['bankAccount']}
              permissions={['Accounting.manage.bankAccount']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <SuspensedView>
                <BankModalProvider>
                  <BankAccount />
                </BankModalProvider>
              </SuspensedView>
            </Guard>
          }
        />
      </Route>
    </Routes>
  );
}

export default AcountingRoutes;
