import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../setup/Store';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

function SwPageNavigation() {
  const title = useSelector((state: RootState) => state.page.title);
  const returnUrl = useSelector((state: RootState) => state.page.returnUrl);
  return (
    <div className='sw-p-nav'>
      {!!returnUrl && (
        <Link to={returnUrl} className='nav-back'>
          <i className='fa-regular fa-arrow-left'></i>
          <span className='nav-back-title'>
            <FormattedMessage id='NAV.BACK_TO_LIST' />
          </span>
        </Link>
      )}
      <h1 className='title-nav'>{title}</h1>
    </div>
  );
}

export default SwPageNavigation;
