import { useLottie } from 'lottie-react';
import loadingAnimation from '../../assets/loading.json';

export function Lottie({
  animation = loadingAnimation,
  width = 250,
  height = 250,
}: {
  animation?: any;
  width?: number;
  height?: number;
  loop?: boolean;
  autoplay?: boolean;
}) {
  const style = {
    height,
    width,
  };
  const options = {
    style,
    animationData: animation,
    loop: true,
    autoplay: true,
  };
  const { View } = useLottie(options);
  return View;
}
