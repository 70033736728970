import doneAnimation from '../../assets/checkedAnimation.json';
import Lottie from 'lottie-react';

const DoneLottie = ({
  height = 150,
  width = 250,
  loop = true,
}: {
  height?: number;
  width?: number;
  loop?: number | boolean;
}) => {
  return (
    <Lottie
      animationData={doneAnimation}
      height={height}
      width={width}
      autoPlay={true}
      loop={loop as boolean}
    />
  );
};

export default DoneLottie;
