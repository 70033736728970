import clsx from 'clsx';
import { forwardRef } from 'react';
import Select, { GroupBase, Props as SelectProps } from 'react-select';

import SelectType from 'react-select/dist/declarations/src/Select';

interface CustomSelectProps extends SelectProps<any> {
  menuWidth?: string;
  menuMinWidth?: string;
}

const SwiverSelect = forwardRef<SelectType<any, boolean, GroupBase<any>>, CustomSelectProps>(
  (
    {
      classNamePrefix = 'swiver-select',
      className = '',
      value,
      options,
      menuWidth = '100%',
      menuMinWidth = '150px',
      placeholder = '',
      ...props
    },
    ref
  ) => {
    return (
      <Select
        ref={ref}
        className={clsx('swiver-select', className)}
        classNamePrefix={classNamePrefix}
        value={options?.filter((option) => option.value === value || option === value)}
        options={options}
        styles={{ menu: (provided) => ({ ...provided, width: menuWidth, minWidth: menuMinWidth }) }}
        placeholder={placeholder}
        {...props}
      />
    );
  }
);

export default SwiverSelect;
