/* eslint-disable jsx-a11y/anchor-is-valid */

import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { loginWithGoogle } from '../../../../setup/redux/effects/UtilsEffects';
import { RootState } from '../../../../setup/Store';
import { signIn, updateTokensAndNavigat } from '../../../../setup/redux/reducers/AuthReducer';

type userLogin = {
  email: string;
  password: string;
  rememberMe: boolean;
};
const initialValues = {
  email: localStorage.getItem('lastRemeberedEmail') || '',
  password: '',
  rememberMe: false,
};
export function Login() {
  const [isPasword, setIsPassword] = useState(true);
  const [code, setCode] = useState(new URLSearchParams(window.location.search).get('code'));
  useEffect(() => {
    if (code) {
      loginAuthGoogle(code);
    }
  }, [code]);
  const navigate = useNavigate();

  const loginAuthGoogle = (code: string) => {
    loginWithGoogle({
      code: code,
      redirectUri: window.location.origin + '/sign-in',
    })
      .then((res) => {
        dispatch(updateTokensAndNavigat(res.data));
      })
      .catch(() => {
        // remove all query param from url
        navigate('/sign-in');
      });
  };
  const goToLink = () => {
    window.open(
      'https://accounts.google.com/o/oauth2/auth?response_type=code&redirect_uri=' +
        window.location.origin +
        '/sign-in&client_id=' +
        process.env.REACT_APP_GOOGLE_CLIENT_ID +
        '&scope=email+profile&access_type=offline&approval_prompt=auto',
      '_self'
    );
  };
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.auth.loading);
  const error = useSelector((state: RootState) => state.auth.error);
  const intl = useIntl();
  const loginSchema = Yup.object().shape({
    email: Yup.string().required('FORM.ERRORS.REQUIRED'),
    password: Yup.string()
      .required('FORM.ERRORS.REQUIRED')
      .min(4, (min) => 'FORM.ERRORS.MINLENGTH'),
  });
  const onSubmit = (values: userLogin) => {
    dispatch(signIn(values));
  };
  const {
    register,
    formState: { errors, touchedFields: touched, isValid },
    handleSubmit,
  } = useForm({
    mode: 'onTouched',
    defaultValues: initialValues,
    resolver: yupResolver(loginSchema),
  });
  return (
    <>
      <form
        className='form w-100 d-flex flex-column justify-content-between'
        onSubmit={handleSubmit(onSubmit)}
        id='kt_login_signup_form'
        noValidate>
        {/* begin::Heading */}
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>
            <FormattedMessage id='AUTH.LOGIN.TITLE' />
          </h1>
          <div className='text-gray-400 fw-semibold d-flex justify-content-center gap-3 fs-4'>
            <FormattedMessage id='AUTH.LOGIN.NEW' />
            <Link to='/sign-up' className='link-primary fw-bold'>
              <FormattedMessage id='AUTH.LOGIN.CREATE' />
            </Link>
          </div>
        </div>

        {/* begin::Google link */}
        {/* <div
          onClick={() => goToLink()}
          className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100 border border-1 border-gray-300'>
          <img
            alt=' '
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          <FormattedMessage
            id='AUTH.LOGIN.PROVIDER'
            values={{ provider: intl.formatMessage({ id: 'PROVIDERS.GOOGLE' }) }}
          />
        </div> */}
        {/* end::Google link */}
        {/* begin::Separator */}
        {/* <div className='text-center text-muted text-uppercase fw-bold my-4'>
          <FormattedMessage id='AUTH.OR' />
        </div> */}
        {/* end::Separator */}
        {/* begin::Heading */}
        {error && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{error}</div>
          </div>
        )}
        <div className='row g-5 '>
          <div className='col-12'>
            <input
              {...register('email')}
              className={clsx('form-control', {
                'is-valid': touched.email && !errors.email,
                'is-invalid': touched.email && errors.email,
              })}
              type='text'
              placeholder={intl.formatMessage({ id: 'LOGIN.EMAIL.NUMBER.PLACEHOLDER' })}
            />

            {touched.email && errors.email && (
              <div className='mt-3'>
                <span className='text-danger' role='alert'>
                  <FormattedMessage
                    id={'' + errors.email?.message}
                    values={{ field: intl.formatMessage({ id: 'FORM.FIELD.EMAIL.LABEL' }) }}
                  />
                </span>
              </div>
            )}
          </div>

          <div className='col-12'>
            <input
              {...register('password')}
              className={clsx('form-control', {
                'is-valid': touched.password && !errors.password,
                'is-invalid': touched.password && errors.password,
              })}
              type='password'
              placeholder={intl.formatMessage({ id: 'AUTH.FORM.PASSWORD.PLACEHOLDER' })}
            />
            {touched.password && errors.password && (
              <div className='mt-3'>
                <span className='text-danger' role='alert'>
                  <FormattedMessage
                    id={'' + errors.password?.message}
                    values={{ field: intl.formatMessage({ id: 'AUTH.FORM.PASSWORD.LABEL' }) }}
                  />
                </span>
              </div>
            )}
          </div>

          <div className='col-6'>
            <label className='form-check form-check-custom form-check-solid'>
              <input className='form-check-input' type='checkbox' {...register('rememberMe')} />
              <span className='form-check-label fw-semibold text-muted'>
                <FormattedMessage id='AUTH.LOGIN.REMEMBER_ME' />
              </span>
            </label>
          </div>
          <div className='col-6 text-end'>
            <Link to='/forgot-password'>
              <FormattedMessage id='AUTH.FORGET_PASSWORD.TITLE' />
            </Link>
          </div>
          <div className='col-12'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-lg btn-primary btn-submit w-100 '
              disabled={loading}>
              {!loading && (
                <span className='indicator-label'>
                  <FormattedMessage id='AUTH.LOGIN.SUBMIT' />
                </span>
              )}
              {loading && (
                <span className='indicator-progress d-block'>
                  <FormattedMessage id='FORM.WAIT' />
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>

        {/* end::Form group */}
        {/* begin::Form group */}

        {/* end::Form group */}

        {/* begin::Action */}
        {/* end::Action */}
      </form>
    </>
  );
}
