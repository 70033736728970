import Lottie from '../../components/Lottie';

function LoadingLottie() {
  return (
    <div className='m-auto'>
      <Lottie />
    </div>
  );
}

export default LoadingLottie;
