import { add } from 'date-fns/esm';
import * as Yup from 'yup';
const tomorrow = add(new Date(), { days: 1 });
tomorrow.setHours(0, 0, 0, 0);
export const EXPENSE_MIN_AMOUNT = 0 as const;
export const depenseValidationSchemas = [
  Yup.object().shape({
    paymentMethod: Yup.string().required('FORM.ERRORS.REQUIRED'),
    amount: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('FORM.ERRORS.REQUIRED')
      .moreThan(EXPENSE_MIN_AMOUNT, 'FORM.ERRORS.GREATER_THAN')
      .typeError('FORM.ERRORS.NOT_NUMBER'),
  }),
  Yup.object().shape({
    expenseCategory: Yup.string().required('FORM.ERRORS.REQUIRED'),
    date: Yup.string().required('FORM.ERRORS.REQUIRED'),
    contact: Yup.string().required('FORM.ERRORS.REQUIRED'),
  }),
  Yup.object().shape({
    checkNumber: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .when('paymentMethod', {
        is: (value: string | number) => value + '' === 'CHECK',
        then: Yup.number()
          .required('FORM.ERRORS.REQUIRED')
          .transform((value) => (isNaN(value) ? undefined : value))
          .typeError('FORM.ERRORS.TYPE'),
      }),
    bankAccountSource: Yup.string().when('paymentMethod', {
      is: (value: string | number) => value + '' === 'CHECK',
      then: Yup.string().required('FORM.ERRORS.REQUIRED'),
    }),
    date: Yup.date()
      .when('paymentMethod', {
        is: (value: string | number) => value + '' === 'CHECK',
        then: Yup.date().required('FORM.ERRORS.REQUIRED').max(tomorrow, 'FORM.ERRORS.MAX_DATE'),
      })
      .typeError('FORM.ERRORS.TYPE'),
    dueDate: Yup.date()
      .when('paymentMethod', {
        is: (value: string | number) => value + '' === 'CHECK',
        then: Yup.date().required('FORM.ERRORS.REQUIRED'),
      })
      .typeError('FORM.ERRORS.TYPE')
      .max(tomorrow, 'FORM.ERRORS.MAX_DATE'),
  }),
  Yup.object({
    note: Yup.string(),
  }),
];
