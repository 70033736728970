import clsx from 'clsx';
import React, { Ref, forwardRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../setup/Store';
import { TopNavTabType } from '../../setup/redux/reducers/PageReducer';
export interface SWTopNavProps {}

interface SwTopNavItemProps {
  active?: boolean;
  disabled?: boolean;
  labelContent?: React.ReactFragment;
  badgeContent?: React.ReactFragment;
  onClick?: () => void;
}

const SwTopNavItem = forwardRef(
  (
    {
      active = false,
      disabled = false,
      labelContent = '',
      badgeContent = undefined,
      onClick = () => {},
    }: SwTopNavItemProps,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <div
        ref={ref}
        onClick={onClick}
        className={clsx('sw-nav-item', active && 'active', disabled && 'disabled')}>
        <div className='sw-nav-item-label'>{labelContent}</div>
        {badgeContent !== undefined && <span className='sw-nav-item-badge'>({badgeContent})</span>}
      </div>
    );
  }
);

function SWTopNav({ navTabs = [] }: { navTabs?: TopNavTabType[] }) {
  useEffect(() => {
    document.body.setAttribute('sw-topnav', 'true');
    return () => {
      document.body.setAttribute('sw-topnav', 'false');
    };
  }, []);
  return (
    <div className='sw-topnav'>
      <div className='sw-nav'>
        {navTabs.map((tab, index) => (
          <SwTopNavItem
            active={tab.active}
            disabled={tab.disabled}
            key={index}
            badgeContent={tab.quantity}
            labelContent={tab.content!}
            onClick={tab.onClick}></SwTopNavItem>
        ))}
      </div>
    </div>
  );
}

export { SwTopNavItem };
export default SWTopNav;
