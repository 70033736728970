import axios from 'axios';
import produce from 'immer';
import { Customer, CustomerResponse } from '../../../app/models/CustomerResponse';
import { delayPromise } from '../../../app/utils/utils';

const turnoverOperations = ['=', '>', '>=', '<', '<=', '!='] as const;
export type Operator = (typeof turnoverOperations)[number];
export type CustomerQuery = {
  enabled: 'true' | 'false' | boolean;
  offset: number;
  limit: number;
  query: string;
  in_debt: '' | boolean;
  type: '' | '0' | '1';
  operator: Operator;
  turnover: string;
  registration: string;
  price_list: string;
  company_name: string;
};

export function getCustomers(params: Partial<CustomerQuery>) {
  return delayPromise(axios.get<CustomerResponse>('/secure/customers/v2', { params }));
}

export function putCustomer(payload: Partial<Customer>) {
  const customer = produce(payload, (draft) => {
    const mobile = payload.contact_phones?.find((phone) => phone.type + '' === '1');
    const phone = payload.contact_phones?.find((p) => p.type + '' === '8');
    const fax = payload.contact_phones?.find((p) => p.type + '' === '2');
    draft.phone1 = mobile ? mobile.value : null;
    draft.phone2 = phone ? phone.value : null;
    draft.phone3 = fax ? fax.value : null;
  });
  return axios.put<Customer>(`/secure/customer/${customer.id}`, customer);
}

export const postCustomer = (payload: Partial<Customer>) => {
  return axios.post<Customer>(`/secure/customer`, payload);
};

export const getCustomer = (payload: { id: string; company: number }) => {
  return axios.get<Customer>(`/secure/customer/${payload.id}`, {
    params: { company: payload.company },
  });
};

export const undelayedGetCustomers = (params: Partial<CustomerQuery>) => {
  return axios.get<CustomerResponse>('/secure/customers/v2', { params });
};

export const exportCustomers = (query: any) => {
  return axios.get(`/secure/customers/export/v2`, {
    params: query,
    responseType: 'arraybuffer',
  });
};

export const verifyListCustomer = (file: any, onUploadProgress: (progressEvent: any) => void) => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post(`/secure/customers/import/verify`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
};
export const importCustomers = (file: string) => {
  //send in query param file_name
  return axios.post(`/secure/customers/import`, null, { params: { file_name: file } });
};
// export const download Customer model
export const downloadCustomerModel = () => {
  return axios.get(`/secure/customers/template`, {
    responseType: 'arraybuffer',
  });
};
