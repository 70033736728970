import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from '../../../../setup/Store';
import { getUser } from '../../../../setup/redux/effects/AuthEffects';
import { finishAddNewCompany } from '../../../../setup/redux/effects/UtilsEffects';
import { signOut } from '../../../../setup/redux/reducers/AuthReducer';
import Loader from '../../../components/Loader/Loader';
import { toAbsoluteUrl } from '../../../helpers';
import { MenuComponent } from '../../../utils/ts/components';
import PreviewCompany from '../../Walkthrough/PreviewCompany';
import Step1Wth from '../../Walkthrough/Step1Wth';
import Step2Wth from '../../Walkthrough/Step2Wth';
import Step3Wth from '../../Walkthrough/Step3Wth';
import Step4Wth from '../../Walkthrough/Step4Wth';

function AddCompany({ closeModal = () => {} }) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);
  const [step, setstep] = useState(1);

  const methods = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      step1: {
        activity: '',
        company_size: '',
      },
      step2: {
        email: '',
        phone1: '',
        phone2: '',
        company_name: '',
        taxNumber: '',
        address: '',
        region: '',
        zip_code: '',
      },
      step3: {
        vat: [] as number[],
        taxes: [] as number[],
      },
      step4: {
        activity: '',
        company_size: '',
      },
      contact_phones: [],
      logo: null,
    },
  });
  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { isSubmitting },
  } = methods;
  const listVats = useSelector((state: RootState) => state.utils.vats);
  const listTaxes = useSelector((state: RootState) => state.utils.taxes);
  const transformObject = (originalObject: any) => {
    const transformedObject: any = {
      vats: [] as any[],
      taxes: [] as any[],
      setting: {
        activity: originalObject.step4.activity,
        company_size: originalObject.step4.company_size,
        email: originalObject.step2.email,
        tax_number: originalObject.step2.taxNumber,
        address: originalObject.step2.address,
        region: originalObject.step2.region,
        country: originalObject.step2.country, // or you can get this from user input
        zip_code: originalObject.step2.zip_code,
        contact_phones: originalObject.contact_phones,
        default_currency: originalObject.step2.currency,
      },
      name: originalObject.step2.company_name,
    };
    if (originalObject.logo) {
      transformedObject.setting.logo = dataURItoBlob(originalObject.logo);
    }

    listVats?.forEach((vat: any) => {
      if (originalObject.step3.vat.includes(vat.id)) {
        transformedObject.vats.push({ vat: vat.vat, enabled: true });
      } else {
        transformedObject.vats.push({ vat: vat.vat, enabled: false });
      }
    });

    listTaxes?.forEach((tax: any) => {
      if (originalObject.step3.taxes.includes(tax.id)) {
        transformedObject.taxes.push({ name: tax.name, amount: tax.amount, enabled: true });
      } else {
        transformedObject.taxes.push({ name: tax.name, amount: tax.amount, enabled: false });
      }
    });
    return transformedObject;
  };

  const dataURItoBlob = (dataURI: any) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 100);
  }, []);
  const nextStep = async (data: any) => {
    if (step === 4) {
      try {
        await finishAddNewCompany(transformObject(data));
        closeModal();
        dispatch(getUser());
      } catch (error) {
        toast.error(formatMessage({ id: 'TOAST.ERROR' }));
      }
    } else {
      setstep(Math.min(step + 1, 4));
    }
    setisLoading(false);
  };

  const handleSignOut = () => {
    dispatch(signOut());
  };
  return (
    <FormProvider {...methods}>
      {step != 0 && (
        <>
          <div className='row  min-h-600px' id='walkthrough'>
            <div className='col-12 col-md-8 bg-body rounded-5 ps-0 rounded-md-end-0'>
              <form className='d-flex flex-column p-7 h-100' onSubmit={handleSubmit(nextStep)}>
                <div>
                  <img src={toAbsoluteUrl('/media/logos/logoBlack.png')} alt='' />
                </div>
                <div className='h-100 min-h-500px py-4 overflow-hidden'>
                  {step == 1 && (
                    <Loader loading={isLoading}>
                      <Step1Wth />
                    </Loader>
                  )}
                  {step == 2 && (
                    <Loader loading={isLoading}>
                      <Step2Wth />
                    </Loader>
                  )}
                  {step == 3 && (
                    <Loader loading={isLoading}>
                      <Step3Wth addCompany={true} setIsloading={setisLoading} />
                    </Loader>
                  )}
                  {step == 4 && (
                    <Loader loading={isLoading}>
                      <Step4Wth />
                    </Loader>
                  )}
                </div>
                <div className='d-flex flex-column gap-4'>
                  <span className='text-muted'>
                    <FormattedMessage id='WALKTHROUGHT.STEP.N.OUT.OF' values={{ step }} />
                  </span>
                  <div className='progress h-6px w-100'>
                    <div
                      className='progress-bar bg-primary'
                      role='progressbar'
                      style={{ width: step * 25 + '%' }}></div>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <div>
                      {step !== 1 && (
                        <button
                          type='button'
                          className='btn btn-link btn-color-muted btn-active-color-primary  fw-bold'
                          onClick={() => {
                            setstep(Math.max(step - 1, 0));
                          }}>
                          <i className='fs-4 fa-regular fa-arrow-left'></i>{' '}
                          <FormattedMessage id='FORM.BACK' />
                        </button>
                      )}
                    </div>
                    {step === 3 && (
                      <button
                        type='button'
                        className='btn btn-link btn-color-muted btn-active-color-primary  fw-semibold ms-auto me-4'
                        onClick={() => {
                          setstep(Math.min(step + 1, 4));
                        }}>
                        <FormattedMessage id='COMMON.SKIP' />
                      </button>
                    )}

                    <button
                      className='btn btn-primary btn-submit'
                      type='submit'
                      disabled={isSubmitting}>
                      <span className='indicator-label'>
                        {isSubmitting && <span className='spinner-border align-middle me-2'></span>}
                        <FormattedMessage id='FORM.NEXT' />
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className='col-4 d-none d-md-block bg-preview rounded-5 rounded-start-0'>
              <div className='d-flex flex-column justify-content-center p-15 h-100'>
                <PreviewCompany />
              </div>
            </div>
          </div>
        </>
      )}
    </FormProvider>
  );
}

export default AddCompany;
