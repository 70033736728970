import { Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../setup/Store';
import OverlayTrigger from '../../OverlayTrigger/OverlayTrigger';

function DetailsActions() {
  const detailsActions = useSelector((state: RootState) => state.page.detailsActions);
  const CustomActions = useSelector((state: RootState) => state.page.customActions);
  return (
    <div className='d-flex align-items-center gap-3 ms-auto'>
      {detailsActions.display?.customActions && CustomActions}
      {detailsActions.display?.edit && (
        <OverlayTrigger
          placement='bottom'
          overlay={<Tooltip>{detailsActions?.tooltips?.edit}</Tooltip>}>
          <button
            onClick={detailsActions?.actions?.edit}
            className='btn btn-sm btn-sw-outline btn-sw-outline-primary d-flex align-items-center ms-auto'>
            <i className='fa-light fa-pen-to-square'></i>
            <div className='d-none d-md-flex'>
              <FormattedMessage id='TOOLBAR.ACTIONBAR.LABEL.EDIT' />
            </div>
          </button>
        </OverlayTrigger>
      )}
    </div>
  );
}
export default DetailsActions;
