import * as Yup from 'yup';
import 'yup-phone';
import { webSitePattern } from '../../../../utils/utils';
const typeSchema = Yup.object().shape({
  type: Yup.number().required('FORM.ERRORS.REQUIRED'),
});
const validatePhone = async (phone: string) => {
  const phoneSchema = Yup.string().phone();
  try {
    const result = await phoneSchema.isValid(phone);
    return result;
  } catch (error: any) {
    return error.message;
  }
};
export const supplierSchemas = [
  Yup.object().shape({
    civility: Yup.string().when('type', {
      is: '0',
      then: Yup.string().required('FORM.ERRORS.REQUIRED'),
    }),
    name: Yup.string().when('type', {
      is: '0',
      then: Yup.string().required('FORM.ERRORS.REQUIRED'),
    }),
    email: Yup.string().email('FORM.ERRORS.EMAIL'),
    website: Yup.string().test('is-url-valid', 'FORM.ERRORS.URL', (value) => {
      if (!value) return true;
      return webSitePattern.test(value);
    }),
    company_name: Yup.string().when('type', {
      is: '1',
      then: Yup.string().required('FORM.ERRORS.REQUIRED'),
    }),
    registration: Yup.string(),
  }),
  Yup.object().shape({
    contact_phones: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().required('FORM.ERRORS.REQUIRED'),
        value: Yup.string()
          .required('FORM.ERRORS.REQUIRED')
          .test('is-phone-valid', 'FORM.ERRORS.PHONE', async (value) => {
            if (!value) return true;
            return await validatePhone(value);
          }),
      })
    ),
    contact_address: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().required('FORM.ERRORS.REQUIRED'),
        address: Yup.object().shape({
          country: Yup.string().required('FORM.ERRORS.REQUIRED'),
          region: Yup.string().required('FORM.ERRORS.REQUIRED'),
          zip_code: Yup.string().required('FORM.ERRORS.REQUIRED'),
          address: Yup.string().required('FORM.ERRORS.REQUIRED'),
        }),
      })
    ),
  }),
  Yup.object().shape({
    in_progress: Yup.number()
      .transform((cv, ov) => {
        return ov === '' ? undefined : cv;
      })
      .typeError('FORM.ERRORS.NUMBER'),
    due_date_type: Yup.string(),
    due_date_number: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .typeError('FORM.ERRORS.NUMBER')
      .when('due_date_type', {
        is: (value: string) => value,
        then: Yup.number()
          .integer('FORM.ERRORS.INTEGER')
          .transform((value) => (isNaN(value) ? undefined : value))
          .required('FORM.ERRORS.REQUIRED')
          .typeError('FORM.ERRORS.NUMBER'),
      }),
  }),
];
export const customerSchemas = [typeSchema, ...supplierSchemas];
