import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { BankAccount } from '../../../app/models/BankAccounts';
import { Bank, UserBank } from '../../../app/models/BankResponse';
import { Currency } from '../../../app/models/Currency';
import { Price } from '../../../app/models/PriceList';
import {
  Brand,
  Category,
  ExpenseCategory,
  IncomeCategories,
  Tax,
  Unit,
  Vat,
} from '../../../app/models/ProductResponse';
import { Project } from '../../../app/models/Project';
import { TransationType } from '../../../app/models/TransactionType';
import { Warehouse } from '../../../app/models/warehousesResponse';
import { Activity } from '../../../app/modules/auth/models/UserModel';
import { delayPromise } from '../../../app/utils/utils';
import store from '../../Store';
import { getBalanceTransactionTypes, getTransactionTypes } from '../effects/BankAccountEffects';
import {
  getActivities,
  getBankAccounts,
  getBankList,
  getBrands,
  getCategories,
  getCountries,
  getCurrencies,
  getExpenseCategories,
  getIncomeCategories,
  getListBankWithTotal,
  getPriceList,
  getProjects,
  getTaxes,
  getUnits,
  getUserBanks,
  getVats,
  getWarehouseProducts,
  getWarehouses,
  patchTaxe,
  patchVat,
  putPriceList,
} from '../effects/UtilsEffects';

type ThemeModeType = 'light' | 'dark' | 'system';

const getThemeModeFromLocalStorage = (): ThemeModeType => {
  const data = localStorage.getItem(THEME);
  if (data === 'dark' || data === 'light') {
    return data;
  }
  return 'light';
};
type Language = 'en' | 'fr' | 'ar';
type Country = { id: number; code: string; country: string };
const getLayout = (language: Language) => (language === 'ar' ? 'RTL' : 'LTR');

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig';
const THEME = process.env.REACT_APP_THEME_CONFIG_KEY || 'theme';
const supportedLanguages = ['en', 'fr', 'ar'];
const lsValue = localStorage.getItem(I18N_CONFIG_KEY);
const currentTheme = (() => {
  const theme = getThemeModeFromLocalStorage();
  document.documentElement.setAttribute('data-theme', theme);
  return theme;
})();
const lang = (() => {
  const value =
    lsValue && supportedLanguages.includes(lsValue)
      ? lsValue
      : supportedLanguages.includes(navigator.language.slice(0, 2))
      ? navigator.language.slice(0, 2)
      : process.env.REACT_APP_DEFAULT_LANGUAGE || 'en';
  document.documentElement.lang = value;

  return value;
})();

export const readExpenseCategories = createAsyncThunk('utils/expenseCategories', async () => {
  const { data } = await getExpenseCategories();
  return data;
});

export const readTransactionTypes = createAsyncThunk('utils/transactionTypes', async () => {
  const { data } = await getTransactionTypes();
  return data;
});
export const readBalanceTransactionTypes = createAsyncThunk(
  'utils/balanceTransactionTypes',
  async () => {
    const { data } = await getBalanceTransactionTypes();
    return data;
  }
);

export const readIncomeCategories = createAsyncThunk('utils/incomeCategories', async () => {
  const { data } = await getIncomeCategories();
  return data;
});

export const readActivities = createAsyncThunk('utils/activities', async () => {
  const { data } = await getActivities();
  return data;
});

export const readCurrencies = createAsyncThunk('utils/currencies', async () => {
  const { data } = await getCurrencies();
  return data;
});

export const readCountries = createAsyncThunk('utils/countries', async () => {
  const { data } = await getCountries();
  return data;
});

export const readPriceList = createAsyncThunk('utils/pricelist', async () => {
  const state = store?.getState();
  const company = state?.auth?.user?.current_company?.id as number;
  const { data } = await getPriceList({ company });
  return data;
});

export const readCategories = createAsyncThunk('utils/categories', async () => {
  const state = store?.getState();
  const company = state?.auth?.user?.current_company?.id as number;
  const { data } = await getCategories({ company });
  return data;
});

export const readVats = createAsyncThunk('utils/vats', async () => {
  const state = store?.getState();
  const company = state?.auth?.user?.current_company?.id as number;
  const { data } = await getVats({ company });
  return data;
});

export const readTaxes = createAsyncThunk('utils/taxes', async () => {
  const state = store?.getState();
  const company = state?.auth?.user?.current_company?.id as number;
  const { data } = await getTaxes({ company });
  return data;
});

export const updateVats = createAsyncThunk('utils/vats/patch', async (payload: any) => {
  const state = store?.getState();
  const { data } = await patchVat(payload.id, payload.isEnabled);
  const updatedVat = state.utils?.vats?.map((vat: Vat) => (vat.id === data.id ? data : vat));
  return updatedVat;
});

export const updateTaxes = createAsyncThunk('utils/taxes/patch', async (payload: any) => {
  const state = store?.getState();
  const { data } = await patchTaxe(payload.id, payload.isEnabled);
  const updatedTax = state.utils?.taxes?.map((tax: Tax) => (tax.id === data.id ? data : tax));
  return updatedTax;
});

export const updatePriceList = createAsyncThunk('utils/pricelist/update', async (payload: any) => {
  const state = store?.getState();
  const company = state?.auth?.user?.current_company?.id as number;
  payload.company = company;
  const { data } = await putPriceList(payload);
  const updatedPriceList = state.utils?.priceList?.map((price: Price) =>
    price.id === data.id ? data : price
  );
  return updatedPriceList;
});

export const readUnits = createAsyncThunk('/utils/units', async () => {
  const { data } = await getUnits();
  return data;
});

export const readBrands = createAsyncThunk('utils/brands', async () => {
  const state = store?.getState();
  const company = state?.auth?.user?.current_company?.id as number;
  const { data } = await getBrands({ company });
  return data;
});

export const readBankList = createAsyncThunk('utils/bankList', async () => {
  const state = store?.getState();
  const country = state?.auth?.user?.current_company?.setting?.country as string;
  const { data } = await getBankList(country);
  return data;
});

export const readBankAccounts = createAsyncThunk('utils/bankAccounts', async () => {
  const state = store?.getState();
  const company = state?.auth?.user?.current_company?.id as number;
  const { data } = await getBankAccounts(company);
  return data;
});

export const readUserBanks = createAsyncThunk('utils/userBanks', async () => {
  const state = store?.getState();
  const company = state?.auth?.user?.current_company?.id as number;
  const { data } = await getUserBanks({ company });
  return data;
});

export const readListBanks = createAsyncThunk('utils/listBanks', async () => {
  const state = store?.getState();
  const company = state?.auth?.user?.current_company?.id as number;
  const { data } = await getListBankWithTotal({ company });
  return data;
});

export const readWarehouses = createAsyncThunk('utils/warehouses', async () => {
  const state = store?.getState();
  const company = state?.auth?.user?.current_company?.id as number;
  const { data } = await getWarehouses({ company });
  return data;
});

export const readProjects = createAsyncThunk('utils/projects', async () => {
  const state = store?.getState();
  const company = state?.auth?.user?.current_company?.id as number;
  const { data }: AxiosResponse<Project[]> = await delayPromise(getProjects(company));
  return data;
});

export const readWarehouseProducts = createAsyncThunk(
  'utils/warehouseProducts',
  async (payload: any) => {
    const { data } = await getWarehouseProducts(payload);
    return data;
  }
);

const authSlice = createSlice({
  name: 'utils',
  initialState: {
    loading: {
      projects: false,
      vats: false,
      taxes: false,
    },
    activities: [] as Activity[],
    currencies: [] as Currency[],
    vats: undefined as Vat[] | undefined,
    taxes: undefined as Tax[] | undefined,
    countries: undefined as Country[] | undefined,
    locale: lang as Language,
    direction: getLayout(lang as Language) as 'RTL' | 'LTR',
    theme: currentTheme as ThemeModeType,
    messages: {},
    priceList: undefined as Price[] | undefined,
    units: undefined as Unit[] | undefined,
    brands: undefined as Brand[] | undefined,
    incomeCategories: undefined as IncomeCategories[] | undefined,
    expenseCategories: undefined as ExpenseCategory[] | undefined,
    categories: undefined as Category[] | undefined,
    bankList: undefined as Bank[] | undefined,
    userBanks: undefined as UserBank[] | undefined,
    warehouses: undefined as Warehouse[] | undefined,
    bankAccounts: undefined as BankAccount[] | undefined,
    projects: undefined as Project[] | undefined,
    warehouseProducts: undefined as any | undefined,
    transactionTypes: undefined as TransationType[] | undefined,
    balanceTransactionTypes: undefined as TransationType[] | undefined,
    listBanks: undefined as [] | undefined,
    posTabs: [] as any[] | undefined,
    FullScreen: false,
  },
  reducers: {
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    toggleFullScreen: (state) => {
      state.FullScreen = !state.FullScreen;
    },
    addPosTab: (state, action) => {
      if (state.posTabs) {
        if (state.posTabs.find((tab) => tab.id === action.payload.id)) return;
        state.posTabs.push(action.payload);
      } else {
        state.posTabs = [action.payload];
      }
    },
    removePosTab: (state, action) => {
      if (state.posTabs) {
        state.posTabs = state.posTabs.filter((tab) => tab.id !== action.payload);
      }
    },
    setLanguage: (
      state,
      action: PayloadAction<{ locale: Language; shouldNotReload?: boolean }>
    ) => {
      if (
        supportedLanguages.includes(action.payload.locale) &&
        action.payload.locale !== state.locale
      ) {
        localStorage.setItem(I18N_CONFIG_KEY, action.payload.locale);
        document.documentElement.lang = action.payload.locale;
        if (getLayout(state.locale) !== getLayout(action.payload.locale)) window.location.reload();
        else state.locale = action.payload.locale;
      }
    },
    setTheme: (state, action) => {
      if (action.payload !== state.theme) {
        localStorage.setItem(THEME, action.payload);
        document.documentElement.setAttribute('data-theme', action.payload);
        state.theme = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(readActivities.fulfilled, (state, action) => {
      state.activities = action.payload;
    });

    builder.addCase(readActivities.rejected, (state, action) => {
      state.activities = [];
    });

    builder.addCase(readCurrencies.fulfilled, (state, action) => {
      state.currencies = action.payload;
    });

    builder.addCase(readCurrencies.rejected, (state, action) => {
      state.currencies = [];
    });

    builder.addCase(readCountries.fulfilled, (state, action) => {
      state.countries = action.payload;
    });
    builder.addCase(readExpenseCategories.fulfilled, (state, action) => {
      state.expenseCategories = action.payload;
    });
    builder.addCase(readIncomeCategories.fulfilled, (state, action) => {
      state.incomeCategories = action.payload;
    });
    builder.addCase(readCountries.rejected, (state, action) => {
      state.countries = [];
    });
    builder.addCase(readPriceList.fulfilled, (state, action) => {
      state.priceList = action.payload;
    });

    builder.addCase(readVats.pending, (state, action) => {
      state.loading.vats = true;
    });
    builder.addCase(readVats.rejected, (state, action) => {
      state.loading.vats = false;
    });
    builder.addCase(readVats.fulfilled, (state, action) => {
      state.vats = action.payload;
      state.loading.vats = false;
    });
    builder.addCase(readTaxes.pending, (state, action) => {
      state.loading.taxes = true;
    });
    builder.addCase(readTaxes.rejected, (state, action) => {
      state.loading.taxes = false;
    });

    builder.addCase(readTaxes.fulfilled, (state, action) => {
      state.taxes = action.payload;
      state.loading.taxes = false;
    });
    builder.addCase(updateVats.fulfilled, (state, action) => {
      state.vats = action.payload;
    });
    builder.addCase(updatePriceList.fulfilled, (state, action) => {
      state.priceList = action.payload;
    });
    builder.addCase(updateTaxes.fulfilled, (state, action) => {
      state.taxes = action.payload;
    });
    builder.addCase(readUnits.fulfilled, (state, action) => {
      state.units = action.payload;
    });

    builder.addCase(readBrands.fulfilled, (state, action) => {
      state.brands = action.payload;
    });

    builder.addCase(readCategories.fulfilled, (state, action) => {
      state.categories = action.payload;
    });

    builder.addCase(readBankList.fulfilled, (state, action) => {
      state.bankList = action.payload;
    });

    builder.addCase(readUserBanks.fulfilled, (state, action) => {
      state.userBanks = action.payload;
    });

    builder.addCase(readWarehouses.fulfilled, (state, action) => {
      state.warehouses = action.payload.rows;
    });

    builder.addCase(readBankAccounts.fulfilled, (state, action) => {
      state.bankAccounts = action.payload;
    });
    builder.addCase(readProjects.pending, (state) => {
      state.loading.projects = true;
    });
    builder.addCase(readProjects.fulfilled, (state, action) => {
      state.projects = action.payload;
      state.loading.projects = false;
    });
    builder.addCase(readProjects.rejected, (state) => {
      state.loading.projects = false;
    });
    builder.addCase(readWarehouseProducts.fulfilled, (state, action) => {
      state.warehouseProducts = action.payload;
    });
    builder.addCase(readTransactionTypes.fulfilled, (state, action) => {
      state.transactionTypes = action.payload;
    });
    builder.addCase(readBalanceTransactionTypes.fulfilled, (state, action) => {
      state.balanceTransactionTypes = action.payload;
    });
    builder.addCase(readListBanks.fulfilled, (state, action) => {
      state.listBanks = action.payload;
    });
  },
});

export default authSlice.reducer;
export const { setLanguage, setMessages, setTheme, addPosTab, removePosTab, toggleFullScreen } =
  authSlice.actions;
