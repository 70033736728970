import axios from 'axios';
import { Supplier, SupplierResponse } from '../../../app/models/SupplierResponse';
import { delayPromise } from '../../../app/utils/utils';
import produce from 'immer';

type SupplierQuery = {
  company?: number;
  enabled: boolean;
  offset: number;
  limit: number;
  query: string;
  type: string;
  operator: string;
  turnover: string;
  registration: string;
  responsable: string;
};

export const getSuppliers = (payload: SupplierQuery | any) => {
  return delayPromise(axios.get<SupplierResponse>('/secure/suppliers/v2', { params: payload }));
};

export const putSupplier = (payload: Partial<Supplier>) => {
  const supplier = produce(payload, (draft) => {
    const mobile = payload.contact_phones?.find((phone) => phone.type + '' === '1');
    const phone = payload.contact_phones?.find((p) => p.type + '' === '8');
    const fax = payload.contact_phones?.find((p) => p.type + '' === '2');
    draft.phone1 = mobile ? mobile.value : null;
    draft.phone2 = phone ? phone.value : null;
    draft.phone3 = fax ? fax.value : null;
  });
  return axios.put<Supplier>(`/secure/supplier/${supplier.id}`, supplier);
};

export const postSupplier = (payload: Supplier) => {
  return axios.post<Supplier>(`/secure/supplier`, payload);
};

export const getSupplier = (payload: { id: string; company: number }) => {
  return axios.get<Supplier>(`/secure/supplier/${payload.id}`, {
    params: { company: payload.company },
  });
};

export const undelayedGetSuppliers = (params: Partial<SupplierQuery>) => {
  return axios.get<SupplierResponse>('/secure/suppliers', {
    params,
  });
};
export const exportSupplier = (query: any) => {
  return axios.get(`/secure/suppliers/export/v2`, { params: query, responseType: 'arraybuffer' });
};

export const importSuppliers = (file: string) => {
  //send in query param file_name
  return axios.post(`/secure/suppliers/import`, null, { params: { file_name: file } });
};
// export const download Customer model
export const downloadSupplierModel = () => {
  return axios.get(`/secure/suppliers/template`, {
    responseType: 'arraybuffer',
  });
};
export const verifyListSupplier = (file: any, onUploadProgress: (progressEvent: any) => void) => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post(`/secure/suppliers/import/verify`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
};
