import { useSelector } from 'react-redux';
import { FormattedNumber } from 'react-intl';
import { RootState } from '../../../setup/Store';

export type CurrencyProps = {
  amount: string | number;
  currency?: string;
  style?: any;
};
export default function CurrencyFormatter({ amount, currency, style = 'currency' }: CurrencyProps) {
  const defaultCurrency = useSelector(
    (state: RootState) => state.auth.user?.current_company?.setting?.default_currency
  );
  return (
    <FormattedNumber
      value={Number(amount)}
      style={style}
      format=''
      currency={currency || defaultCurrency || process.env.REACT_APP_DEFAULT_CURRENCY || 'TND'}
    />
  );
}
