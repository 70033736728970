import clsx from 'clsx';
import { useContext, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { readUserBanks } from '../../../../setup/redux/reducers/UtilsReducers';
import { RootState } from '../../../../setup/Store';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { BankModalContext } from '../../../ModalProviders/BankModal';
import { dateFormatsPerLocale } from '../../../utils/configs';
import { useWindowWidth } from '@react-hook/window-size/throttled';
function AddDepenseStep3() {
  const dispatch = useDispatch();
  const userBanks = useSelector((state: RootState) => state.utils.userBanks);
  const { formatMessage } = useIntl();
  const {
    register,
    watch,
    formState: { errors, touchedFields },
  } = useFormContext();
  const width = useWindowWidth();
  const bankModal = useContext(BankModalContext);
  useEffect(() => {
    if (userBanks === undefined) dispatch(readUserBanks());
    return () => {};
  }, [userBanks]);
  const paymentMethod = watch('paymentMethod');
  const locale = useSelector((state: RootState) => state.utils.locale);
  return (
    <div className='w-100'>
      {paymentMethod === 'CHECK' && (
        <div className='mb-10'>
          <label className='form-label required mb-3'>
            <FormattedMessage id='PAYMENT.CHECK_NUMBER' />
          </label>
          <input
            type='text'
            className={clsx('form-control form-control-solid', {
              'is-invalid': touchedFields?.checkNumber && errors?.checkNumber,
              'is-valid': !touchedFields?.checkNumber && !errors?.checkNumber,
            })}
            {...register('checkNumber')}
            autoComplete='off'
          />
          {touchedFields.checkNumber && errors.checkNumber && (
            <div className='text-danger'>
              <span role='alert'>
                <FormattedMessage
                  id={errors?.checkNumber.message + ''}
                  values={{
                    field: formatMessage({ id: 'PAYMENT.CHECK_NUMBER' }),
                  }}
                />
              </span>
            </div>
          )}
        </div>
      )}

      <div className='mb-10'>
        <label className='form-label mb-3'>
          <span className='required'>
            <FormattedMessage id='FORM.FIELD.SETTLEMENT_DATE' />
          </span>
        </label>
        <Controller
          name='date'
          render={({
            field: { name, onBlur, onChange, value },
            fieldState: { error, isTouched },
          }) => (
            <>
              <ReactDatePicker
                onChange={onChange}
                selected={value}
                locale={locale}
                name={name}
                dateFormat={dateFormatsPerLocale[locale || 'fr']}
                onBlur={onBlur}
                withPortal={width < 768}
                className={clsx('form-control form-control-solid', {
                  'is-valid': isTouched && !error,
                  'is-invalid': isTouched && error,
                })}
                maxDate={new Date()}
              />
              {error && isTouched && (
                <div className='text-danger'>
                  <span role='alert'>
                    <FormattedMessage
                      id={error.message}
                      values={{
                        field: formatMessage({ id: 'FORM.FIELD.SETTLEMENT_DATE' }),
                      }}
                    />
                  </span>
                </div>
              )}
            </>
          )}
        />
      </div>
      {paymentMethod === 'CHECK' && (
        <>
          <div className='mb-10'>
            <label className='form-label mb-3'>
              <span className='required'>
                <FormattedMessage id='LIST_DOCUMENT.FIELD.DUE_DATE' />
              </span>
            </label>
            <Controller
              name='dueDate'
              render={({
                field: { name, onBlur, onChange, value },
                fieldState: { error, isTouched },
              }) => (
                <>
                  <ReactDatePicker
                    onChange={onChange}
                    selected={value}
                    locale={locale}
                    name={name}
                    withPortal={width < 768}
                    dateFormat={dateFormatsPerLocale[locale || 'fr']}
                    className={clsx('form-control form-control-solid', {
                      'is-valid': isTouched && !error,
                      'is-invalid': isTouched && error,
                    })}
                    onBlur={onBlur}
                    calendarClassName='bg-body'
                    maxDate={new Date()}
                  />
                  {error && isTouched && (
                    <div className='text-danger'>
                      <span role='alert'>
                        <FormattedMessage
                          id={error.message}
                          values={{
                            field: formatMessage({ id: 'LIST_DOCUMENT.FIELD.DUE_DATE' }),
                          }}
                        />
                      </span>
                    </div>
                  )}
                </>
              )}
            />
          </div>
          <div className='mb-10'>
            <label className='form-label required mb-3'>
              <FormattedMessage id='FORM.FIELD.SOURCE_ACCOUNT' />
            </label>
            <Controller
              name='bankAccountSource'
              render={({
                field: { name, onBlur, onChange, value },
                fieldState: { error, isTouched },
              }) => (
                <>
                  <div className='row row-cols-1 row-cols-md-2 g-5'>
                    {userBanks?.map((bank) => (
                      <div className='col' key={bank.id}>
                        <input
                          type='radio'
                          className='btn-check'
                          name={name}
                          onChange={onChange}
                          value={bank.id + ''}
                          checked={value === bank.id + ''}
                          id={`sw_expense_bank_${bank.id}`}
                        />
                        <label
                          className={clsx(
                            'btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-start gap-5 h-100',
                            value === bank.id + '' && 'border border-primary active'
                          )}
                          htmlFor={`sw_expense_bank_${bank.id}`}>
                          <img
                            src={toAbsoluteUrl(`/media/banks/${(bank.bank as any)?.code}.png`)}
                            alt=''
                            className='w-50px'
                          />
                          <div className='d-flex align-items-start flex-column gap-3'>
                            <div className='d-flex align-items-center'>
                              <span>{bank.name}</span>
                              {bank.is_display_on_document && (
                                <div className='badge badge-primary ms-5'>Default</div>
                              )}
                            </div>
                            <div className='text-dark fw-bold'>{bank.number}</div>
                            <div className='text-muted'>
                              <FormattedMessage id='BANK.AGENCY' />: {bank.agency}
                            </div>
                          </div>
                        </label>
                      </div>
                    ))}

                    <div className='col'>
                      <div
                        className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex flex-column flex-center gap-5 h-100'
                        onClick={() => bankModal.showAddBankModal()}>
                        <KTSVG
                          className='svg-icon svg-icon-3hx svg-icon-primary'
                          path='/media/icons/duotune/general/gen041.svg'
                        />
                        <div className='fs-5 fw-bold'>
                          <FormattedMessage id='ADD.NEW.BANK' />
                        </div>
                      </div>
                    </div>
                  </div>
                  {error && isTouched && (
                    <div className='text-danger'>
                      <span role='alert'>
                        <FormattedMessage
                          id={error.message}
                          values={{
                            field: formatMessage({ id: 'FORM.FIELD.SOURCE_ACCOUNT' }),
                          }}
                        />
                      </span>
                    </div>
                  )}
                </>
              )}
            />
          </div>
        </>
      )}
    </div>
  );
}
export { AddDepenseStep3 };
