import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../setup/Store';
import { setTheme } from '../../../setup/redux/reducers/UtilsReducers';

/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
  toggleBtnClass?: string;
  toggleBtnIconClass?: string;
  menuPlacement?: string;
  menuTrigger?: string;
};
type ThemeModeType = 'light' | 'dark' | 'system';
const ThemeModeSwitcher = ({
  toggleBtnClass = '',
  menuPlacement = 'bottom-end',
  menuTrigger = "{default: 'click', lg: 'hover'}",
}: Props) => {
  const theme = useSelector((state: RootState) => state.utils.theme);
  const dispatch = useDispatch();
  const switchMode = (mode: ThemeModeType) => {
    dispatch(setTheme(mode));
  };

  return (
    <>
      <div
        className={clsx('btn-navbar', toggleBtnClass)}
        data-kt-menu-trigger={menuTrigger}
        data-kt-menu-attach='parent'
        data-kt-menu-placement={menuPlacement}>
        <i
          className={clsx('fa-light', {
            'fa-sun-bright': theme === 'light',
            'fa-moon': theme === 'dark',
          })}></i>
      </div>
      {/* begin::Menu toggle */}

      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column sw-menu w-175px'
        data-kt-menu='true'>
        {/* begin::Menu item */}
        <div className='sw-menu-body'>
          <div
            className={clsx('sw-menu-item', { active: theme !== 'dark' })}
            onClick={() => switchMode('light')}>
            <span className='sw-menu-item-icon'>
              <i className='fa-sharp fa-light fa-brightness'></i>
            </span>
            <span className='sw-menu-item-title'>
              <FormattedMessage id='TOPBAR.MODE.LIGHT' />
            </span>
          </div>
          {/* end::Menu item */}
          {/* begin::Menu item */}
          <div
            className={clsx('sw-menu-item', { active: theme === 'dark' })}
            onClick={() => switchMode('dark')}>
            <span className='sw-menu-item-icon'>
              <i className='fa-sharp fa-light fa-moon'></i>
            </span>
            <span className='sw-menu-item-title'>
              <FormattedMessage id='TOPBAR.MODE.DARK' />
            </span>
          </div>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  );
};

export { ThemeModeSwitcher };
