import { OverlayTrigger as Trigger, OverlayTriggerProps } from 'react-bootstrap';
import { tooltipDelay } from '../../utils/configs';

function OverlayTrigger({ children, ...props }: OverlayTriggerProps) {
  return (
    <Trigger {...props} delay={tooltipDelay}>
      {children}
    </Trigger>
  );
}

export default OverlayTrigger;
