import { useWindowWidth } from '@react-hook/window-size/throttled';
import clsx from 'clsx';
import { differenceInDays } from 'date-fns/esm';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { Accordion, AccordionContext, useAccordionButton } from 'react-bootstrap';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { KTSVG, toAbsoluteUrl } from '../../helpers';
import { StickyComponent } from '../../utils/ts/components';
import Loader from '../Loader/Loader';
import StyledCurrency from '../StyledCurrency/StyledCurrency';
import { CheckOrDraft } from '../../models/ChecksAndDraftResponse';
const today = new Date();
const ContextAwareToggle: FC<{
  eventKey: string;
  callback?: (key: string) => void;
  check: CheckOrDraft;
  checked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}> = ({ eventKey, callback, check, checked = false, onChange = () => {} }) => {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <div
      className='d-flex flex-column flex-sm-row align-items-sm-center justify-sm-content-between p-4'
      onClick={decoratedOnClick}>
      <div className='d-flex align-items-center flex-grow-1 gap-3'>
        <div className={clsx('rotate', isCurrentEventKey && 'active')}>
          <KTSVG path='/media/icons/duotune/arrows/arr071.svg' className='svg-icon-2 rotate-90' />
        </div>
        <div
          className='form-check form-check-custom form-check-solid'
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <input
            className='form-check-input'
            type='checkbox'
            checked={checked}
            value={check.id}
            onChange={onChange}
          />
        </div>
        <div className='symbol symbol-35px'>
          {check?.bank ? (
            <img src={toAbsoluteUrl(`/media/banks/${check.bank.code}.png`)} alt='' />
          ) : (
            <div className='symbol-label bg-light'></div>
          )}
        </div>
        <div className='d-flex flex-column gap-1'>
          <div className='text-gray-800 fw-semibold w-150px w-md-175px text-truncate'>
            {check?.payment_method?.method_abrev === 'CHECK'
              ? check?.check_number
              : check?.payment_method?.method_abrev === 'DRAFT'
              ? check?.draft_number
              : '--'}
            <div
              className={clsx('badge ms-2', {
                'badge-light-primary': differenceInDays(new Date(check.due_date), today) > 7,
                'badge-light-warning':
                  differenceInDays(new Date(check.due_date), today) <= 7 &&
                  differenceInDays(new Date(check.due_date), today) > 0,
                'badge-light-danger': differenceInDays(new Date(check.due_date), today) < 0,
              })}>
              <FormattedDate value={check.due_date} day='2-digit' month='short' year='numeric' />
            </div>
          </div>
          <div className='text-muted'>
            {check?.contact?.company_name || check?.contact?.name || '--'}
          </div>
        </div>
      </div>
      <div className='ms-auto me-4'>
        <StyledCurrency
          amount={check.amount}
          amountClassName='fw-bold text-primary fs-5'
          supClassName='fs-8 text-muted fw-bold'
          containerClassName='d-flex gap-1 align-items-center'
        />
      </div>
    </div>
  );
};
export default function ChecksList({
  selectedChecks = [],
  selectedDrafts = [],
  onChecksChange = () => {},
  onDraftsChange = () => {},
  onCheckSumChange = () => {},
  onDraftSumChange = () => {},
  getChecksOrDrafts = () => {},
  checks,
  drafts,
  type = 'CHECK',
  loading = false,
  isModal = false,
  className = '',
}: {
  selectedChecks?: number[];
  selectedDrafts?: number[];
  onChecksChange?: (value: number[]) => void;
  onDraftsChange?: (value: number[]) => void;
  onCheckSumChange?: (value: number) => void;
  onDraftSumChange?: (value: number) => void;
  getChecksOrDrafts?: (type: 'CHECK' | 'DRAFT') => void;
  checks?: CheckOrDraft[];
  drafts?: CheckOrDraft[];
  type: 'CHECK' | 'DRAFT';
  loading: boolean;
  isModal?: boolean;
  className?: string;
}) {
  const parentRef = useRef<HTMLDivElement>(null);
  const [headerWidth, setHeaderWidth] = useState<number>();
  const width = useWindowWidth();
  const [checksTotal, setChecksTotal] = useState<number>();
  const [draftsTotal, setDraftsTotal] = useState<number>();
  const [checksSum, setChecksSum] = useState<number>();
  const [draftsSum, setDraftsSum] = useState<number>();

  useEffect(() => {
    return () => {
      if ((type === 'CHECK' && checks === undefined) || (type === 'DRAFT' && drafts === undefined))
        getChecksOrDrafts(type);
    };
  }, [type]);
  useEffect(() => {
    if (checks) {
      setChecksTotal(checks.reduce((prev, current) => prev + Number(current.amount), 0));
    }
  }, [checks]);
  useEffect(() => {
    if (drafts) {
      setDraftsTotal(drafts.reduce((prev, current) => prev + Number(current.amount), 0));
    }
  }, [drafts]);
  useEffect(() => {
    if (parentRef && !loading) {
      setTimeout(() => {
        StickyComponent.reInitialization('sw-checks-list');
      }, 100);
    }
  }, [parentRef, loading]);
  useEffect(() => {
    if (parentRef) {
      setHeaderWidth(parentRef?.current?.offsetWidth || 0);
    }
  }, [width, parentRef]);
  useEffect(() => {
    if (checks?.length) {
      setChecksSum(
        selectedChecks?.reduce(
          (prev, current) =>
            Number(checks?.find((check) => check.id === current)?.amount || 0) + prev,
          0
        ) || 0
      );
    }
  }, [selectedChecks, checks]);
  useEffect(() => {
    if (drafts?.length) {
      setDraftsSum(
        selectedDrafts?.reduce(
          (prev, current) =>
            Number(drafts?.find((draft) => draft.id === current)?.amount || 0) + prev,
          0
        )
      );
    }
  }, [selectedDrafts, drafts]);
  useEffect(() => {
    onCheckSumChange(checksSum || 0);
  }, [checksSum]);

  useEffect(() => {
    onDraftSumChange(draftsSum || 0);
  }, [draftsSum]);

  const handleCheckChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    onChecksChange(
      e.target.checked
        ? [...selectedChecks, Number(e.target.value)]
        : selectedChecks?.filter((v) => v !== Number(e.target.value))
    );
  };
  const handleDraftChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    onDraftsChange(
      e.target.checked
        ? [...selectedDrafts, Number(e.target.value)]
        : selectedDrafts?.filter((v) => v !== Number(e.target.value))
    );
  };
  const handleCheckAll: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (type === 'CHECK' && checks)
      onChecksChange(e.target.checked ? checks?.map((check) => check.id) : []);
    if (type === 'DRAFT' && drafts)
      onDraftsChange(e.target.checked ? drafts?.map((check) => check.id) : []);
  };

  return (
    <div ref={parentRef} className={clsx(isModal && 'd-flex flex-column gap-3', className)}>
      <Loader loading={loading}>
        {!isModal ? (
          <div
            className='card mb-4'
            style={{ width: `${headerWidth}px` }}
            data-kt-sticky='true'
            data-kt-sticky-width={`${headerWidth}px`}
            data-kt-sticky-name='sw-checks-list'
            data-kt-sticky-zindex='95'>
            <div className='card-header ps-10'>
              <div className='card-title'>
                <label className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    onChange={handleCheckAll}
                    checked={
                      type === 'CHECK'
                        ? checks && checks?.length > 0 && selectedChecks.length === checks?.length
                        : drafts && drafts?.length > 0 && selectedDrafts.length === drafts?.length
                    }
                  />
                  <span className='form-check-label'>
                    <FormattedMessage
                      id='CHECK.COUNT'
                      values={
                        type === 'CHECK'
                          ? { current: selectedChecks.length, max: checks?.length }
                          : { current: selectedDrafts.length, max: drafts?.length }
                      }
                    />
                  </span>
                </label>
              </div>
              <div className='card-toolbar d-flex gap-1'>
                <StyledCurrency
                  amount={(type === 'CHECK' ? checksSum : draftsSum) as number}
                  amountClassName='fw-bold text-primary fs-3'
                  supClassName='fs-8 text-muted fw-bold'
                  containerClassName='d-flex gap-1 align-items-center'
                />
                <div className='text-muted fw-bold'>/</div>
                <StyledCurrency
                  amount={(type === 'CHECK' ? checksTotal : draftsTotal) as number}
                  amountClassName='fw-bold text-primary fs-3'
                  supClassName='fs-8 text-muted fw-bold'
                  containerClassName='d-flex gap-1 align-items-center'
                />
              </div>
            </div>
          </div>
        ) : (
          <div className='card mb-4'>
            <div className='card-header ps-10'>
              <div className='card-title'>
                <label className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    onChange={handleCheckAll}
                    checked={
                      type === 'CHECK'
                        ? checks && checks?.length > 0 && selectedChecks.length === checks?.length
                        : drafts && drafts?.length > 0 && selectedDrafts.length === drafts?.length
                    }
                  />
                  <span className='form-check-label'>
                    <FormattedMessage
                      id={type === 'CHECK' ? 'CHECK.COUNT' : 'DRAFT.COUNT'}
                      values={
                        type === 'CHECK'
                          ? { current: selectedChecks.length, max: checks?.length }
                          : { current: selectedDrafts.length, max: drafts?.length }
                      }
                    />
                  </span>
                </label>
              </div>
              <div className='card-toolbar d-flex gap-1'>
                <StyledCurrency
                  amount={(type === 'CHECK' ? checksSum : draftsSum) as number}
                  amountClassName='fw-bold text-primary fs-3'
                  supClassName='fs-8 text-muted fw-bold'
                  containerClassName='d-flex gap-1 align-items-center'
                />
                <div className='text-muted fw-bold'>/</div>
                <StyledCurrency
                  amount={(type === 'CHECK' ? checksTotal : draftsTotal) as number}
                  amountClassName='fw-bold text-primary fs-3'
                  supClassName='fs-8 text-muted fw-bold'
                  containerClassName='d-flex gap-1 align-items-center'
                />
              </div>
            </div>
          </div>
        )}
        <Accordion className={clsx(isModal && 'flex-grow-1 overflow-auto')}>
          {type === 'CHECK'
            ? checks?.map((check) => (
                <Accordion.Item key={check.id} eventKey={check.id + ''} className='border-dashed'>
                  <ContextAwareToggle
                    check={check}
                    eventKey={check.id + ''}
                    checked={selectedChecks.includes(check.id)}
                    onChange={handleCheckChange}
                  />
                  <Accordion.Body className='ps-10'>
                    <div className='d-flex flex-wrap'>
                      <div className='flex-equal me-5'>
                        <table className='table table-flush fw-semibold gy-1'>
                          <tbody>
                            <tr>
                              <td className='text-muted min-w-125px w-125px'>
                                <FormattedMessage id='FORM.FIELD.NUMBER' />
                              </td>
                              <td className='text-gray-800'>
                                {check?.payment_method?.method_abrev === 'CHECK'
                                  ? check?.check_number
                                  : check?.payment_method?.method_abrev === 'DRAFT'
                                  ? check?.draft_number
                                  : '--'}
                              </td>
                            </tr>
                            <tr>
                              <td className='text-muted min-w-125px w-125px'>
                                <FormattedMessage id='FORM.FIELD.HOLDER' />
                              </td>
                              <td className='text-gray-800'>{check.holder || '--'}</td>
                            </tr>
                            <tr>
                              <td className='text-muted min-w-125px w-125px'>
                                <FormattedMessage id='SUPPLIER.NAME' />
                              </td>
                              <td className='text-gray-800'>
                                {check.contact?.company_name || check.contact?.name || '--'}
                              </td>
                            </tr>
                            <tr>
                              <td className='text-muted min-w-125px w-125px'>
                                <FormattedMessage id='COMMON.FIELDS.STATUS' />
                              </td>
                              <td className='text-gray-800'>
                                <FormattedMessage
                                  id={`DOCUMENT.STATE.${check.status.toUpperCase()}`}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className='flex-equal'>
                        <table className='table table-flush fw-semibold gy-1'>
                          <tbody>
                            <tr>
                              <td className='text-muted min-w-125px w-125px'>
                                <FormattedMessage id='LIST_DOCUMENT.FIELD.DUE_DATE' />
                              </td>
                              <td className='text-gray-800'>
                                <FormattedDate
                                  value={check.due_date}
                                  day='2-digit'
                                  month='long'
                                  year='numeric'
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className='text-muted min-w-125px w-125px'>
                                <FormattedMessage id='COMMON.FIELDS.DATE' />
                              </td>
                              <td className='text-gray-800'>
                                <FormattedDate
                                  value={check.date}
                                  day='2-digit'
                                  month='long'
                                  year='numeric'
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className='text-muted min-w-125px w-125px'>
                                <FormattedMessage id='PRICING.PAYMENT.METHOD' />
                              </td>
                              <td className='text-gray-800'>
                                <FormattedMessage
                                  id={`PAYMENT.METHOD.${check.payment_method.method_abrev}`}
                                />
                                <KTSVG
                                  path={
                                    check.payment_method.method_type === 'in'
                                      ? '/media/icons/duotune/arrows/arr044.svg'
                                      : '/media/icons/duotune/arrows/arr045.svg'
                                  }
                                  className={clsx(
                                    'svg-icon-2 ms-2',
                                    check.payment_method.method_type === 'in'
                                      ? 'svg-icon-success'
                                      : 'svg-icon-danger'
                                  )}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))
            : drafts?.map((draft) => (
                <Accordion.Item key={draft.id} eventKey={draft.id + ''} className='border-dashed'>
                  <ContextAwareToggle
                    check={draft}
                    eventKey={draft.id + ''}
                    checked={selectedDrafts.includes(draft.id)}
                    onChange={handleDraftChange}
                  />
                  <Accordion.Body className='ps-10'>
                    <div className='d-flex flex-wrap'>
                      <div className='flex-equal me-5'>
                        <table className='table table-flush fw-semibold gy-1'>
                          <tbody>
                            <tr>
                              <td className='text-muted min-w-125px w-125px'>
                                <FormattedMessage id='FORM.FIELD.NUMBER' />
                              </td>
                              <td className='text-gray-800'>
                                {draft?.payment_method?.method_abrev === 'CHECK'
                                  ? draft?.check_number
                                  : draft?.payment_method?.method_abrev === 'DRAFT'
                                  ? draft?.draft_number
                                  : '--'}
                              </td>
                            </tr>
                            <tr>
                              <td className='text-muted min-w-125px w-125px'>
                                <FormattedMessage id='FORM.FIELD.HOLDER' />
                              </td>
                              <td className='text-gray-800'>{draft.holder || '--'}</td>
                            </tr>
                            <tr>
                              <td className='text-muted min-w-125px w-125px'>
                                <FormattedMessage id='SUPPLIER.NAME' />
                              </td>
                              <td className='text-gray-800'>
                                {draft.contact?.company_name || draft.contact?.name}
                              </td>
                            </tr>
                            <tr>
                              <td className='text-muted min-w-125px w-125px'>
                                <FormattedMessage id='COMMON.FIELDS.STATUS' />
                              </td>
                              <td className='text-gray-800'>
                                <FormattedMessage
                                  id={`DOCUMENT.STATE.${draft.status.toUpperCase()}`}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className='flex-equal'>
                        <table className='table table-flush fw-semibold gy-1'>
                          <tbody>
                            <tr>
                              <td className='text-muted min-w-125px w-125px'>
                                <FormattedMessage id='LIST_DOCUMENT.FIELD.DUE_DATE' />
                              </td>
                              <td className='text-gray-800'>
                                <FormattedDate
                                  value={draft.due_date}
                                  day='2-digit'
                                  month='long'
                                  year='numeric'
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className='text-muted min-w-125px w-125px'>
                                <FormattedMessage id='COMMON.FIELDS.DATE' />
                              </td>
                              <td className='text-gray-800'>
                                <FormattedDate
                                  value={draft.date}
                                  day='2-digit'
                                  month='long'
                                  year='numeric'
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className='text-muted min-w-125px w-125px'>
                                <FormattedMessage id='PRICING.PAYMENT.METHOD' />
                              </td>
                              <td className='text-gray-800'>
                                <FormattedMessage
                                  id={`PAYMENT.METHOD.${draft.payment_method.method_abrev}`}
                                />
                                <KTSVG
                                  path={
                                    draft.payment_method.method_type === 'in'
                                      ? '/media/icons/duotune/arrows/arr044.svg'
                                      : '/media/icons/duotune/arrows/arr045.svg'
                                  }
                                  className={clsx(
                                    'svg-icon-2 ms-2',
                                    draft.payment_method.method_type === 'in'
                                      ? 'svg-icon-success'
                                      : 'svg-icon-danger'
                                  )}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
        </Accordion>
      </Loader>
    </div>
  );
}
