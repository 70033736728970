// const documentStateProviderDefaultValues = {

import { PropsWithChildren, createContext, useContext } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';

const documentStateDefaultValues = {
  isSaving: false,
  isSending: false,
  isLoading: true,
  isPreview: false,
  shouldResave: false,
  canBeUpdated: false,
  lastSentVersion: 0,
  areActionsBlocked: false,
  containsProducts: false,
};

export interface DocumentStateProviderType
  extends UseFormReturn<typeof documentStateDefaultValues> {
  resetDocumentState: () => void;
}
const FormContext = createContext<DocumentStateProviderType | undefined>(undefined);

// const useDocumentState = () => {
//   return useContext(Context);
// };
export const useDocumentState = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useFormContext must be used within a FormProvider');
  }
  return context;
};

function DocumentStateProvider({ children }: PropsWithChildren<{}>) {
  const methods = useForm({
    defaultValues: documentStateDefaultValues,
  });
  const resetDocumentState = () => {
    methods.reset(documentStateDefaultValues);
  };
  return (
    <FormContext.Provider value={{ ...methods, resetDocumentState }}>
      {children}
    </FormContext.Provider>
  );
}

export default DocumentStateProvider;
// export { useDocumentState };
