/* eslint-disable react/jsx-no-target-blank */
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { SidebarMenuItem } from './SidebarMenuItem';
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub';
import { postDraft } from '../../../../../setup/redux/effects/DocumentEffects';
import Guard, {
  useGuard,
  authorizeOnRoleAndPlanPermission,
  authorizeOnRole,
  authorizeOnRoleOrPermissionAndPermissionPlan,
} from '../../../../components/Security/Guard';
import { capitalize } from '../../../../utils/utils';

const SidebarMenuMain = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const guard = useGuard();
  const addDocument = async (type: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9) => {
    const { data } = await postDraft({ type });
    navigate(`/document/${data.id}`);
  };
  return (
    <>
      <div className='my-2'>
        <SidebarMenuItem
          to='/home'
          icon='/media/icons/duotune/abstract/abs027.svg'
          title={intl.formatMessage({ id: 'TOOLBAR.BREADCRUMBS.HOME' })}
          fontIcon='fa-house'
        />

        <Guard
          planPermissions={['reports']}
          roles={['ROLE_ADMIN']}
          authorize={authorizeOnRoleAndPlanPermission}
          alt={
            <Guard roles={['ROLE_ADMIN']} authorize={authorizeOnRole}>
              <Link to='/pricing' className='menu-item disabled'>
                <div className='menu-link without-sub'>
                  <span className='menu-icon'>
                    <i className='fa-light fa-file-lines'></i>
                  </span>
                  <span className='menu-title'>
                    {capitalize(intl.formatMessage({ id: 'PERMISSION.CATEGORY.REPORTS' }))}
                  </span>
                  <span className='badge badge-light-primary'>
                    <FormattedMessage id='USER.MENU.ACCOUNT.UPGRADE' />
                  </span>
                </div>
              </Link>
            </Guard>
          }>
          <SidebarMenuItem
            to='/reports'
            icon='/media/icons/duotune/communication/com014.svg'
            title={capitalize(intl.formatMessage({ id: 'PERMISSION.CATEGORY.REPORTS' }))}
            fontIcon='fa-file-lines'
            isTrial
          />
        </Guard>
      </div>
      <Guard
        planPermissions={['saleManagement', 'purchaseManagement']}
        permissions={['Sales', 'Purchase']}
        roles={['ROLE_ADMIN']}
        authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
        <div className='my-2'>
          <div className='menu-item'>
            <div className='menu-content'>
              <span className='menu-heading fw-bold text-uppercase fs-7'>
                <FormattedMessage id='SIDEMENU.TITLE.DOCUMENT' />
              </span>
            </div>
          </div>

          <div className='my-2'>
            <Guard
              planPermissions={['saleManagement']}
              permissions={['Sales']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
              <SidebarMenuItemWithSub
                to=''
                title={intl.formatMessage({ id: 'SIDEMENU.ITEM.SALE' })}
                icon='/media/icons/duotune/files/fil010.svg'
                fontIcon='fa-cart-arrow-up'>
                <Guard
                  planPermissions={['estimateManagement']}
                  permissions={['Estimate.read', 'Estimate.read.write']}
                  roles={['ROLE_ADMIN']}
                  authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
                  <SidebarMenuItem
                    to='/documents/estimate'
                    title={intl.formatMessage({ id: 'SIDEMENU.SUBITEM.ESTIMATE' })}
                    hasBullet={true}
                    action={
                      guard(
                        {
                          roles: ['ROLE_ADMIN'],
                          permissions: ['Estimate.read.write'],
                          planPermissions: ['estimateManagement'],
                        },
                        authorizeOnRoleOrPermissionAndPermissionPlan
                      )
                        ? () => {
                            addDocument(6);
                          }
                        : false
                    }
                  />
                </Guard>
                <Guard
                  planPermissions={['orderManagement']}
                  permissions={['Order.read', 'Order.read.write']}
                  roles={['ROLE_ADMIN']}
                  authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
                  <SidebarMenuItem
                    to='/documents/order'
                    title={intl.formatMessage({ id: 'SIDEMENU.SUBITEM.CLIENTORDER' })}
                    hasBullet={true}
                    action={
                      guard(
                        {
                          roles: ['ROLE_ADMIN'],
                          permissions: ['Order.read.write'],
                          planPermissions: ['orderManagement'],
                        },
                        authorizeOnRoleOrPermissionAndPermissionPlan
                      )
                        ? () => {
                            addDocument(4);
                          }
                        : false
                    }
                  />
                </Guard>
                <Guard
                  planPermissions={['deliveryNoteManagement']}
                  permissions={['DeliveryNote.read', 'DeliveryNote.read.write']}
                  roles={['ROLE_ADMIN']}
                  authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
                  <SidebarMenuItem
                    to='/documents/deliverynote'
                    title={intl.formatMessage({ id: 'SIDEMENU.SUBITEM.DELIVERYNOTE' })}
                    hasBullet={true}
                    action={
                      guard(
                        {
                          roles: ['ROLE_ADMIN'],
                          permissions: ['DeliveryNote.read.write'],
                          planPermissions: ['deliveryNoteManagement'],
                        },
                        authorizeOnRoleOrPermissionAndPermissionPlan
                      )
                        ? () => {
                            addDocument(5);
                          }
                        : false
                    }
                  />
                </Guard>
                <Guard
                  planPermissions={['invoiceManagement']}
                  permissions={['Invoice.read', 'Invoice.read.write']}
                  roles={['ROLE_ADMIN']}
                  authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
                  <SidebarMenuItem
                    to='/documents/invoice'
                    title={intl.formatMessage({ id: 'SIDEMENU.SUBITEM.INVOICE' })}
                    hasBullet={true}
                    action={
                      guard(
                        {
                          roles: ['ROLE_ADMIN'],
                          permissions: ['Invoice.read.write'],
                          planPermissions: ['invoiceManagement'],
                        },
                        authorizeOnRoleOrPermissionAndPermissionPlan
                      )
                        ? () => {
                            addDocument(3);
                          }
                        : false
                    }
                  />
                </Guard>
                <Guard
                  planPermissions={['issueNoteManagement']}
                  permissions={['IssueNote.read', 'IssueNote.read.write']}
                  roles={['ROLE_ADMIN']}
                  authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
                  <SidebarMenuItem
                    to='/documents/issuenote'
                    title={intl.formatMessage({ id: 'SIDEMENU.SUBITEM.ISSUENOTE' })}
                    hasBullet={true}
                    action={
                      guard(
                        {
                          roles: ['ROLE_ADMIN'],
                          permissions: ['IssueNote.read.write'],
                          planPermissions: ['issueNoteManagement'],
                        },
                        authorizeOnRoleOrPermissionAndPermissionPlan
                      )
                        ? () => {
                            addDocument(8);
                          }
                        : false
                    }
                  />
                </Guard>
              </SidebarMenuItemWithSub>
            </Guard>
            <Guard
              planPermissions={['purchaseManagement']}
              permissions={['Purchase']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
              <SidebarMenuItemWithSub
                to='/crafted/accounts'
                title={intl.formatMessage({ id: 'SIDEMENU.ITEM.PURCHASE' })}
                icon='/media/icons/duotune/files/fil009.svg'
                fontIcon='fa-cart-arrow-down'>
                <Guard
                  planPermissions={['supplierOrderManagement']}
                  permissions={['SupplierOrder.read.write']}
                  roles={['ROLE_ADMIN']}
                  authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
                  <SidebarMenuItem
                    to='/documents/supplierorder'
                    title={intl.formatMessage({ id: 'SIDEMENU.SUBITEM.SUPPLIERORDER' })}
                    hasBullet={true}
                    action={
                      guard(
                        {
                          roles: ['ROLE_ADMIN'],
                          permissions: ['SupplierOrder.read.write'],
                          planPermissions: ['supplierOrderManagement'],
                        },
                        authorizeOnRoleOrPermissionAndPermissionPlan
                      )
                        ? () => {
                            addDocument(0);
                          }
                        : false
                    }
                  />
                </Guard>
                <Guard
                  planPermissions={['supplierDeliveryNoteManagement']}
                  permissions={['SupplierDeliveryNote.read', 'SupplierDeliveryNote.read.write']}
                  roles={['ROLE_ADMIN']}
                  authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
                  <SidebarMenuItem
                    to='/documents/supplierdeliverynote'
                    title={intl.formatMessage({ id: 'SIDEMENU.SUBITEM.SUPPLIERDELIVERYNOTE' })}
                    hasBullet={true}
                    action={
                      guard(
                        {
                          roles: ['ROLE_ADMIN'],
                          permissions: ['SupplierDeliveryNote.read.write'],
                          planPermissions: ['supplierDeliveryNoteManagement'],
                        },
                        authorizeOnRoleOrPermissionAndPermissionPlan
                      )
                        ? () => {
                            addDocument(2);
                          }
                        : false
                    }
                  />
                </Guard>
                <Guard
                  planPermissions={['supplierInvoiceManagement']}
                  permissions={['SupplierInvoice.read', 'SupplierInvoice.read.write']}
                  roles={['ROLE_ADMIN']}
                  authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
                  <SidebarMenuItem
                    to='/documents/supplierinvoice'
                    title={intl.formatMessage({ id: 'SIDEMENU.SUBITEM.SUPPLIERINVOICE' })}
                    hasBullet={true}
                    action={
                      guard(
                        {
                          roles: ['ROLE_ADMIN'],
                          permissions: ['SupplierInvoice.read.write'],
                          planPermissions: ['supplierInvoiceManagement'],
                        },
                        authorizeOnRoleOrPermissionAndPermissionPlan
                      )
                        ? () => {
                            addDocument(1);
                          }
                        : false
                    }
                  />
                </Guard>
              </SidebarMenuItemWithSub>
            </Guard>
          </div>
        </div>
      </Guard>
      <Guard
        planPermissions={['customerManagement', 'supplierManagement', 'productsManagement']}
        permissions={['Customer', 'Supplier', 'Catalog']}
        roles={['ROLE_ADMIN']}
        authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
        <div className='my-2'>
          <div className='menu-item'>
            <div className='menu-content pb-2'>
              <span className='menu-heading fw-bold text-uppercase fs-7'>
                <FormattedMessage id='SIDEMENU.TITLE.CARD' />
              </span>
            </div>
          </div>
          <Guard
            planPermissions={['customerManagement']}
            permissions={['Customer']}
            roles={['ROLE_ADMIN']}
            authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
            <SidebarMenuItem
              to='/customers'
              icon='/media/icons/duotune/communication/com014.svg'
              title={capitalize(intl.formatMessage({ id: 'CUSTOMER.TITLE' }))}
              fontIcon='fa-people-group'
              action={
                guard(
                  {
                    roles: ['ROLE_ADMIN'],
                    permissions: ['Customer.read.write'],
                    planPermissions: ['customerManagement'],
                  },
                  authorizeOnRoleOrPermissionAndPermissionPlan
                )
                  ? () => {
                      navigate('/customers/add');
                    }
                  : false
              }
            />
          </Guard>
          <Guard
            planPermissions={['supplierManagement']}
            permissions={['Supplier']}
            roles={['ROLE_ADMIN']}
            authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
            <SidebarMenuItem
              to='/suppliers'
              icon='/media/icons/duotune/general/gen019.svg'
              title={capitalize(intl.formatMessage({ id: 'SUPPLIER.TITLE' }))}
              fontIcon='fa-user-tie'
              action={
                guard(
                  {
                    roles: ['ROLE_ADMIN'],
                    permissions: ['Supplier.read.write'],
                    planPermissions: ['supplierManagement'],
                  },
                  authorizeOnRoleOrPermissionAndPermissionPlan
                )
                  ? () => {
                      navigate('/suppliers/add');
                    }
                  : false
              }
            />
          </Guard>
          <Guard
            planPermissions={['productsManagement']}
            permissions={['Catalog']}
            roles={['ROLE_ADMIN']}
            authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
            <SidebarMenuItem
              to='/products'
              icon='/media/icons/duotune/ecommerce/ecm002.svg'
              title={capitalize(intl.formatMessage({ id: 'PRODUCT.TITLE' }))}
              fontIcon='fa-basket-shopping'
              action={
                guard(
                  {
                    roles: ['ROLE_ADMIN'],
                    permissions: ['Catalog.read.write'],
                    planPermissions: ['productsManagement'],
                  },
                  authorizeOnRoleOrPermissionAndPermissionPlan
                )
                  ? () => {
                      navigate('/products/add');
                    }
                  : false
              }
            />
          </Guard>
        </div>
      </Guard>
      <Guard
        planPermissions={['accountingManagement', 'stockManagement']}
        permissions={['Accounting', 'Inventory']}
        roles={['ROLE_ADMIN']}
        authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
        <div className='my-2'>
          <div className='menu-item'>
            <div className='menu-content pb-2'>
              <span className='menu-heading fw-bold text-uppercase fs-7'>
                <FormattedMessage id='SIDEMENU.TITLE.STOCK' />
              </span>
            </div>
          </div>
          <Guard
            planPermissions={['accountingManagement']}
            permissions={['Accounting']}
            roles={['ROLE_ADMIN']}
            authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
            <SidebarMenuItemWithSub
              to='#'
              title={intl.formatMessage({ id: 'SIDEMENU.ITEM.ACCOUNTING' })}
              icon='/media/icons/duotune/finance/fin002.svg'
              fontIcon='fa-credit-card-front'>
              <Guard
                planPermissions={['cashManagement']}
                permissions={['Accounting.manage.balance']}
                roles={['ROLE_ADMIN']}
                authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
                <SidebarMenuItem
                  to='/accounting/cash-management'
                  title={intl.formatMessage({ id: 'SIDEMENU.SUBITEM.CASHMANAGEMENT' })}
                  hasBullet={true}
                />
              </Guard>
              <Guard
                planPermissions={['bankAccount']}
                permissions={['Accounting.manage.bankAccount']}
                roles={['ROLE_ADMIN']}
                authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
                <SidebarMenuItem
                  to='/accounting/bank-account'
                  title={intl.formatMessage({ id: 'SIDEMENU.SUBITEM.BANKACCOUNT' })}
                  hasBullet={true}
                />
              </Guard>
              <Guard
                planPermissions={['checkAndDraft']}
                permissions={['Accounting.manage.checks_draft']}
                roles={['ROLE_ADMIN']}
                authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
                <SidebarMenuItem
                  to='/checks'
                  title={intl.formatMessage({ id: 'SIDEMENU.SUBITEM.CHECKSANDDRAFTS' })}
                  hasBullet={true}
                />
              </Guard>
            </SidebarMenuItemWithSub>
          </Guard>
          <Guard
            planPermissions={['stockManagement']}
            permissions={['Inventory']}
            roles={['ROLE_ADMIN']}
            authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
            <SidebarMenuItemWithSub
              to='#'
              title={intl.formatMessage({ id: 'SIDEMENU.ITEM.STOCK' })}
              icon='/media/icons/duotune/general/gen017.svg'
              fontIcon='fa-cubes'>
              <SidebarMenuItem
                to='/stock/management'
                title={intl.formatMessage({ id: 'SIDEMENU.SUBITEM.STOCKMANAGEMENT' })}
                hasBullet={true}
              />
              <Guard
                planPermissions={['inventoryManagement']}
                permissions={['Inventory']}
                roles={['ROLE_ADMIN']}
                authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
                <SidebarMenuItem
                  to='/documents/inventory'
                  title={intl.formatMessage({ id: 'SIDEMENU.SUBITEM.INVENTORY' })}
                  hasBullet={true}
                />
              </Guard>
              <Guard
                planPermissions={['transferOrderManagement']}
                permissions={['TransferOrder.read', 'TransferOrder.read.write']}
                roles={['ROLE_ADMIN']}
                authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
                <SidebarMenuItem
                  to='/documents/transferorder'
                  title={intl.formatMessage({ id: 'SIDEMENU.SUBITEM.TRANSFERORDER' })}
                  hasBullet={true}
                  action={
                    guard(
                      {
                        roles: ['ROLE_ADMIN'],
                        permissions: ['TransferOrder.read.write'],
                        planPermissions: ['transferOrderManagement'],
                      },
                      authorizeOnRoleOrPermissionAndPermissionPlan
                    )
                      ? () => {
                          addDocument(1);
                        }
                      : false
                  }
                />
              </Guard>
            </SidebarMenuItemWithSub>
          </Guard>
        </div>
      </Guard>
    </>
  );
};

export { SidebarMenuMain };
