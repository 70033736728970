import clsx from 'clsx';
import { useEffect } from 'react';
import { StickyComponent } from '../../utils/ts/components';
type StickyBarConfig = {
  sideBar?: JSX.Element;
  content?: JSX.Element;
  contentHeader?: JSX.Element;
  sideBarHeader?: JSX.Element;
  sideBarPosition?: 'start' | 'end';
  sideBarHeaderClassNames?: string;
  sideBarBodyClassNames?: string;
  contentHeaderClassNames?: string;
  contentBodyClassNames?: string;
  contentClassNames?: string;
  sideBarClassNames?: string;
};

function LeftStickyBar({
  sideBar,
  content,
  sideBarHeader,
  contentHeader,
  contentClassNames,
  sideBarClassNames,
  sideBarHeaderClassNames,
  sideBarBodyClassNames,
  contentHeaderClassNames = 'align-items-center py-5 gap-2 gap-md-5',
  contentBodyClassNames = 'p-12',
  sideBarPosition = 'start',
}: StickyBarConfig) {
  useEffect(() => {
    setTimeout(() => {
      StickyComponent.reInitialization();
    }, 100);
  }, []);
  return (
    <div className='d-flex flex-column flex-lg-row'>
      {/* Begin: SideBar start */}
      {sideBarPosition === 'start' && (
        <div
          className={clsx(
            'flex-column flex-lg-row-auto w-100 w-lg-375px mb-10 mb-lg-0',
            sideBarClassNames
          )}>
          {/* Begin: Sticky aside */}

          {/* End: Sticky aside */}
        </div>
      )}
      {/* End: SideBar start */}
      {/* Begin: Content */}
      <div
        className={clsx('flex-lg-row-fluid ms-lg-7 me-xl-10', {
          'mb-10 mb-lg-0': sideBarPosition === 'end',
        })}>
        {/* Begin: Card */}
        <div className={clsx(contentClassNames)}>
          {content}
          {/* End: Card Body */}
        </div>
        {/* End: Card */}
      </div>
      {/* End: Content */}
      {/* Begin: SideBar end */}
      {sideBarPosition === 'end' && (
        <div className={clsx('flex-column flex-lg-row-auto w-100 w-lg-375px', sideBarClassNames)}>
          {/* Begin: Sticky aside */}
          <div
            className={clsx('card mb-0 p-1', { 'card-flush': sideBarHeader })}
            data-kt-sticky='true'
            data-kt-sticky-name='inbox-aside-sticky'
            data-kt-sticky-offset="{default: false, xl: '0px'}"
            data-kt-sticky-width="{lg: '375px'}"
            data-kt-sticky-left='auto'
            data-kt-sticky-top='150px'
            data-kt-sticky-animation='false'
            data-kt-sticky-zindex='95'>
            {sideBarHeader && (
              <div className={clsx('card-header', sideBarHeaderClassNames)}>{sideBarHeader}</div>
            )}
            {/* Begin: Aside content */}
            <div className={clsx('card-body py-0 pb-2', sideBarBodyClassNames)}>{sideBar}</div>
            {/* End: Aside content */}
          </div>
          {/* End: Sticky aside */}
        </div>
      )}
      {/* End: SideBar end */}
    </div>
  );
}

export default LeftStickyBar;
