/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { RootState } from '../../../setup/Store';
import { toAbsoluteUrl } from '../../helpers';

const ErrorsPage: React.FC = () => {
  const navigate = useNavigate();
  const redirectToDashboard = () => {
    navigate('/');
  };
  const theme = useSelector((state: RootState) => state.utils.theme);

  return (
    <div className='d-flex flex-column flex-root'>
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{
          backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/progress-hd.png')}')`,
        }}>
        <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-20'>
          <a href='/dashboard' className='mb-10 pt-lg-20'>
            <img
              alt=' '
              src={toAbsoluteUrl(
                theme === 'dark'
                  ? '/media/logos/logo-swiver.svg'
                  : '/media/logos/logo-swiver-dark.svg'
              )}
              className='h-50px mb-5'
            />
          </a>
          <div className='pt-lg-10 mb-10'>
            <Outlet />

            <div className='text-center'>
              <a onClick={redirectToDashboard} className='btn btn-lg btn-primary fw-bold'>
                Go to homepage
              </a>
            </div>
          </div>
          <div
            className='
          d-flex
          flex-row-auto
          bgi-no-repeat
          bgi-position-x-center
          bgi-size-contain
          bgi-position-y-bottom
          min-h-100px min-h-lg-350px
        '
            style={{
              backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/sketchy-1/17.png')}')`,
            }}></div>
        </div>
      </div>
    </div>
  );
};

export { ErrorsPage };
