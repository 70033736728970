import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import store from '../../Store';
import { Customer } from '../../../app/models/CustomerResponse';
import { toast } from 'react-toastify';
import { CustomerQuery, getCustomers, postCustomer, putCustomer } from '../effects/CustomerEffects';
import { createIntl, createIntlCache } from 'react-intl';
import { capitalize } from '../../../app/utils/utils';

export const defaultCustomerQuery = {
  company_name: '',
  enabled: 'true',
  offset: 0,
  limit: 10,
  query: '',
  in_debt: '',
  type: '',
  operator: '=',
  turnover: '',
  registration: '',
  price_list: '',
} as CustomerQuery;

export const readCustomers = createAsyncThunk(
  'customers/get',
  async (payload: CustomerQuery, thunkAPI) => {
    try {
      const { data: customers } = await getCustomers(payload);

      return customers;
    } catch (error: any) {
      thunkAPI.rejectWithValue(
        error?.response?.data.message || error?.toJSON()?.message || 'Error'
      );
    }
  }
);
export const toggleEnabled = createAsyncThunk(
  'products/toggleEnabled',
  async (payload: Customer, thunkAPI) => {
    const state = store?.getState();
    const cache = createIntlCache();
    const intl = createIntl({ locale: state.utils.locale, messages: state.utils.messages }, cache);
    try {
      await toast.promise(() => putCustomer(payload), {
        pending: capitalize(
          intl.formatMessage(
            { id: !payload.enabled ? 'TOAST.DISABLE.PENDING' : 'TOAST.ENABLE.PENDING' },
            { name: payload.name, category: intl.formatMessage({ id: 'CUSTOMER.NAME' }) }
          )
        ),
        success: capitalize(
          intl.formatMessage(
            { id: !payload.enabled ? 'TOAST.DISABLE.SUCCESS' : 'TOAST.ENABLE.SUCCESS' },
            { name: payload.name, category: intl.formatMessage({ id: 'CUSTOMER.NAME' }) }
          )
        ),
        error: {
          autoClose: 2500,
          render: () => {
            return capitalize(
              intl.formatMessage(
                { id: !payload.enabled ? 'TOAST.DISABLE.ERROR' : 'TOAST.ENABLE.ERROR' },
                { name: payload.name, category: intl.formatMessage({ id: 'CUSTOMER.NAME' }) }
              )
            );
          },
        },
      });
    } catch (error: any) {
      thunkAPI.rejectWithValue(error.response.data.message || error?.toJSON()?.message || 'Error');
    }
  }
);
export const createCustomer = createAsyncThunk(
  'customer/create',
  async (payload: Customer, thunkAPI) => {
    try {
      const state = store.getState();
      const cache = createIntlCache();
      const intl = createIntl(
        { locale: state.utils.locale, messages: state.utils.messages },
        cache
      );
      await toast.promise(
        postCustomer({
          ...payload,
        }),
        {
          pending: capitalize(
            intl.formatMessage(
              { id: 'TOAST.ADD.PENDING' },
              { name: payload.name, category: intl.formatMessage({ id: 'CUSTOMER.NAME' }) }
            )
          ),
          error: capitalize(
            intl.formatMessage(
              { id: 'TOAST.ADD.ERROR' },
              { name: payload.name, category: intl.formatMessage({ id: 'CUSTOMER.NAME' }) }
            )
          ),
          success: capitalize(
            intl.formatMessage(
              { id: 'TOAST.ADD.SUCCESS' },
              { name: payload.name, category: intl.formatMessage({ id: 'CUSTOMER.NAME' }) }
            )
          ),
        }
      );
    } catch (error: any) {
      thunkAPI.rejectWithValue(
        error?.response?.data?.message || error?.toJSON()?.message || 'Error'
      );
    }
  }
);

const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    count: 0,
    countArchived: 0,
    countEnabled: 0,
    customers: undefined as Customer[] | undefined,
    loading: false,
    query: defaultCustomerQuery as CustomerQuery,
  },
  reducers: {
    changeLimit: (state, action) => {
      state.query.limit = action.payload;
      state.query.offset = 0;
    },
    setEnabled: (state, action) => {
      state.query.enabled = action.payload.toString();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(readCustomers.fulfilled, (state, action) => {
      state.customers = (action.payload?.rows as Customer[]) || [];
      state.count = action.payload?.count || 0;
      state.countArchived = Number(action.payload?.countArchived);
      state.countEnabled = Number(action.payload?.countEnabled);
      state.loading = false;
      const args = action.meta.arg;
      state.query = args;
    });
    builder.addCase(readCustomers.rejected, (state, action) => {
      state.customers = [];
      state.loading = false;
    });
    builder.addCase(readCustomers.pending, (state) => {
      state.loading = true;
    });
  },
});

export default customerSlice.reducer;
export const { changeLimit, setEnabled } = customerSlice.actions;
