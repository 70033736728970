import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../setup/Store';
import { toAbsoluteUrl } from '../../helpers';

function CustomerBilling() {
  const currentCompany = useSelector((state: RootState) => state.auth.user?.current_company);
  const currentUser = useSelector((state: RootState) => state.auth.user);
  const { register, watch, formState } = useFormContext();
  const taxNumber = watch('tax_number');

  return (
    <div className='table-responsive mb-5'>
      {/*begin::Table*/}
      <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
        {/*begin::Table body*/}
        <tbody className='fw-semibold text-gray-600'>
          {/*begin::Customer name*/}
          <tr>
            <td className='text-muted'>
              <div className='d-flex justify-content-start'>
                {/*begin::Svg Icon | path: icons/duotune/communication/com006.svg*/}
                <span className='svg-icon svg-icon-2 me-2'>
                  <svg
                    width={18}
                    height={18}
                    viewBox='0 0 18 18'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      opacity='0.3'
                      d='M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z'
                      fill='currentColor'
                    />
                    <path
                      d='M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z'
                      fill='currentColor'
                    />
                    <rect x={7} y={6} width={4} height={4} rx={2} fill='currentColor' />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
                <FormattedMessage id='PRICING.CUSTOMER.COMPANY' />
              </div>
            </td>
            <td className='fw-semibold text-end'>
              <div className='d-flex align-items-center justify-content-end'>
                {/*begin:: Avatar */}
                <div className='symbol symbol-circle symbol-25px overflow-hidden me-3'>
                  <a href='/metronic8/demo1/../demo1/apps/ecommerce/customers/details.html'>
                    <div className='symbol-label'>
                      <img
                        src={
                          currentCompany?.setting?.logo?.web_path ||
                          toAbsoluteUrl('/media/avatars/swiver-avatar.png')
                        }
                        alt={currentCompany?.name}
                        className='w-100'
                      />
                    </div>
                  </a>
                </div>
                {/*end::Avatar*/}
                {/*begin::Name*/}
                <div className='text-gray-600 text-hover-primary'>{currentCompany?.name}</div>
                {/*end::Name*/}
              </div>
            </td>
          </tr>
          {/*end::Customer name*/}
          {/*begin::Customer email*/}
          <tr>
            <td className='text-muted'>
              <div className='d-flex justify-content-start'>
                {/*begin::Svg Icon | path: icons/duotune/communication/com011.svg*/}
                <span className='svg-icon svg-icon-2 me-2'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      opacity='0.3'
                      d='M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z'
                      fill='currentColor'
                    />
                    <path
                      d='M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z'
                      fill='currentColor'
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
                <FormattedMessage id='PRICING.CUSTOMER.TAX_NUMBER' />
              </div>
            </td>
            <td className='fw-semibold text-end'>
              <a className='text-gray-600 text-hover-primary'>
                {taxNumber && taxNumber}
                {!taxNumber && (
                  <input
                    type='text'
                    className={clsx(
                      'form-control form-control-solid',
                      formState.errors.taxNumber && 'is-invalid'
                    )}
                    {...register('taxNumber', { required: 'aaaaaaaaaaa' })}
                  />
                )}
                {currentCompany?.setting?.tax_number}
              </a>
            </td>
          </tr>
          {/*end::Payment method*/}
          {/*begin::Date*/}
          <tr>
            <td className='text-muted'>
              <div className='d-flex justify-content-start'>
                {/*begin::Svg Icon | path: icons/duotune/electronics/elc003.svg*/}
                <span className='svg-icon svg-icon-2 me-2'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M5 20H19V21C19 21.6 18.6 22 18 22H6C5.4 22 5 21.6 5 21V20ZM19 3C19 2.4 18.6 2 18 2H6C5.4 2 5 2.4 5 3V4H19V3Z'
                      fill='currentColor'
                    />
                    <path opacity='0.3' d='M19 4H5V20H19V4Z' fill='currentColor' />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
                <FormattedMessage id='PRICING.CUSTOMER.PHONE' />
              </div>
            </td>
            <td className='fw-semibold text-end'>{currentUser?.phone}</td>
          </tr>
          {/*end::Date*/}
        </tbody>
        {/*end::Table body*/}
      </table>
      {/*end::Table*/}
    </div>
  );
}

export default CustomerBilling;
