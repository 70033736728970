import { Navigate, Route, Routes } from 'react-router-dom';
import { ReportType } from '../models/Reports';
import { lazyWithRetry } from '../utils/LazyWithRetry';
const ReportsHome = lazyWithRetry(() => import('../pages/Reports/ReportsHome'));
const ReportsTable = lazyWithRetry(() => import('../pages/Reports/ReportsTable'));
const routes: {
  path: string;
  api: string;
  name: string;
  reportType: ReportType;
  exportAPi?: string;
}[] = [
  {
    path: '/revenue-by-product',
    api: '/secure/reporting/revenue-by-product',
    exportAPi: '/secure/reporting/revenue-by-product/export',
    name: 'REPORT.TURNOVER.BY.PRODUCT',
    reportType: 'product',
  },
  {
    path: '/revenue-by-product-category',
    api: '/secure/reporting/revenue-by-product',
    exportAPi: '/secure/reporting/revenue-by-product/export',
    name: 'REPORT.SALES.BY.CATEGORY',
    reportType: 'productByCategory',
  },
  {
    path: '/product-line-sale',
    api: '/secure/reporting/product-line-report',
    exportAPi: '/secure/reporting/product-line-report/export',
    name: 'REPORT.SALE.DETAILS.BY.PRODUCT.LINE',
    reportType: 'productLineSale',
  },
  {
    path: '/product-line-purchase',
    api: '/secure/reporting/product-line-report',
    exportAPi: '/secure/reporting/product-line-report/export',
    name: 'REPORT.PURCHASE.DETAILS.BY.PRODUCT.LINE',
    reportType: 'productLinePurchase',
  },
  {
    path: '/vats-sale',
    api: '/secure/reporting/vat-report',
    exportAPi: '/secure/reporting/vat-report/export',
    name: 'REPORT.SALES.VAT',
    reportType: 'vatSale',
  },
  {
    path: '/vats-purchase',
    api: '/secure/reporting/vat-report',
    exportAPi: '/secure/reporting/vat-report/export',
    name: 'REPORT.PURCHASE.VAT',
    reportType: 'vatPurchase',
  },
  {
    path: '/sales-to-customer',
    api: '/secure/reporting/contact-document-report',
    exportAPi: '/secure/reporting/contact-document-report/export',
    name: 'REPORT.SALES.BY.CUSTOMER',
    reportType: 'saleToCustomer',
  },
  {
    path: '/purchase-from-supplier',
    api: '/secure/reporting/contact-document-report',
    exportAPi: '/secure/reporting/contact-document-report/export',
    name: 'REPORT.PURCHASES.BY.SUPPLIER',
    reportType: 'purchaseFromSupplier',
  },
  {
    path: '/customer-payments',
    api: '/secure/reporting/contact-payment',
    exportAPi: '/secure/reporting/contact-payment/export',
    name: 'REPORT.CUSTOMER.PAYMENTS',
    reportType: 'customerPayment',
  },
  {
    path: '/supplier-payments',
    api: '/secure/reporting/contact-payment',
    exportAPi: '/secure/reporting/contact-payment/export',
    name: 'REPORT.SUPPLIER.PAYMENTS',
    reportType: 'supplierPayment',
  },
  {
    path: '/inventory-report',
    api: '/secure/reporting/inventory-report',
    exportAPi: '/secure/reporting/inventory-report/export',
    name: 'REPORT.DETAILED.STOCK.MOVEMENTS',
    reportType: 'inventory',
  },
  {
    path: '/commercial-profit',
    api: '/secure/reporting/commercial-profit/product',
    exportAPi: '/secure/reporting/commercial-profit/product/export',
    name: 'REPORT.PROFIT.BY.PRODUCT',
    reportType: 'commercialProfit',
  },
  {
    path: '/customer-revenue',
    api: '/secure/reporting/contact-revenue',
    exportAPi: '/secure/reporting/contact-revenue/export',
    name: 'REPORT.CUSTOMER.TURNOVER',
    reportType: 'customerRevenue',
  },
  {
    path: '/supplier-revenue',
    api: '/secure/reporting/contact-revenue',
    exportAPi: '/secure/reporting/contact-revenue/export',
    name: 'REPORT.SUPPLIER.TURNOVER',
    reportType: 'supplierRevenue',
  },
  {
    path: '/commercial-document-profit',
    api: '/secure/reporting/commercial-profit/document',
    exportAPi: '/secure/reporting/commercial-profit/document/export',
    name: 'REPORT.PROFIT.BY.ITEM',
    reportType: 'profitPerDocument',
  },
  {
    path: '/revenue-by-product-by-client',
    api: '/secure/reporting/revenue-by-product-by-client',
    exportAPi: '/secure/reporting/revenue-by-product-by-client/export',
    name: 'REPORT.SALES.BY.PRODUCT.BY.CUSTOMER',
    reportType: 'revenueByProductByContact',
  },
  {
    path: '/supplier-transactions',
    api: '/secure/reporting/contact-transaction',
    exportAPi: '/secure/reporting/contact-transaction/export',
    name: 'REPORT.SUPPLIER.TRANSACTIONS',
    reportType: 'contactTransactionsPurchase',
  },
  {
    path: '/customer-transactions',
    api: '/secure/reporting/contact-transaction',
    exportAPi: '/secure/reporting/contact-transaction/export',
    name: 'REPORT.CUSTOMER.TRANSACTIONS',
    reportType: 'contactTransactionsSale',
  },
  {
    path: '/commercial-profit-by-month',
    api: '/secure/reporting/commercial-profit/month',
    exportAPi: '/secure/reporting/commercial-profit/month/export',
    name: 'REPORT.MONTHLY.PROFIT',
    reportType: 'commercialProfitByMonth',
  },
  {
    path: '/commercial-profit-by-line',
    api: '/secure/reporting/commercial-profit/line',
    exportAPi: '/secure/reporting/commercial-profit/line/export',
    name: 'REPORT.PROFIT.BY.LINE',
    reportType: 'commercialProfitByLine',
  },
  {
    path: '/total-witholding-customers',
    api: '/secure/reporting/total-withholding',
    exportAPi: '/secure/reporting/total-withholding/export',
    name: 'REPORT.TOTAL_WITHHOLDING_CUSTOMERS',
    reportType: 'totalWitholdingCustomer',
  },
  {
    path: '/total-witholding-suppliers',
    api: '/secure/reporting/total-withholding',
    exportAPi: '/secure/reporting/total-withholding/export',
    name: 'REPORT.TOTAL_WITHHOLDING_SUPPLIERS',
    reportType: 'totalWitholdingSupplier',
  },
  {
    path: '/unpaid-invoices',
    api: '/secure/reporting/unpaid-invoices',
    exportAPi: '/secure/reporting/unpaid-invoices/export',
    name: 'REPORT.UNPAID.INVOICES.OVER.ONE.MONTH',
    reportType: 'unpaidInvoices',
  },
  // {
  //   path: '/inventory-status',
  //   api: '/secure/reporting/inventory-status',
  //   exportAPi: '/secure/reporting/inventory-status/export',
  //   name: 'REPORT.STOCK.STATUS.AT.PREVIOUS.DATE',
  //   reportType: 'inventoryStatus',
  // },
];
function ReportsRoutes() {
  return (
    <Routes>
      <Route path='/' element={<ReportsHome />} />
      {routes.map(({ path, api, name, reportType, exportAPi }, index) => (
        <Route
          key={index}
          path={path}
          element={
            <ReportsTable apiUrl={api} exportUrl={exportAPi} name={name} reportType={reportType} />
          }
        />
      ))}
      <Route path='/*' element={<Navigate to='/reports' />} />
    </Routes>
  );
}

export default ReportsRoutes;
