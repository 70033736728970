import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../setup/Store';
import { lazyWithRetry } from '../../utils/LazyWithRetry';

const RTLMode = lazyWithRetry(() => import('../Layout/RTLMode'));
const LTRMode = lazyWithRetry(() => import('../Layout/LTRMode'));

const ThemeProvider: FC = ({ children }) => {
  const layout = useSelector((state: RootState) => state.utils.direction);
  return layout === 'RTL' ? <RTLMode>{children}</RTLMode> : <LTRMode>{children}</LTRMode>;
};

export default ThemeProvider;
