import clsx from 'clsx';
import { FC } from 'react';
import loadingAnimation from '../../assets/loading_2.json';

import Lottie from '../Lottie';

type LoaderProps = {
  loading: boolean;
  animation?: any;
  loaderClasseNames?: string;
  width?: number;
  height?: number;
  isCentredVertically?: boolean;
  isCentredHorizontally?: boolean;
  showChildren?: boolean;
  showProgress?: boolean;
  percentage?: number;
};

const Loader: FC<LoaderProps> = ({
  loading = true,
  children,
  loaderClasseNames,
  animation = loadingAnimation,
  width = 250,
  height = 250,
  isCentredHorizontally = true,
  isCentredVertically = true,
  showChildren = false,
  showProgress = false,
  percentage = 0,
}) => {
  return (
    <>
      {loading ? (
        <div
          className={clsx(
            !showChildren && 'd-flex',
            !showChildren && isCentredVertically && 'align-items-center',
            !showChildren && isCentredHorizontally && 'justify-content-center',
            loaderClasseNames
          )}>
          {showChildren && children ? (
            <>
              <div className='vertical-center'>
                <Lottie animation={animation} width={width} height={height} />
                {showProgress && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '115px',
                      left: '112px',
                      fontWeight: '600',
                      color: 'white',
                    }}>
                    <span className=''>{Math.floor(percentage || 0)}%</span>
                  </div>
                )}
              </div>
              {children}
            </>
          ) : (
            <Lottie animation={animation} width={width} height={height} />
          )}
        </div>
      ) : (
        children
      )}
    </>
  );
};
export default Loader;
