import { createSlice, PayloadAction } from '@reduxjs/toolkit';
const initialDocumentState = {
  isPreview: false,
  shouldDocumentBeUpdated: false,
  isSaving: false,
  isLoading: true,
  areActionsBlocked: false,
  containsProducts: false,
  shouldBeUpdated: false,
};
const documentSlice = createSlice({
  name: 'document',
  initialState: initialDocumentState,
  reducers: {
    enableDocumentUpdate: (state) => {
      state.shouldDocumentBeUpdated = true;
    },
    disableDocumentUpdate: (state) => {
      state.shouldDocumentBeUpdated = false;
    },
    resetDocument: (state) => {
      state.isPreview = initialDocumentState.isPreview;
      state.shouldDocumentBeUpdated = initialDocumentState.shouldDocumentBeUpdated;
      state.isSaving = initialDocumentState.isSaving;
      state.isLoading = initialDocumentState.isLoading;
      state.areActionsBlocked = initialDocumentState.areActionsBlocked;
      state.containsProducts = initialDocumentState.containsProducts;
      state.shouldBeUpdated = initialDocumentState.shouldBeUpdated;
    },
    setIsPreviewDocument: (state, action: PayloadAction<boolean>) => {
      state.isPreview = action.payload;
    },
    setIsDocumentLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShouldBeUpdated: (state, action: PayloadAction<boolean>) => {
      state.shouldBeUpdated = action.payload;
    },
    setIsSavingDocument: (state, action: PayloadAction<boolean>) => {
      state.isSaving = action.payload;
    },
    setDocumentLinesStatuses: (
      state,
      action: PayloadAction<{ areActionsBlocked: boolean; containsProducts: boolean }>
    ) => {
      state.areActionsBlocked = action.payload.areActionsBlocked;
      state.containsProducts = action.payload.containsProducts;
    },
  },
});

export const {
  enableDocumentUpdate,
  disableDocumentUpdate,
  resetDocument,
  setIsSavingDocument,
  setIsDocumentLoading,
  setIsPreviewDocument,
  setDocumentLinesStatuses,
  setShouldBeUpdated,
} = documentSlice.actions;
export default documentSlice.reducer;
