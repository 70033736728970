import clsx from 'clsx';
import { FC } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { checkIsActive, KTSVG } from '../../../../helpers';
import { useLayout } from '../../../core';

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  isTrial?: boolean;
  action?: (() => void) | false;
};

const SidebarMenuItem: FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  isTrial = false,
  action = false,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { app } = config;

  return (
    <div className='menu-item'>
      <Link className={clsx('menu-link without-sub', { active: isActive })} to={to}>
        {hasBullet && (
          <span className='sw-menu-bullet'>
            {/* <span className='bullet bullet-dot'></span> */}
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <span className='menu-icon'>
            <i className={clsx('fa-light', fontIcon)}></i>
          </span>
        )}
        <span className='menu-title'>{title}</span>

        {!!action && (
          <span className='menu-action'>
            <i
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                action();
              }}
              className='fa-regular fa-plus'></i>
          </span>
        )}
      </Link>
      {children}
    </div>
  );
};

export { SidebarMenuItem };
