import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Controller, UseFormReturn, useFieldArray } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import ar from 'react-phone-input-2/lang/ar.json';
import fr from 'react-phone-input-2/lang/fr.json';
import { useSelector } from 'react-redux';
import { RootState } from '../../../setup/Store';
import { usePriceListContext } from '../../ModalContexts/PriceListModalsContext';
import { KTSVG } from '../../helpers';
import { Customer } from '../../models/CustomerResponse';
import { Supplier } from '../../models/SupplierResponse';
import { contactAddressesLimit, contactPhonesLimit } from '../../utils/configs';
import CustomRadio from '../CustomRadio/CustomRadio';
import SwiverSelect from '../SwiverSelect/SwiverSelect';

const customersSteps = [
  'FORM.STEPPER.CUSTOMER.TYPE',
  'FORM.STEPPER.CUSTOMER.INFORMATIONS',
  'FORM.STEPPER.CUSTOMER.CONTACT-INFORMATIONS',
  'FORM.STEPPER.CUSTOMER.ADDITIONAL-INFORMATIONS',
];
const suppliersSteps = [
  'FORM.STEPPER.SUPLLIER.INFORMATIONS',
  'FORM.STEPPER.CUSTOMER.CONTACT-INFORMATIONS',
];
export default function QuickAddContactModal({
  isAddCustomerOrSupplierModalShown = false,
  handleHideAddCustomerOrSupplier = () => {},
  methods,
  currentContactStep = 0,
  contactType,
  submitContactStep,
  previousContactStep,
  canQuickAdd,
  isContactSubmit,
}: {
  isAddCustomerOrSupplierModalShown?: boolean;
  handleHideAddCustomerOrSupplier?: () => void;
  currentContactStep: number;
  methods: UseFormReturn<Partial<Customer | Supplier>>;
  contactType: 'customer' | 'supplier';
  submitContactStep: (values: Partial<Customer | Supplier>, isQuickAdd?: boolean) => Promise<void>;
  previousContactStep: () => void;
  canQuickAdd: boolean;
  isContactSubmit: boolean;
}) {
  const { formatMessage } = useIntl();
  const {
    handleSubmit: handleContactSubmit,
    control: contactControl,
    setValue: setContactValue,
    watch: contactWatch,
    register: contactRegister,
    formState: {
      touchedFields: contactTouchedFields,
      errors: contactErrors,
      isSubmitting: isContactSubmitting,
    },
  } = methods;
  const {
    fields: addressesFields,
    append: appendAddress,
    remove: removeAddress,
  } = useFieldArray({
    name: 'contact_address',
    control: contactControl,
  });
  const {
    fields: phoneFields,
    append: appendPhone,
    remove: removePhone,
  } = useFieldArray({ name: 'contact_phones', control: contactControl });
  const addAddress = () => {
    appendAddress({
      id: '',
      is_default: false,
      type: '0',
      address: {
        id: '',
        country: process.env.REACT_APP_DEFAULT_COUNTRY || 'TN',
        region: '',
        zip_code: '',
        address: '',
      },
    });
  };
  const locale = useSelector((state: RootState) => state.utils.locale);
  const selectedContactType = contactWatch('type');
  const countries = useSelector((state: RootState) => state.utils.countries);
  const priceList = useSelector((state: RootState) => state.utils.priceList);
  const currency = useSelector(
    (state: RootState) => state.auth.user?.current_company?.setting.default_currency
  );
  const { showPriceListModal } = usePriceListContext();
  const [countryOptions, setCountryOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  useEffect(() => {
    if (countries?.length) {
      const options = countries.map((country) => ({
        value: country.code,
        label: country.country,
      }));
      setCountryOptions(options);
    }
  }, [countries]);
  return (
    <Modal
      show={isAddCustomerOrSupplierModalShown}
      size='xl'
      onHide={handleHideAddCustomerOrSupplier}
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id='TOPBAR.QUICK_ACTIONS.ADD_CUSTOMER' />
        </Modal.Title>
      </Modal.Header>
      <form
        onSubmit={handleContactSubmit((values) => {
          submitContactStep(values);
        })}>
        <Modal.Body>
          <div className='mx-auto w-100 mw-800px'>
            <div className='stepper stepper-links d-flex flex-column'>
              <div className='stepper-nav d-none d-md-flex mb-5'>
                {contactType === 'supplier'
                  ? suppliersSteps.map((step, index) => (
                      <div
                        className={clsx('stepper-item', index === currentContactStep && 'current')}
                        key={index}>
                        <h3 className='stepper-title'>
                          <FormattedMessage id={step} />
                        </h3>
                      </div>
                    ))
                  : customersSteps.map((step, index) => (
                      <div
                        className={clsx('stepper-item', index === currentContactStep && 'current')}
                        key={index}>
                        <h3 className='stepper-title'>
                          <FormattedMessage id={step} />
                        </h3>
                      </div>
                    ))}
              </div>
              <div className='current mt-10 w-100'>
                {contactType === 'customer' && currentContactStep === 0 && (
                  <div className='w-100'>
                    <div className='mb-4'>
                      <h2 className='fw-bold d-flex align-items-center text-dark mb-6 required'>
                        <FormattedMessage id={'FORM.CUSTOMER.TYPE.TITLE'} />
                      </h2>
                    </div>
                    <div className='row'>
                      <Controller
                        name='type'
                        control={contactControl}
                        render={({
                          field: { name, onBlur, onChange, value },
                          fieldState: { invalid, isTouched, error },
                        }) => (
                          <>
                            <div className='col-md-6'>
                              <input
                                name={name}
                                type='radio'
                                onChange={onChange}
                                onBlur={onBlur}
                                className='btn-check'
                                id='kt_create_account_form_account_type_personal'
                                value={0}
                                checked={value === 0 || value === '0'}
                              />
                              <label
                                className={clsx(
                                  'btn btn-outline btn-outline-dashed btn-outline-default  p-7 d-flex align-items-center mb-10',
                                  (value === 0 || value === '0') && 'border border-primary'
                                )}
                                htmlFor='kt_create_account_form_account_type_personal'>
                                <KTSVG
                                  path='/media/icons/swiver/swiver-client.svg'
                                  className='svg-icon svg-icon-3x me-5'
                                />
                                <span className='d-block fw-semibold text-start'>
                                  <span className='text-dark fw-bold d-block fs-4 mb-2'>
                                    <FormattedMessage id='FORM.CUSTOMER.TYPE.INDIVIDUAL.TITLE' />
                                  </span>
                                </span>
                              </label>
                            </div>
                            <div className='col-md-6'>
                              <input
                                name={name}
                                type='radio'
                                onChange={onChange}
                                onBlur={onBlur}
                                className='btn-check'
                                id='kt_create_account_form_account_type_corporate'
                                value={1}
                                checked={value === 1 || value === '1'}
                              />
                              <label
                                className={clsx(
                                  'btn btn-outline btn-outline-dashed btn-outline-default  p-7 d-flex align-items-center mb-10',
                                  (value === 1 || value === '1') && 'border border-primary'
                                )}
                                htmlFor='kt_create_account_form_account_type_corporate'>
                                <KTSVG
                                  path='/media/icons/swiver/swiver-enterprise.svg'
                                  className='svg-icon svg-icon-3x me-5'
                                />
                                <span className='d-block fw-semibold text-start'>
                                  <span className='text-dark fw-bold d-block fs-4 mb-2'>
                                    <FormattedMessage id='FORM.CUSTOMER.TYPE.PROFESSIONAL.TITLE' />
                                  </span>
                                </span>
                              </label>
                            </div>
                            <div className='text-danger mt-2'>
                              {invalid && (
                                <FormattedMessage
                                  id={error?.message}
                                  values={{
                                    field: formatMessage({ id: 'PRODUCT.FIELD.TYPE' }),
                                  }}
                                />
                              )}
                            </div>
                          </>
                        )}
                      />
                    </div>
                  </div>
                )}
                {((contactType === 'customer' && currentContactStep === 1) ||
                  (contactType === 'supplier' && currentContactStep === 0)) && (
                  <>
                    {selectedContactType === '0' && (
                      <div className='border rounded p-8'>
                        <div className='mb-4'>
                          <h2
                            id='second-step'
                            className='fw-bold d-flex align-items-center text-dark mb-6'>
                            <FormattedMessage
                              id={
                                contactType === 'customer'
                                  ? 'FORM.STEPPER.CUSTOMER.INFORMATIONS'
                                  : 'FORM.STEPPER.SUPLLIER.INFORMATIONS'
                              }
                            />
                          </h2>
                        </div>
                        <Controller
                          name='civility'
                          control={contactControl}
                          render={({
                            field: { name, onBlur, onChange, value },
                            fieldState: { invalid, isTouched, error },
                          }) => (
                            <>
                              <div className='fv-row mb-4'>
                                <label className='form-label required mb-3'>
                                  <FormattedMessage id='CUSTOMER.FIELD.CIVILITY' />
                                </label>
                                <div className='d-flex gap-6 mb-3'>
                                  <CustomRadio
                                    name={name}
                                    value='M'
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    checked={value === 'M'}>
                                    <FormattedMessage id='CUSTOMER.CIVILITY.M' />
                                  </CustomRadio>
                                  <CustomRadio
                                    name={name}
                                    value='F'
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    checked={value === 'F'}>
                                    <FormattedMessage id='CUSTOMER.CIVILITY.MRS' />
                                  </CustomRadio>
                                </div>
                                {invalid && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block text-danger'>
                                      <span role='alert'>
                                        <FormattedMessage
                                          id={error?.message}
                                          values={{
                                            field: formatMessage({
                                              id: 'CUSTOMER.FIELD.CIVILITY',
                                            }),
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        />
                        <div className='fv-row mb-4'>
                          <label className='form-label required mb-3'>
                            <FormattedMessage id='CUSTOMER.FIELD.NAME' />
                          </label>
                          <input
                            type='text'
                            {...contactRegister('name')}
                            autoComplete='off'
                            className={clsx('form-control form-control-lg form-control-solid', {
                              'is-invalid': contactTouchedFields?.name && contactErrors?.name,
                              'is-valid': contactTouchedFields?.name && !contactErrors?.name,
                            })}
                          />
                          {contactErrors?.name && contactTouchedFields?.name && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger'>
                                <span role='alert'>
                                  <FormattedMessage
                                    id={'' + contactErrors?.name?.message}
                                    values={{
                                      field: formatMessage({
                                        id: 'CUSTOMER.FIELD.NAME',
                                      }),
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='row fv-row mb-4'>
                          <div className='col-xl-6 mb-4 mb-xl-0'>
                            <label className='form-label  mb-3'>
                              <FormattedMessage id='FORM.FIELD.EMAIL' />
                            </label>
                            <input
                              type='text'
                              {...contactRegister('email')}
                              className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid': contactTouchedFields?.email && contactErrors?.email,
                                'is-valid': contactTouchedFields?.email && !contactErrors?.email,
                              })}
                              autoComplete={'off'}
                            />
                            {contactErrors?.email && contactTouchedFields?.email && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block text-danger'>
                                  <span role='alert'>
                                    <FormattedMessage
                                      id={'' + contactErrors?.email?.message}
                                      values={{
                                        field: formatMessage({
                                          id: 'FORM.FIELD.EMAIL',
                                        }),
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className='col-xl-6'>
                            <div className='fv-row mb-5'>
                              <label className='form-label mb-3'>
                                <FormattedMessage id='CUSTOMER.FIELD.REGISTRAION.INDIVIDUAL' />
                              </label>
                              <input
                                type='text'
                                {...contactRegister('registration')}
                                className={clsx('form-control form-control-lg form-control-solid', {
                                  'is-invalid':
                                    contactTouchedFields?.registration &&
                                    contactErrors?.registration,
                                  'is-valid':
                                    contactTouchedFields?.registration &&
                                    !contactErrors?.registration,
                                })}
                              />
                              {contactErrors?.registration &&
                                contactTouchedFields?.registration && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block text-danger'>
                                      <span role='alert'>
                                        <FormattedMessage
                                          id={'' + contactErrors?.registration?.message}
                                          values={{
                                            field: formatMessage({
                                              id: 'CUSTOMER.FIELD.REGISTRAION.INDIVIDUAL',
                                            }),
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* End: Customer informations */}
                    {/* Begin: Enterprise informations */}
                    {selectedContactType === '1' && (
                      <div className='d-flex flex-column gap-4'>
                        <div className='border rounded p-8'>
                          <h2 className='fw-bold d-flex align-items-center text-dark mb-6'>
                            <FormattedMessage id='FORM.CUSTOMER.ENTERPRISE.INFORMATIONS' />
                          </h2>
                          <div className='row fv-row mb-4'>
                            <div className='col-xl-4 mb-4 '>
                              <label className='form-label required mb-3'>
                                <FormattedMessage id='CUSTOMER.FIELD.COMPANY-NAME' />
                              </label>
                              <input
                                type='text'
                                {...contactRegister('company_name')}
                                className={clsx('form-control form-control-lg form-control-solid', {
                                  'is-invalid':
                                    contactTouchedFields?.company_name &&
                                    contactErrors?.company_name,
                                  'is-valid':
                                    contactTouchedFields?.company_name &&
                                    !contactErrors?.company_name,
                                })}
                              />
                              {contactErrors?.company_name &&
                                contactTouchedFields?.company_name && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block text-danger'>
                                      <span role='alert'>
                                        <FormattedMessage
                                          id={'' + contactErrors?.company_name?.message}
                                          values={{
                                            field: formatMessage({
                                              id: 'CUSTOMER.FIELD.COMPANY-NAME',
                                            }),
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                )}
                            </div>
                            <div className='col-xl-4 mb-4 mb-xl-0'>
                              <label className='form-label required mb-3'>
                                <FormattedMessage id='CUSTOMER.FIELD.REGISTRAION.PROFESSIONAL' />
                              </label>
                              <input
                                type='text'
                                {...contactRegister('registration')}
                                className={clsx('form-control form-control-lg form-control-solid', {
                                  'is-invalid':
                                    contactTouchedFields?.registration &&
                                    contactErrors?.registration,
                                  'is-valid':
                                    contactTouchedFields?.registration &&
                                    !contactErrors?.registration,
                                })}
                              />
                              {contactErrors?.registration &&
                                contactTouchedFields?.registration && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block text-danger'>
                                      <span role='alert'>
                                        <FormattedMessage
                                          id={'' + contactErrors?.registration?.message}
                                          values={{
                                            field: formatMessage({
                                              id: 'CUSTOMER.FIELD.REGISTRAION.PROFESSIONAL',
                                            }),
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                )}
                            </div>
                            <div className='col-xl-4 mb-4 mb-xl-0'>
                              <label className='form-label mb-3'>
                                <FormattedMessage id='FORM.FIELD.WEBSITE' />
                              </label>
                              <input
                                type='text'
                                {...contactRegister('website')}
                                className={clsx('form-control form-control-lg form-control-solid', {
                                  'is-invalid':
                                    contactTouchedFields?.website && contactErrors?.website,
                                  'is-valid':
                                    contactTouchedFields?.website && !contactErrors?.website,
                                })}
                              />
                              {contactErrors?.website && contactTouchedFields?.website && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block text-danger'>
                                    <span role='alert'>
                                      <FormattedMessage
                                        id={'' + contactErrors?.website?.message}
                                        values={{ field: 'Website' }}
                                      />
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='border rounded p-8'>
                          <div className='mb-4'>
                            <h2 className='fw-bold d-flex align-items-center text-dark mb-6'>
                              <FormattedMessage id='FORM.CUSTOMER.ENTERPRISE.CONTACT' />
                            </h2>
                          </div>
                          <div className='row fv-row'>
                            <Controller
                              control={contactControl}
                              name='civility'
                              render={({
                                field: { name, onBlur, onChange, value },
                                fieldState: { invalid, isTouched, error },
                              }) => (
                                <>
                                  <div className='mb-4'>
                                    <label className='form-label required mb-3'>
                                      <FormattedMessage id='CUSTOMER.FIELD.CIVILITY' />
                                    </label>
                                    <div className='d-flex gap-6 mb-3'>
                                      <CustomRadio
                                        name={name}
                                        value='M'
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        checked={value === 'M'}>
                                        <FormattedMessage id='CUSTOMER.CIVILITY.M' />
                                      </CustomRadio>
                                      <CustomRadio
                                        name={name}
                                        value='F'
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        checked={value === 'F'}>
                                        <FormattedMessage id='CUSTOMER.CIVILITY.MRS' />
                                      </CustomRadio>
                                    </div>
                                    {invalid && (
                                      <div className='fv-help-block text-danger'>
                                        <span role='alert'>
                                          <FormattedMessage
                                            id={error?.message}
                                            values={{
                                              field: formatMessage({
                                                id: 'CUSTOMER.FIELD.CIVILITY',
                                              }),
                                            }}
                                          />
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                            />
                            <div className='col-xl-6'>
                              <label className='form-label required mb-3'>
                                <FormattedMessage id='CUSTOMER.FIELD.NAME' />
                              </label>
                              <input
                                type='text'
                                {...contactRegister('name')}
                                autoComplete='off'
                                className={clsx('form-control form-control-lg form-control-solid', {
                                  'is-invalid': contactTouchedFields?.name && contactErrors?.name,
                                  'is-valid': contactTouchedFields?.name && !contactErrors?.name,
                                })}
                              />
                              {contactErrors?.name && contactTouchedFields?.name && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block text-danger'>
                                    <span role='alert'>
                                      <FormattedMessage
                                        id={'' + contactErrors?.name?.message}
                                        values={{
                                          field: formatMessage({
                                            id: 'CUSTOMER.FIELD.NAME',
                                          }),
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className='col-xl-6 mb-4 mb-xl-0'>
                              <label className='form-label required mb-3'>
                                <FormattedMessage id='FORM.FIELD.EMAIL' />
                              </label>
                              <input
                                type='text'
                                {...contactRegister('email')}
                                className={clsx('form-control form-control-lg form-control-solid', {
                                  'is-invalid': contactTouchedFields?.email && contactErrors?.email,
                                  'is-valid': contactTouchedFields?.email && !contactErrors?.email,
                                })}
                                autoComplete={'off'}
                              />
                              {contactErrors?.email && contactTouchedFields?.email && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block text-danger'>
                                    <span role='alert'>
                                      <FormattedMessage
                                        id={'' + contactErrors?.email?.message}
                                        values={{
                                          field: formatMessage({
                                            id: 'FORM.FIELD.EMAIL',
                                          }),
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {((contactType === 'customer' && currentContactStep === 2) ||
                  (contactType === 'supplier' && currentContactStep === 1)) && (
                  <div className='w-100 d-flex flex-column justify-content-center'>
                    <div className='row g-4'>
                      <div className='border rounded p-8 flex-grow-1 text-gray-600 fs-5 d-flex flex-column gap-3'>
                        <div className='mb-4'>
                          <h2 className='fw-bold d-flex align-items-center text-dark mb-6'>
                            <FormattedMessage id='FORM.CUSTOMER.PHONES' />
                          </h2>
                        </div>
                        <div className='d-flex flex-column gap-4'>
                          {phoneFields.map((phoneField, index) => (
                            <div className='d-flex gap-3 align-items-start' key={phoneField.id}>
                              <Controller
                                control={contactControl}
                                name={`contact_phones.${index}.type`}
                                render={({
                                  field: { name, onChange, value },
                                  fieldState: { isTouched, error },
                                }) => (
                                  <div>
                                    <label className='form-label required mb-3'>
                                      <FormattedMessage id='CUSTOMER.FIELD.PHONE-TYPE' />
                                    </label>
                                    <SwiverSelect
                                      className='h-50px w-75px w-md-150px swiver-select-solid'
                                      menuWidth='200px'
                                      name={name}
                                      value={value}
                                      isClearable={false}
                                      isSearchable={false}
                                      onChange={(e) => {
                                        onChange(e.value || '');
                                      }}
                                      options={[
                                        {
                                          label: formatMessage({
                                            id: 'PHONE.TYPES.MOBILE',
                                          }),
                                          value: '1',
                                        },
                                        {
                                          label: formatMessage({
                                            id: 'PHONE.TYPES.FAX',
                                          }),
                                          value: '2',
                                        },
                                        {
                                          label: formatMessage({
                                            id: 'PHONE.TYPES.WHATSAPP',
                                          }),
                                          value: '3',
                                        },
                                        {
                                          label: formatMessage({
                                            id: 'PHONE.TYPES.VIBER',
                                          }),
                                          value: '4',
                                        },
                                        {
                                          label: formatMessage({
                                            id: 'PHONE.TYPES.SUPPORT',
                                          }),
                                          value: '5',
                                        },
                                        {
                                          label: formatMessage({
                                            id: 'PHONE.TYPES.LINE',
                                          }),
                                          value: '8',
                                        },
                                      ]}
                                    />
                                    {error && (
                                      <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block text-danger'>
                                          <span role='alert'>
                                            <FormattedMessage
                                              id={error?.message}
                                              values={{
                                                field: formatMessage({
                                                  id: 'CUSTOMER.FIELD.PHONE-TYPE',
                                                }),
                                              }}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              />
                              <Controller
                                control={contactControl}
                                name={`contact_phones.${index}.value`}
                                render={({
                                  field: { value, onChange, onBlur, name },
                                  fieldState: { isTouched, error },
                                }) => (
                                  <>
                                    <div className='flex-grow-1'>
                                      <label className='form-label required mb-3'>
                                        <FormattedMessage id='CUSTOMER.FIELD.PHONE-NUMBER' />
                                      </label>
                                      <div className='d-flex align-items-center gap-3'>
                                        <PhoneInput
                                          localization={
                                            locale === 'ar' ? ar : locale === 'fr' ? fr : undefined
                                          }
                                          // containerClass='d-flex flex-row flex-grow-1'
                                          inputClass={clsx(
                                            'form-control form-control-sm form-control-solid h-50px',
                                            {
                                              'is-invalid': error,
                                              // 'ps-16 pe-3': !error,
                                            }
                                          )}
                                          searchClass='form-control form-control-solid form-control-lg'
                                          // dropdownClass='bg-body'
                                          alwaysDefaultMask={true}
                                          defaultMask='...........................'
                                          country={(
                                            process.env.REACT_APP_DEFAULT_COUNTRY || 'TN'
                                          ).toLowerCase()}
                                          value={value}
                                          inputProps={{
                                            name: name,
                                            required: true,
                                            autoComplete: 'off',
                                          }}
                                          onChange={(value, data, event, formattedValue) => {
                                            const phone = formattedValue.replaceAll(' ', '');
                                            onChange(phone);
                                            setContactValue(
                                              `contact_phones.${index}.country`,
                                              (data as CountryData)?.countryCode.toUpperCase() || ''
                                            );
                                          }}
                                          onBlur={() => {
                                            onBlur();
                                          }}
                                        />
                                        <div
                                          className='my-auto w-50px cursor-pointer'
                                          onClick={() => {
                                            removePhone(index);
                                          }}>
                                          <KTSVG
                                            path={'/media/icons/duotune/general/gen036.svg'}
                                            className='svg-icon svg-icon-danger svg-icon-3x'
                                          />
                                        </div>
                                      </div>
                                      {error && (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>
                                            <span role='alert' className='text-danger'>
                                              <FormattedMessage
                                                id={error.message}
                                                values={{
                                                  field: formatMessage({
                                                    id: 'COMMON.FIELDS.PHONE',
                                                  }),
                                                }}
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </>
                                )}
                              />
                            </div>
                          ))}
                          {phoneFields.length < contactPhonesLimit && (
                            <div>
                              <button
                                type='button'
                                className='btn btn-primary btn-sm'
                                onClick={() => {
                                  appendPhone({
                                    country: '',
                                    value: '',
                                    type: '1',
                                    display_in_doc: true,
                                  });
                                }}>
                                <FormattedMessage
                                  id={
                                    phoneFields.length > 0
                                      ? 'FORM.CUSTOMER.ADD-PHONE'
                                      : 'FORM.CUSTOMER.NO-PHONE'
                                  }
                                />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='border rounded p-8 flex-grow-1 text-gray-600 fs-5 d-flex flex-column gap-3'>
                        <div className='mb-4'>
                          <h2 className='fw-bold d-flex align-items-center text-dark mb-6'>
                            <FormattedMessage id='FORM.CUSTOMER.ADDRESSES' />
                          </h2>
                        </div>
                        {/*  Begin : Address */}
                        <div className='d-flex flex-column gap-4'>
                          {addressesFields.map((field, index) => (
                            <div key={field.id} className='border text-gray-600 p-3 d-flex gap-3'>
                              <div className='fv-row flex-grow-1'>
                                <div className='row'>
                                  <Controller
                                    control={contactControl}
                                    name={`contact_address.${index}.type`}
                                    render={({
                                      field: { name, onBlur, onChange, value },
                                      fieldState: { invalid, isTouched, error },
                                    }) => (
                                      <>
                                        <div className='col-md-6 mb-3 mb-md-0'>
                                          <input
                                            name={name}
                                            type='radio'
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            className='btn-check'
                                            id={`sw_addresses_${field.id}_type_0`}
                                            value={0}
                                            checked={value === 0 || value === '0'}
                                          />
                                          <label
                                            className={clsx(
                                              'btn btn-outline btn-outline-dashed btn-outline-default  p-3 d-flex align-items-center',
                                              (value === 0 || value === '0') &&
                                                'border border-primary'
                                            )}
                                            htmlFor={`sw_addresses_${field.id}_type_0`}>
                                            <KTSVG
                                              path='/media/icons/duotune/finance/fin002.svg'
                                              className='svg-icon svg-icon-2x me-5'
                                            />
                                            <span className='d-block fw-semibold text-start'>
                                              <span className='text-dark fw-normal d-block fs-5 mb-2'>
                                                <FormattedMessage id='COMMON.ADDRESSES.BILLING.TITLE' />
                                              </span>
                                              <span className='text-gray-400 fw-normal fs-7'>
                                                <FormattedMessage id='COMMON.ADDRESSES.BILLING.SUBTITLE' />
                                              </span>
                                            </span>
                                          </label>
                                        </div>
                                        <div className='col-md-6'>
                                          <input
                                            name={name}
                                            type='radio'
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            className='btn-check'
                                            id={`sw_addresses_${field.id}_type_1`}
                                            value={1}
                                            checked={value === 1 || value === '1'}
                                          />
                                          <label
                                            className={clsx(
                                              'btn btn-outline btn-outline-dashed btn-outline-default  p-3 d-flex align-items-center',
                                              (value === 1 || value === '1') &&
                                                'border border-primary'
                                            )}
                                            htmlFor={`sw_addresses_${field.id}_type_1`}>
                                            <KTSVG
                                              path='/media/icons/duotune/ecommerce/ecm006.svg'
                                              className='svg-icon svg-icon-2x me-5'
                                            />
                                            <span className='d-block fw-semibold text-start'>
                                              <span className='text-dark fw-normal d-block fs-4 mb-2'>
                                                <FormattedMessage id='COMMON.ADDRESSES.DELIVERY.TITLE' />
                                              </span>
                                              <span className='text-gray-400 fw-normal fs-7'>
                                                <FormattedMessage id='COMMON.ADDRESSES.DELIVERY.SUBTITLE' />
                                              </span>
                                            </span>
                                          </label>
                                        </div>
                                        {invalid && (
                                          <div className='fv-plugins-message-container mt-3'>
                                            <div className='fv-help-block text-danger'>
                                              <span role='alert'>
                                                <FormattedMessage
                                                  id={error?.message}
                                                  values={{
                                                    field: formatMessage({
                                                      id: 'PRODUCT.FIELD.TYPE',
                                                    }),
                                                  }}
                                                />
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  />
                                  <div className='row mt-3'>
                                    <div className='col-md-4 mb-3'>
                                      <div className='fv-row fv-plugins-icon-container'>
                                        <label className='form-label required mb-3'>
                                          <FormattedMessage id='FORM.FIELD.COUNTRY' />
                                        </label>
                                        <Controller
                                          control={contactControl}
                                          name={`contact_address.${index}.address.country`}
                                          render={({
                                            field: { name, onBlur, onChange, value },
                                            fieldState: { error },
                                          }) => (
                                            <>
                                              <SwiverSelect
                                                className='swiver-select-solid h-45px'
                                                options={countryOptions}
                                                name={name}
                                                onChange={(e) => {
                                                  onChange(e ? e.value : '');
                                                }}
                                                onBlur={onBlur}
                                                value={value}
                                                isClearable={false}
                                              />
                                              {error && (
                                                <div className='fv-plugins-message-container mt-3'>
                                                  <div className='fv-help-block text-danger'>
                                                    <span role='alert'>
                                                      <FormattedMessage
                                                        id={error?.message}
                                                        values={{
                                                          field: formatMessage({
                                                            id: 'FORM.FIELD.COUNTRY',
                                                          }),
                                                        }}
                                                      />
                                                    </span>
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          )}
                                        />
                                      </div>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                      <div className='fv-row fv-plugins-icon-container'>
                                        <label className='form-label required mb-3'>
                                          <FormattedMessage id='FORM.FIELD.REGION' />
                                        </label>
                                        <Controller
                                          control={contactControl}
                                          name={`contact_address.${index}.address.region`}
                                          render={({ field, fieldState: { error } }) => (
                                            <>
                                              <input
                                                className={clsx(
                                                  'form-control form-control-solid h-45px',
                                                  {
                                                    // 'is-valid': isTouched && !error,
                                                    'is-invalid': error,
                                                  }
                                                )}
                                                {...field}
                                                value={field.value || ''}
                                                type='text'
                                              />
                                              {error && (
                                                <div className='fv-plugins-message-container mt-3'>
                                                  <div className='fv-help-block text-danger'>
                                                    <span role='alert'>
                                                      <FormattedMessage
                                                        id={error?.message}
                                                        values={{
                                                          field: formatMessage({
                                                            id: 'FORM.FIELD.REGION',
                                                          }),
                                                        }}
                                                      />
                                                    </span>
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          )}
                                        />
                                      </div>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                      <div className='fv-row fv-plugins-icon-container'>
                                        <label className='form-label required mb-3'>
                                          <FormattedMessage id='FORM.FIELD.ZIP' />
                                        </label>
                                        <Controller
                                          control={contactControl}
                                          name={`contact_address.${index}.address.zip_code`}
                                          render={({ field, fieldState: { error } }) => (
                                            <>
                                              <input
                                                className={clsx(
                                                  'form-control form-control-solid h-45px',
                                                  {
                                                    // 'is-valid': !error,
                                                    'is-invalid': error,
                                                  }
                                                )}
                                                {...field}
                                                value={field.value || ''}
                                                type='text'
                                              />
                                              {error && (
                                                <div className='fv-plugins-message-container mt-3'>
                                                  <div className='fv-help-block text-danger'>
                                                    <span role='alert'>
                                                      <FormattedMessage
                                                        id={error?.message}
                                                        values={{
                                                          field: formatMessage({
                                                            id: 'FORM.FIELD.ZIP',
                                                          }),
                                                        }}
                                                      />
                                                    </span>
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          )}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className='fv-row fv-plugins-icon-container my-3'>
                                    <label className='form-label required mb-3'>
                                      <FormattedMessage id='FORM.FIELD.ADDRESS' />
                                    </label>
                                    <Controller
                                      control={contactControl}
                                      name={`contact_address.${index}.address.address`}
                                      render={({
                                        field,
                                        fieldState: { invalid, isTouched, error },
                                      }) => (
                                        <>
                                          <input
                                            className={clsx(
                                              'form-control form-control-solid h-45px',
                                              {
                                                // 'is-valid': !error,
                                                'is-invalid': error,
                                              }
                                            )}
                                            {...field}
                                            type='text'
                                          />
                                          {error && (
                                            <div className='fv-plugins-message-container mt-3'>
                                              <div className='fv-help-block text-danger'>
                                                <span role='alert'>
                                                  <FormattedMessage
                                                    id={error?.message}
                                                    values={{
                                                      field: formatMessage({
                                                        id: 'FORM.FIELD.ADDRESS',
                                                      }),
                                                    }}
                                                  />
                                                </span>
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='d-flex flex-column'>
                                <div
                                  // className='mt-auto'
                                  onClick={() => {
                                    removeAddress(index);
                                  }}>
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen036.svg'
                                    className='svg-icon svg-icon-danger svg-icon-3x cursor-pointer'
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        {addressesFields.length < contactAddressesLimit && (
                          <button
                            className='btn btn-primary btn-sm w-200px'
                            type='button'
                            onClick={addAddress}>
                            <FormattedMessage
                              id={
                                !addressesFields?.length
                                  ? 'FORM.CUSTOMER.NO-ADDRESS'
                                  : 'FORM.CUSTOMER.ADD-ADDRESS'
                              }
                            />
                          </button>
                        )}
                        {/*  End : Address */}
                      </div>
                    </div>
                  </div>
                )}
                {contactType === 'customer' && currentContactStep === 3 && (
                  <div className='border rounded px-8 py-4 text-gray-600 fs-5 d-flex flex-column gap-3'>
                    <div className='mb-4'>
                      <h2 className='fw-bold d-flex align-items-center text-dark mb-6'>
                        <FormattedMessage id='FORM.STEPPER.CUSTOMER.ADDITIONAL-INFORMATIONS' />
                      </h2>
                    </div>
                    <div className='row'>
                      <div className='mb-4'>
                        <label className='form-label mb-3'>
                          <FormattedMessage id='CUSTOMER.FIELD.AUTHORIZED-OUTSTRANDINGS' />
                          {/* <sup className='fs-8'>
                            <FormattedMessage
                              id='CURRENCY.IN'
                              values={{ currency }}></FormattedMessage>
                          </sup> */}
                        </label>
                        <input
                          type='number'
                          step='any'
                          {...contactRegister('in_progress')}
                          className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid':
                              contactTouchedFields?.in_progress && contactErrors?.in_progress,
                            'is-valid':
                              contactTouchedFields?.in_progress && !contactErrors?.in_progress,
                          })}
                          autoComplete='off'
                        />
                        {contactErrors?.in_progress && contactTouchedFields?.in_progress && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger'>
                              <span role='alert'>
                                <FormattedMessage
                                  id={'' + contactErrors?.in_progress?.message}
                                  values={{
                                    field: formatMessage({
                                      id: 'CUSTOMER.FIELD.AUTHORIZED-OUTSTRANDINGS',
                                    }),
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className='mb-4'>
                        <label className='form-label mb-3'>
                          <FormattedMessage id='CUSTOMER.FIELD.PRICE-LIST' />
                        </label>
                        <Controller
                          name='price_list'
                          control={contactControl}
                          render={({
                            field: { name, onBlur, onChange, value },
                            fieldState: { invalid, isTouched, error },
                          }) => (
                            <>
                              <div className='d-flex flex-wrap gap-3'>
                                {priceList?.map((price) => (
                                  <CustomRadio
                                    name={name}
                                    value={price.id}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    key={price.id}
                                    checked={value?.toString() === price.id.toString()}>
                                    {price.name}
                                  </CustomRadio>
                                ))}
                                <button
                                  type='button'
                                  className='btn btn-icon btn-light-success'
                                  onClick={showPriceListModal}>
                                  <KTSVG
                                    path={'/media/icons/duotune/arrows/arr087.svg'}
                                    className='svg-icon svg-icon-1'
                                  />
                                </button>
                              </div>
                              {isTouched && error && (
                                <div className='text-danger'>
                                  <span role='alert'>
                                    <FormattedMessage
                                      id={error.message}
                                      values={{
                                        field: formatMessage({
                                          id: 'CUSTOMER.FIELD.PRICE-LIST',
                                        }),
                                      }}
                                    />
                                  </span>
                                </div>
                              )}
                            </>
                          )}
                        />
                      </div>
                      <div className='mb-4'>
                        <label className='form-label mb-3'>
                          <FormattedMessage id='CUSTOMER.FIELD.PAYMENTDEADLINE' />
                        </label>
                        <div className='d-flex flex-column flex-lg-row gap-3'>
                          <Controller
                            name='due_date_type'
                            control={contactControl}
                            render={({
                              field: { name, onBlur, onChange, value },
                              fieldState: { isTouched, error },
                            }) => (
                              <div className='d-flex flex-column'>
                                <div className='d-flex flex-wrap gap-3'>
                                  <CustomRadio
                                    name={name}
                                    value='day'
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    checked={value === 'day'}>
                                    <FormattedMessage id='COMMON.DATE.DAY' />
                                  </CustomRadio>
                                  <CustomRadio
                                    name={name}
                                    value='month'
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    checked={value === 'month'}>
                                    <FormattedMessage id='COMMON.DATE.MONTH' />
                                  </CustomRadio>
                                  <CustomRadio
                                    name={name}
                                    value='year'
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    checked={value === 'year'}>
                                    <FormattedMessage id='COMMON.DATE.YEAR' />
                                  </CustomRadio>
                                  {isTouched && error && (
                                    <div className='text-danger'>
                                      <span role='alert'>
                                        <FormattedMessage
                                          id={error.message}
                                          values={{
                                            field: formatMessage({
                                              id: 'CUSTOMER.FIELD.PAYMENTDEADLINE',
                                            }),
                                          }}
                                        />
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          />
                          <Controller
                            control={contactControl}
                            name='due_date_number'
                            render={({
                              field: { name, onBlur, onChange, value, ref },
                              fieldState: { invalid, isTouched, error },
                            }) => (
                              <div>
                                <div className='input-group'>
                                  <button
                                    type='button'
                                    onClick={() => {
                                      if (Number(value) > 0) onChange(Number(value) - 1);
                                    }}
                                    className='btn btn-icon btn-light-primary'>
                                    <KTSVG
                                      path='/media/icons/duotune/arrows/arr089.svg'
                                      className='svg-icon svg-icon-primary svg-icon-1'
                                    />
                                  </button>
                                  <input
                                    step='any'
                                    type='number'
                                    name={name}
                                    onBlur={onBlur}
                                    ref={ref}
                                    onChange={onChange}
                                    value={value || ''}
                                    min={0}
                                    className='form-control form-control form-control-solid flex-grow-1 text-center'
                                    autoComplete='off'
                                  />
                                  <button
                                    onClick={() => {
                                      onChange(Number(value) + 1);
                                    }}
                                    type='button'
                                    className='btn btn-icon btn-light-primary'>
                                    <KTSVG
                                      path='/media/icons/duotune/arrows/arr087.svg'
                                      className='svg-icon svg-icon-primary svg-icon-1'
                                    />
                                  </button>
                                </div>
                                {error && (
                                  <div className='text-danger mt-3'>
                                    <span role='alert'>
                                      <FormattedMessage
                                        id={error.message}
                                        values={{
                                          field: formatMessage({
                                            id: 'CUSTOMER.FIELD.PAYMENTDEADLINE',
                                          }),
                                        }}
                                      />
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            className='btn btn btn-bg-light btn-color-primary me-auto'
            onClick={previousContactStep}>
            <KTSVG
              path={
                locale === 'ar'
                  ? '/media/icons/duotune/arrows/arr064.svg'
                  : '/media/icons/duotune/arrows/arr063.svg'
              }
              className='svg-icon-4 me-1'
            />
            <FormattedMessage id='FORM.BACK' />
          </div>
          {canQuickAdd && (
            <button
              type='button'
              disabled={isContactSubmitting}
              onClick={handleContactSubmit(async (values) => {
                await submitContactStep(values, true);
              })}
              className='btn btn-bg-light btn-color-primary'>
              <FormattedMessage id='FORM.QUICK-ADD' />
            </button>
          )}
          <button className='btn btn-primary' disabled={isContactSubmitting}>
            <span className='indicator-label'>
              {isContactSubmitting && <span className='spinner-border align-middle me-2'></span>}
              {!isContactSubmit && (
                <>
                  <FormattedMessage id='FORM.CONTINUE' />
                  <KTSVG
                    path={
                      locale === 'ar'
                        ? '/media/icons/duotune/arrows/arr063.svg'
                        : '/media/icons/duotune/arrows/arr064.svg'
                    }
                    className='svg-icon-3 ms-2 me-0'
                  />
                </>
              )}
              {isContactSubmit && <FormattedMessage id='FORM.SUBMIT' />}
            </span>
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
