import axios from 'axios';

type EmailPayload = {
  email: string;
};

type PhonePayload = {
  phone: string;
};

export const checkEmail = (payload: EmailPayload) => {
  return axios.post('/open/user/check-email', payload);
};

export const checkPhone = (payload: PhonePayload) => {
  return axios.post('/open/user/check-phone', payload);
};
