import { useSelector } from 'react-redux';
import { RootState } from '../../../setup/Store';
import SwPageNavigation from '../../../stories/Organismes/SwPageNavigation/SwPageNavigation';

function PageNavigation() {
  const hideTitleAndBreadCrumbs = useSelector(
    (state: RootState) => state.page.hideTitleAndBreadCrumbs
  );
  return <>{!hideTitleAndBreadCrumbs && <SwPageNavigation></SwPageNavigation>}</>;
}

export default PageNavigation;
