import { useWindowWidth } from '@react-hook/window-size/throttled';
import clsx from 'clsx';
import { useContext, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../setup/Store';
import { readUserBanks } from '../../../../setup/redux/reducers/UtilsReducers';
import { BankModalContext } from '../../../ModalProviders/BankModal';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { Balance } from '../../../models/CashManagementResponse';
import { dateFormatsPerLocale } from '../../../utils/configs';

const BankDepositStep2 = ({ balance }: { balance: Balance }) => {
  const width = useWindowWidth();
  const locale = useSelector((state: RootState) => state.utils.locale);
  const dispatch = useDispatch();

  const { formatMessage } = useIntl();
  const { setValue } = useFormContext();
  const userBanks = useSelector((state: RootState) => state.utils.userBanks);
  const bankModal = useContext(BankModalContext);

  useEffect(() => {
    if (userBanks === undefined) dispatch(readUserBanks());
    return () => {};
  }, [userBanks]);

  return (
    <div className='row g-6'>
      <div>
        <label className='form-label required mb-3'>
          <FormattedMessage id='BANK.DEPOSIT.NUMBER' />
        </label>
        <Controller
          name='depositNumber'
          defaultValue=''
          rules={{ required: 'FORM.ERRORS.REQUIRED' }}
          render={({
            field: { name, onBlur, onChange, value },
            fieldState: { error, isTouched },
          }) => (
            <>
              <input
                name={name}
                type='text'
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                className={clsx('form-control form-control-solid', {
                  'is-valid': isTouched && !error,
                  'is-invalid': error,
                })}
              />
              {error && (
                <div className='text-danger'>
                  <span role='alert'>
                    <FormattedMessage
                      id={error.message}
                      values={{
                        field: formatMessage({ id: 'BANK.DEPOSIT.NUMBER' }),
                      }}
                    />
                  </span>
                </div>
              )}
            </>
          )}
        />
      </div>
      <div>
        <label className='form-label required mb-3'>
          <FormattedMessage id='BANKING.DATE' />
        </label>
        <Controller
          name='date'
          rules={{ required: 'FORM.ERRORS.REQUIRED' }}
          defaultValue={new Date()}
          render={({
            field: { name, onBlur, onChange, value },
            fieldState: { error, isTouched },
          }) => (
            <>
              <ReactDatePicker
                onChange={onChange}
                selected={value}
                locale={locale}
                name={name}
                dateFormat={dateFormatsPerLocale[locale || 'fr']}
                onBlur={onBlur}
                withPortal={width < 768}
                className={clsx('form-control form-control-solid', {
                  'is-valid': isTouched && !error,
                  'is-invalid': isTouched && error,
                })}
              />
              {error && isTouched && (
                <div className='text-danger'>
                  <span role='alert'>
                    <FormattedMessage
                      id={error.message}
                      values={{
                        field: formatMessage({ id: 'BANKING.DATE' }),
                      }}
                    />
                  </span>
                </div>
              )}
            </>
          )}
        />
      </div>
      <div>
        <label className='form-label required mb-3'>
          <FormattedMessage id='FORM.FIELD.DESTINATION_ACCOUNT' />
        </label>
        <Controller
          name='bankAccountDestination'
          rules={{ required: 'FORM.ERRORS.REQUIRED' }}
          render={({
            field: { name, onBlur, onChange, value },
            fieldState: { error, isTouched },
          }) => (
            <>
              <div className='row row-cols-1 row-cols-md-2 g-5'>
                {userBanks?.map((bank) => (
                  <div className='col' key={bank.id}>
                    <input
                      type='radio'
                      className='btn-check'
                      name={name}
                      onChange={(e) => {
                        onChange(e);
                        setValue('bankDetails', bank);
                      }}
                      onBlur={onBlur}
                      value={bank.id + ''}
                      checked={value === bank.id + ''}
                      id={`sw_deposit_bank_${bank.id}`}
                    />
                    <label
                      className={clsx(
                        'btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-start gap-5 h-100',
                        value === bank.id + '' && 'border border-primary active'
                      )}
                      htmlFor={`sw_deposit_bank_${bank.id}`}>
                      <img
                        src={toAbsoluteUrl(`/media/banks/${(bank.bank as any)?.code}.png`)}
                        alt=''
                        className='w-50px'
                      />
                      <div className='d-flex align-items-start flex-column gap-3'>
                        <div className='d-flex align-items-center'>
                          <span>{bank.name}</span>
                        </div>
                        <div className='text-dark fw-bold'>{bank.number}</div>
                        <div className='text-muted'>
                          <FormattedMessage id='BANK.AGENCY' />: {bank.agency}
                        </div>
                      </div>
                    </label>
                  </div>
                ))}

                <div className='col'>
                  <div
                    className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex flex-column flex-center gap-5 h-100'
                    onClick={() => bankModal.showAddBankModal()}>
                    <KTSVG
                      className='svg-icon svg-icon-3hx svg-icon-primary'
                      path='/media/icons/duotune/general/gen041.svg'
                    />
                    <div className='fs-5 fw-bold'>
                      <FormattedMessage id='ADD.NEW.BANK' />
                    </div>
                  </div>
                </div>
              </div>
              {error && (
                <div className='text-danger'>
                  <span role='alert'>
                    <FormattedMessage
                      id={error.message}
                      values={{
                        field: formatMessage({ id: 'FORM.FIELD.SOURCE_ACCOUNT' }),
                      }}
                    />
                  </span>
                </div>
              )}
            </>
          )}
        />
      </div>
    </div>
  );
};
export { BankDepositStep2 };
