import { postRefreshToken } from '../redux/effects/AuthEffects';
// import { updateTokens } from '../redux/reducers/auth';
type Tokens = { token: string; refreshToken: string };
export default function setupAxios(axios: any, store: any, updateTokens: (token: Tokens) => void) {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
  axios.interceptors.request.use(function (config: any) {
    const token = store.getState().auth.token;
    config.headers.Authorization = `Bearer ${token}`;
    config.headers['Accept-Language'] = store.getState().utils.locale;
    return config;
  });
  axios.interceptors.response.use(
    (response: any) => {
      return response;
    },
    async function (error: any) {
      const originalRequest = error.config;
      if (
        (error?.response?.status === 401 &&
          error?.response?.data?.message === 'Expired JWT Token') ||
        (error?.response?.data?.message === 'Invalid JWT Token' && !originalRequest?._retry)
      ) {
        originalRequest._retry = true;
        const refresh_token = localStorage.getItem('refreshToken');
        if (refresh_token) {
          const {
            data: { token, refreshToken },
          } = await postRefreshToken(refresh_token);
          updateTokens({ token, refreshToken });
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

          return axios(originalRequest);
        }
      }
      return Promise.reject(error);
    }
  );
}
