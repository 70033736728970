import { Navigate, Outlet, Route, Routes, useNavigate, useParams } from 'react-router-dom';

import { useEffect } from 'react';
import { SuspensedView } from '../../routing/PrivateRoutes';
import { lazyWithRetry } from '../../utils/LazyWithRetry';
const StockAvailability = lazyWithRetry(() => import('./StockAvailability/StockAvailability'));
const Overview = lazyWithRetry(() => import('./Overview/Overview'));
const StockManagement = lazyWithRetry(() => import('./stockmanagment'));

function StockRoutes() {
  return (
    <Routes>
      <Route path='/management' element={<StockManagement />} />

      <Route path='/' element={<StockMasterLayout />}>
        <Route
          path='/availability/:id'
          element={
            <SuspensedView>
              <StockAvailability />
            </SuspensedView>
          }
        />
        <Route
          path='/overview/:id'
          element={
            <SuspensedView>
              <Overview />
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to={'/home'} />} />
      </Route>
    </Routes>
  );
}

export default StockRoutes;

const StockMasterLayout = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    if (!id) {
      navigate('/stock/management');
    }
  }, [id]);

  return (
    <>
      <div className='col'>
        <Outlet />
      </div>
    </>
  );
};
