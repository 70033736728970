/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../../../setup/Store';
import { signOut } from '../../../../setup/redux/reducers/AuthReducer';
import Guard, {
  authorizeOnRole,
  authorizeOnRoleOrPermissionAndPermissionPlan,
} from '../../../components/Security/Guard';
import { toAbsoluteUrl } from '../../../helpers';
import { Languages } from './Languages';
import { LayoutTypeKey } from '../../../layout/core';

const HeaderUserMenu: FC = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();

  const handleSignOut = () => {
    dispatch(signOut());
  };
  const [sidebarTheme, setSidebarTheme] = useState(localStorage.getItem(LayoutTypeKey));
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-275px'
      data-kt-menu='true'>
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              alt=' '
              src={user?.avatar?.web_path || toAbsoluteUrl('/media/avatars/swiver-avatar.png')}
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bold d-flex align-items-center fs-5'>
              {`${user?.first_name} ${user?.last_name}`}
              <span className='badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2'>
                <FormattedMessage id='USER.MENU.PRO' />
              </span>
            </div>
            <div className='fw-semibold text-muted text-hover-primary fs-7'>{user?.email}</div>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/profile'} className='menu-link px-5'>
          <FormattedMessage id='USER.MENU.PROFILE' />
        </Link>
      </div>
      <Guard
        planPermissions={['allowPurchase']}
        permissions={['Customer.read.write']}
        roles={['ROLE_ADMIN']}
        authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
        <div className='menu-item px-5'>
          <Link to={'/profile'} className='menu-link px-5'>
            <FormattedMessage id='USER.MENU.SUBSCRIPTION' />
          </Link>
        </div>
      </Guard>
      <div className='separator my-2'></div>

      <Languages type='labeled' />
      <Guard
        planPermissions={['allowPurchase']}
        permissions={['Customer.read.write']}
        roles={['ROLE_ADMIN']}
        authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
        <div className='menu-item px-5 my-1'>
          <Link to='/pricing' className='menu-link px-5'>
            <FormattedMessage id='USER.MENU.ACCOUNT.UPGRADE' />
          </Link>
        </div>
      </Guard>
      <Guard roles={['ROLE_ADMIN']} authorize={authorizeOnRole}>
        <div className='menu-item px-5 my-1'>
          <Link to='/settings' className='menu-link px-5'>
            <FormattedMessage id='USER.MENU.ACCOUNT.SETTINGS' />
          </Link>
        </div>
      </Guard>
      <div className='menu-item px-3'>
        <div className='menu-content px-3'>
          <label className='form-check form-switch form-check-custom form-check-solid menu-link '>
            <span className=''>
              <FormattedMessage id='HEADER.DARK_SIDEBAR' />
            </span>
            <input
              className='form-check-input w-30px h-20px ms-auto'
              type='checkbox'
              checked={sidebarTheme === 'dark-sidebar'}
              onChange={(e) => {
                setSidebarTheme(e.currentTarget.checked ? 'dark-sidebar' : 'light-sidebar');
                localStorage.setItem(
                  LayoutTypeKey,
                  e.currentTarget.checked ? 'dark-sidebar' : 'light-sidebar'
                );
                document.body.setAttribute(
                  'data-kt-app-layout',
                  e.currentTarget.checked ? 'dark-sidebar' : 'light-sidebar'
                );
              }}
            />
          </label>
        </div>
      </div>
      <div className='menu-item px-5'>
        <a onClick={handleSignOut} className='menu-link px-5'>
          <FormattedMessage id='USER.MENU.SIGNOUT' />
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
