import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';

function GatwayPayment({ onChangeType, data = [], type = 'column' }: any) {
  return (
    <div
      data-kt-buttons='true'
      className={clsx(type == 'inline' && 'd-flex justify-content-center gap-4 py-4')}>
      {data.map((item: any, index: number) => (
        <label
          key={'gtw-' + index}
          className={clsx(
            'btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-3 ',
            type == 'column' && 'mb-5'
          )}>
          <div className='d-flex align-items-center me-2'>
            <div className='form-check form-check-custom form-check-solid form-check-primary me-6'>
              <input
                className='form-check-input'
                type='radio'
                name='plan'
                defaultChecked={index == 0}
                onChange={() => onChangeType('creditCard', item.id)}
                defaultValue='advanced'
              />
            </div>
            <div className='flex-grow-1'>
              <h2
                className={clsx(
                  'd-flex align-items-center fs-3 fw-bold flex-wrap ms-0',
                  type == 'inline' && 'mb-0'
                )}>
                <FormattedMessage id={'PRICING.PAYMENT.BANK_CARD'} />{' '}
                <span className='badge badge-light-success ms-2 fs-7'>{item.name}</span>
              </h2>
              {type == 'column' && (
                <div className='fw-semibold opacity-50'>
                  <FormattedMessage id='PRICING.PAYMENT.INSTANT_ACTIVATION' />
                </div>
              )}
            </div>
          </div>
        </label>
      ))}

      <label className='btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-3'>
        <div className='d-flex align-items-center me-2'>
          <div className='form-check form-check-custom form-check-solid form-check-primary me-6'>
            <input
              className='form-check-input'
              type='radio'
              name='plan'
              defaultValue='enterprise'
              onChange={() => onChangeType('transaction')}
            />
          </div>
          <div className='flex-grow-1'>
            <h2
              className={clsx(
                'd-flex align-items-center fs-3 fw-bold flex-wrap',
                type == 'inline' && 'mb-0'
              )}>
              <FormattedMessage id='PRICING.PAYMENT.TRANSFERT_BANK' />
            </h2>
            {type == 'column' && (
              <div className='fw-semibold opacity-50'>
                <FormattedMessage id='PRICING.PAYMENT.DELAI_ACTIVATION' />
              </div>
            )}
          </div>
        </div>
      </label>
    </div>
  );
}

export default GatwayPayment;
