import React, { LazyExoticComponent, ComponentType, ComponentPropsWithRef } from 'react';
import { SuspensedView } from '../routing/PrivateRoutes';

function withRetry<T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
  retries = 3
): Promise<{ default: T }> {
  return new Promise((resolve, reject) => {
    let attempt = 0;

    function tryLoad() {
      factory()
        .then((module) => {
          resolve(module);
        })
        .catch((error) => {
          attempt++;

          if (attempt < retries) {
            tryLoad();
          } else {
            window.location.reload();
            reject(error);
          }
        });
    }

    tryLoad();
  });
}

export function lazyWithRetry<T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
  retries = 3
): LazyExoticComponent<T> {
  const LazyComponent = React.lazy(() => withRetry(factory, retries));

  const LazyComponentWithRef = (props: ComponentPropsWithRef<T>) => (
    <SuspensedView>
      <LazyComponent {...props} />
    </SuspensedView>
  );

  Object.assign(LazyComponentWithRef, {
    $$typeof: Symbol.for('react.lazy'),
    _ctor: LazyComponent,
    _status: -1,
    _result: null,
  });

  return LazyComponentWithRef as LazyExoticComponent<T>;
}
