import clsx from 'clsx';
import { createContext, FC, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  addBrand,
  addCategory,
  postTax,
  postVat,
  putTax,
  putVat,
} from '../../../../setup/redux/effects/UtilsEffects';
import {
  readBrands,
  readCategories,
  readTaxes,
  readVats,
} from '../../../../setup/redux/reducers/UtilsReducers';
import { RootState } from '../../../../setup/Store';
import CustomRadio from '../../../components/CustomRadio/CustomRadio';
import { KTSVG } from '../../../helpers';
import { Brand, Category } from '../../../models/ProductResponse';
import { Tax } from '../../Documents/models/Document';
import { Vat } from '../../Documents/models/DocumentLine';

const defaultValue = {
  isVatModalShown: false,
  hideVatModal: () => {},
  showVatModal: () => {},
  hideTaxModal: () => {},
  showTaxModal: () => {},
  showEditTaxModal: (tax: Tax) => {},
  showEditVatModal: (vat: Vat) => {},
  isTaxModalShown: false,
  isBrandModalShown: false,
  showBrandModal: (onSubmit: (brand: Brand) => void) => {},
  showCategoryModal: (onSubmit: (category: Category) => void) => {},
  isCategoryModalShown: false,
  hideCategoryModal: () => {},
  hideBrandModal: () => {},
};

export const AddProductModalsContext = createContext(defaultValue);

const AddProductModalsProvider: FC = ({ children }) => {
  const [isVatModalShown, setIsVatModalShown] = useState(false);
  const [isTaxModalShown, setisTaxModalShown] = useState(false);
  const [isBrandModalShown, setisBrandModalShown] = useState(false);
  const [isCategoryModalShown, setisCategoryModalShown] = useState(false);
  const onCategorySubmitRef = useRef<(c: Category) => void>((c: Category) => {});
  const [vatToEdit, setVatToEdit] = useState<Vat>();
  const [taxToEdit, setTaxToEdit] = useState<Tax>();
  const [vatId, setVatId] = useState<number>();
  const onBrandSubmitRef = useRef<(b: Brand) => void>((b: Brand) => {});
  const [taxId, setTaxId] = useState<number>();
  const [isSubmittingBrand, setIsSubmittingBrand] = useState(false);
  const intl = useIntl();
  const company = useSelector((state: RootState) => state.auth.user?.current_company?.id) as number;
  const dispatch = useDispatch();

  const {
    control: vatControl,
    reset: vatReset,
    handleSubmit: handleVatSubmit,
  } = useForm({ mode: 'onTouched' });
  const {
    register: taxesRegister,
    control: taxesControl,
    reset: taxesReset,
    formState: { touchedFields: taxesTouchedFields, errors: taxesErrors },
    handleSubmit: handleTaxesSubmit,
    watch: taxesWatch,
  } = useForm({ mode: 'onTouched' });
  const belongToVAT = taxesWatch('belong_vat');
  const submitVat = async (values: FieldValues) => {
    const vat: number = values.sign === '+' ? values.vat : -values.vat;
    await toast.promise(
      vatToEdit
        ? putVat({
            company,
            vat,
            id: vatId as number,
          })
        : postVat({ company, enabled: true, vat }),
      {
        success: intl.formatMessage(
          { id: 'TOAST.ADD.SUCCESS' },
          { category: intl.formatMessage({ id: 'PRODUCT.FIELD.VAT' }), name: '' }
        ),
        error: intl.formatMessage(
          { id: 'TOAST.ADD.ERROR' },
          { category: intl.formatMessage({ id: 'PRODUCT.FIELD.VAT' }), name: '' }
        ),
        pending: intl.formatMessage(
          { id: 'TOAST.ADD.PENDING' },
          { category: intl.formatMessage({ id: 'PRODUCT.FIELD.VAT' }), name: '' }
        ),
      }
    );
    dispatch(readVats());
    setVatToEdit(undefined);
    hideVatModal();
  };
  const submitTaxes = async (values: FieldValues) => {
    await toast.promise(
      taxToEdit
        ? putTax({ company, ...taxToEdit, ...values })
        : postTax({ company, enabled: true, ...values }),
      {
        success: intl.formatMessage(
          { id: 'TOAST.ADD.SUCCESS' },
          {
            category: intl.formatMessage({ id: 'PRODUCT.FIELD.TAX' }),
            name: taxToEdit?.name || taxToEdit?.label,
          }
        ),
        error: intl.formatMessage(
          { id: 'TOAST.ADD.ERROR' },
          {
            category: intl.formatMessage({ id: 'PRODUCT.FIELD.TAX' }),
            name: taxToEdit?.name || taxToEdit?.label,
          }
        ),
        pending: intl.formatMessage(
          { id: 'TOAST.ADD.PENDING' },
          {
            category: intl.formatMessage({ id: 'PRODUCT.FIELD.TAX' }),
            name: taxToEdit?.name || taxToEdit?.label,
          }
        ),
      }
    );

    dispatch(readTaxes());
    setTaxToEdit(undefined);
    hideTaxModal();
  };
  const hideVatModal = () => {
    setIsVatModalShown(false);
    setVatToEdit(undefined);
    vatReset({ sign: '+', vat: 0 }, { keepErrors: false, keepTouched: false });
  };
  const showVatModal = () => {
    vatReset({ sign: '+', vat: 0 }, { keepErrors: false, keepTouched: false });
    setIsVatModalShown(true);
  };
  const hideTaxModal = () => {
    setTaxToEdit(undefined);
    setisTaxModalShown(false);
    taxesReset(
      {
        name: '',
        label: '',
        belong_vat: false,
        is_product_tax_only: false,
        tax_sign: true,
        amount: 0,
        type: 1,
        tax_group: 1,
        is_vat_tax: true,
      },
      { keepErrors: false, keepTouched: false }
    );
  };
  const showTaxModal = () => {
    taxesReset(
      {
        name: '',
        label: '',
        belong_vat: false,
        is_product_tax_only: false,
        tax_sign: true,
        amount: 0,
        type: 1,
        tax_group: 1,
        is_vat_tax: true,
      },
      { keepErrors: false, keepTouched: false }
    );
    setisTaxModalShown(true);
  };
  const showEditTaxModal = (tax: Tax) => {
    setTaxToEdit(tax);
    setTaxId(tax.id);
    taxesReset(
      {
        name: tax.name,
        label: tax.label,
        belong_vat: tax.belong_vat,
        is_product_tax_only: tax.is_product_tax_only,
        tax_sign: tax.tax_sign,
        amount: tax.amount,
        type: tax.type,
        tax_group: tax.tax_group,
        is_vat_tax: tax.is_vat_tax,
      },
      { keepErrors: false, keepTouched: false }
    );
    setisTaxModalShown(true);
  };
  const showEditVatModal = (vat: Vat) => {
    setVatId(vat.id);
    vatReset(
      { sign: vat.vat > 0 ? '+' : '-', vat: Math.abs(vat.vat) },
      { keepErrors: false, keepTouched: false }
    );
    setIsVatModalShown(true);
    setVatToEdit(vat);
  };
  const hideCategoryModal = () => {
    setisCategoryModalShown(false);
    resetCategory(
      { label: '', restriction: '' },
      { keepValues: false, keepTouched: false, keepErrors: false }
    );
  };
  const showCategoryModal = (onSubmit: (category: Category) => void) => {
    onCategorySubmitRef.current = onSubmit;
    setisCategoryModalShown(true);
  };
  const hideBrandModal = () => {
    setisBrandModalShown(false);
    resetBrand({ name: '' }, { keepValues: false, keepTouched: false, keepErrors: false });
  };
  const showBrandModal = (onSubmit: (brand: Brand) => void) => {
    onBrandSubmitRef.current = onSubmit;
    setisBrandModalShown(true);
  };

  const handleBrandSubmit = async (values: FieldValues) => {
    addBrand({ company, name: values.name }).then(({ data }) => {
      onBrandSubmitRef?.current?.(data);
      dispatch(readBrands());
      hideBrandModal();
    });
  };
  const handleCategorySubmitAction = async (values: FieldValues) => {
    addCategory({ company, label: values.label, restriction: values.restriction }).then(
      ({ data }) => {
        onCategorySubmitRef?.current?.(data);
        dispatch(readCategories());
        hideCategoryModal();
      }
    );
  };
  const {
    handleSubmit,
    reset: resetBrand,
    control,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: { name: '' },
  });
  const {
    handleSubmit: handleCategorySubmit,
    reset: resetCategory,
    control: controlCategory,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: { label: '', restriction: '' },
  });
  const handleHideBrandModal = () => {
    resetBrand({ name: '' }, { keepValues: false, keepTouched: false, keepErrors: false });
  };
  const { formatMessage } = useIntl();

  return (
    <AddProductModalsContext.Provider
      value={{
        isVatModalShown,
        isTaxModalShown,
        isBrandModalShown,
        hideBrandModal,
        showBrandModal,
        isCategoryModalShown,
        hideCategoryModal,
        showCategoryModal,
        hideTaxModal,
        showTaxModal,
        hideVatModal,
        showVatModal,
        showEditTaxModal,
        showEditVatModal,
      }}>
      {children}
      <Modal show={isVatModalShown} onHide={hideVatModal} centered>
        <Modal.Header className='border-bottom-0'>
          <Modal.Title>
            <FormattedMessage
              id={vatToEdit ? 'TOOLBAR.ACTIONBAR.TOOLTIPS.EDIT' : 'TOOLBAR.ACTIONBAR.TOOLTIPS.ADD'}
              values={{ type: intl.formatMessage({ id: 'TVAANDTAX.VAT' }) }}
            />
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleVatSubmit(submitVat)}>
          <Modal.Body>
            <div className='row g-4'>
              <div className='col-lg-6'>
                <Controller
                  name='sign'
                  control={vatControl}
                  defaultValue={'+'}
                  render={({ field: { name, onChange, onBlur, value } }) => (
                    <div className='d-flex justify-content-center gap-3'>
                      <CustomRadio
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={'+'}
                        checked={value === '+'}>
                        +
                      </CustomRadio>
                      <CustomRadio
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={'-'}
                        checked={value === '-'}>
                        -
                      </CustomRadio>
                    </div>
                  )}
                />
              </div>
              <div className='col-lg-6'>
                <Controller
                  name='vat'
                  control={vatControl}
                  defaultValue={0}
                  rules={{
                    min: { value: 0, message: 'FORM.ERRORS.MIN' },
                    required: 'FORM.ERRORS.REQUIRED',
                  }}
                  render={({
                    field: { name, value, onBlur, onChange },
                    fieldState: { isTouched, error },
                  }) => (
                    <>
                      <div className='d-flex gap-3 align-items-center'>
                        <div className='input-group'>
                          <span
                            className='input-group-text py-0 px-1 cursor-pointer'
                            onClick={() => {
                              onChange(Math.max(value - 1, 0));
                            }}>
                            <KTSVG
                              className='svg-icon svg-icon-2hx'
                              path={'/media/icons/duotune/general/gen036.svg'}
                            />
                          </span>
                          <input
                            type='number'
                            step='any'
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            min={0}
                            className={clsx(
                              'form-control form-control-solid flex-grow-1 text-center'
                            )}
                            autoComplete='off'
                          />
                          <span
                            className='input-group-text py-0 px-1 cursor-pointer'
                            onClick={() => {
                              onChange(value + 1);
                            }}>
                            <KTSVG
                              className='svg-icon svg-icon-2hx'
                              path={'/media/icons/duotune/general/gen035.svg'}
                            />
                          </span>
                        </div>
                        <div className='fs-2 fw-bold'>%</div>
                      </div>
                      {isTouched && error && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger'>
                            <span role='alert'>
                              <FormattedMessage
                                id={error?.message}
                                values={{
                                  field: intl.formatMessage({ id: 'PRODUCT.FIELD.VAT' }),
                                  min: 0,
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className='btn' type='button' onClick={hideVatModal}>
              <FormattedMessage id='FORM.CANCEL' />
            </button>
            <button className='btn btn-primary' type='submit'>
              <FormattedMessage id='FORM.SUBMIT' />
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal
        show={isTaxModalShown}
        dialogClassName={'w-auto mw-md-600px mw-lg-850px'}
        onHide={hideTaxModal}
        centered>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id='FORM.TAXES.ADD.TITLE' />
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleTaxesSubmit(submitTaxes)}>
          <Modal.Body>
            <div className='row p-4'>
              <div className='col-lg-6 mb-4'>
                <label className='form-label required mb-3'>
                  <FormattedMessage id='FORM.FIELD.NAME' />
                </label>
                <input
                  type='text'
                  {...taxesRegister('name', { required: 'FORM.ERRORS.REQUIRED' })}
                  className={clsx('form-control form-control-solid')}
                  autoComplete='off'
                />
                {taxesTouchedFields?.name && taxesErrors?.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>
                      <span role='alert'>
                        <FormattedMessage
                          id={'' + taxesErrors.name?.message}
                          values={{
                            field: intl.formatMessage({ id: 'FORM.FIELD.NAME' }),
                          }}
                        />
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 mb-4'>
                <label className='form-label required mb-3'>
                  <FormattedMessage id='FORM.FIELD.LABEL' />
                </label>
                <input
                  type='text'
                  {...taxesRegister('label', { required: 'FORM.ERRORS.REQUIRED' })}
                  className={clsx('form-control form-control-solid')}
                  autoComplete='off'
                />
                {taxesTouchedFields?.label && taxesErrors?.label && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>
                      <span role='alert'>
                        <FormattedMessage
                          id={'' + taxesErrors.label?.message}
                          values={{
                            field: intl.formatMessage({ id: 'FORM.FIELD.LABEL' }),
                          }}
                        />
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className='mb-4'>
                <label className='form-label required mb-3'>
                  <FormattedMessage id='FORM.FIELD.TYPE' />
                </label>
                <div className='row fv-row'>
                  <div className='col-sm-6 col-lg-4 mb-4 mb-lg-0'>
                    <Controller
                      name='tax_sign'
                      control={taxesControl}
                      defaultValue={true}
                      render={({
                        field: { name, onBlur, onChange, value },
                        fieldState: { invalid, isTouched, error },
                      }) => (
                        <>
                          <div className='d-flex gap-3'>
                            <CustomRadio
                              name={name}
                              onChange={() => {
                                onChange(true);
                              }}
                              onBlur={onBlur}
                              checked={value === true || value === 'true'}
                              value={true}>
                              +
                            </CustomRadio>
                            <CustomRadio
                              name={name}
                              onChange={() => {
                                onChange(false);
                              }}
                              onBlur={onBlur}
                              checked={value === false || value === 'false'}
                              value={false}>
                              -
                            </CustomRadio>
                          </div>
                          {isTouched && invalid && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger'>
                                <span role='alert'>
                                  <FormattedMessage
                                    id={error?.message}
                                    values={{
                                      field: intl.formatMessage({ id: 'FORM.FIELD.TYPE' }),
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                  <div className='col-sm-6 col-lg-4 mb-4 mb-sm-0'>
                    <Controller
                      name='amount'
                      control={taxesControl}
                      defaultValue={0}
                      rules={{
                        required: 'FORM.ERRORS.REQUIRED',
                        pattern: {
                          value: /^-?\d+(?:\.\d+)?$/,
                          message: 'FORM.ERRORS.NUMBER',
                        },
                        validate: (value) => value >= 0 || 'FORM.ERRORS.GREATER_THAN',
                      }}
                      render={({
                        field: { name, value, onBlur, onChange },
                        fieldState: { invalid, isTouched, error },
                      }) => (
                        <>
                          <div className='d-flex gap-3 align-items-center'>
                            <div className='input-group'>
                              <span
                                className='input-group-text py-0 px-1 cursor-pointer'
                                onClick={() => {
                                  onChange(Math.max(value - 1, 0));
                                }}>
                                <KTSVG
                                  className='svg-icon svg-icon-2hx'
                                  path={'/media/icons/duotune/general/gen036.svg'}
                                />
                              </span>
                              <input
                                type='number'
                                name={name}
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                min={0}
                                step='any'
                                className={clsx(
                                  'form-control form-control-solid flex-grow-1 text-center'
                                )}
                                autoComplete='off'
                              />
                              <span
                                className='input-group-text py-0 px-1 cursor-pointer'
                                onClick={() => {
                                  onChange(value + 1);
                                }}>
                                <KTSVG
                                  className='svg-icon svg-icon-2hx'
                                  path={'/media/icons/duotune/general/gen035.svg'}
                                />
                              </span>
                            </div>
                          </div>
                          {isTouched && invalid && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger'>
                                <span role='alert'>
                                  <FormattedMessage
                                    id={error?.message}
                                    values={{
                                      field: intl.formatMessage({ id: 'FORM.FIELD.AMOUNT' }),
                                      min: 0,
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                  <div className='col-lg-4'>
                    <Controller
                      name='type'
                      control={taxesControl}
                      defaultValue={2}
                      rules={{ required: 'FORM.ERRORS.REQUIRED' }}
                      render={({
                        field: { name, onBlur, onChange, value },
                        fieldState: { invalid, isTouched, error },
                      }) => (
                        <>
                          <div className='d-flex gap-3'>
                            <CustomRadio
                              name={name}
                              value={2}
                              checked={value === 2 || value === '2'}
                              onBlur={onBlur}
                              onChange={onChange}>
                              <FormattedMessage id='FORM.TYPE.PERCENT' />
                            </CustomRadio>
                            <CustomRadio
                              name={name}
                              value={1}
                              checked={value === 1 || value === '1'}
                              onBlur={onBlur}
                              onChange={onChange}>
                              <FormattedMessage id='FORM.TYPE.FIXED' />
                            </CustomRadio>
                          </div>
                          {isTouched && invalid && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block text-danger'>
                                <span role='alert'>
                                  <FormattedMessage
                                    id={error?.message}
                                    values={{
                                      field: intl.formatMessage({ id: 'FORM.FIELD.TYPE' }),
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className='mb-4'>
                <div className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    {...taxesRegister('belong_vat')}
                    id='taxes-applied-to-vat'
                  />
                  <label className='form-check-label' htmlFor='taxes-applied-to-vat'>
                    <FormattedMessage id='FORM.FIELD.TAXES.APPLIED-TO-VAT' />
                  </label>
                </div>
              </div>
              <div className='mb-4'>
                <label className='form-label required mb-3'>
                  <FormattedMessage id='FORM.TAXES.USE' />
                </label>
                <Controller
                  name='tax_group'
                  control={taxesControl}
                  defaultValue={1}
                  render={({
                    field: { name, onBlur, onChange, value },
                    fieldState: { invalid, isTouched, error },
                  }) => (
                    <>
                      <div className='d-flex gap-3'>
                        <CustomRadio
                          name={name}
                          value={1}
                          checked={value === 1 || value === '1'}
                          onChange={onChange}
                          onBlur={onBlur}>
                          <FormattedMessage id='FORM.TAXES.USES.ALL' />
                        </CustomRadio>
                        <CustomRadio
                          name={name}
                          value={2}
                          checked={value === 2 || value === '2'}
                          onChange={onChange}
                          onBlur={onBlur}>
                          <FormattedMessage id='FORM.TAXES.USES.SALE' />
                        </CustomRadio>
                        <CustomRadio
                          name={name}
                          value={3}
                          checked={value === 3 || value === '3'}
                          onChange={onChange}
                          onBlur={onBlur}>
                          <FormattedMessage id='COMMON.PURCHASE' />
                        </CustomRadio>
                      </div>
                      {isTouched && invalid && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger'>
                            <span role='alert'>
                              <FormattedMessage
                                id={error?.message}
                                values={{
                                  field: intl.formatMessage({ id: 'FORM.TAXES.USES' }),
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
              {!belongToVAT && (
                <div className='mb-4'>
                  <label className='form-label required mb-3'>
                    <FormattedMessage id='FORM.FIELD.APPLICATION' />
                  </label>
                  <Controller
                    name='is_vat_tax'
                    control={taxesControl}
                    defaultValue={true}
                    render={({
                      field: { name, onBlur, onChange, value },
                      fieldState: { invalid, isTouched, error },
                    }) => (
                      <>
                        <div className='d-flex gap-3'>
                          <CustomRadio
                            name={name}
                            value={false}
                            checked={value === false}
                            onChange={() => {
                              onChange(false);
                            }}
                            onBlur={onBlur}>
                            <FormattedMessage id='FORM.FIELD.APPLICATIONS.BEFORE' />
                          </CustomRadio>
                          <CustomRadio
                            name={name}
                            value={true}
                            checked={value === true}
                            onChange={() => {
                              onChange(true);
                            }}
                            onBlur={onBlur}>
                            <FormattedMessage id='FORM.FIELD.APPLICATIONS.AFTER' />
                          </CustomRadio>
                        </div>
                        {invalid && isTouched && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger'>
                              <span role='alert'>
                                <FormattedMessage
                                  id={error?.message}
                                  values={{
                                    field: intl.formatMessage({ id: 'FORM.FIELD.APPLICATION' }),
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
              )}
              {!belongToVAT && (
                <div>
                  <div className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      {...taxesRegister('is_product_tax_only')}
                      id='taxes-on-products'
                    />
                    <label className='form-check-label' htmlFor='taxes-on-products'>
                      <FormattedMessage id='FORM.TAXES.TAX-ON-PRODUCTS' />
                    </label>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className='border-top-0 py-2'>
            <button className='btn' type='button' onClick={hideTaxModal}>
              <FormattedMessage id='FORM.CANCEL' />
            </button>
            <button className='btn btn-primary' type='submit'>
              <FormattedMessage id='FORM.SUBMIT' />
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal show={isBrandModalShown} onHide={hideBrandModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id={'PRODUCT.ADD.BRAND'} />
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(handleBrandSubmit)}>
          <Modal.Body>
            <div className='row'>
              <div>
                <label className='form-label required mb-3'>
                  <FormattedMessage id='FORM.FIELD.NAME' />
                </label>
                <Controller
                  name='name'
                  control={control}
                  rules={{ required: 'FORM.ERRORS.REQUIRED' }}
                  render={({ field, fieldState: { error, isTouched } }) => (
                    <>
                      <input
                        type='text'
                        className={clsx('form-control form-control-solid', {
                          'is-valid': isTouched && !error,
                          'is-invalid': isTouched && error,
                        })}
                        {...field}
                      />
                      {error && isTouched && (
                        <div className='text-danger mt-3'>
                          <span role='alert'>
                            <FormattedMessage
                              id={error.message}
                              values={{
                                field: formatMessage({ id: 'FORM.FIELD.NAME' }),
                              }}
                            />
                          </span>
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='btn btn-light' onClick={handleHideBrandModal}>
              <FormattedMessage id='FORM.CLOSE' />
            </div>
            <button disabled={isSubmittingBrand} type='submit' className='btn btn-primary'>
              {isSubmittingBrand && <span className='spinner-border align-middle me-2'></span>}
              <FormattedMessage id='FORM.SUBMIT' />
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal show={isCategoryModalShown} onHide={hideCategoryModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id={'PRODUCT.ADD.CATEGORY'} />
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleCategorySubmit(handleCategorySubmitAction)}>
          <Modal.Body>
            <div className='row g-4'>
              <div>
                <label className='form-label required mb-3'>
                  <FormattedMessage id='FORM.FIELD.NAME' />
                </label>
                <Controller
                  name='label'
                  control={controlCategory}
                  rules={{ required: 'FORM.ERRORS.REQUIRED' }}
                  render={({ field, fieldState: { error, isTouched } }) => (
                    <>
                      <input
                        type='text'
                        className={clsx('form-control form-control-solid', {
                          'is-valid': isTouched && !error,
                          'is-invalid': isTouched && error,
                        })}
                        {...field}
                      />
                      {error && isTouched && (
                        <div className='text-danger mt-3'>
                          <span role='alert'>
                            <FormattedMessage
                              id={error.message}
                              values={{
                                field: formatMessage({ id: 'FORM.FIELD.NAME' }),
                              }}
                            />
                          </span>
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
              <div>
                <label className='form-label required mb-3'>
                  <FormattedMessage id='FORM.FIELD.TYPE' />
                </label>
                <div className='d-flex align-items-center flex-wrap gap-3'>
                  <Controller
                    control={controlCategory}
                    name='restriction'
                    render={({
                      field: { name, onBlur, onChange, value },
                      fieldState: { invalid, isTouched, error },
                    }) => (
                      <>
                        <CustomRadio
                          name={name}
                          value=''
                          onBlur={onBlur}
                          onChange={onChange}
                          checked={value === ''}>
                          <FormattedMessage id='COMMON.PURCHASE&SALE' />
                        </CustomRadio>
                        <CustomRadio
                          name={name}
                          value='purchase'
                          onBlur={onBlur}
                          onChange={onChange}
                          checked={value === 'purchase'}>
                          <FormattedMessage id='PERMISSION.CATEGORY.PURCHASE' />
                        </CustomRadio>
                        <CustomRadio
                          name={name}
                          value='sale'
                          onBlur={onBlur}
                          onChange={onChange}
                          checked={value === 'sale'}>
                          <FormattedMessage id='FORM.TAXES.USES.SALE' />
                        </CustomRadio>
                      </>
                    )}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='btn btn-light' onClick={hideCategoryModal}>
              <FormattedMessage id='FORM.CLOSE' />
            </div>
            <button disabled={isSubmittingBrand} type='submit' className='btn btn-primary'>
              {isSubmittingBrand && <span className='spinner-border align-middle me-2'></span>}
              <FormattedMessage id='FORM.SUBMIT' />
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </AddProductModalsContext.Provider>
  );
};
export default AddProductModalsProvider;
