import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createIntl, createIntlCache } from 'react-intl';
import { toast } from 'react-toastify';
import { Supplier } from '../../../app/models/SupplierResponse';
import { capitalize } from '../../../app/utils/utils';
import store from '../../Store';
import { getSuppliers, postSupplier, putSupplier } from '../effects/SupplierEffects';

type SupplierQuery = {
  type?: string;
  operator?: string;
  turnover?: string;
  registration?: string;
  responsable?: string;
  page?: number;
  query?: string;
  limit?: number;
  enabled?: boolean;
};

export const readSuppliers = createAsyncThunk(
  'supplier/fetch',
  async (payload: SupplierQuery | any, thunkAPI) => {
    const state = store?.getState();
    const supplier = state.supplier;

    try {
      const { data } = await getSuppliers({
        company: state?.auth?.user?.current_company?.id,
        enabled: payload.enabled,
        offset: payload?.page ? payload.page * supplier.limit - supplier.limit : 0,
        limit: supplier.limit || 10,
        query: payload?.queryString || '',
        operator: payload?.operator || '',
        turnover: payload?.turnover || '',
        registration: payload?.registration || '',
        responsable: payload?.responsable || '',
        in_credit: payload?.inCredit,
        date_start: payload?.dateStart || '',
        date_end: payload?.dateEnd || '',
        company_name: payload?.companyName || '',
      });
      return data;
    } catch (error: any) {
      thunkAPI.rejectWithValue(
        error?.response?.data.message || error?.toJSON()?.message || 'Error'
      );
    }
  }
);
export const updateSupplier = createAsyncThunk(
  'supplier/update',
  async (payload: Supplier, thunkAPI) => {
    const state = store?.getState();
    const cache = createIntlCache();
    const intl = createIntl({ locale: state.utils.locale, messages: state.utils.messages }, cache);
    try {
      await toast.promise(putSupplier(payload), {
        pending: capitalize(
          intl.formatMessage(
            { id: payload.enabled ? 'TOAST.DISABLE.PENDING' : 'TOAST.ENABLE.PENDING' },
            { name: payload.name, category: intl.formatMessage({ id: 'SUPPLIER.NAME' }) }
          )
        ),
        error: capitalize(
          intl.formatMessage(
            { id: payload.enabled ? 'TOAST.DISABLE.ERROR' : 'TOAST.ENABLE.ERROR' },
            { name: payload.name, category: intl.formatMessage({ id: 'SUPPLIER.NAME' }) }
          )
        ),
        success: capitalize(
          intl.formatMessage(
            { id: payload.enabled ? 'TOAST.DISABLE.SUCCESS' : 'TOAST.ENABLE.SUCCESS' },
            { name: payload.name, category: intl.formatMessage({ id: 'SUPPLIER.NAME' }) }
          )
        ),
      });
    } catch (error: any) {
      thunkAPI.rejectWithValue(
        error?.response?.data?.message || error?.toJSON()?.message || 'Error'
      );
    }
  }
);
export const createSupplier = createAsyncThunk(
  'supplier/create',
  async (payload: Supplier, thunkAPI) => {
    try {
      const state = store.getState();
      const cache = createIntlCache();
      const intl = createIntl(
        { locale: state.utils.locale, messages: state.utils.messages },
        cache
      );
      await toast.promise(
        postSupplier({
          ...payload,
          company: store.getState().auth.user?.current_company?.id,
        }),
        {
          pending: capitalize(
            intl.formatMessage(
              { id: 'TOAST.ADD.PENDING' },
              { name: payload.name, category: intl.formatMessage({ id: 'SUPPLIER.NAME' }) }
            )
          ),
          error: capitalize(
            intl.formatMessage(
              { id: 'TOAST.ADD.ERROR' },
              { name: payload.name, category: intl.formatMessage({ id: 'SUPPLIER.NAME' }) }
            )
          ),
          success: capitalize(
            intl.formatMessage(
              { id: 'TOAST.ADD.SUCCESS' },
              { name: payload.name, category: intl.formatMessage({ id: 'SUPPLIER.NAME' }) }
            )
          ),
        }
      );
    } catch (error: any) {
      thunkAPI.rejectWithValue(
        error?.response?.data?.message || error?.toJSON()?.message || 'Error'
      );
    }
  }
);

const supplierSlice = createSlice({
  name: 'supplier',
  initialState: {
    count: 0,
    countArchived: 0,
    countEnabled: 0,
    suppliers: [] as Supplier[],
    loading: false,
    offset: 0,
    limit: 10,
    enabled: true,
  },
  reducers: {
    changeLimit: (state, action) => {
      state.limit = action.payload;
      state.offset = 0;
    },
    setEnabled: (state, action) => {
      state.enabled = action.payload as boolean;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(readSuppliers.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(readSuppliers.fulfilled, (state, action) => {
      state.loading = false;
      state.suppliers = action.payload?.rows || [];
      state.count = action.payload?.count || 0;
      state.countArchived = Number(action.payload?.countArchived || '') || 0;
      state.countEnabled = Number(action.payload?.countEnabled || '') || 0;

      const args = action.meta.arg;

      state.offset = args.page ? args.page * state.limit - state.limit : 0;
    });
    builder.addCase(readSuppliers.rejected, (state, action) => {
      state.loading = false;
      state.suppliers = [];
      state.count = 0;
      state.countArchived = 0;
      state.countEnabled = 0;
    });
  },
});

export default supplierSlice.reducer;
export const { changeLimit, setEnabled } = supplierSlice.actions;
