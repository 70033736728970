import axios from 'axios';
import { StateTransition, SwiverDocument } from '../../../app/pages/Documents/models/Document';
import store from '../../Store';

type CreateDraftPayload = {
  type: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  pos?: boolean;
};

export const postDraft = (payload: CreateDraftPayload) => {
  const state = store.getState();
  const currentCompany = state.auth.user?.current_company.id;
  return axios.post('/secure/document/draft', { ...payload, company: currentCompany });
};

export const postClone = (id: number | string) => {
  return axios.post('/secure/document/duplicate', { id: id });
};
export const getDocument = (id: number | string) => {
  return axios.get<SwiverDocument>(`/secure/document/${id}`);
};
export const uploadAttachmentDocument = (id: number | string, files: any[]) => {
  const formData = new FormData();
  files.forEach((file, index) => {
    formData.append(`attachment${index + 1}`, file);
  });
  return axios.post(`/secure/document/${id}/attachments`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const downloadAttachmentDocument = (attachmentId: number | string) => {
  return axios.get(`/secure/s3/attachment/${attachmentId}`, {
    responseType: 'blob',
  });
};
export const deleteAttachmentDocument = (attachmentId: number | string) => {
  return axios.delete(`/secure/s3/attachment/${attachmentId}`);
};
export const putDocument = (
  payload: SwiverDocument,
  id: number | string,
  version: number | string
) => {
  return axios.put<SwiverDocument>(`/secure/document/${id}/${version}`, payload);
};

export const putReceipt = (payload: any, id: number | string, version: number | string) => {
  return axios.put<any>(`/secure/receipt/${id}/${version}`, payload);
};
export const putDocumentState = (
  id: number | string,
  version: number | string,
  state: string,
  ignoreReference = false
) => {
  if (!ignoreReference)
    return axios.put<
      | {
          reference: string;
          state_transitions: StateTransition[];
          version: number;
        }
      | boolean
      | string
    >(`/secure/document/${id}/${version}/state`, { transition: state });
  return axios.put<
    | {
        reference: string;
        state_transitions: StateTransition[];
        version: number;
      }
    | boolean
    | string
  >(`/secure/document/${id}/${version}/state`, { transition: state, ignoreReference });
};

export const verifyListInventory = (file: any, onUploadProgress: (progressEvent: any) => void) => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post(`/secure/inventory/import/verify`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
};
export const importInventory = (file: string, warehouse?: number) => {
  //send in query param file_name
  return axios.post(`/secure/inventory/import`, null, {
    params: { file_name: file, warehouse: warehouse || null },
  });
};

export const importInvoiceNoon = (file: any, warehouse?: number) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('warehouse', warehouse + '');
  return axios.post(`/secure/invoice/import`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const importSwiverDocument = (from: number | string, to: number | string) => {
  return axios.post<SwiverDocument>(`/secure/document/import_swiver/${from}/${to}`, {});
};
