import clsx from 'clsx';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { GroupBase, InputActionMeta, OptionProps } from 'react-select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { RootState } from '../../../../setup/Store';
import { undelayedGetSuppliers } from '../../../../setup/redux/effects/SupplierEffects';
import SwiverSelect from '../../../components/SwiverSelect/SwiverSelect';
import { KTSVG } from '../../../helpers';
import { Supplier } from '../../../models/SupplierResponse';

type selectOption = { value: number; label: string };
type OptionType = { data: Supplier; value: string | number; label: string };
function AddDepenseStep2() {
  const [suppliers, setSuppliers] = useState<Supplier[]>();
  const company = useSelector((state: RootState) => state.auth.user?.current_company.id);
  const [suppliersOptions, setSuppliersOptions] = useState<FilterOptionOption<Supplier>[]>([]);
  const locale = useSelector((state: RootState) => state.utils.locale);
  const [expenseCategoriesSelectOption, setExpenseCategoriesSelectOption] = useState<
    selectOption[]
  >([]);
  const fetchSuppliers = async (query: string) => {
    const {
      data: { rows },
    } = await undelayedGetSuppliers({ enabled: true, limit: 10, offset: 0, company, query });
    setSuppliers(rows);
  };
  useEffect(() => {
    if (suppliers === undefined) return;
    setSuppliersOptions(
      suppliers?.map((supplier) => ({
        data: supplier,
        value: supplier.id as string,
        label: supplier.company_name,
      }))
    );
  }, [suppliers]);
  const changeHandler = (event: string, meta: InputActionMeta) => {
    if (meta.action === 'input-change') {
      fetchSuppliers(event);
    }
  };
  const debouncedChangeHandler = useCallback(debounce(changeHandler, 1000), []);
  const {
    watch,
    setValue,
    register,
    formState: { errors },
  } = useFormContext();
  const { formatMessage } = useIntl();
  const expenseCategoriesState = useSelector((state: RootState) => state?.utils?.expenseCategories);
  const dispatch = useDispatch();
  const expenseCategory = watch('expenseCategory');
  useEffect(() => {
    if (expenseCategoriesState === undefined) return;
    const expensesOptions = Array.from(expenseCategoriesState, (expenseCategory) => ({
      label: expenseCategory.name,
      value: expenseCategory.id,
    }));
    setExpenseCategoriesSelectOption(expensesOptions);
    if (!expenseCategory) setValue('expenseCategory', expensesOptions[0]?.value);
  }, [expenseCategoriesState]);
  return (
    <div className='w-100'>
      <div className='mb-10 fv-row fv-plugins-icon-container'>
        <div className='form-label required mb-3'>
          <FormattedMessage id='COMMON.CATEGORY' />
        </div>
        <Controller
          name='expenseCategory'
          render={({
            fieldState: { isTouched, error },
            field: { value, onChange, onBlur, name },
          }) => (
            <>
              <SwiverSelect
                className='swiver-select-solid'
                options={expenseCategoriesSelectOption}
                name={name}
                onChange={(e) => {
                  onChange(e?.value || '');
                }}
                onBlur={onBlur}
                value={value}
              />

              {error && isTouched && (
                <div className='text-danger'>
                  <span role='alert'>
                    <FormattedMessage
                      id={error.message + ''}
                      values={{ field: formatMessage({ id: 'COMMON.CATEGORY' }) }}
                    />
                  </span>
                </div>
              )}
            </>
          )}
        />
      </div>
      <div className='mb-10 fv-row fv-plugins-icon-container'>
        <label className='form-label required mb-3'>
          <FormattedMessage id='SUPPLIER.NAME' />
        </label>
        <Controller
          name='contact'
          render={({
            field: { value, name, onChange, onBlur },
            fieldState: { error, isTouched },
          }) => (
            <>
              <SwiverSelect
                name={name}
                className='swiver-select-solid'
                onInputChange={debouncedChangeHandler}
                options={suppliersOptions}
                filterOption={(option: FilterOptionOption<OptionType>, searchText) =>
                  option.data?.data?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
                  option.data?.data?.company_name
                    ?.toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                  option.data?.data?.reference?.toLowerCase().includes(searchText.toLowerCase())
                }
                components={{
                  Option: CustomOption,
                }}
                onChange={(e) => {
                  onChange(e ? e?.value : '');
                }}
                onMenuOpen={() => {
                  if (suppliers !== undefined) return;
                  fetchSuppliers('');
                }}
                onBlur={onBlur}
                value={value}
              />
              {/* {error && isTouched && } */}
              {error && isTouched && (
                <div className='text-danger'>
                  <span role='alert'>
                    <FormattedMessage
                      id={error.message + ''}
                      values={{
                        field: formatMessage({ id: 'SUPPLIER.NAME' }),
                      }}
                    />
                  </span>
                </div>
              )}
            </>
          )}
        />
      </div>
    </div>
  );
}
const CustomOption: React.ComponentType<
  OptionProps<OptionType, boolean, GroupBase<OptionType>>
> = ({ innerRef, innerProps, data, isFocused, isSelected }) => (
  <div ref={innerRef} {...innerProps}>
    <div
      className={clsx(
        'd-flex gap-3 p-2 cursor-pointer align-items-center gap-3',
        isFocused && !isSelected && 'bg-light-primary text-primary active',
        isSelected && 'bg-primary text-white'
      )}>
      <KTSVG
        className={clsx('svg-icon-2 ps-2', isFocused && !isSelected && 'svg-icon-primary')}
        path={
          data.data.type === 0
            ? '/media/icons/swiver/swiver-client.svg'
            : '/media/icons/swiver/swiver-enterprise.svg'
        }
      />
      <div className='flex-grow-1 fs-5  ms-8'>
        {data.data.name || data.data.company_name || '--'}
      </div>
    </div>
  </div>
);

export { AddDepenseStep2 };
