import { useSelector } from 'react-redux';
import { RootState } from '../../../../setup/Store';
import { KTSVG } from '../../../helpers';

export default function DocumentsActions() {
  const documentActions = useSelector((state: RootState) => state.page.documentsActions);
  return (
    <div className='d-flex gap-3 align-items-center ms-auto'>
      <div onClick={documentActions.actions.approveAndPreview} className='btn btn-sm btn-success'>
        <KTSVG path='/media/icons/duotune/general/gen028.svg'></KTSVG>
        <span className='d-none d-md-inline-block'>Duplicate</span>
      </div>
      <div onClick={documentActions.actions.return} className='btn btn-sm btn-sw-primary'>
        {documentActions.labels.return}
      </div>
      <div onClick={documentActions.actions.cancel} className='btn btn-sm btn-sw-danger'>
        {documentActions.labels.cancel}
      </div>
      <div
        onClick={documentActions.actions.approveAndPreview}
        className='btn btn-sm btn-sw-primary'>
        {documentActions.labels.approveAndPreview}
      </div>
    </div>
  );
}
