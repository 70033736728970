import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../setup/Store';
import { readCountries, readCurrencies } from '../../../setup/redux/reducers/UtilsReducers';
import ModalCropper from '../../components/ModalCropper/ModalCropper';
import SwiverSelect from '../../components/SwiverSelect/SwiverSelect';
import { toAbsoluteUrl } from '../../helpers';

type option = {
  label: string;
  value: string;
};
function Step1Wth() {
  const { register, setValue, watch } = useFormContext();
  const currentCurrency = watch('step2.currency');
  const currentCountry = watch('step2.country');
  const logo = watch('logo');

  const dispatch = useDispatch();
  const countries = useSelector((state: RootState) => state.utils.countries);
  const currencies = useSelector((state: RootState) => state.utils.currencies);
  const [countryOptions, setCountryOptions] = useState<option[]>([]);
  const [currencyOptions, setCurrencyOptions] = useState<option[]>([]);
  const currentCompany = useSelector((state: RootState) => state.auth.user?.current_company);
  useEffect(() => {
    if (countries === undefined) {
      dispatch(readCountries());
    }
    if (!currencies.length) {
      dispatch(readCurrencies());
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countries?.length) {
        const options = countries.map((country) => ({
          value: country.code,
          label: country.country,
        }));

        if (!currentCountry)
          setValue(
            'step2.country',
            options.find((option) => option.value === currentCompany?.setting.country)?.value
          );
        setCountryOptions(options);
        clearInterval(interval);
      } else {
        dispatch(readCountries());
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [countries]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (currencies?.length) {
        const options = currencies.map((currency) => ({
          value: currency.code,
          label: `${currency.code} - ${currency.currency}`,
        }));
        if (!currentCurrency)
          setValue(
            'step2.currency',
            options.find((option) => option.value === currentCompany?.setting.default_currency)
              ?.value
          );
        setCurrencyOptions(options);
        clearInterval(interval);
      } else {
        dispatch(readCurrencies());
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [currencies]);
  const onCropImage = (image: any) => {
    setValue('logo', image);
  };

  const intl = useIntl();
  return (
    <>
      <div className='animate__animated animate__fadeInLeft animate__fast'>
        {' '}
        <h1>
          <FormattedMessage id='COMMON.GREETING' />!
        </h1>
        <p>
          <FormattedMessage id='WALKTHROUGHT.STEP1_DESC' />
        </p>
      </div>

      <div className='px-0 px-md-20 py-10 row g-6 animate__animated animate__fadeInRight animate__fast min-h-400px'>
        <div className='col-12'>
          <div className='d-flex gap-2'>
            <div className='image-input '>
              <img
                src={logo || toAbsoluteUrl('/media/logos/placeHolder.png')}
                className='image-input-wrapper '
                width='73px'
                alt={`logo_${currentCompany?.name}`}
              />
              <ModalCropper onCropImage={onCropImage}></ModalCropper>
            </div>
            <div className='flex-fill'>
              <div className='fv-row '>
                <label className='required fw-semibold fs-6 mb-2'>
                  <FormattedMessage id='WALKTHROUGHT.ENTREPRISE.NAME' />{' '}
                </label>

                <input
                  type='text'
                  className='form-control '
                  {...register('step2.company_name', {
                    required: true,
                  })}
                  placeholder={intl.formatMessage({
                    id: 'WALKTHROUGHT.TYPE.ENTREPRISE.NAME',
                  })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 '>
          <label className='fw-semibold fs-6 mb-2'>
            <FormattedMessage id='CUSTOMER.FIELD.REGISTRAION.PROFESSIONAL' />{' '}
          </label>

          <input
            type='text'
            className='form-control '
            {...register('step2.tradeRegister')}
            placeholder={intl.formatMessage({
              id: 'WALKTHROUGHT.TYPE.TAX.ID',
            })}
          />
        </div>
        <div className='col-6 '>
          <label className='required fw-semibold fs-6 mb-2'>
            <FormattedMessage id='FORM.FIELD.COUNTRY' />{' '}
          </label>

          <Controller
            name='step2.country'
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <SwiverSelect
                menuPlacement='top'
                isClearable={false}
                options={countryOptions}
                value={value}
                onChange={(e) => {
                  onChange(e.value);
                }}
              />
            )}
          />
        </div>
        <div className='col-6'>
          <label className='required fw-semibold fs-6 mb-2'>
            <FormattedMessage id='FORM.FIELD.MAIN.CURRENCY' />{' '}
          </label>

          <Controller
            name='step2.currency'
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <>
                <SwiverSelect
                  menuPlacement='top'
                  isClearable={false}
                  options={currencyOptions}
                  value={value}
                  onChange={(e) => {
                    onChange(e.value);
                  }}
                />
              </>
            )}
          />
        </div>
        <div className='col-12'>
          <span>
            <i className='me-2 text-primary fa-light fa-lightbulb'></i>
            <FormattedMessage id='WALKTHROUGHT.HINT' />.
          </span>
        </div>
      </div>
    </>
  );
}

export default Step1Wth;
