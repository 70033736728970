import { useEffect, useLayoutEffect, useRef } from 'react';
import {
  ToggleComponent,
  ScrollTopComponent,
  StickyComponent,
  DrawerComponent,
  MenuComponent,
  ScrollComponent,
  SwapperComponent,
} from '../utils/ts/components';
import { useLayout } from './core';

export function MasterInit() {
  const { config } = useLayout();
  const isFirstRun = useRef(true);
  const isFirstRender = useRef(true);
  const pluginsInitialization = () => {
    isFirstRun.current = false;
    setTimeout(() => {
      ToggleComponent.bootstrap();
      ScrollTopComponent.bootstrap();
      StickyComponent.bootstrap();
      DrawerComponent.bootstrap();
      MenuComponent.bootstrap();
      ScrollComponent.bootstrap();
      SwapperComponent.bootstrap();
    }, 750);
  };
  const pluginsReInitialization = () => {
    setTimeout(() => {
      ScrollComponent.reinitialization();
      DrawerComponent.reinitialization();
      StickyComponent.reInitialization();
      ScrollTopComponent.reinitialization();
      SwapperComponent.reinitialization();
    }, 2500);
  };

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      pluginsInitialization();
    }
  }, [config]);
  useLayoutEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;

      pluginsReInitialization();
    }
  }, [config]);

  return <></>;
}
