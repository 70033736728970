import { configureStore } from '@reduxjs/toolkit';
import AuthReducer from './redux/reducers/AuthReducer';
import BalanceReducer from './redux/reducers/BalanceReducer';
import ChecksAndDraftsReducer from './redux/reducers/ChecksAndDraftsReducer';
import CustomerReducer from './redux/reducers/CustomersReducer';
import DocumentReducer from './redux/reducers/DocumentReducer';
import DocumentsReducer from './redux/reducers/ListDocumentReducer';
import PageReducer from './redux/reducers/PageReducer';
import ProductReducer from './redux/reducers/ProductsReducer';
import SubscriptionsREducer from './redux/reducers/SubscriptionsReducer';
import SupplierReducer from './redux/reducers/SuppliersReducer';
import UtilsReducer from './redux/reducers/UtilsReducers';
import PermissionsReducer from './redux/reducers/settings/PermissionsReducer';
import UsersReducer from './redux/reducers/settings/UsersReducer';
const store = configureStore({
  reducer: {
    auth: AuthReducer,
    utils: UtilsReducer,
    customer: CustomerReducer,
    page: PageReducer,
    product: ProductReducer,
    supplier: SupplierReducer,
    users: UsersReducer,
    permissions: PermissionsReducer,
    document: DocumentReducer,
    documents: DocumentsReducer,
    balance: BalanceReducer,
    checksAndDrafts: ChecksAndDraftsReducer,
    subscription: SubscriptionsREducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== 'production',
});
export type RootState = ReturnType<typeof store.getState>;
export default store;
