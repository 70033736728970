import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrentCompany } from '../../../app/models/currentCompanyResponse';
import { SignUpRequest } from '../../../app/modules/auth/models/SignUpModel';
import { PermissionPlan, User } from '../../../app/modules/auth/models/UserModel';
import {
  enableUser as enableUserEffect,
  getCurrentCompany,
  getUser as getUserEffect,
  logOut,
  putCompany,
  signIn as signInEffect,
  signUp as signUpEffect,
  updateProfilePictureEffect,
} from '../effects/AuthEffects';
import store from '../../Store';

type Credentials = { email: string; password: string; rememberMe: boolean };

export const signIn = createAsyncThunk('auth/login', async (payload: Credentials, thunkAPI) => {
  try {
    const { rememberMe, ...loginData } = payload;
    const response = await signInEffect(loginData);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(
      error?.response?.data.message || error?.toJSON()?.message || 'Error'
    );
  }
});

export const updateProfilePicture = createAsyncThunk(
  'auth/picture-update',
  async (payload: FormData, thunkAPI) => {
    const userId = store.getState().auth.user?.id;
    const { data } = await updateProfilePictureEffect(payload, userId!);
    return data;
  }
);

export const signUp = createAsyncThunk('auth/signup', async (payload: SignUpRequest, thunkAPI) => {
  try {
    const response = await signUpEffect(payload);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(
      error?.response?.data.message || error?.toJSON()?.message || 'Error'
    );
  }
});

export const enableUser = createAsyncThunk(
  'auth/enable',
  async (payload: SignUpRequest, thunkAPI) => {
    try {
      const response = await enableUserEffect(payload);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error?.response?.data.message || error?.toJSON()?.message || 'Error'
      );
    }
  }
);

export const signOut = createAsyncThunk('auth/signOut', async () => {
  const refreshToken = store.getState().auth.refreshToken;
  const { data } = await logOut(refreshToken);
  return data;
});

export const getUser = createAsyncThunk('auth/getUser', async () => {
  const { data } = await getUserEffect();
  return data;
});

export const updateCompany = createAsyncThunk('auth/updateCompany', async (payload: number) => {
  await putCompany(payload);
});

export const readCurrentCompany = createAsyncThunk('auth/readCurrentCompany', async () => {
  const state = store?.getState();
  const { data } = await getCurrentCompany(state?.auth?.user?.current_company?.id as number);
  return data;
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
    user: undefined as User | undefined,
    refreshToken: '',
    token: '',
    loading: false,
    error: '',
    isMessageSent: false,
    isSigningUp: false,
    signUpDraft: {},
    signUpError: '',
    defaultCurrency: '',
    isOnboarded: false,
    isExpired: false,
    currentCompany: undefined as CurrentCompany | undefined,
  },
  reducers: {
    loadToken: (state) => {
      const token = localStorage.getItem('token');
      sessionStorage.clear();
      const refreshToken = localStorage.getItem('refreshToken');
      if (token && refreshToken) {
        state.isAuthenticated = true;
        state.token = token;
        state.refreshToken = token;
      }
    },
    updateTokens: (state, action) => {
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('refreshToken', action.payload.refreshToken);
    },
    updateTokensAndNavigat: (state, action) => {
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('refreshToken', action.payload.refreshToken);
      window.location.reload();
    },
    setIsOnBoarded: (state) => {
      state.isOnboarded = true;
    },
    updateCurrentCompany: (state, action: PayloadAction<CurrentCompany>) => {
      state.currentCompany = action.payload;
    },
    updateUserForStorybook: (state) => {
      state.user = {
        id: 1,
        email: 'ilyes.blagui@gmail.com',
        first_name: 'Ilyes',
        last_name: 'Blagui',
        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
        last_login: '2023-07-11T08:22:43+00:00',
        last_activity_date: '2023-07-12T13:17:48+00:00',
        time_zone: null,
        date_format: null,
        hour_format: null,
        companies: [
          {
            id: 1,
            name: 'Swiver',
            setting: {
              id: 1,
              trade_register: '',
              tax_number: '',
              address: '',
              default_currency: process.env.REACT_APP_DEFAULT_CURRENCY || 'TND',
              language: 'en-US',
              phone1: '',
              phone2: '',
              zip_code: '',
              region: '',
              activity: '',
              country: process.env.REACT_APP_DEFAULT_COUNTRY || 'TN',
              doc_prefix_locale: 'fr',
              sales_workflow_type: 1,
              has_invoice_inventory_impact: true,
              sms_header: '99491661',
              company_size: 3,
              integration: false,
              contact_phones: [],
              civility: '',
              email: '',
              first_name: 'Azzam',
              last_name: 'Swalmiya',
              website: '',
              is_vat_exempt: '',
              job_title: '',
              prefix_type: 1,
            },
            permissions: [],
            credit: null,
            profile: null,
            count_documents: 9,
            count_documents_per_month: 3,
            // is_master: ,
            // integration: false,
            is_walkthrough: false,
            plan: {
              id: 5,
              name: 'PRO',
              price_per_month: 79,
              price_per_year: 71.1,
              max_user: -1,
              max_company: 4,
              max_document: -1,
              max_document_per_month: -1,
              price_per_month_ttc: 0,
              price_per_year_ttc: 0,
              vat: 0,
              extra_user: 0,
              extra_company: 0,
              permissions_plan: {
                allowSendSms: true,
                bankAccount: true,
                cashManagement: true,
                checkAndDraft: true,
                allowPurchase: true,
                dashboardView: true,
                saleManagement: true,
                userManagement: true,
                orderManagement: true,
                stockManagement: true,
                invoiceManagement: true,
                customerManagement: true,
                estimateManagement: true,
                productsManagement: true,
                purchaseManagement: true,
                supplierManagement: true,
                inventoryManagement: true,
                issueNoteManagement: true,
                accountingManagement: true,
                deliveryNoteManagement: true,
                supplierOrderManagement: true,
                hasInvoiceInventoryImpact: true,
                supplierInvoiceManagement: true,
                supplierDeliveryNoteManagement: true,
                transferOrderManagement: true,
                reports: true,
              },
            },
            created_at: '2023-06-26T11:40:57+00:00',
          },
        ],
        commercial: null,
        phone: '+21699491661',
        current_company: {
          id: 1,
          name: 'Test company',
          setting: {
            id: 1,
            trade_register: '',
            tax_number: '',
            address: '',
            logo: undefined,
            default_currency: process.env.REACT_APP_DEFAULT_CURRENCY || 'TND',
            language: 'en-US',
            phone1: '',
            phone2: '',
            zip_code: '',
            region: '',
            first_name: '',
            last_name: '',
            job_title: '',
            civility: 'M',
            email: 'swiver@swiver.net',
            is_vat_exempt: '',
            prefix_type: 1,
            website: '',
            activity: {
              id: 116,
              name: 'Manufacturing',
            },
            country: process.env.REACT_APP_DEFAULT_COUNTRY || 'TN',
            doc_prefix_locale: 'fr',
            sales_workflow_type: 1,
            has_invoice_inventory_impact: true,
            sms_header: '99491661',
            company_size: 3,
            integration: false,
            contact_phones: [],
          },
          permissions: [],
          credit: null,
          profile: null,
          count_documents: 9,
          count_documents_per_month: 3,
          // is_master: false,
          // integration: [],
          is_walkthrough: false,
          plan: {
            id: 5,
            name: 'PRO',
            price_per_month: 79,
            price_per_year: 71.1,
            max_user: -1,
            max_company: 4,
            max_document: -1,
            max_document_per_month: -1,
            price_per_month_ttc: 0,
            price_per_year_ttc: 0,
            vat: 0,
            extra_user: 0,
            extra_company: 0,
            permissions_plan: {
              allowSendSms: true,
              allowPurchase: true,
              dashboardView: true,
              saleManagement: true,
              userManagement: true,
              orderManagement: true,
              stockManagement: true,
              invoiceManagement: true,
              customerManagement: true,
              estimateManagement: true,
              productsManagement: true,
              purchaseManagement: true,
              supplierManagement: true,
              inventoryManagement: true,
              issueNoteManagement: true,
              accountingManagement: true,
              deliveryNoteManagement: true,
              supplierOrderManagement: true,
              hasInvoiceInventoryImpact: true,
              supplierInvoiceManagement: true,
              supplierDeliveryNoteManagement: true,
              transferOrderManagement: true,
              reports: true,
              bankAccount: true,
              cashManagement: true,
              checkAndDraft: true,
            },
          },
          created_at: '2023-06-26T11:40:57+00:00',
          planPermissions: [
            'allowSendSms',
            'allowPurchase',
            'dashboardView',
            'saleManagement',
            'userManagement',
            'orderManagement',
            'stockManagement',
            'invoiceManagement',
            'customerManagement',
            'estimateManagement',
            'productsManagement',
            'purchaseManagement',
            'supplierManagement',
            'inventoryManagement',
            'issueNoteManagement',
            'accountingManagement',
            'deliveryNoteManagement',
            'supplierOrderManagement',
            'hasInvoiceInventoryImpact',
            'supplierInvoiceManagement',
            'supplierDeliveryNoteManagement',
          ],
        },
        done_actions: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
        ],
        xp: 0,
        s_access_token: null,
        partner: null,
        is_touched: true,
        current_plan: {
          id: 5,
          name: 'PRO',
          price_per_month: 79,
          price_per_year: 71.1,
          max_user: -1,
          max_company: 4,
          max_document: -1,
          max_document_per_month: -1,
          price_per_month_ttc: 0,
          price_per_year_ttc: 0,
          vat: 0,
          extra_user: 0,
          extra_company: 0,
          stock_management: false,
          customer_management: true,
          supplier_management: true,
          account_management: true,
          dashboard_view: true,
          products_management: true,
          purchase_management: true,
          sale_management: true,
          permissions_plan: {
            allowSendSms: true,
            allowPurchase: true,
            dashboardView: true,
            saleManagement: true,
            userManagement: true,
            orderManagement: true,
            stockManagement: true,
            invoiceManagement: true,
            customerManagement: true,
            estimateManagement: true,
            productsManagement: true,
            purchaseManagement: true,
            supplierManagement: true,
            inventoryManagement: true,
            issueNoteManagement: true,
            accountingManagement: true,
            deliveryNoteManagement: true,
            supplierOrderManagement: true,
            hasInvoiceInventoryImpact: true,
            supplierInvoiceManagement: true,
            supplierDeliveryNoteManagement: true,
          },
        },
        // avatar: null,
        is_suspended: false,
        subscription_expiry: '2024-12-31T11:40:57+00:00',
        reference: 'SW-1-X7AY',
        purchase_date: '2023-06-26T11:40:57+00:00',
        last_purchase_date: null,
        products_type: null,
        monthly_turnover_target: 0,
        credits: [
          {
            resource: 'PACK_SMS',
            qty: 20,
          },
          {
            resource: 'EXTRA_USER',
            qty: 0,
          },
          {
            resource: 'EXTRA_COMPANY',
            qty: 0,
          },
        ],
        created_at: '2023-06-26T11:40:57+00:00',
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.loading = false;
      state.refreshToken = action.payload.refreshToken;
      state.token = action.payload.token;
      state.error = '';
      if (action.meta.arg.rememberMe)
        localStorage.setItem('lastRemeberedEmail', action.meta.arg.email);
      else localStorage.removeItem('lastRemeberedEmail');
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('refreshToken', action.payload.refreshToken);
    });
    builder.addCase(signIn.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(signIn.rejected, (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.user = undefined;
      state.refreshToken = '';
      state.token = '';
      state.error = action.payload as string;
    });
    builder.addCase(getUser.fulfilled, (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      const permissionsPlan = action.payload.current_company?.owner
        ? action.payload.current_company?.owner?.current_plan?.permissions_plan
        : action.payload?.current_plan?.permissions_plan;
      const companyPermission = Object.entries(permissionsPlan || {}).reduce(
        (previousValue, [currentKey, currentValue]) => {
          return currentValue
            ? ([...previousValue, currentKey] as PermissionPlan[])
            : previousValue;
        },
        [] as PermissionPlan[]
      );
      state.user.current_company.permissions = [
        ...(action.payload.current_company.permissions || []),
      ];
      if (action.payload.current_company?.owner) {
        state.user.current_plan = {};
      }
      state.user.current_plan.planPermissions = companyPermission;
      if (action.payload?.last_login) {
        state.isOnboarded = true;
      }
      if (new Date(action.payload?.subscription_expiry).getTime() < new Date().getTime()) {
        state.isExpired = true;
      }
    });
    builder.addCase(getUser.rejected, (state) => {
      state.user = undefined;
      state.isAuthenticated = false;
    });
    builder.addCase(signUp.fulfilled, (state, action) => {
      state.isSigningUp = true;
      state.signUpDraft = { ...action.meta.arg, registrationId: action?.payload?.id?.toString() };
      state.loading = false;
      state.error = '';
    });
    builder.addCase(signUp.rejected, (state, action) => {
      state.isSigningUp = false;
      state.signUpError = action.payload as string;
      state.loading = false;
    });
    builder.addCase(signUp.pending, (state) => {
      state.isSigningUp = false;
      state.loading = true;
    });
    builder.addCase(enableUser.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('refreshToken', action.payload.refreshToken);
      state.loading = false;
      state.signUpDraft = {};
      state.error = '';
      state.isSigningUp = false;
    });
    builder.addCase(enableUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(enableUser.rejected, (state, action) => {
      state.loading = false;
      state.signUpError = action.payload as string;
    });
    builder.addCase(updateCompany.fulfilled, (state, action) => {
      window.location.replace('/');
    });
    builder.addCase(readCurrentCompany.fulfilled, (state, action) => {
      state.currentCompany = action.payload;
    });
    builder.addCase(signOut.fulfilled, () => {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      window.location.replace('/');
    });
    builder.addCase(updateProfilePicture.fulfilled, (state, action) => {
      state.user!.avatar = action.payload.avatar;
    });
  },
});

export default authSlice.reducer;
export const {
  loadToken,
  setIsOnBoarded,
  updateTokens,
  updateTokensAndNavigat,
  updateCurrentCompany,
  updateUserForStorybook,
} = authSlice.actions;
