import clsx from 'clsx';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { KTSVG } from '../../../helpers';
import { EXPENSE_MIN_AMOUNT } from '../DepenseValidationSchemas';

function AddDepenseStep1() {
  const { formatMessage } = useIntl();
  const {
    watch,
    setValue,
    register,
    formState: { errors, touchedFields },
  } = useFormContext();

  return (
    <div className='w-100'>
      <div className='mb-10 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-invalid'>
        <label className='form-label required mb-3'>
          <FormattedMessage id='FORM.FIELD.AMOUNT' />
        </label>
        <input
          type='text'
          className={clsx('form-control form-control-lg form-control-solid', {
            'is-valid': touchedFields?.amount && !errors?.amount,
            'is-invalid': touchedFields?.amount && errors?.amount,
          })}
          {...register('amount')}
          placeholder=''
          autoComplete='off'
        />
        {errors?.amount && (
          <div className='text-danger'>
            <span role='alert'>
              <FormattedMessage
                id={errors.amount.message + ''}
                values={{
                  field: formatMessage({ id: 'FORM.FIELD.AMOUNT' }),
                  min: EXPENSE_MIN_AMOUNT,
                }}
              />
            </span>
          </div>
        )}
      </div>
      <div className='mb-10'>
        <label className='form-label required mb-3'>
          <FormattedMessage id='REGULATION.EXPENSE_TYPE' />
        </label>
        <Controller
          name='paymentMethod'
          render={({
            field: { name, onChange, onBlur, value },
            fieldState: { error, isTouched },
          }) => (
            <>
              <div className='row row-cols-1 row-cols-md-2 g-5'>
                <div className='col'>
                  <input
                    type='radio'
                    className='btn-check'
                    name={name}
                    id='sw_expense_cash'
                    onChange={onChange}
                    onBlur={onBlur}
                    value='CASH'
                    checked={value === 'CASH'}
                  />
                  <label
                    className={clsx(
                      'btn btn-outline btn-outline-dashed btn-outline-default  p-7 d-flex align-items-center h-100',
                      value === 'CASH' && 'border border-primary text-primary active'
                    )}
                    htmlFor='sw_expense_cash'>
                    <span className='svg-icon svg-icon-3hx svg-icon-primary'>
                      <KTSVG
                        path='/media/icons/duotune/finance/fin003.svg'
                        className='svg-icon-1'
                      />
                    </span>
                    <span className='d-block fw-semibold text-start'>
                      <span className='fw-semibold d-block fs-3'>
                        <FormattedMessage id='PAYMENT.CASH' />
                      </span>
                      <span className='text-muted fw-semibold fs-6'>
                        <FormattedMessage id='PAYMENT.CASH.DESCRIPTION' />
                      </span>
                    </span>
                  </label>
                </div>
                <div className='col'>
                  <input
                    type='radio'
                    className='btn-check'
                    name={name}
                    value='CHECK'
                    onChange={onChange}
                    onBlur={onBlur}
                    checked={value === 'CHECK'}
                    id='sw_expense_check'
                  />
                  <label
                    htmlFor='sw_expense_check'
                    className={clsx(
                      'btn btn-outline btn-outline-dashed btn-outline-default  p-7 d-flex align-items-center h-100',
                      value === 'CHECK' && 'border border-primary active'
                    )}>
                    <span className='svg-icon svg-icon-3hx svg-icon-primary'>
                      <KTSVG
                        path='/media/icons/duotune/finance/fin002.svg'
                        className='svg-icon-1'
                      />
                    </span>
                    <span className='d-block fw-semibold text-start'>
                      <span className='fw-semibold d-block fs-3'>
                        <FormattedMessage id='PAYMENT.CHECK' />
                      </span>
                      <span className='text-muted fw-semibold fs-6'>
                        <FormattedMessage id='PAYMENT.CHECK.DESCRIPTION' />
                      </span>
                    </span>
                  </label>
                </div>
              </div>
              {error && isTouched && (
                <div className='text-danger'>
                  <span role='alert'>
                    <FormattedMessage
                      id={error.message + ''}
                      values={{
                        field: formatMessage({ id: 'REGULATION.EXPENSE_TYPE' }),
                      }}
                    />
                  </span>
                </div>
              )}
            </>
          )}
        />
      </div>
    </div>
  );
}
export { AddDepenseStep1 };
