/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { format, formatISO, parseISO } from 'date-fns';
import pick from 'lodash.pick';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../../../setup/Store';
import {
  readBankList,
  readExpenseCategories,
  readIncomeCategories,
  readUserBanks,
} from '../../../../../setup/redux/reducers/UtilsReducers';
import CustomRadio from '../../../../components/CustomRadio/CustomRadio';
import SwiverSelect from '../../../../components/SwiverSelect/SwiverSelect';
import { toAbsoluteUrl } from '../../../../helpers';
import { Bank } from '../../../../models/BankResponse';
import { dateFormatsPerLocale } from '../../../../utils/configs';
import { PaymentMethod } from './BankDeposit';
function isNumber(value: string | number) {
  return !isNaN(Number(value));
}
type SelectOption = { value: number; label: string };
type BankOptionType = {
  value: string | number;
  label: string;
  image: string;
  code: string;
};
const defaultValues = {
  paymentMode: 1,
  amount: 0,
  incomeCategory: '',
  expensecategory: '',
  bankAccountDestination: '',
  bankAccountDestinationNumber: '',
  bankAccountSource: '',
  bankAccountSourceNumber: '',
  bankExtra: '',
  bankSource: '',
  bank: '',
  bankSourceExtra: '',
  checkNumber: '',
  contact: '',
  date: formatISO(new Date(), { representation: 'date' }),
  document: '',
  draftNumber: '',
  dueDate: formatISO(new Date(), { representation: 'date' }),
  exchange: '',
  expenseCategory: '',
  holder: '',
  note: '',
  paymentMethod: '' as string | number,
};
function SaveOperation({
  onHide = () => {},
  show = false,
  balanceId,
}: {
  onHide?: () => void;
  balanceId?: number;
  show?: boolean;
}) {
  const { formatMessage } = useIntl();
  const userBanks = useSelector((state: RootState) => state.utils.userBanks);
  const dispatch = useDispatch();
  const locale = useSelector((state: RootState) => state.utils.locale);
  const expenseCategories = useSelector((state: RootState) => state?.utils?.expenseCategories);
  const incomeCategories = useSelector((state: RootState) => state?.utils?.incomeCategories);
  const [creditPaymentMehtods, setCreditPaymentMethods] = useState<PaymentMethod[]>();
  const [debitPaymentMehtods, setDebitPaymentMethods] = useState<PaymentMethod[]>();
  const navigate = useNavigate();
  const currency = useSelector(
    (state: RootState) => state.auth.user?.current_company.setting.default_currency
  );
  const [expenseCategoriesSelectOptions, setExpenseCategoriesSelectOptions] = useState<
    SelectOption[]
  >([]);
  useEffect(() => {
    if (creditPaymentMehtods?.length && debitPaymentMehtods?.length) {
      setValue(
        'paymentMethod',
        +formValues.paymentMode! === 1 ? debitPaymentMehtods[0].id! : creditPaymentMehtods[0].id!
      );
    }
  }, [creditPaymentMehtods, debitPaymentMehtods]);

  const [incomeCategoriesOptions, setIncomeCategoriesOptions] = useState<SelectOption[]>([]);
  const [bankOptions, setBankOptions] = useState<BankOptionType[]>([]);
  const bankList = useSelector((state: RootState) => state.utils.bankList);
  const [userBanksSelectOption, setUserBanksSelectOption] = useState<
    { value: number; label: string }[]
  >([]);
  const getPaymentMethods = async () => {
    const paymentPromises = [1, 3].map((type) =>
      axios.get<PaymentMethod[]>('/secure/payment/method/get', { params: { type } })
    );

    const [{ data: allDebitMethods }, { data: AllCreditMethods }] = await Promise.all(
      paymentPromises
    );

    setDebitPaymentMethods(
      allDebitMethods.filter((method) => ['CHECK', 'CASH'].includes(method.method_abrev!))
    );

    setCreditPaymentMethods(
      AllCreditMethods.filter((method) => ['CHECK', 'CASH'].includes(method.method_abrev!))
    );
  };
  const {
    register,
    handleSubmit,
    watch,
    setValue,

    reset,
    control,
  } = useForm({
    defaultValues,
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });
  const formValues = watch();
  useEffect(() => {
    if (userBanks === undefined) return;

    const userBanksOptions = userBanks?.map((bank) => ({
      value: bank.id,
      label: `(${(bank.bank as Bank).code}) ${bank.name}`,
    }));
    setUserBanksSelectOption(userBanksOptions);
  }, [userBanks]);
  useEffect(() => {
    if (expenseCategories !== undefined) {
      const expensesOptions = Array.from(expenseCategories, (expenseCategory) => ({
        label: expenseCategory.name,
        value: expenseCategory.id,
      }));
      setExpenseCategoriesSelectOptions(expensesOptions);
    }
  }, [expenseCategories]);
  useEffect(() => {
    if (incomeCategories !== undefined) {
      const expensesOptions = Array.from(incomeCategories, (incomeCategory) => ({
        label: incomeCategory.name,
        value: incomeCategory.id,
      }));
      setIncomeCategoriesOptions(expensesOptions);
    }
  }, [incomeCategories]);
  const resetForm = () => {
    reset(defaultValues, { keepValues: false, keepTouched: false, keepErrors: false });
  };
  useEffect(() => {
    if (bankList === undefined) return;

    const blOptions: BankOptionType[] = bankList.map((bank) => ({
      value: bank.id,
      label: bank.name,
      image: bank.logo?.web_path || toAbsoluteUrl(`/media/banks/${bank.code}.png`),
      code: bank.code,
    }));
    setBankOptions(blOptions);
  }, [bankList]);
  const handleSaveOperationFormSubmit = async () => {
    const { paymentMode, paymentMethod } = formValues;
    const paymentType =
      +paymentMode === 1
        ? debitPaymentMehtods?.find((payment) => payment.id === +paymentMethod)?.method_abrev
        : creditPaymentMehtods?.find((payment) => payment.id === +paymentMethod)?.method_abrev;

    const pickedFields = pick(
      { ...formValues, balance: balanceId },
      +paymentMode === 1 && paymentType === 'CASH'
        ? ['amount', 'expenseCategory', 'balance', 'paymentMethod', 'date', 'note']
        : +paymentMode === 1 && paymentType === 'CHECK'
        ? [
            'bankAccountSource',
            'dueDate',
            'checkNumber',
            'expenseCategory',
            'balance',
            'paymentMethod',
            'date',
            'note',
            'amount',
          ]
        : +paymentMode === 3 && paymentType === 'CASH'
        ? ['amount', 'incomeCategory', 'balance', 'paymentMethod', 'date', 'note']
        : +paymentMode === 3 && paymentType === 'CHECK'
        ? [
            'amount',
            'balance',
            'incomeCategory',
            'holder',
            'checkNumber',
            'bank',
            'note',
            'paymentMethod',
            'date',
            'dueDate',
          ]
        : []
    );
    const { data } = await axios.post('/secure/payment/v2/new', {
      payments: [pickedFields],
      transaction_type: 'operation.diverse',
    });
    navigate(`/accounting/balance/${balanceId}`);
    handleHideSaveOperation();
  };
  const handleHideSaveOperation = () => {
    resetForm();
    onHide();
  };
  return (
    <Modal
      size='xl'
      show={show}
      onHide={handleHideSaveOperation}
      centered
      onShow={() => {
        if (bankList === undefined) dispatch(readBankList());

        if (expenseCategories === undefined) dispatch(readExpenseCategories());

        if (incomeCategories === undefined) dispatch(readIncomeCategories());

        if (userBanks === undefined) dispatch(readUserBanks());
        if (debitPaymentMehtods === undefined || creditPaymentMehtods === undefined)
          getPaymentMethods();
      }}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id='SAVE.THE.OPERATION' />
        </Modal.Title>
      </Modal.Header>

      <form onSubmit={handleSubmit(handleSaveOperationFormSubmit)}>
        <Modal.Body>
          <div className='row g-4'>
            <div>
              <Controller
                name='paymentMode'
                control={control}
                rules={{ required: 'FORM.ERRORS.REQUIRED' }}
                render={({
                  field: { name, onBlur, onChange, value },
                  fieldState: { isTouched, error },
                }) => (
                  <>
                    <div className='form-label mb-3 required'>
                      <FormattedMessage id='PAYMENT.TYPE' />
                    </div>
                    <div className='row g-4'>
                      <div className='col-6'>
                        <CustomRadio
                          className='w-100'
                          name={name}
                          value={1}
                          onBlur={onBlur}
                          onChange={(e) => {
                            onChange(e);
                            setValue('paymentMethod', debitPaymentMehtods?.[0]?.id || 8);
                          }}
                          checked={+value === 1}>
                          <FormattedMessage id='SEARCH_OPERATION.TABLE.DEBIT' />
                        </CustomRadio>
                      </div>
                      <div className='col-6'>
                        <CustomRadio
                          className='w-100'
                          name={name}
                          value={3}
                          onBlur={onBlur}
                          onChange={(e) => {
                            onChange(e);
                            setValue('paymentMethod', creditPaymentMehtods?.[0]?.id || 3);
                          }}
                          checked={+value === 3}>
                          <FormattedMessage id='SEARCH_OPERATION.TABLE.CREDIT' />
                        </CustomRadio>
                      </div>
                    </div>
                    {error && isTouched && (
                      <div className='text-danger mt-3'>
                        <span role='alert'>
                          <FormattedMessage
                            id={error.message}
                            values={{
                              field: formatMessage({ id: 'PAYMENT.TYPE' }),
                            }}
                          />
                        </span>
                      </div>
                    )}
                  </>
                )}
              />
            </div>
            {+formValues.paymentMode === 1 && (
              <div>
                <label className='form-label mb-3'>
                  <FormattedMessage id='DOCUMENT.DETAILS_BLOCK.DOCUMENT_EXPENSE_CATEGORY' />
                </label>
                <Controller
                  control={control}
                  name='expenseCategory'
                  render={({
                    field: { value, onChange, onBlur, name },
                    fieldState: { invalid, isTouched, error },
                  }) => (
                    <SwiverSelect
                      className='swiver-select-solid'
                      options={expenseCategoriesSelectOptions}
                      name={name}
                      onChange={(e) => {
                        onChange(e ? e.value : '');
                      }}
                      onBlur={onBlur}
                      value={value}
                    />
                  )}
                />
              </div>
            )}
            {+formValues.paymentMode === 3 && (
              <div>
                <label className='form-label mb-3'>
                  <FormattedMessage id='DOCUMENT.DETAILS_BLOCK.DOCUMENT_INCOME_CATEGORY' />
                </label>
                <Controller
                  control={control}
                  name='incomeCategory'
                  render={({
                    field: { value, onChange, onBlur, name },
                    fieldState: { invalid, isTouched, error },
                  }) => (
                    <SwiverSelect
                      className='swiver-select-solid'
                      options={incomeCategoriesOptions}
                      name={name}
                      onChange={(e) => {
                        onChange(e ? e.value : '');
                      }}
                      onBlur={onBlur}
                      value={value}
                    />
                  )}
                />
              </div>
            )}
            <div>
              <label className='form-label required mb-3'>
                <FormattedMessage id='PRICING.PAYMENT.METHOD' />
              </label>
              <Controller
                name='paymentMethod'
                control={control}
                rules={{ required: 'FORM.ERROR.REQUIRED' }}
                render={({
                  field: { name, onBlur, onChange, value },
                  fieldState: { invalid, isTouched, error },
                }) => (
                  <>
                    <div className='row g-4'>
                      {+formValues.paymentMode === 1
                        ? debitPaymentMehtods?.map((v) => (
                            <div key={v.id} className='col-6'>
                              <CustomRadio
                                className='w-100'
                                name={name}
                                value={v.id}
                                onBlur={onBlur}
                                onChange={onChange}
                                checked={+value === v.id}>
                                <FormattedMessage id={`PAYMENT.${v.method_abrev}`} />
                              </CustomRadio>
                            </div>
                          ))
                        : creditPaymentMehtods?.map((v) => (
                            <div key={v.id} className='col-6'>
                              <CustomRadio
                                className='w-100'
                                name={name}
                                value={v.id}
                                onBlur={onBlur}
                                onChange={onChange}
                                checked={+value === v.id}>
                                <FormattedMessage id={`PAYMENT.${v.method_abrev}`} />
                              </CustomRadio>
                            </div>
                          ))}
                    </div>
                    {isTouched && error && (
                      <div className='text-danger'>
                        <span role='alert'>
                          <FormattedMessage
                            id={error.message}
                            values={{
                              field: formatMessage({ id: 'PRICING.PAYMENT.METHOD' }),
                            }}
                          />
                        </span>
                      </div>
                    )}
                  </>
                )}
              />
            </div>
            <div className='col-lg-6 '>
              <label className='form-label required mb-3'>
                <FormattedMessage id='FORM.FIELD.AMOUNT' />
              </label>

              <Controller
                name='amount'
                control={control}
                rules={{
                  required: 'FORM.ERRORS.REQUIRED',
                  validate: (value) => {
                    if (!isNumber(value)) return 'FORM.ERRORS.NUMBER';
                    if (Number(value) <= 0) return 'FORM.ERRORS.GREATER_THAN';
                    return true;
                  },
                }}
                render={({ field, fieldState: { error, isTouched } }) => (
                  <>
                    <div className='input-group'>
                      <span className='input-group-text'>{currency}</span>
                      <input
                        type='number'
                        className='form-control form-control-solid'
                        onWheel={(event) => event.currentTarget.blur()}
                        {...field}
                      />
                    </div>
                    {isTouched && error && (
                      <div className='text-danger mt-3'>
                        <span role='alert'>
                          <FormattedMessage
                            id={error.message}
                            values={{
                              field: formatMessage({ id: 'FORM.FIELD.AMOUNT' }),
                              min: 0,
                            }}
                          />
                        </span>
                      </div>
                    )}
                  </>
                )}
              />
            </div>
            <div className='col-lg-6'>
              <label className='form-label mb-3'>
                <FormattedMessage id='FORM.FIELD.SETTLEMENT_DATE' />
              </label>
              <Controller
                name='date'
                control={control}
                render={({ field: { name, onBlur, onChange, value, ref } }) => (
                  <DatePicker
                    locale={locale}
                    name={name}
                    ref={ref}
                    onBlur={onBlur}
                    selected={value ? parseISO(value) : null}
                    dateFormat={dateFormatsPerLocale[locale || 'fr']}
                    className='form-control form-control-solid'
                    onChange={(date: Date) => {
                      onChange(format(date, 'yyyy-MM-dd'));
                    }}
                  />
                )}
              />
            </div>
            {(+formValues.paymentMode === 3
              ? creditPaymentMehtods?.some(
                  (method) =>
                    method.id === +formValues.paymentMethod && method.method_abrev === 'CHECK'
                )
              : debitPaymentMehtods?.some(
                  (method) =>
                    method.id === +formValues.paymentMethod && method.method_abrev === 'CHECK'
                )) && (
              <>
                {+formValues.paymentMode === 3 && (
                  <div className='col-lg-6'>
                    <label className='form-label mb-3'>
                      <FormattedMessage id='FORM.FIELD.HOLDER' />
                    </label>
                    <input {...register('holder')} className='form-control form-control-solid' />
                  </div>
                )}
                <div className={+formValues.paymentMode === 3 ? 'col-lg-6' : 'col-lg-4'}>
                  <label className='form-label required mb-3'>
                    <FormattedMessage id='PAYMENT.CHECK_NUMBER' />
                  </label>
                  <Controller
                    name='checkNumber'
                    control={control}
                    rules={{ required: 'FORM.ERRORS.REQUIRED' }}
                    render={({ field, fieldState: { error, isTouched } }) => (
                      <>
                        <input {...field} type='text' className='form-control form-control-solid' />
                        {isTouched && error && (
                          <div className='text-danger'>
                            <span role='alert'>
                              <FormattedMessage
                                id={error.message}
                                values={{
                                  field: formatMessage({ id: 'PAYMENT.CHECK_NUMBER' }),
                                }}
                              />
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className={+formValues.paymentMode === 3 ? 'col-lg-6' : 'col-lg-4'}>
                  <label className='form-label required mb-3'>
                    <FormattedMessage
                      id={+formValues.paymentMode === 1 ? 'FORM.FIELD.SOURCE_ACCOUNT' : 'BANK.BANK'}
                    />
                  </label>
                  <Controller
                    name={formValues.paymentMode === 1 ? 'bankAccountSource' : 'bank'}
                    control={control}
                    rules={{ required: 'FORM.ERRORS.REQUIRED' }}
                    render={({
                      field: { name, onBlur, onChange, value },
                      fieldState: { error, isTouched },
                    }) => (
                      <>
                        <SwiverSelect
                          className='swiver-select-solid h-40px'
                          options={
                            +formValues.paymentMode === 1 ? userBanksSelectOption : bankOptions
                          }
                          name={name}
                          isClearable={false}
                          onChange={(e) => {
                            onChange(e ? e.value : '');
                          }}
                          onBlur={onBlur}
                          value={value}
                        />
                        {error && isTouched && (
                          <div className='text-danger'>
                            <span role='alert'>
                              <FormattedMessage
                                id={error.message}
                                values={{
                                  field: formatMessage({
                                    id:
                                      +formValues.paymentMode === 1
                                        ? 'FORM.FIELD.SOURCE_ACCOUNT'
                                        : 'BANK.BANK',
                                  }),
                                }}
                              />
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className={+formValues.paymentMode === 3 ? 'col-lg-6' : 'col-lg-4'}>
                  <label className='form-label mb-3'>
                    <FormattedMessage id='COMMON.FIELDS.DUE-DATE' />
                  </label>
                  <Controller
                    name='dueDate'
                    control={control}
                    render={({
                      field: { name, onBlur, onChange, value },
                      fieldState: { invalid, error },
                    }) => (
                      <>
                        <DatePicker
                          locale={locale}
                          name={name}
                          selected={value ? parseISO(value) : null}
                          dateFormat={dateFormatsPerLocale[locale || 'fr']}
                          className='form-control form-control-solid'
                          onChange={(date: Date) => {
                            onChange(format(date, 'yyyy-MM-dd'));
                          }}
                        />
                      </>
                    )}
                  />
                </div>
              </>
            )}
            <div>
              <label className='form-label mb-3'>
                <FormattedMessage id='DOCUMENT.DETAILS_BLOCK.DOCUMENT_NOTE' />
              </label>
              <textarea {...register('note')} className='form-control form-control-solid' />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='btn btn-secondary' onClick={onHide}>
            <FormattedMessage id='FORM.CANCEL' />
          </div>
          <button type='submit' className='btn btn-primary'>
            <FormattedMessage id='FORM.SUBMIT' />
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default SaveOperation;
