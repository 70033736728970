import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { differenceInMilliseconds } from 'date-fns';
import isEqual from 'lodash.isequal';
import { SwSubscription } from '../../../app/models/SubscriptionsResponse';
import store from '../../Store';
import { getSubscriptions } from '../effects/SubscriptionsEffects';

export type SubscriptionQuery = {
  limit: 5 | 10 | 20;
  offset: number;
  sort: 'desc' | 'asc';
  'sort-field': string;
  forceQuery?: boolean;
};
export const readSubscriptions = createAsyncThunk(
  'subscriptions/get',
  async (payload: SubscriptionQuery, thunkAPI) => {
    try {
      const state = store.getState();
      const lastSentQuery = state.subscription.query;
      const { forceQuery, ...payloadData } = payload;
      if (
        forceQuery ||
        !isEqual(lastSentQuery, payloadData) ||
        !state.subscription.lastSentAt ||
        differenceInMilliseconds(state.subscription.lastSentAt, new Date()) >= 60000
      ) {
        const { data } = await getSubscriptions(payloadData);
        return data;
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error?.response?.data.message || error?.toJSON()?.message || 'Error'
      );
    }
  }
);

const subsriptionsSlice = createSlice({
  name: 'subscription',
  initialState: {
    loading: false,
    subscriptions: undefined as SwSubscription[] | undefined,
    query: {
      limit: 5,
      offset: 0,
      sort: 'desc',
      'sort-field': 'created_at',
    } as SubscriptionQuery,
    count: 0,
    lastSentAt: undefined as Date | undefined,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(readSubscriptions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(readSubscriptions.fulfilled, (state, action) => {
      state.loading = false;
      const { forceQuery, ...query } = action.meta.arg;
      state.query = query;
      if (action.payload) {
        state.subscriptions = action.payload.rows;
        state.count = action.payload.count;
        state.lastSentAt = new Date();
      }
    });
  },
});

export default subsriptionsSlice.reducer;
