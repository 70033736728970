import { Project } from '../../../app/models/Project';
import axios from 'axios';
import { FieldValues } from 'react-hook-form';
import { BankAccount } from '../../../app/models/BankAccounts';
import { Bank, UserBank } from '../../../app/models/BankResponse';
import { Warehouse } from '../../../app/models/warehousesResponse';
import { delayPromise } from '../../../app/utils/utils';
import { ContactPhone } from '../../../app/models/SupplierResponse';
import { UserPlan } from '../../../app/models/UserPlan';
import { PaymentGateway } from '../../../app/models/PaymentGateway';
import { SubscriptionPrice } from '../../../app/models/SubscriptionPrice';

type CompanyQuery = {
  company: number;
};
type PriceQuery = {
  id: number;
  name: string;
  enabled: boolean;
  company: number;
};

export interface PaymentMethod {
  id: number;
  name: string;
  method_abrev: string;
  method_type: string;
  allow_due_date: boolean;
}

export const getCountries = () => {
  return axios.get('/open/register/countries');
};

export const getCurrencies = () => {
  return axios.get('/open/register/currencies');
};

export const getActivities = () => {
  return axios.get('/open/activities', { params: { vendor: process.env.REACT_APP_VENDOR_SLUG } });
};

export const getPriceList = (query: CompanyQuery) => {
  return axios.get('/secure/company/price-list', { params: query });
};

export const putPriceList = (price: Partial<PriceQuery>) => {
  return axios.put(`/secure/company/price-list/${price.id}`, price);
};

export const postPriceList = (price: Partial<PriceQuery>) => {
  return axios.post(`/secure/company/price-list`, price);
};

export const getCategories = (query: CompanyQuery) => {
  return axios.get('/secure/product/categories', { params: query });
};

export const getVats = (query: CompanyQuery) => {
  return axios.get('/secure/vat', { params: { ...query } });
};

export const getTaxes = (query: CompanyQuery) => {
  return axios.get('/secure/tax', { params: { ...query } });
};

export const getTaxesDocument = (query: CompanyQuery, documentType: number) => {
  return axios.get('/secure/tax', { params: { ...query, documentType } });
};

export const patchTaxe = (id: number, isEnabled: boolean) => {
  return axios.patch(`/secure/tax/enabled/${id}`, { enabled: isEnabled });
};

export const patchVat = (id: number, isEnabled: boolean) => {
  return axios.patch(`/secure/vat/enabled/${id}`, { enabled: isEnabled });
};

export const getUnits = () => {
  return axios.get('/secure/units');
};

export const getBrands = (query: CompanyQuery) => {
  return axios.get('/secure/product/brands', { params: query });
};

export const addBrand = (query: any) => {
  return axios.post('/secure/product/brand', query);
};
export const addCategory = (query: any) => {
  return axios.post('/secure/product/category', query);
};
export const postVat = (vat: { company: number | undefined; enabled: boolean; vat: number }) => {
  return axios.post('/secure/vat/new', vat);
};

export const putVat = (vat: { id: number; company: number; vat: number }) => {
  return axios.put(`/secure/vat/edit/${vat.id}`, vat);
};

export const postTax = (tax: FieldValues) => {
  return axios.post('/secure/tax/new', { ...tax, enabled: true });
};

export const putTax = (tax: FieldValues) => {
  return axios.put(`/secure/tax/edit/${tax.id}`, { ...tax });
};

export const getBankList = (country: string) => {
  return axios.get<Bank[]>('/secure/bank/list', { params: { country: country?.toUpperCase() } });
};

export const getUserBanks = ({ company }: { company: string | number }) => {
  return axios.get<UserBank[]>('/secure/company/bank-account/list', {
    params: { company },
  });
};

export const postBank = (payload: Partial<UserBank>) => {
  return axios.post('/secure/company/bank-account/new', { ...payload });
};

export const putBank = (payload: Partial<UserBank>) => {
  return axios.post(`/secure/company/bank-account/edit/${payload.id}`, { ...payload });
};

export const getExpenseCategories = () => {
  return axios.get('/secure/expense-categories');
};

export const getIncomeCategories = () => {
  return axios.get('/secure/income-categories');
};

export const getWarehouses = (query: {
  company?: string | number;
  with_stock_value?: boolean;
  active?: true;
}) => {
  return axios.get<{ rows: Warehouse[]; count: number }>('/secure/warehouses/v2', {
    params: query,
  });
};

export const getPaymentMethods = (type: string | number) => {
  return axios.get<PaymentMethod[]>('/secure/payment/method/get', { params: { type } });
};

export const getBankAccounts = (company: number | string) => {
  return axios.get<BankAccount[]>('/secure/bank-account/list', { params: { company } });
};

// Begin Projects

export const getProjects = (company: number | string) => {
  return axios.get<Project[]>('/secure/project', { params: { company } });
};

export const putProject = (project: Project, company: string | number) => {
  return axios.put(`/secure/project/edit/${project.id}`, {
    company,
    name: project.name,
    enabled: project.enabled,
  });
};
// End Projects

export const getWarehouseProducts = (query: any) => {
  return axios.get(`/secure/products/v2`, { params: query });
};

export const getPlanPrice = (type: string) => {
  return axios.get<SubscriptionPrice[]>('/secure/user/prices', {
    params: { type: type?.toUpperCase() },
  });
};

export const checkOrderId = (id: string) => {
  return axios.get(`/secure/purchases/verify/${id}`);
};

export const checkPaymentId = (id: string) => {
  return axios.get(`/secure/purchases/payment/verify/${id}`);
};
export const getUserPlan = () => {
  return axios.get<UserPlan[]>(`/secure/user/plans`);
};

export const getLinkPayment = (data: any) => {
  return axios.post<any>('/secure/purchases/new', data);
};
export const getPaymentGateways = (data: any) => {
  return axios.get<PaymentGateway[]>('/secure/purchases/paymentGateway/all', {
    params: { country: data.country, currency: data.currency },
  });
};
export const addNewCompany = () => {
  return axios.post<any>('/secure/company/new/v2', null);
};

export const exportStockToFile = (query: any) => {
  return axios.get(`/secure/stock/export`, { params: query, responseType: 'arraybuffer' });
};

export const sendSmsDoc = (query: any) => {
  return axios.post(`/secure/document/${query.id}/sms`, { phone: query.phone });
};

export const sendEmailDoc = (query: any, id: number) => {
  return axios.post(`/secure/document/${id}/send`, query);
};
export const headerSMSReq = (query: any) => {
  return axios.post(`/secure/user/requests/new`, {
    code: 'SMSHeader',
    smsHeader: query.smsHeader,
    company: query.company,
  });
};

export const getListBankWithTotal = ({ company }: { company: string | number }) => {
  return axios.get(`/secure/bank-account/list`, { params: { company } });
};

export const getTaxesSwiver = () => {
  return axios.get<any>('/secure/tax/swiver');
};
export const getVatSwiver = () => {
  return axios.get<any>('/secure/vat/swiver');
};

export const finishAddNewCompany = (data: any) => {
  const formData = new FormData();

  // Append vats

  data.vats.forEach((vat: any, i: number) => {
    formData.append(`vats[${i}][vat]`, vat.vat);
    formData.append(`vats[${i}][enabled]`, vat.enabled);
  });

  // Append taxes
  data.taxes.forEach((tax: any, i: number) => {
    formData.append(`taxes[${i}][name]`, tax.name);
    formData.append(`taxes[${i}][amount]`, tax.amount);
    formData.append(`taxes[${i}][enabled]`, tax.enabled);
  });

  const setting = data.setting;
  Object.keys(setting).forEach((key) => {
    if (key === 'contact_phones') {
      const contactPhones: ContactPhone[] = setting[key] || [];
      contactPhones.forEach((contactPhone, index) => {
        Object.keys(contactPhone)
          .filter((contactKey) => contactKey !== 'id')
          .forEach((contactKey) => {
            formData.append(
              `setting[contact_phones][${index}][${contactKey}]`,
              contactPhone[contactKey as keyof typeof contactPhone] + ''
            );
          });
      });
    } else {
      formData.append(`setting[${key}]`, setting[key]);
    }
  });

  // Append name
  formData.append('name', data.name);

  return delayPromise(axios.post(`/secure/company/new`, formData));
};

export const sendMemberMail = (email: string) => {
  return axios.post('/secure/user/sendInvitation', { email: email });
};

export const getCloudFlareTrace = () => {
  return axios.get('https://www.cloudflare.com/cdn-cgi/trace');
};
export const loginWithGoogle = (data: any) => {
  return axios.post('/open/user/login-google', data);
};

export const updatePhone = (phone: string) => {
  return axios.post('/secure/user/updatePhone', { phone });
};

export const getDocumentTemplates = () => {
  return axios.get<any>('/secure/documentTemplate');
};

export const updateDefaultTemplate = (id: number) => {
  return axios.post<any>('/secure/documentTemplate/', { id });
};
