import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import PhoneInput from 'react-phone-input-2';
import ar from 'react-phone-input-2/lang/ar.json';
import fr from 'react-phone-input-2/lang/fr.json';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import isEmail from 'validator/es/lib/isEmail';
import * as Yup from 'yup';
import 'yup-phone';
import { RootState } from '../../../../setup/Store';
import { requestSMS } from '../../../../setup/redux/effects/AuthEffects';
import { checkEmail, checkPhone } from '../../../../setup/redux/effects/ValidationEffects';
import { enableUser, signUp } from '../../../../setup/redux/reducers/AuthReducer';
import {
  readActivities,
  readCountries,
  readCurrencies,
  setLanguage,
} from '../../../../setup/redux/reducers/UtilsReducers';
import currency from '../../../assets/currency.json';
import SwiverSelect from '../../../components/SwiverSelect/SwiverSelect';
import { toAbsoluteUrl } from '../../../helpers';
import { language } from '../../../i18n/InternalisationProvider';
import { PasswordMeterComponent } from '../../../utils/ts/components';
import { SignUpRequest } from '../models/SignUpModel';

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: '',
  phone: '',
  name: 'company',
  coupon: '',
  activity: '',
  companySize: 1,
  country: process.env.REACT_APP_DEFAULT_COUNTRY || 'TN',
  currency: process.env.REACT_APP_DEFAULT_CURRENCY || 'TND',
  firstNumber: '',
  secondNumber: '',
  thirdNumber: '',
  fourthNumber: '',
  terms: false,
};

const validateEmail = async (email: string) => {
  if (isEmail(email || '')) {
    try {
      const res = await checkEmail({ email });
      return !res.data ? true : 'FORM.ERRORS.IN-USE';
    } catch (error: any) {
      return error.toJSON().message;
    }
  } else return 'FORM.ERRORS.EMAIL';
};
const validatePhone = async (phone: string) => {
  const phoneSchema = Yup.string()
    .required('FORM.ERRORS.REQUIRED')
    .phone(undefined, undefined, 'FORM.ERRORS.PHONE')
    .test('phone-validation', 'FORM.ERRORS.IN-USE', async (value) => {
      const phoneValidation = Yup.string()
        .required('FORM.ERRORS.REQUIRED')
        .phone(undefined, undefined, 'FORM.ERRORS.PHONE');
      const isValid = await phoneValidation.isValid(value);
      if (isValid) {
        try {
          const { data } = await checkPhone({ phone: value || '' });
          return !data;
        } catch (error: any) {
          return false;
        }
      }
      return true;
    });
  try {
    await phoneSchema.validate(phone);
    return true;
  } catch (error: any) {
    return error.message;
  }
};

export function Registration() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [isEmailMessageShown, setIsEmailMessageShown] = useState(false);
  const isSigningUp = useSelector((state: RootState) => state.auth.isSigningUp);
  const locale = useSelector((state: RootState) => state.utils.locale);
  const [countryOptions, setCountryOptions] = useState<any[]>([]);
  const [step, setStep] = useState(0);
  const [isCodeShown, setIsCodeShown] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(
    (process.env.REACT_APP_DEFAULT_COUNTRY || 'TN').toLowerCase()
  );
  const countries = useSelector((state: RootState) => state.utils.countries);
  const activities = useSelector((state: RootState) => state.utils.activities);
  const currencies = useSelector((state: RootState) => state.utils.currencies);
  const signUpError = useSelector((state: RootState) => state.auth.signUpError);
  const signUpDraft = useSelector((state: RootState) => state.auth.signUpDraft);
  const intl = useIntl();
  const submitRef = useRef(null);
  const direction = useSelector((state: RootState) => state.utils.direction);
  useEffect(() => {
    if (isSigningUp === true) {
      setStep(2);
      setTimeout(() => {
        setIsEmailMessageShown(true);
      }, 5000);
    }
  }, [isSigningUp]); // eslint-disable-line react-hooks/exhaustive-deps
  const [customerCountry, setCustomerCountry] = useState<string>(
    process.env.REACT_APP_DEFAULT_COUNTRY || 'TN'
  );
  useEffect(() => {
    fetch('https://www.cloudflare.com/cdn-cgi/trace', {
      method: 'GET',
      redirect: 'follow',
    })
      .then((response) => response.text())
      .then((result) => {
        const lines = result.split('\n');
        let loc = null;

        for (const line of lines) {
          if (line.startsWith('loc=')) {
            loc = line.split('=')[1];
            break;
          }
        }
        if (loc === 'SA') {
          dispatch(setLanguage({ locale: 'ar' as language }));
        }
        setCustomerCountry(loc || process.env.REACT_APP_DEFAULT_COUNTRY || 'TN');
      })
      .catch((error) => {});

    if (countries === undefined) {
      dispatch(readCountries());
    }
    if (!currencies.length) {
      dispatch(readCurrencies());
    }
    if (!activities.length) {
      dispatch(readActivities());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const previous = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const handleShowCode = (e: any) => {
    setIsCodeShown(e.target.checked);
  };
  const [sendSMS, setSendSMS] = useState(0);
  const [remainingTime, setRemainingTime] = useState(60);

  const requestNewSMSCode = () => {
    setIsDisabled(true);
    if (sendSMS === 3) {
      return;
    }
    setRemainingTime(60);
    const intervalId = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);
    setSendSMS(sendSMS + 1);
    requestSMS(getValues('phone'))
      .then((res) => {})
      .catch(() => {
        toast.error(intl.formatMessage({ id: 'TOAST.ERROR' }));
      });
    setTimeout(() => {
      clearInterval(intervalId);

      setIsDisabled(false);
    }, 60000);
  };
  const [isDisabled, setIsDisabled] = useState(false);
  const listCurrency: { [key: string]: string } = currency;
  const onSubmit = async (values: any) => {
    if (step === 0) {
      const userData: SignUpRequest = {
        owner: {
          firstName: values.firstname,
          lastName: values.lastname,
          email: values.email,
          password: values.password,
          phone: values.phone,
          coupon: values.coupon,
          origin: process.env.REACT_APP_VENDOR_SLUG || 'V_SWIVER',
        },
        smsCode: '',
        registrationId: '',
        token: '',
        name: values.name,
        sub: '',
        setting: {
          defaultCurrency:
            process.env.REACT_APP_DEFAULT_CURRENCY ||
            listCurrency[values.country || process.env.REACT_APP_DEFAULT_COUNTRY || 'TN'] ||
            'TND',
          language: `${locale}-${customerCountry}`,
          jobTitle: 'CHIEF',
          country: customerCountry || process.env.REACT_APP_DEFAULT_COUNTRY || 'TN',
          activity: values.activity,
          companyActivity: 116,
          company_size: 1,
          autre: '',
          currency:
            process.env.REACT_APP_DEFAULT_CURRENCY ||
            listCurrency[values.country || process.env.REACT_APP_DEFAULT_COUNTRY || 'TN'] ||
            'TND',
          pays: customerCountry || process.env.REACT_APP_DEFAULT_COUNTRY || 'TN',
        },
      };
      dispatch(signUp(userData));
      setStep(2);
    } else if (step === 2) {
      setLoading(true);
      await dispatch(
        enableUser({
          ...signUpDraft,
          smsCode: `${values.firstNumber}${values.secondNumber}${values.thirdNumber}${values.fourthNumber}`,
        } as SignUpRequest)
      );
      setLoading(false);
    }
  };
  const goToLink = () => {
    window.open(
      'https://accounts.google.com/o/oauth2/auth?response_type=code&redirect_uri=' +
        window.location.origin +
        '/sign-in&client_id=' +
        process.env.REACT_APP_GOOGLE_CLIENT_ID +
        '&scope=email+profile&access_type=offline&approval_prompt=auto',
      '_self'
    );
  };
  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields: touched, isValid },
    getValues,
    control,
    setFocus,
  } = useForm({ mode: 'onTouched', defaultValues: initialValues });
  const email = useWatch({ name: 'email', control });
  const phone = useWatch({ name: 'phone', control });
  const validatePassword = (password: string) => {
    return getValues('password') === password || 'FORM.ERRORS.PASSWORD.DONT-MATCH';
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (countries?.length) {
        const options = countries.map((country) => ({
          value: country.code,
          label: country.country,
        }));
        setCountryOptions(options);
        clearInterval(interval);
      } else {
        dispatch(readCountries());
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [countries]);
  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)} id='kt_login_signup_form'>
      {step !== 2 && (
        <>
          {/* begin::Heading */}
          <div className='text-center'>
            {/* begin::Title */}
            <h1 className='text-dark mb-3'>
              <FormattedMessage id='AUTH.REGISTRATION.TITLE' />
            </h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='text-gray-400 fw-semibold fs-4 mb-4'>
              <FormattedMessage id='AUTH.REGISTRATION.HAVEACCOUNT' />
            </div>
            {/* end::Link */}

            {/* begin::Google link */}
            {/* <div
              onClick={() => goToLink()}
              className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100 border border-1 border-gray-300'>
              <img
                alt=' '
                src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                className='h-20px me-3'
              />
              <FormattedMessage
                id='AUTH.LOGIN.PROVIDER'
                values={{ provider: intl.formatMessage({ id: 'PROVIDERS.GOOGLE' }) }}
              />
            </div> */}
            {/* end::Google link */}
            {/* begin::Separator */}
            {/* <div className='d-flex justifiy-content-between align-items-center '>
              <hr className='w-100 me-3' />
              <div className='text-center text-muted text-uppercase fw-bold my-4'>
                <FormattedMessage id='AUTH.OR' />
              </div>
              <hr className='w-100 ms-3' />
            </div> */}

            {/* end::Separator */}
          </div>
          {/* end::Heading */}
        </>
      )}

      {step === 0 && (
        <>
          <div className='row  g-6'>
            <div className='col-md-6'>
              <input
                placeholder={intl.formatMessage({ id: 'AUTH.REGISTRATION.FIRSTNAME.PLACEHOLDER' })}
                type='text'
                autoComplete='off'
                {...register('firstname', { required: 'FORM.ERRORS.REQUIRED' })}
                className={clsx(
                  'form-control ',
                  {
                    'is-invalid': touched?.firstname && errors?.firstname,
                  },
                  {
                    'is-valid': touched?.firstname && !errors?.firstname,
                  }
                )}
              />
              {touched?.firstname && errors.firstname && (
                <div className='fv-help-block'>
                  <span role='alert' className='text-danger'>
                    <FormattedMessage
                      id={'' + errors.firstname.message}
                      values={{
                        field: intl.formatMessage({ id: 'AUTH.REGISTRATION.FIRSTNAME.LABEL' }),
                      }}
                    />
                  </span>
                </div>
              )}
            </div>
            <div className='col-md-6'>
              <input
                placeholder={intl.formatMessage({ id: 'AUTH.REGISTRATION.LASTNAME.PLACEHOLDER' })}
                type='text'
                autoComplete='off'
                {...register('lastname', {
                  required: 'FORM.ERRORS.REQUIRED',
                  maxLength: { value: 50, message: 'FORM.ERRORS.MAXLENGTH' },
                })}
                className={clsx(
                  'form-control',
                  {
                    'is-invalid': touched?.lastname && errors?.lastname,
                  },
                  {
                    'is-valid': touched?.lastname && !errors?.lastname,
                  }
                )}
              />
              {touched.lastname && errors?.lastname && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      <FormattedMessage
                        id={'' + errors.lastname.message}
                        values={{
                          field: intl.formatMessage({ id: 'AUTH.REGISTRATION.LASTNAME.LABEL' }),
                          max: 50,
                        }}
                      />
                    </span>
                  </div>
                </div>
              )}
              {/* end::Form group */}
            </div>
            <div className='col-12 d-flex  gap-3'>
              <Controller
                name='country'
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <SwiverSelect
                    menuPlacement='top'
                    className=' h-45px w-150px'
                    isClearable={false}
                    options={countryOptions}
                    value={value}
                    onChange={(e) => {
                      onChange(e.value);
                    }}
                  />
                )}
              />
              <Controller
                name='phone'
                control={control}
                defaultValue=''
                rules={{
                  required: 'FORM.ERRORS.REQUIRED',
                  validate: validatePhone,
                }}
                render={({
                  field: { value, onChange, onBlur, name },
                  fieldState: { invalid, isTouched, error },
                }) => (
                  <div className='d-flex flex-column gap-2'>
                    <PhoneInput
                      localization={locale === 'ar' ? ar : locale === 'fr' ? fr : undefined}
                      containerClass='d-flex flex-row'
                      // inputClass={clsx('form-control input-phone w-100 h-45px', {
                      //   'is-invalid': isTouched && error,
                      //   'is-valid': isTouched && !error,
                      //   'ps-16 pe-3': !isTouched,
                      // })}
                      searchClass='form-control form-control-lg'
                      // dropdownClass='bg-body'
                      alwaysDefaultMask={true}
                      defaultMask='...........................'
                      country={(process.env.REACT_APP_DEFAULT_COUNTRY || 'TN').toLowerCase()}
                      value={value}
                      inputProps={{
                        name: name,
                        required: true,
                        autoComplete: 'off',
                      }}
                      onChange={(value, data: any, event, formattedValue) => {
                        setSelectedCountry(data?.countryCode);
                        const phone = formattedValue.replaceAll(' ', '');
                        onChange(phone);
                      }}
                      onBlur={() => {
                        onBlur();
                      }}
                    />
                    {isTouched && error && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert' className='text-danger'>
                            <FormattedMessage
                              id={error.message}
                              values={{ field: intl.formatMessage({ id: 'FORM.FIELD.PHONE' }) }}
                            />
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              />
            </div>
            <div className='col-12'>
              <Controller
                control={control}
                render={({
                  field: { value, onChange, onBlur, name },
                  fieldState: { invalid, isTouched, error },
                }) => (
                  <>
                    <input
                      placeholder={intl.formatMessage({ id: 'FORM.FIELD.EMAIL.PLACEHOLDER' })}
                      type='text'
                      name={name}
                      onChange={onChange}
                      value={value}
                      onBlur={onBlur}
                      autoComplete='off'
                      className={clsx(
                        'form-control form-control',
                        { 'is-invalid': isTouched && invalid },
                        {
                          'is-valid': isTouched && !invalid,
                        }
                      )}
                    />
                    {isTouched && invalid && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert' className='text-danger'>
                            <FormattedMessage
                              id={error?.message}
                              values={{
                                field: intl.formatMessage({ id: 'FORM.FIELD.EMAIL' }),
                                value: value,
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    )}
                  </>
                )}
                name='email'
                rules={{ required: 'FORM.ERRORS.REQUIRED', validate: validateEmail }}
              />
            </div>

            <div className='col-12' data-kt-password-meter='true'>
              <input
                placeholder={intl.formatMessage({ id: 'AUTH.FORM.PASSWORD.PLACEHOLDER' })}
                type='password'
                {...register('password', {
                  required: 'FORM.ERRORS.REQUIRED',
                  minLength: { value: 8, message: 'FORM.ERRORS.MINLENGTH' },
                })}
                autoComplete='off'
                className={clsx(
                  'form-control ',
                  { 'is-invalid': touched?.password && errors?.password },
                  {
                    'is-valid': touched?.password && !errors?.password,
                  }
                )}
              />
              <div
                className='d-flex align-items-center my-3'
                data-kt-password-meter-control='highlight'>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
              </div>
              <span className='text-muted'>
                <FormattedMessage id='PASSWORD.HINT' />.
              </span>
              {touched?.password && errors?.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      <FormattedMessage
                        id={'' + errors?.password.message}
                        values={{
                          field: intl.formatMessage({ id: 'AUTH.FORM.PASSWORD.LABEL' }),
                          min: 8,
                        }}
                      />
                    </span>
                  </div>
                </div>
              )}
              {/* </div> */}
            </div>
            <div className='col-12'>
              <div className='form-check form-switch form-check-custom form-check-solid d-flex '>
                <input
                  className='form-check-input'
                  onChange={handleShowCode}
                  type='checkbox'
                  value=''
                />
                <label
                  className='form-check-label text-primary fw-bold'
                  htmlFor='flexSwitchDefault'>
                  <FormattedMessage id='AUTH.REGISTRATION.HAVEPARTNERCODE' />
                </label>
              </div>
            </div>
            {isCodeShown && (
              <div className='col-12  animate fadeIn'>
                <input
                  type='text'
                  placeholder={intl.formatMessage({
                    id: 'AUTH.REGISTRATION.PARTNERCODE.LABEL',
                  })}
                  autoComplete='off'
                  {...register('coupon')}
                  className={clsx(
                    'form-control ',
                    {
                      'is-invalid': touched?.coupon && errors?.coupon,
                    },
                    {
                      'is-valid': touched?.coupon && !errors?.coupon,
                    }
                  )}
                />
                {touched?.coupon && errors?.coupon && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert' className='text-danger'>
                        <FormattedMessage id={'' + errors?.coupon.message} />
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className='col-12'>
              <div className='form-check form-check-custom form-check-solid form-check-sm'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  {...register('terms', { validate: (value) => value === true })}
                  id='flexRadioLg'
                />
                <label className='form-check-label text-muted fw-bold' htmlFor='flexRadioLg'>
                  <FormattedMessage id='REGISTRATION.ACCEPT.TERMS' />{' '}
                  <a href='https://swiver.io/termes/' className=''>
                    <FormattedMessage id='REGISTRATION.TERMS.OF.USE' />
                  </a>
                </label>
              </div>
            </div>

            <div className='col-12'>
              <button
                className='btn btn-lg btn-primary btn-submit w-100'
                type='submit'
                ref={submitRef}>
                {!loading && (
                  <>
                    <span className='indicator-label'>
                      <FormattedMessage id='FORM.SUBMIT' />
                    </span>
                  </>
                )}
                {loading && (
                  <span className='indicator-progress d-block'>
                    <FormattedMessage id='FORM.WAIT' />
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            <div className='col-12 text-center'>
              <span className='text-muted fw-semibold  '>
                <FormattedMessage id='AUTH.ALREADY.USED.SWIVER' />{' '}
                <Link to='/sign-in' className='link-primary'>
                  <FormattedMessage id='AUTH.LOGIN.TITLE' />
                </Link>
              </span>
            </div>
          </div>
        </>
      )}
      {/* end::First step */}

      {/* end::Second step */}
      {step === 2 && (
        <>
          <div className='d-flex flex-column justify-content-between h-100'>
            <div className='align-self-center pb-15'>
              <img
                src={toAbsoluteUrl('/media/icons/swiver/smsCode.png')}
                className='img-fluid'
                alt=''
              />
            </div>
            {selectedCountry == 'tn' && (
              <div className=' d-flex flex-column text-center gap-5'>
                <h1>
                  <FormattedMessage id='REGISTRATION.STEP2.TITLE_SMS' />
                </h1>
                <h5>
                  {phone}
                  <i
                    onClick={() => {
                      setStep(0);
                    }}
                    className='cursor-pointer ms-2 fs-4 text-primary fa-light fa-pen-to-square'></i>
                </h5>
                {isEmailMessageShown && (
                  <div className='fs-7 fw-semibold'>
                    <FormattedMessage id='REGISTRATION.STEP2.TITLE_2_EMAIL' values={{ email }} />
                  </div>
                )}
              </div>
            )}
            {selectedCountry != 'tn' && (
              <div className=' d-flex flex-column text-center gap-5'>
                <h1>
                  <FormattedMessage id='REGISTRATION.STEP2.TITLE_SMS' />
                </h1>
                <h5>
                  <b>{email}</b>
                  <i
                    onClick={() => {
                      setStep(0);
                    }}
                    className='cursor-pointer ms-2 fs-4 text-primary fa-light fa-pen-to-square'></i>
                </h5>
              </div>
            )}

            {signUpError && (
              <div className='notice d-flex bg-light-danger rounded border-danger border border-dashed  p-6'>
                <span className='svg-icon svg-icon-2tx svg-icon-danger me-4'>
                  <i className='fs-1 text-danger fa-light fa-triangle-exclamation'></i>
                </span>
                <div className='d-flex flex-stack flex-grow-1 '>
                  <div className=' fw-semibold'>
                    <h4 className='text-gray-900 fw-bold'>
                      <FormattedMessage id='REGISTRATION.STEP2.CODE_INVALID' />
                    </h4>
                    <div className='fs-6 text-gray-700 '>
                      <FormattedMessage id='REGISTRATION.STEP2.CODE_INVALID_MSG' />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              className={clsx('row g-5', direction === 'RTL' && 'flex-row-reverse')}
              id='smsCode'>
              <div className='col'>
                <input
                  step='any'
                  type='number'
                  className='input-sms p-2'
                  inputMode='numeric'
                  onKeyUp={(e) => {
                    if (e.currentTarget.value.length > 0) setFocus('secondNumber');
                  }}
                  {...register('firstNumber', { required: true, valueAsNumber: true })}></input>
              </div>
              <div className='col'>
                <input
                  step='any'
                  type='number'
                  className='input-sms p-2'
                  inputMode='numeric'
                  onKeyUp={(e) => {
                    if (e.currentTarget.value.length > 0) setFocus('thirdNumber');
                  }}
                  {...register('secondNumber', { required: true, valueAsNumber: true })}></input>
              </div>{' '}
              <div className='col'>
                <input
                  step='any'
                  type='number'
                  className='input-sms p-2'
                  inputMode='numeric'
                  onKeyUp={(e) => {
                    if (e.currentTarget.value.length > 0) setFocus('fourthNumber');
                  }}
                  {...register('thirdNumber', { required: true, valueAsNumber: true })}></input>
              </div>
              <div className='col'>
                <input
                  type='number'
                  step='any'
                  className='input-sms p-2'
                  inputMode='numeric'
                  {...register('fourthNumber', { required: true, valueAsNumber: true })}></input>
              </div>
              <div className='col-12'>
                <button
                  type='submit'
                  ref={submitRef}
                  className='btn btn-lg btn-primary btn-submit w-100'>
                  <FormattedMessage id='REGISTRATION.STEP2.BTN_CHECK' />
                </button>
              </div>
            </div>
            <div className='text-center mt-5'>
              <button
                disabled={isDisabled}
                className='btn btn-link btn-color-primary btn-active-color-primary me-5 mb-2'
                onClick={() => {
                  requestNewSMSCode();
                }}>
                {isDisabled
                  ? `Wait ${remainingTime} seconds to send again`
                  : intl.formatMessage({ id: 'REGISTER.STEP2.RESEND_CODE' })}
              </button>
            </div>
          </div>
        </>
      )}

      {/* end::Form group */}
    </form>
  );
}
