import clsx from 'clsx';

type CheckBox = {
  checked?: boolean;
  name?: string;
  value?: string | number;
  label?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  children?: React.ReactNode;
};

function CustomCheckbox({
  checked = false,
  name = '',
  value = '',
  onChange = () => {},
  onBlur = () => {},
  children,
}: CheckBox) {
  return (
    <label
      className={clsx(
        'btn btn-outline btn-outline-dashed d-flex align-items-center gap-3 px-4 py-auto',
        checked && 'btn-light-primary btn-active-light-primary border border-1 border-primary'
      )}>
      <div className='form-check form-check-custom form-check-solid'>
        <input
          type='checkbox'
          className='form-check-input'
          name={name}
          value={value}
          checked={checked}
          onChange={(e) => {
            onChange(e);
          }}
          onBlur={onBlur}
        />
        <span className='form-check-label'>{children}</span>
      </div>
    </label>
  );
}

export default CustomCheckbox;
