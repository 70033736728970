import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CheckOrDraft } from '../../../app/models/ChecksAndDraftResponse';
import { getChecksAndDrafts, GetChecksAndDraftsPayload } from '../effects/ChecksAndDraftsEffects';

export const readChecksAndDrafts = createAsyncThunk(
  'checksAndDrafts/get',
  async (payload: Partial<GetChecksAndDraftsPayload | any>) => {
    const { data } = await getChecksAndDrafts({
      ...payload,
    });
    return data;
  }
);

const checksAndDraftSlice = createSlice({
  name: 'checksAndDrafts',
  initialState: {
    checksAndDrafts: undefined as CheckOrDraft[] | undefined,
    loading: false,
    count: 0,
    offset: 0,
    limit: 10,
  },
  reducers: {
    changeCheckAndDrafLimit: (state, action) => {
      state.limit = action.payload;
      state.offset = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(readChecksAndDrafts.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(readChecksAndDrafts.fulfilled, (state, action) => {
      state.checksAndDrafts = action.payload.rows;
      state.count = action.payload.count;
      state.loading = false;
      const args = action.meta.arg;
      state.offset = args.page ? args.page * state.limit - state.limit : 0;
    });
    builder.addCase(readChecksAndDrafts.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default checksAndDraftSlice.reducer;
export const { changeCheckAndDrafLimit } = checksAndDraftSlice.actions;
