import * as Yup from 'yup';
const editSchemas = [
  Yup.object().shape({
    name: Yup.string().required('FORM.ERRORS.REQUIRED'),
    vat: Yup.number(),
    taxes: Yup.array(),
    allow_empty_stock: Yup.boolean(),
    unit_price: Yup.number()
      .required('FORM.ERRORS.REQUIRED')
      .transform((cv, ov) => {
        return ov === '' ? undefined : cv;
      })
      .typeError('FORM.ERRORS.NUMBER'),
    tPrice: Yup.boolean(),
    product_price_list: Yup.array().of(
      Yup.object().shape({
        price_list: Yup.string().required('FORM.ERRORS.REQUIRED'),
        price: Yup.number().required('FORM.ERRORS.REQUIRED'),
        calculType: Yup.string().required('FORM.ERRORS.REQUIRED'),
        calcul_method: Yup.string().test(
          'REQUIRED',
          'FORM.ERRORS.REQUIRED',
          (value, { parent }) =>
            Number(parent.calculType) === 1 ||
            (Number(parent.calculType) === 2 && ['FMR', 'RMR'].includes(value + ''))
        ),
        rate: Yup.number()
          .transform((cv, ov) => {
            return ov === '' ? undefined : cv;
          })
          .test(
            'REQUIRED',
            'FORM.ERRORS.REQUIRED',
            (value, { parent }) =>
              !(
                ((Number(parent.calculType) === 2 && parent.calcul_method === 'FMR') ||
                  Number(parent.calculType) === 1) &&
                value === undefined
              )
          )
          .typeError('FORM.ERROR.NUMBER'),
        margin: Yup.number()
          .transform((cv, ov) => {
            return ov === '' ? undefined : cv;
          })
          .test(
            'REQUIRED',
            'FORM.ERRORS.REQUIRED',
            (value, { parent }) =>
              !(
                Number(parent.calculType) === 2 &&
                parent.calcul_method === 'RMR' &&
                value === undefined
              )
          )
          .typeError('FORM.ERROR.NUMBER'),
        tPrice: Yup.boolean(),
      })
    ),
    logistics_infos: Yup.array().when('model', {
      is: 'materiel',
      then: Yup.array().of(
        Yup.object().shape({
          min_stock: Yup.number()
            .transform((cv, ov) => {
              return ov === '' ? undefined : cv;
            })
            .typeError('FORM.ERRORS.NUMBER'),
          max_stock: Yup.number()
            .transform((cv, ov) => {
              return ov === '' ? undefined : cv;
            })
            .required('FORM.ERRORS.REQUIRED')
            .test('SUPMIN', 'FORM.ERRORS.MIN', (value, context) => {
              if (value === undefined) return false;
              return Number(value) >= Number(context.parent.min_stock);
            })
            .typeError('FORM.ERRORS.NUMBER'),
          warehouse: Yup.string()
            .transform((value) => (!value ? null : value))
            .nullable()
            .required('FORM.ERRORS.REQUIRED'),
        })
      ),
    }),
  }),
  Yup.object().shape({
    brand: Yup.string().when('model', {
      is: 'materiel',
      then: Yup.string().required('FORM.ERRORS.REQUIRED'),
    }),
    category: Yup.string().required('FORM.ERRORS.REQUIRED'),
    unit: Yup.string().required('FORM.ERRORS.REQUIRED'),
  }),
];
const addSchemas = [
  Yup.object().shape({
    model: Yup.string().required('FORM.ERRORS.REQUIRED'),
  }),
  ...editSchemas,
];

export { addSchemas, editSchemas };
