import { useEffect } from 'react';
import { ProductPriceList } from '../../models/ProductResponse';
import { getPartialPrice } from '../../utils/calcUtils';

const isStringTrue = (value?: string) => value === 'true';

export default function PriceListItem({
  price,
  value,
  onChange = () => {},
  vat,
  unitPrice,
  tPrice,
}: {
  price: ProductPriceList;
  value: number | string;
  onChange: (value: number) => void;
  vat: number | string;
  unitPrice: number | string;
  tPrice: 'true' | 'false';
}) {
  useEffect(() => {
    const normalPrice = !isStringTrue(tPrice)
      ? getPartialPrice(Number(unitPrice), Number(vat))
      : Number(unitPrice);
    const calcMethod = price?.calcul_method || 'FMR';
    const calcType = +(price?.calculType || 2);
    const rate = +(price?.rate || 0);
    const margin = +Number(price?.margin || 0);
    const tPriceG = isStringTrue(price?.tPriceG);
    if (calcType === 2) {
      if (calcMethod === 'FMR') {
        onChange(normalPrice || normalPrice === 0 ? normalPrice + (rate || 0) : 0);
        return;
      }
      if (calcMethod === 'RMR') {
        onChange(normalPrice || normalPrice === 0 ? normalPrice * ((100 + margin) / 100) : 0);
        return;
      }
    } else if (calcType === 1) {
      if (tPriceG) {
        onChange(rate ? rate : 0);
        return;
      } else {
        onChange(rate ? getPartialPrice(rate, Number(vat)) : 0);
        return;
      }
    } else {
      onChange(0);
    }
  }, [JSON.stringify(price), vat, unitPrice, tPrice]);

  return <></>;
}
