import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../setup/Store';
import StyledCurrency from '../../components/StyledCurrency/StyledCurrency';
import { KTSVG } from '../../helpers';

function BillingDetails({ planDetails, paymentAction, withAction = true }: any) {
  const currentPlant = useSelector((state: RootState) => state.auth.user?.current_plan);

  const totalCompany = () => {
    return planDetails.total.type == 'monthly'
      ? (planDetails.nbrCompany - planDetails.minCompany + planDetails.extratCompany) *
          planDetails.total.monthlyPrices.priceExtraCompany
      : (planDetails.nbrCompany - planDetails.minCompany + planDetails.extratCompany) *
          planDetails.total.yearlyPrices.priceExtraCompany *
          12;
  };
  const totalSMS = () => {
    return planDetails.total.type == 'monthly'
      ? ((planDetails.nbrSMS - 20) / 1000) * planDetails.total.monthlyPrices.priceExtraSMS
      : ((planDetails.nbrSMS - 20) / 1000) * planDetails.total.yearlyPrices.priceExtraSMS;
  };
  const totalUser = () => {
    return planDetails.total.type == 'monthly'
      ? (planDetails.nbrUser - planDetails.minUser + planDetails.extratUser) *
          planDetails.total.monthlyPrices.priceExtraUser
      : (planDetails.nbrUser - planDetails.minUser + planDetails.extratUser) *
          planDetails.total.yearlyPrices.priceExtraUser *
          12;
  };
  return (
    <>
      <div className='table-responsive'>
        <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
          <tbody className='fw-semibold text-gray-600'>
            <tr>
              <td className='text-muted'>
                <FormattedMessage id='PRICING.PAYMENT_DETAIL.SUBSCRIPTION' />{' '}
                {planDetails.total.type == 'monthly' ? (
                  <FormattedMessage id='PRICING.PAYMENT_DETAIL.MONTHLY' />
                ) : (
                  <FormattedMessage id='PRICING.PAYMENT_DETAIL.YEARLY' />
                )}{' '}
                <FormattedMessage id='PRICING.PAYMENT_DETAIL.PREMIUM' />
                <br />
                <StyledCurrency
                  amount={
                    planDetails.total.type == 'monthly'
                      ? planDetails.total.monthlyPrices.price
                      : planDetails.total.yearlyPrices.price
                  }
                  amountClassName='text-gray-800 text-hover-primary'
                  supClassName='fs-6 text-muted '
                  containerClassName=''
                  currency={planDetails?.data?.currency}
                />{' '}
                * {process.env.REACT_APP_DEFAULT_CURRENCY} *{' '}
                {planDetails.total.type == 'monthly' ? 1 : 12}{' '}
                <FormattedMessage id='PRICING.PAYMENT_DETAIL.ABRV_MONTH' />
              </td>
              <td className='fw-semibold text-end'>
                <span className='text-gray-800 text-hover-primary'>
                  <StyledCurrency
                    amount={
                      planDetails.total.type == 'monthly'
                        ? planDetails.total.monthlyPrices.price
                        : planDetails.total.yearlyPrices.price * 12
                    }
                    amountClassName='text-gray-800 text-hover-primary'
                    supClassName='fs-6 text-muted '
                    containerClassName=''
                    currency={planDetails?.data?.currency}
                  />
                </span>
              </td>
            </tr>
            <tr>
              <td className='text-muted'>
                <FormattedMessage id='PRICING.PAYMENT_DETAIL.COMPANY' /> X {planDetails.nbrCompany}
              </td>
              <td className='fw-semibold text-end'>
                <StyledCurrency
                  amount={totalCompany()}
                  amountClassName='text-gray-800 text-hover-primary'
                  supClassName='fs-6 text-muted '
                  containerClassName=''
                  currency={planDetails?.data?.currency}
                />
              </td>
            </tr>
            <tr>
              <td className='text-muted'>
                <FormattedMessage id='PRICING.PAYMENT_DETAIL.USERS' /> X {planDetails.nbrUser}
              </td>
              <td className='fw-semibold text-end'>
                <StyledCurrency
                  amount={totalUser()}
                  amountClassName='text-gray-800 text-hover-primary'
                  supClassName='fs-6 text-muted '
                  containerClassName=''
                  currency={planDetails?.data?.currency}
                />
              </td>
            </tr>
            <tr>
              <td className='text-muted'>
                <FormattedMessage id='PRICING.PAYMENT_DETAIL.PACK_SMS' /> X {planDetails.nbrSMS}
              </td>
              <td className='fw-semibold text-end'>
                <StyledCurrency
                  amount={totalSMS()}
                  amountClassName='text-gray-800 text-hover-primary'
                  supClassName='fs-6 text-muted '
                  containerClassName=''
                  currency={planDetails?.data?.currency}
                />
              </td>
            </tr>
            <tr>
              <td className='text-muted'>
                {' '}
                <FormattedMessage id='PRICING.PAYMENT_DETAIL.AMOUT_NET_TTC' />
              </td>
              <td className='fw-semibold text-end'>
                <StyledCurrency
                  amount={planDetails.totalTTC}
                  amountClassName='text-gray-800 text-hover-primary'
                  supClassName='fs-6 text-muted '
                  containerClassName=''
                  currency={planDetails?.data?.currency}
                />
              </td>
            </tr>{' '}
            <tr>
              <td className='text-muted'>
                {' '}
                <FormattedMessage id='PRICING.PAYMENT_DETAIL.AMOUT_TTC' />
              </td>
              <td className='fw-semibold text-end'>
                <StyledCurrency
                  amount={planDetails.totalTTC * (1 + planDetails.vat / 100) + 1}
                  amountClassName='text-gray-800 text-hover-primary'
                  supClassName='fs-6 text-muted '
                  containerClassName=''
                  currency={planDetails?.data?.currency}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {planDetails.paymentType == 'transaction' && (
        <div className='alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 '>
          <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0'>
            {' '}
            <KTSVG path='/media/icons/duotune/general/gen045.svg' className='svg-icon svg-icon-2' />
          </span>
          <div className='d-flex flex-column pe-0 pe-sm-10'>
            <span>
              <FormattedMessage id='PRICING.PAYMENT_DETAIL.MESSAGE_BANK_TRANSFERT' />
            </span>
          </div>
        </div>
      )}
      {withAction && (
        <div className='d-flex justify-content-center py-2 pt-4'>
          <button
            className='btn btn-primary w-100'
            onClick={() => {
              paymentAction();
            }}>
            <FormattedMessage id='PRICING.PAYMENT_DETAIL.CONFIRM_ORDER' />
          </button>
        </div>
      )}
    </>
  );
}

export default BillingDetails;
