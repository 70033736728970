import axios from 'axios';
import { Balance } from '../../../app/models/CashManagementResponse';

export const getBalancesList = async (company: number) => {
  return await axios.get<Balance[]>(`/secure/balance/list`, { params: { company, enabled: true } });
};

export const getBalanceHistory = async (query: any) => {
  return await axios.get(`/secure/balance/history/payments/v2`, { params: query });
};
