import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../setup/Store';
import { readCountries } from '../../../setup/redux/reducers/UtilsReducers';
import SwiverSelect from '../../components/SwiverSelect/SwiverSelect';

type option = {
  label: string;
  value: string;
};
function BillingAddress() {
  const intl = useIntl();

  const [countryOptions, setCountryOptions] = useState<option[]>([]);
  const countries = useSelector((state: RootState) => state.utils.countries);
  const dispatch = useDispatch();
  const { register, formState } = useFormContext();
  const currentCompany = useSelector((state: RootState) => state.auth.user?.current_company);
  const [zipCode, setzipCode] = useState(currentCompany?.setting.zip_code);
  const [city, setcity] = useState(currentCompany?.setting.region);
  useEffect(() => {
    const interval = setInterval(() => {
      if (countries?.length) {
        const options = countries.map((country: any) => ({
          value: country.code,
          label: country.country,
        }));
        setCountryOptions(options);
        clearInterval(interval);
      } else {
        dispatch(readCountries());
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [countries]);
  return (
    <div className='d-flex flex-column gap-5 gap-md-7 pb-5'>
      <div className='d-flex flex-column flex-md-row gap-5'>
        <div className='fv-row flex-row-fluid fv-plugins-icon-container'>
          <label className='required form-label'>
            <FormattedMessage id='PRICING.ADDRESS.ADDRESS_LINE1' />
          </label>
          <input
            className={clsx(
              'form-control form-control-solid',
              formState.errors.address && 'is-invalid'
            )}
            {...register('address', { required: 'Address is required' })}
            placeholder={intl.formatMessage({ id: 'PRICING.ADDRESS.ADDRESS_LINE1' })}
          />
          <div className='fv-plugins-message-container invalid-feedback' />
        </div>
        <div className='flex-row-fluid'>
          <label className='form-label'>
            <FormattedMessage id='PRICING.ADDRESS.ADDRESS_LINE2' />
          </label>
          <input
            className='form-control form-control-solid'
            name='billing_order_address_2'
            placeholder={intl.formatMessage({ id: 'PRICING.ADDRESS.ADDRESS_LINE2' })}
          />
        </div>
      </div>
      <div className='d-flex flex-column flex-md-row gap-5'>
        <div className='flex-row-fluid'>
          <label className='form-label'>
            <FormattedMessage id='PRICING.ADDRESS.CITY' />
          </label>
          <input
            className='form-control form-control-solid'
            name='billing_order_city'
            value={city}
            onChange={() => {}}
          />
        </div>
        <div className='fv-row flex-row-fluid fv-plugins-icon-container w-100px'>
          <label className='required form-label'>
            <FormattedMessage id='PRICING.ADDRESS.POSTCODE' />
          </label>
          <input
            className='form-control form-control-solid'
            name='billing_order_postcode'
            value={zipCode}
            onChange={() => {}}
          />
          <div className='fv-plugins-message-container invalid-feedback' />
        </div>
        <div className='fv-row flex-row-fluid fv-plugins-icon-container'>
          <label className='required form-label'>
            <FormattedMessage id='PRICING.ADDRESS.COUNTRY' />
          </label>
          <SwiverSelect
            className='swiver-select swiver-select-solid  h-40px'
            value={currentCompany?.setting.country}
            onChange={() => {}}
            options={countryOptions}
          />{' '}
        </div>
      </div>
    </div>
  );
}

export default BillingAddress;
