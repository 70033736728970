import { ReactNode, useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../../setup/Store';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { toAbsoluteUrl } from '../../helpers';
import { SwImage } from '../../models/ProductResponse';
export interface aspectRatioProps {
  width: number;
  height: number;
  label: string;
}
const TO_RADIANS = Math.PI / 180;
export default function ImageCropper({
  rotate = 0,
  scale = 1,
  aspect = 1,
  value = '',
  onChange = () => {},
  modalTitle = <FormattedMessage id='IMAGE_CROPPER.TITLE' />,
  isCustomComponent = false,
  toggleRender = () => <></>,
  useAspectRation = true,
  multipleAspectRatio = true,
  aspectRatio = [
    { width: 1, height: 1, label: '1:1' },
    { width: 4, height: 3, label: '4:3' },
    { width: 16, height: 9, label: '16:9' },
  ],
  onChangeAspectRatio = () => {},
}: {
  rotate?: number;
  scale?: number;
  aspect?: number;
  value?: File | string | SwImage;
  onChange?: (newValue: string | File) => void;
  modalTitle?: ReactNode;
  isCustomComponent?: boolean;
  toggleRender?: (handleOpenImageCropper: () => void) => ReactNode;
  useAspectRation?: boolean;
  multipleAspectRatio?: boolean;
  aspectRatio?: any[];
  onChangeAspectRatio?: (aspect: aspectRatioProps) => void;
}) {
  const theme = useSelector((state: RootState) => state.utils.theme);
  const imageRef = useRef<HTMLInputElement>(null);
  const { formatMessage } = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [crop, setCrop] = useState<Crop>();
  const [imageSrc, setImageSrc] = useState('');
  const [output, setOutput] = useState('');
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const imgRef = useRef<HTMLImageElement>(null);
  const [fileType, setFileType] = useState('');
  const [fileName, setfileName] = useState('');
  const handleModalOpen = () => {
    setCrop(undefined);
    setCompletedCrop(undefined);
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      e.target.files &&
      e.target.files.length > 0 &&
      e.target.files[0].type.startsWith('image/')
    ) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      handleModalOpen();
      reader.addEventListener('load', () => {
        setImageSrc(reader.result?.toString() || '');
      });
      reader.readAsDataURL(e.target.files[0]);
      setFileType(e.target.files[0].type);
      setfileName(e.target.files[0].name);
    }
  };
  useEffect(() => {
    if (value instanceof String) {
      setOutput('');
    } else if (!(value instanceof File)) {
      setOutput((value as SwImage).web_path);
    }
  }, [value]);
  const handleOpenImageCropper = () => {
    imageRef?.current?.click();
  };
  const cropImageNow = () => {
    const canvas = document.createElement('canvas');
    const image = imgRef?.current;
    const ctx = canvas.getContext('2d');
    if (!ctx || !image || !crop || !completedCrop) {
      toast.error(formatMessage({ id: 'TOAST.ERROR' }));
      return;
    }
    const c = completedCrop || crop;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const pixelRatio = window.devicePixelRatio;

    canvas.width = Math.floor(c.width * scaleX * pixelRatio);
    canvas.height = Math.floor(c.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';

    const cropX = c.x * scaleX;
    const cropY = c.y * scaleY;

    const rotateRads = rotate * TO_RADIANS;
    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    ctx.save();

    ctx.translate(-cropX, -cropY);
    ctx.translate(centerX, centerY);
    ctx.rotate(rotateRads);
    ctx.scale(scale, scale);
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight
    );

    ctx.restore();
    const base64Image = canvas.toDataURL(fileType);

    canvas.toBlob((blob) => {
      if (!blob) return;
      //if size more 5 mb show error
      if (blob.size > 5 * 1024 * 1024) {
        toast.error(formatMessage({ id: 'IMAGE_CROPPER.ERROR' }));
        return;
      }

      const file = new File([blob], fileName);
      onChange(file);
    }, fileType);
    setOutput(base64Image);
    handleModalClose();
  };
  function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }
  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  return (
    <>
      {!isCustomComponent ? (
        <div className='symbol symbol-125px shadow p-1'>
          <img
            src={
              output ||
              toAbsoluteUrl(
                theme === 'dark'
                  ? '/media/svg/files/blank-image-dark.svg'
                  : '/media/svg/files/blank-image.svg'
              )
            }
            alt=''
          />
          <span
            className='symbol-badge btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow start-100'
            onClick={() => {
              imageRef?.current?.click();
            }}>
            <i className='fa-light fa-pencil fs-7' />
          </span>
          {value && (
            <span
              onClick={() => {
                onChange('');
              }}
              className='symbol-badge symbol-badge btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow top-100 start-100'>
              <i className='fa-light fa-x fs-2' />
            </span>
          )}
        </div>
      ) : (
        toggleRender(handleOpenImageCropper)
      )}
      <input ref={imageRef} onChange={onSelectFile} type='file' accept='image/*' hidden />
      <Modal size='xl' centered show={isModalOpen} backdrop='static'>
        <Modal.Header className='text-dark'>
          <Modal.Title>{modalTitle}</Modal.Title>
          <button type='button' className='btn-close' onClick={handleModalClose}></button>
        </Modal.Header>
        <Modal.Body className='d-flex flex-column gap-4 align-items-center py-2'>
          {!!imageSrc && (
            <>
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => {
                  setCrop(percentCrop);
                }}
                onComplete={(c) => {
                  setCompletedCrop(c);
                }}
                aspect={useAspectRation ? aspect : undefined}>
                <img
                  alt='Crop me'
                  src={imageSrc}
                  ref={imgRef}
                  style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
              {multipleAspectRatio && (
                <div className='d-flex justify-content-center'>
                  <div className='btn-group' role='group' aria-label='Basic example'>
                    {aspectRatio.map((ar) => (
                      <button
                        type='button'
                        className='btn btn-secondary'
                        key={ar.label}
                        onClick={() => {
                          setCrop(
                            centerAspectCrop(
                              imgRef.current?.width || 0,
                              imgRef.current?.height || 0,
                              ar.width / ar.height
                            )
                          );
                          onChangeAspectRatio(ar);
                        }}>
                        {ar.label}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className='btn btn-light' onClick={handleModalClose}>
            <FormattedMessage id='FORM.CANCEL' />
          </div>
          <div className='btn btn-primary' onClick={cropImageNow}>
            <FormattedMessage id='FORM.SUBMIT' />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
