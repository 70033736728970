import { FC, Suspense } from 'react';
import { getCSSVariableValue } from '../utils/ts/_utils';
import TopBarProgress from 'react-topbar-progress-indicator';

export const SuspensedView: FC = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary') || '#0288D1';
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};
