import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import store from '../../../Store';
import { getUsers } from '../../effects/settings/UsersEffects';
import { User } from '../../../../app/models/settings/User';

export const readUsers = createAsyncThunk('users/get', async (payload, thunkAPI) => {
  const state = store?.getState();
  try {
    const { data: users } = await getUsers({
      company: state?.auth?.user?.current_company.id as number,
    });
    return users;
  } catch (error: any) {
    thunkAPI.rejectWithValue(error?.response?.data.message || error?.toJSON()?.message || 'Error');
  }
});

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    users: [] as User[],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(readUsers.fulfilled, (state, action: any) => {
      state.users = (action.payload as User[]) || [];
      state.loading = false;
    });
    builder.addCase(readUsers.rejected, (state, action) => {
      state.users = [];
      state.loading = false;
    });
    builder.addCase(readUsers.pending, (state) => {
      state.loading = true;
    });
  },
});
export default usersSlice.reducer;
